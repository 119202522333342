<template>
  <div class="pt-0 mt-0"
    :style="`background: url(${require('@/assets/img/order-settings.jpg')}) top center fixed; background-size: cover; height: 100vh;`">
    <v-layout row justify-center>
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent
        :retain-focus="true">
        <v-card class="card-shadow border-radius-xl">
          <div class="pt-0 mt-0"
            :style="`background: url(${require('@/assets/img/disclaimer-subject.jpg')}) top center; background-size: cover; height: 100vh;`">
            <v-card-actions class="disclaimer-overlay">
              <v-row>
                <v-col cols="2" class="text-sm text-body text-center my-0 py-0">
                  <div>
                    <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E" @click="dialog = false">
                      不同意
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="8" class="text-sm text-body text-center my-0 py-0">
                </v-col>
                <v-col cols="2" class="text-sm text-body text-center my-0 py-0">
                  <div>
                    <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white" color="primary" @click="startProcess">
                      同意
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-layout row justify-center>
      <v-dialog v-model="dialog_wg" fullscreen transition="dialog-bottom-transition" persistent scrollable
        :retain-focus="true">
        <v-card class="card-shadow border-radius-xl">
          <div class="bg-white border-radius-xl px-3 pt-4">
            <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
              {{ rankName }}，{{ orderName }}
            </h5>
            <hr class="horizontal mb-1 font-weight-bold" :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
              style="color:#1A237E;background-color:#1A237E" />
            <v-row no-gutters>
              <v-col v-if="isRandomType" sm="6" cols="12">
                <v-select v-model="random_type" :items="random_types" :value="random_type" :rules="requireRules"
                  color="#e91e63" class="
                                  font-size-input
                                  input-style
                                  placeholder-light
                                  border-radius-md
                                  select-style
                                  mt-0
                                  pt-0
                                  align-center
                                " single-line height="38" @change="selectRandomType">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">隨機選擇</label>
                  </template>
                </v-select>
              </v-col>
              <v-col sm="6" cols="12">
                <v-select v-model="bet_type" :items="bet_types" :value="bet_type" :rules="requireRules" color="#e91e63"
                  class="
                                  font-size-input
                                  input-style
                                  placeholder-light
                                  border-radius-md
                                  select-style
                                  mt-0
                                  pt-0
                                  align-center
                                " single-line height="38" @change="selectBetType">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">選擇下注法</label>
                  </template>
                </v-select>
                <p v-if="bet_type_ao_chu" class="my-0 text-sm text-body font-weight-light">
                  {{ bet_type_ao_chu_desc }}
                </p>
                <p v-if="bet_type_ciang_gong" class="my-0 text-sm text-body font-weight-light">
                  {{ bet_type_ciang_gong_desc }}</p>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="txt_exeucte_num_of_pass" placeholder="1~10" color="#e91e63" type="number"
                  :rules="exeucteNumOfPassRules" required class="font-size-input input-style align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">執行關數</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_1" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒈關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_6" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒍關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_11" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒒關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_2" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒉關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_7" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒎關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_12" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒓關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_3" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒊關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_8" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒏關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_13" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒔關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_4" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒋關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_9" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒐關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_14" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒕關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_5" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒌關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_10" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒑關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_15" placeholder="0" color="#e91e63" :rules="notOrderAmountRules" required
                  type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒖關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="7">
              </v-col>
              <v-col cols="5">
                <v-checkbox v-model="chk_disable" color="#5e72e4" :ripple="false"
                  class="checkbox-custom checkbox-thinner my-0 py-0" hide-details>
                  <template v-slot:label>
                    <span class="text-muted text-body-2 ls-0">不啟動此設定</span>
                  </template>
                </v-checkbox>
              </v-col>

              <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                <div class="d-flex">
                  <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E" @click="dialog_wg = false">
                    取消
                  </v-btn>
                  <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white" color="primary"
                    @click="verifyAndSaveVariableValue(rankName, orderName)">
                    確認
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-container fluid fill-height>
            <v-row align="center" justify="center">
              <v-snackbar :value="alertSuccess" v-model="alertSuccess" min-height="100px" color="#1A237E"
                content-class="text text-h4 mb-4" :timeout="timeout" absolute centered elevation="24">{{
                  repsMessage
                }}</v-snackbar>
              <v-snackbar :value="alertError" v-model="alertError" min-height="100px" color="#1A237E"
                content-class="text text-h4 mb-4" :timeout="timeout" absolute centered elevation="24">{{
                  repsMessage
                }}</v-snackbar>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_wg_batch" fullscreen transition="dialog-bottom-transition" persistent scrollable
        :retain-focus="true">
        <v-card class="card-shadow border-radius-xl">
          <div class="bg-white border-radius-xl px-3 pt-4">
            <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
              大、小、單、雙，批次設定
            </h5>
            <hr class="horizontal mb-1 font-weight-bold" :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
              style="color:#1A237E;background-color:#1A237E" />
            <v-row no-gutters>
              <v-col sm="6" cols="12">
                <v-select v-model="bet_type_batch" :items="bet_types" :value="bet_type_batch" :rules="requireRules"
                  color="#e91e63" class="
                                  font-size-input
                                  input-style
                                  placeholder-light
                                  border-radius-md
                                  select-style
                                  mt-0
                                  pt-0
                                  align-center
                                " single-line height="38" @change="selectBetType">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">選擇下注法</label>
                  </template>
                </v-select>
                <p v-if="bet_type_ao_chu" class="my-0 text-sm text-body font-weight-light">
                  {{ bet_type_ao_chu_desc }}
                </p>
                <p v-if="bet_type_ciang_gong" class="my-0 text-sm text-body font-weight-light">
                  {{ bet_type_ciang_gong_desc }}</p>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="txt_exeucte_num_of_pass_batch" placeholder="1~10" color="#e91e63" type="number"
                  :rules="exeucteNumOfPassRules" required class="font-size-input input-style align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">執行關數</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_1_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒈關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_6_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒍關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_11_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒒關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_2_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒉關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_7_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒎關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_12_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒓關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_3_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒊關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_8_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒏關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_13_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒔關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_4_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒋關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_9_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒐關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_14_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒕關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_5_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒌關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_10_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒑關</label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
              </v-col>
              <v-col cols="3" class="text-sm text-body text-center my-0 py-0">
                <v-text-field v-model="txt_15_batch" placeholder="0" color="#e91e63" :rules="notOrderAmountRules"
                  required type="number" class="font-size-input input-style text-center align-center my-0 py-0">
                  <template slot="prepend">
                    <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">第⒖關</label>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                <div class="d-flex">
                  <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E" @click="dialog_wg_batch = false">
                    取消
                  </v-btn>
                  <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white" color="primary" @click="saveBatchSetting">
                    確認
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-container fluid fill-height>
            <v-row align="center" justify="center">
              <v-snackbar :value="alertSuccess" v-model="alertSuccess" min-height="100px" color="#1A237E"
                content-class="text text-h4 mb-4" :timeout="timeout" absolute centered elevation="24">{{
                  repsMessage
                }}</v-snackbar>
              <v-snackbar :value="alertError" v-model="alertError" min-height="100px" color="#1A237E"
                content-class="text text-h4 mb-4" :timeout="timeout" absolute centered elevation="24">{{
                  repsMessage
                }}</v-snackbar>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-container fluid>
      <v-row>
        <v-col lg="8" md="10" cols="12" class="mx-auto">
          <div class="text-center mt-5">
            <h4 v-if="isShowTitle" class="text-h4 text-typo font-weight-bold">
              新增WG彩票機器人
            </h4>
          </div>
          <v-card class="shadow border-radius-xl mt-2 mx-auto">
            <v-stepper mobile elevation="0" v-model="e1" class="bg-transparent overflow-visible">
              <v-row no-gutters>
                <v-col cols="12" class="mx-auto">
                  <v-stepper-items class="border-radius-xl overflow-hidden">
                    <v-stepper-content step="1" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div>
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            娛樂城選擇
                          </h5>
                          <hr class="horizontal mb-4 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <v-row no-gutters>
                            <v-col sm="6" cols="12">
                              <v-select v-model="betting" :items="bettings" :value="betting" :rules="requireRules"
                                color="#e91e63" class="
                                  font-size-input
                                  input-style
                                  placeholder-light
                                  border-radius-md
                                  select-style
                                  mt-0
                                  align-center
                                " single-line height="38" @change="selectBetting">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">娛樂城</label>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col sm="6" cols="12">
                              <v-text-field v-model="account" placeholder="simonhung" :rules="requireRules"
                                color="#e91e63" required class="font-size-input input-style">
                                <template slot="prepend">
                                  <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">帳號</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col sm="6" cols="12">
                              <v-text-field v-model="password" placeholder="abcd1234" :rules="requireRules"
                                color="#e91e63" required class="font-size-input input-style">
                                <template slot="prepend">
                                  <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">密碼</label>
                                </template></v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="text-end">
                          <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              btn-default
                              text-xs
                              py-5
                              px-6
                              mt-6
                              mb-2
                              me-2
                            " style="background-color: #1A237E;color: white" @click="step1Next">
                            下一步
                          </v-btn>
                        </div>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div>
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            彩種選擇
                          </h5>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <div class="d-flex justify-center align-center mb-10 ml-3 mt-2">
                            <v-row align="center" justify="center" no-gutters>
                              <v-col cols="3">
                                <label class="custom-radio-lf">
                                  <input type="radio" value="lf" v-model="selectedLotteryType">
                                  <span class="custom-radio-lf-icon"></span>
                                </label>
                              </v-col>
                              <v-col cols="3">
                                <label class="custom-radio-1">
                                  <input type="radio" value="1" v-model="selectedLotteryType">
                                  <span class="custom-radio-1-icon"></span>
                                </label>
                              </v-col>
                              <v-col cols="3">
                                <label class="custom-radio-3">
                                  <input type="radio" value="3" v-model="selectedLotteryType">
                                  <span class="custom-radio-3-icon"></span>
                                </label>
                              </v-col>
                              <v-col cols="3">
                                <label class="custom-radio-5">
                                  <input type="radio" value="5" v-model="selectedLotteryType">
                                  <span class="custom-radio-5-icon"></span>
                                </label>
                              </v-col>
                            </v-row>
                          </div>

                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            下注法選擇
                          </h5>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <div class="d-flex justify-center align-center mb-10 ml-3 mt-2">
                            <v-row align="center" justify="center">
                              <v-col cols="6">
                                <label class="custom-radio-basic">
                                  <input type="radio" value="basic" v-model="selectedOrderType">
                                  <span class="custom-radio-basic-icon"></span>
                                </label>
                              </v-col>
                              <v-col cols="6">
                                <label class="custom-radio-10star">
                                  <input type="radio" value="10star" v-model="selectedOrderType">
                                  <span class="custom-radio-10star-icon"></span>
                                </label>
                              </v-col>
                              <v-col cols="6">
                                <label class="custom-radio-follow">
                                  <input type="radio" value="follow" v-model="selectedOrderType">
                                  <span class="custom-radio-follow-icon"></span>
                                </label>
                              </v-col>
                              <v-col cols="6">
                                <label class="custom-radio-dev">
                                  <input type="radio" value="dev" v-model="selectedOrderType">
                                  <span class="custom-radio-dev-icon"></span>
                                </label>
                              </v-col>
                              <v-col cols="6">
                                <label class="custom-radio-dev">
                                  <input type="radio" value="dev" v-model="selectedOrderType">
                                  <span class="custom-radio-dev-icon"></span>
                                </label>
                              </v-col>
                              <v-col cols="6">
                                <label class="custom-radio-dev">
                                  <input type="radio" value="dev" v-model="selectedOrderType">
                                  <span class="custom-radio-dev-icon"></span>
                                </label>
                              </v-col>
                            </v-row>
                          </div>

                          <v-row no-gutters>
                            <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                              <div class="d-flex">
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E" @click="e1 = 1">
                                  上一步
                                </v-btn>
                                <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white" @click="step2Next">
                                  下一步
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="3" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div class="mb-4">
                          <v-row no-gutters>
                            <v-col cols="3" class="d-flex justify-center align-center my-1">
                            </v-col>
                            <v-col cols="6" class="d-flex justify-center align-end mb-0">
                              <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                                基礎兩面盤
                              </h5>
                            </v-col>
                            <v-col cols="3" class="d-flex justify-end align-end mb-1">
                              <v-btn :ripple="false" :elevation="0" class="
                              px-0
                              py-0
                            " style="background-color: #1A237E;color: white; font-size: 12px; height: 24px;"
                                @click="cancelBatchSetting(4)">
                                批次設定
                              </v-btn>
                            </v-col>
                          </v-row>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <v-row v-for="(item, index) in items" :key="index" no-gutters>
                            <v-col cols="2" class="d-flex justify-center align-center my-1">
                              <label class="
                              font-weight-bold
                              btn-outline-secondary
                              align-center
                              justify-center
                              rounded-lg
                            " style="background-color: white;color: #1A237E; height: 23.22px; font-size: 14px;">
                                {{ item.name }}
                              </label>
                            </v-col>
                            <v-col cols="2" class="my-1">
                              <v-img v-if="!item.isSetting[0]" class="fill-width fill-height"
                                style="width: 40px; height: 23.22px;" src="../../../assets/img/common/wg/big-off.png"
                                @click="wgSetting(item.name, '大')">
                              </v-img>
                              <v-img v-if="item.isSetting[0]" class="fill-width fill-height"
                                style="width: 40px; height: 23.22px;" src="../../../assets/img/common/wg/big-on.png"
                                @click="wgSetting(item.name, '大')">
                              </v-img>
                            </v-col>
                            <v-col cols="2" class="my-1">
                              <v-img v-if="!item.isSetting[1]" class="fill-width fill-height"
                                style="width: 40px; height: 23.22px;" src="../../../assets/img/common/wg/small-off.png"
                                @click="wgSetting(item.name, '小')">
                              </v-img>
                              <v-img v-if="item.isSetting[1]" class="fill-width fill-height"
                                style="width: 40px; height: 23.22px;" src="../../../assets/img/common/wg/small-on.png"
                                @click="wgSetting(item.name, '小')">
                              </v-img>
                            </v-col>
                            <v-col cols="2" class="my-1">
                              <v-img v-if="!item.isSetting[2]" class="fill-width fill-height"
                                style="width: 40px; height: 23.22px;" src="../../../assets/img/common/wg/odd-off.png"
                                @click="wgSetting(item.name, '單')">
                              </v-img>
                              <v-img v-if="item.isSetting[2]" class="fill-width fill-height"
                                style="width: 40px; height: 23.22px;" src="../../../assets/img/common/wg/odd-on.png"
                                @click="wgSetting(item.name, '單')">
                              </v-img>
                            </v-col>
                            <v-col cols="2" class="my-1">
                              <v-img v-if="!item.isSetting[3]" class="fill-width fill-height"
                                style="width: 40px; height: 23.22px;" src="../../../assets/img/common/wg/even-off.png"
                                @click="wgSetting(item.name, '雙')">
                              </v-img>
                              <v-img v-if="item.isSetting[3]" class="fill-width fill-height"
                                style="width: 40px; height: 23.22px;" src="../../../assets/img/common/wg/even-on.png"
                                @click="wgSetting(item.name, '雙')">
                              </v-img>
                            </v-col>
                            <v-col cols="2" class="my-1">
                              <v-img v-if="!item.isSetting[4]" src="../../../assets/img/common/wg/random-off.png"
                                @click="wgSetting(item.name, '隨機')">
                              </v-img>
                              <v-img v-if="item.isSetting[4]" src="../../../assets/img/common/wg/random-on.png"
                                @click="wgSetting(item.name, '隨機')">
                              </v-img>
                            </v-col>
                          </v-row>
                        </div>
                        <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                          贏停損停
                        </h5>
                        <hr class="horizontal mb-1 font-weight-bold" :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                          style="color:#1A237E;background-color:#1A237E" />
                        <p class="mb-0 text-sm text-body font-weight-light">
                          當啟動後，將開始累積輸贏金額，達到或超過下列任一設定值，則機器人將停止自動下注。
                        </p>
                        <v-row no-gutters>
                          <v-col cols="5" class="text-sm text-body text-center my-0 py-0 mb-2">
                            <v-text-field v-model="stop_loss" placeholder="-9999999" color="#e91e63" type="number"
                              :rules="stopLossRules" required
                              class="font-size-input input-style text-center align-center my-0 py-0">
                              <template slot="prepend">
                                <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">損停</label>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="1" class="text-sm text-body text-center my-0 py-0 mb-2">
                          </v-col>
                          <v-col cols="5" class="text-sm text-body text-center my-0 py-0 mb-2">
                            <v-text-field v-model="stop_win" placeholder="9999999" color="#e91e63" :rules="stopWinRules"
                              type="number" required
                              class="font-size-input input-style text-center align-center my-0 py-0">
                              <template slot="prepend">
                                <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">贏停</label>
                              </template>
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                            <div class="d-flex">
                              <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E;" @click="e1 = 2">
                                上一步
                              </v-btn>
                              <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white;" color="primary" @click="openDisclaimer">
                                啟動
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="4" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div class="mb-4">
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            基礎兩面盤、批次設定
                          </h5>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <v-row v-for="(item, index) in items_batch" :key="index" no-gutters>
                            <v-col cols="2" class="d-flex justify-center align-center my-1">
                              <label class="
                              font-weight-bold
                              btn-outline-secondary
                              align-center
                              justify-center
                              rounded-lg
                            " style="background-color: white;color: #1A237E; height: 23.22px; font-size: 14px;">
                                {{ item.name }}
                              </label>
                            </v-col>
                            <v-col cols="2" class="my-1">
                              <v-checkbox v-model="item.isSetting[0]" color="#5e72e4" :ripple="true"
                                class="checkbox-custom checkbox-thinner my-0 py-0" hide-details>
                                <template v-slot:label>
                                  <span class="text-muted text-body-2 ls-0">大</span>
                                </template>
                              </v-checkbox>
                            </v-col>
                            <v-col cols="2" class="my-1">
                              <v-checkbox v-model="item.isSetting[1]" color="#5e72e4" :ripple="true"
                                class="checkbox-custom checkbox-thinner my-0 py-0" hide-details>
                                <template v-slot:label>
                                  <span class="text-muted text-body-2 ls-0">小</span>
                                </template>
                              </v-checkbox>
                            </v-col>
                            <v-col cols="2" class="my-1">
                              <v-checkbox v-model="item.isSetting[2]" color="#5e72e4" :ripple="true"
                                class="checkbox-custom checkbox-thinner my-0 py-0" hide-details>
                                <template v-slot:label>
                                  <span class="text-muted text-body-2 ls-0">單</span>
                                </template>
                              </v-checkbox>
                            </v-col>
                            <v-col cols="2" class="my-1">
                              <v-checkbox v-model="item.isSetting[3]" color="#5e72e4" :ripple="true"
                                class="checkbox-custom checkbox-thinner my-0 py-0" hide-details>
                                <template v-slot:label>
                                  <span class="text-muted text-body-2 ls-0">雙</span>
                                </template>
                              </v-checkbox>
                            </v-col>
                            <v-col cols="2" class="my-1">
                            </v-col>
                          </v-row>
                        </div>
                        <v-row no-gutters>
                          <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                            <div class="d-flex">
                              <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E;" @click="cancelBatchSetting(3)">
                                取消
                              </v-btn>
                              <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white;" color="primary" @click="batchSetting">
                                設定
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="5" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div class="mb-4">
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            十星下注法
                          </h5>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <v-row no-gutters>
                            <v-col cols="12" class="d-flex justify-center align-center">
                              <p class="mt-2 mb-0 font-weight-bold text-xs" style="color: #1A237E;">
                                【過關判斷策略】
                              </p>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center align-center">
                              <p class="mb-0 font-weight-bold text-xs" style="color: #1A237E;">
                                中0顆：未過關，繼續在當前階段重新選擇
                              </p>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center align-center">
                              <p class="mb-0 font-weight-bold text-xs" style="color: #1A237E;">
                                中1顆：平手，保持在當前階段繼續下注
                              </p>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center align-center">
                              <p class="mb-0 font-weight-bold text-xs" style="color: #1A237E;">
                                中2顆以上：過關，回到第一階段重新開始
                              </p>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center align-center">
                              <v-btn :ripple="false" :elevation="0" class="
                              mt-3
                              mb-2
                              me-2
                              align-center
                            " style="background-color: #1A237E;color: white; height: 60px; width: 160px; font-size: 18px;"
                                color="primary" @click="selectMethodType10star('自選')">
                                自選
                              </v-btn>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center align-center">
                              <v-btn :ripple="false" :elevation="0" class="
                              mb-2
                              me-2
                              align-center
                            " style="background-color: #1A237E;color: white; height: 60px; width: 160px; font-size: 18px;"
                                color="primary" @click="selectMethodType10star('過關隨機')">
                                過關隨機
                              </v-btn>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center align-center">
                              <p class="mb-0 font-weight-bold text-xs" style="color: #1A237E;">
                                首先隨機抽出投注選項
                              </p>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center align-center">
                              <p class="mb-0 font-weight-bold text-xs" style="color: #1A237E;">
                                ●過關：進行隨機重抽
                              </p>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center align-center">
                              <p class="mb-0 font-weight-bold text-xs" style="color: #1A237E;">
                                ●未過關：繼續使用原始抽出號碼
                              </p>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center align-center">
                              <v-btn :ripple="false" :elevation="0" class="
                              mt-6
                              mb-2
                              me-2
                              align-center
                            " style="background-color: #1A237E;color: white; height: 60px; width: 160px; font-size: 18px;"
                                color="primary" @click="selectMethodType10star('每關隨機')">
                                每關隨機
                              </v-btn>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center align-center">
                              <p class="mb-0 font-weight-bold text-xs" style="color: #1A237E;">
                                每關隨機重抽
                              </p>
                            </v-col>
                          </v-row>
                        </div>
                        <v-row no-gutters>
                          <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                            <div class="d-flex">
                              <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E;" @click="e1 = 2">
                                上一步
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="6" class="bg-white border-radius-xl px-6 pt-4">
                      <v-card>
                        <div class="mb-1">
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            十星下注法【{{ method_type_10star }}】
                          </h5>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <v-row no-gutters>
                            <v-col cols="12">
                              <v-text-field v-model="txt_exeucte_num_of_pass_10star" placeholder="1~10" color="#e91e63"
                                type="number" :rules="exeucteNumOfPass10starRules" required
                                class="font-size-input input-style align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">執行關數</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_1_10star" placeholder="0" color="#e91e63"
                                :rules="notOrderAmount10starRules" required type="number"
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第一關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_6_10star" placeholder="0" color="#e91e63"
                                :rules="notOrderAmount10starRules" required type="number"
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第六關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_2_10star" placeholder="0" color="#e91e63"
                                :rules="notOrderAmount10starRules" required type="number"
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第二關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_7_10star" placeholder="0" color="#e91e63"
                                :rules="notOrderAmount10starRules" required type="number"
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第七關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_3_10star" placeholder="0" color="#e91e63"
                                :rules="notOrderAmount10starRules" required type="number"
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第三關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_8_10star" placeholder="0" color="#e91e63"
                                :rules="notOrderAmount10starRules" required type="number"
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第八關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_4_10star" placeholder="0" color="#e91e63"
                                :rules="notOrderAmount10starRules" required type="number"
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第四關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_9_10star" placeholder="0" color="#e91e63"
                                :rules="notOrderAmount10starRules" required type="number"
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第九關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_5_10star" placeholder="0" color="#e91e63"
                                :rules="notOrderAmount10starRules" required type="number"
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第五關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="1" class="text-sm text-body text-center my-0 py-0">
                            </v-col>
                            <v-col cols="5" class="text-sm text-body text-center my-0 py-0">
                              <v-text-field v-model="txt_10_10star" placeholder="0" color="#e91e63"
                                :rules="notOrderAmount10starRules" required type="number"
                                class="font-size-input input-style text-center align-center my-0 py-0">
                                <template slot="prepend">
                                  <label
                                    class="text-sm text-typo text-no-wrap font-weight-bold align-center">第十關</label>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center align-center ma-0">
                              <p class="ma-0 font-weight-bold text-xs" style="color: #1A237E;">
                                關數金額請輸入單一顆號碼的金額，此下注法基本需要十顆號碼金額，系統將會後續計算。
                              </p>
                            </v-col>
                          </v-row>
                        </div>
                        <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                          贏停損停
                        </h5>
                        <hr class="horizontal mb-1 font-weight-bold" :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                          style="color:#1A237E;background-color:#1A237E" />
                        <p class="mb-0 text-sm text-body font-weight-light">
                          當啟動後，將開始累積輸贏金額，達到或超過下列任一設定值，則機器人將停止自動下注。
                        </p>
                        <v-row no-gutters>
                          <v-col cols="5" class="text-sm text-body text-center my-0 py-0 mb-2">
                            <v-text-field v-model="stop_loss" placeholder="-9999999" color="#e91e63" type="number"
                              :rules="stopLossRules" required
                              class="font-size-input input-style text-center align-center my-0 py-0">
                              <template slot="prepend">
                                <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">損停</label>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="1" class="text-sm text-body text-center my-0 py-0 mb-2">
                          </v-col>
                          <v-col cols="5" class="text-sm text-body text-center my-0 py-0 mb-2">
                            <v-text-field v-model="stop_win" placeholder="9999999" color="#e91e63" :rules="stopWinRules"
                              type="number" required
                              class="font-size-input input-style text-center align-center my-0 py-0">
                              <template slot="prepend">
                                <label class="text-sm text-typo text-no-wrap font-weight-bold align-center">贏停</label>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                            <div class="d-flex">
                              <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E;" @click="e1 = 5">
                                上一步
                              </v-btn>
                              <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white;" color="primary"
                                @click="openDisclaimer10star">
                                啟動
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-stepper-content>


                    <v-stepper-content step="7" class="bg-white border-radius-xl px-1 pt-4">
                      <v-card>
                        <div class="mb-4">
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0 text-center">
                            十星下注法【{{ method_type_10star }}】
                          </h5>
                          <hr class="horizontal mb-1 font-weight-bold"
                            :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                            style="color:#1A237E;background-color:#1A237E" />
                          <v-row v-for="(item, index) in items_10star" :key="index" v-bind="`${item.src}`" no-gutters>
                            <v-col cols="2" class="d-flex justify-center align-center my-1">
                              <label class="
                              font-weight-bold
                              btn-outline-secondary
                              align-center
                              justify-center
                              rounded-lg
                            " style="background-color: white;color: #1A237E; height: 23.22px; font-size: 14px;">
                                {{ item.name }}
                              </label>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-center align-center pa-1">
                              <v-img v-if="!item.isSetting[0]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/purple/1.png"
                                @click="wgNumberSelect(item.name, 1)">
                              </v-img>
                              <v-img v-if="item.isSetting[0]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/red/1.png"
                                @click="wgNumberSelect(item.name, 1)">
                              </v-img>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-center align-center pa-1">
                              <v-img v-if="!item.isSetting[1]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/purple/2.png"
                                @click="wgNumberSelect(item.name, 2)">
                              </v-img>
                              <v-img v-if="item.isSetting[1]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/red/2.png"
                                @click="wgNumberSelect(item.name, 2)">
                              </v-img>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-center align-center pa-1">
                              <v-img v-if="!item.isSetting[2]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/purple/3.png"
                                @click="wgNumberSelect(item.name, 3)">
                              </v-img>
                              <v-img v-if="item.isSetting[2]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/red/3.png"
                                @click="wgNumberSelect(item.name, 3)">
                              </v-img>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-center align-center pa-1">
                              <v-img v-if="!item.isSetting[3]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/purple/4.png"
                                @click="wgNumberSelect(item.name, 4)">
                              </v-img>
                              <v-img v-if="item.isSetting[3]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/red/4.png"
                                @click="wgNumberSelect(item.name, 4)">
                              </v-img>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-center align-center pa-1">
                              <v-img v-if="!item.isSetting[4]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/purple/5.png"
                                @click="wgNumberSelect(item.name, 5)">
                              </v-img>
                              <v-img v-if="item.isSetting[4]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/red/5.png"
                                @click="wgNumberSelect(item.name, 5)">
                              </v-img>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-center align-center pa-1">
                              <v-img v-if="!item.isSetting[5]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/purple/6.png"
                                @click="wgNumberSelect(item.name, 6)">
                              </v-img>
                              <v-img v-if="item.isSetting[5]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/red/6.png"
                                @click="wgNumberSelect(item.name, 6)">
                              </v-img>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-center align-center pa-1">
                              <v-img v-if="!item.isSetting[6]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/purple/7.png"
                                @click="wgNumberSelect(item.name, 7)">
                              </v-img>
                              <v-img v-if="item.isSetting[6]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/red/7.png"
                                @click="wgNumberSelect(item.name, 7)">
                              </v-img>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-center align-center pa-1">
                              <v-img v-if="!item.isSetting[7]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/purple/8.png"
                                @click="wgNumberSelect(item.name, 8)">
                              </v-img>
                              <v-img v-if="item.isSetting[7]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/red/8.png"
                                @click="wgNumberSelect(item.name, 8)">
                              </v-img>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-center align-center pa-1">
                              <v-img v-if="!item.isSetting[8]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/purple/9.png"
                                @click="wgNumberSelect(item.name, 9)">
                              </v-img>
                              <v-img v-if="item.isSetting[8]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/red/9.png"
                                @click="wgNumberSelect(item.name, 9)">
                              </v-img>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-center align-center pa-1">
                              <v-img v-if="!item.isSetting[9]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;"
                                src="../../../assets/img/common/wg/cars/purple/10.png"
                                @click="wgNumberSelect(item.name, 10)">
                              </v-img>
                              <v-img v-if="item.isSetting[9]" class="fill-width fill-height rounded-circle"
                                style="width: 100%; height: 100%;" src="../../../assets/img/common/wg/cars/red/10.png"
                                @click="wgNumberSelect(item.name, 10)">
                              </v-img>
                            </v-col>
                          </v-row>
                        </div>
                        <v-row no-gutters>
                          <v-col cols="12" class="d-flex justify-center align-center mt-1">
                            <p class="mt-2 mb-0 font-weight-bold text-xs" style="color: #1A237E;">
                              【自選號碼規則】
                            </p>
                          </v-col>
                          <v-col cols="12" class="d-flex justify-center align-center mb-1">
                            <p class="mt-2 mb-0 font-weight-bold text-xs" style="color: #1A237E;">
                              各個名次只能挑選一個號碼，且各個名次挑選的號碼不得重複
                            </p>
                          </v-col>
                          <v-col cols="12" class="text-sm text-body text-center my-0 py-0">
                            <div class="d-flex">
                              <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              placeholder-lighter
                              text-xs
                              shadow-0
                              btn-outline-secondary
                              px-6
                              align-center
                            " style="background-color: white;color: #1A237E;" @click="e1 = 5">
                                上一步
                              </v-btn>
                              <v-btn :ripple="false" :elevation="0" class="
                              font-weight-bold
                              text-xs
                              btn-default
                              px-6
                              mb-2
                              me-2
                              ms-auto
                              align-center
                            " style="background-color: #1A237E;color: white;" color="primary"
                                @click="wgNumberSelectCheck">
                                下一步
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-stepper-content>

                  </v-stepper-items>
                </v-col>
              </v-row>
            </v-stepper>
          </v-card>
        </v-col>
      </v-row>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-progress-circular v-if="isShowProgress" indeterminate color="#5E35B1" :size="70" :width="7"
            class="progress-overlay"></v-progress-circular>
          <v-snackbar :value="alertSuccess" v-model="alertSuccess" min-height="100px" color="#1A237E"
            content-class="text text-h4 mb-4" :timeout="timeout" absolute centered elevation="24">{{
              repsMessage
            }}</v-snackbar>
          <v-snackbar :value="alertError" v-model="alertError" min-height="100px" color="#1A237E"
            content-class="text text-h4 mb-4" :timeout="timeout" absolute centered elevation="24">{{
              repsMessage
            }}</v-snackbar>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import HtmlEditor from "./Widgets/HtmlEditor.vue";
import Dropzone from "./Widgets/Dropzone.vue";
import Cookie from "js-cookie";
import { gameSettingData, gameData } from "@/apis/gameService";
import { userData } from "@/apis/userService";
import { casinoData } from "@/apis/casinoService";

Vue.use(VueSweetalert2);

function checkBetTypeNotNull(betType) {
  var errorBetTypeCount = 0
  if ((betType === 'null') || (typeof (betType) === "undefined") || (betType === "")) {
    errorBetTypeCount = errorBetTypeCount + 1
  }
  return errorBetTypeCount
}

function checkAnyAmount(amountList) {
  var errorAmountCount = 0
  let amountListLen = amountList.length
  for (var i = 0; i < amountListLen; i++) {
    let intValue = parseInt(amountList[i]);

    if (intValue === 1) {
      errorAmountCount = errorAmountCount + 1
    }
  }
  return errorAmountCount
}

function checkAnyAmount10star(amountList) {
  var errorAmountCount = 0
  let amountListLen = amountList.length
  for (var i = 0; i < amountListLen; i++) {
    let intValue = parseInt(amountList[i]);

    if (intValue >= 1 && intValue <= 19) {
      errorAmountCount = errorAmountCount + 1
    }
  }
  return errorAmountCount
}

function checkSettingTrueCount(itemsBatch) {
  var trueCount = 0
  for (var i = 0; i < itemsBatch.length; i++) {
    for (var j = 0; j < itemsBatch[i].isSetting.length; j++) {
      if (itemsBatch[i].isSetting[j] === true) {
        trueCount = trueCount + 1
      }
    }
  }
  return trueCount
}


function checkAnyAmountIsNotZero(amountList, exeucteNumOfPass) {
  var errorZeroAmountCount = 0
  var zeroAmountCount = 0
  //let amountListLen = amountList.length
  let amountListLen = parseInt(exeucteNumOfPass ? exeucteNumOfPass : 1);
  for (var i = 0; i < amountListLen; i++) {
    let intValue = parseInt(amountList[i]);

    if (intValue === 0) {
      zeroAmountCount = zeroAmountCount + 1
    }
  }

  if (zeroAmountCount === amountListLen) {
    errorZeroAmountCount = 1
  }
  return errorZeroAmountCount
}

function checkAnyOrderTactic(orderTacticList) {
  var errorOrderTactic = false
  var orderTacticCount = 0
  let orderTacticListLen = orderTacticList.length
  for (var i = 0; i < orderTacticListLen; i++) {
    if (orderTacticList[i] === 1 || orderTacticList[i] === true) {
      orderTacticCount = orderTacticCount + 1
    }
  }
  if (orderTacticCount === orderTacticListLen) {
    errorOrderTactic = true
  }
  return errorOrderTactic
}

function checkOrderTacticCount(orderTacticList) {
  var orderTacticCount = 0
  let orderTacticListLen = orderTacticList.length
  for (var i = 0; i < orderTacticListLen; i++) {
    if (orderTacticList[i] === 0 || orderTacticList[i] === false) {
      orderTacticCount = orderTacticCount + 1
    }
  }
  return orderTacticCount
}

function checkExeucteNumOfPass(amountList, exeucteNumOfPass) {
  var errorCount = 0
  let intValue = parseInt(exeucteNumOfPass ? exeucteNumOfPass : 1);
  for (var i = 0; i < intValue; i++) {
    let amount = amountList[i]
    if ((amount === 'null') || (typeof (amount) === "undefined") || (amount === "")) {
      errorCount = errorCount + 1
    }
  }
  return errorCount
}

export default {
  components: {
    HtmlEditor,
    Dropzone,
  },
  data() {
    return {
      requireRules: [
        value => { //rule 1
          //console.log("requireRules")
          //console.log(value)
          if (value) return true

          return '必填欄位'
          /*
          if ((value === 'null') || (typeof (value) === "undefined") || (value === "")) {
            return '必填欄位'
          } else {
            return true
          }*/
        },
      ],
      notOrderAmountRules: [
        value => { //rule 2
          //console.log("notOrderAmountRules")
          //console.log(value)
          var intValue = parseInt(value ? value : 0)
          if (intValue === 1) {
            return '不能輸入1，該遊戲最小下注金額為2'
          }
        },
      ],
      notOrderAmount10starRules: [
        value => { //rule 2
          //console.log("notOrderAmountRules")
          //console.log(value)
          var intValue = parseInt(value ? value : 0)
          if (intValue >= 1 && intValue <= 19) {
            return '不能輸入1~19，該遊戲最小下注金額為20'
          }
        },
      ],
      exeucteNumOfPassRules: [
        value => { //rule 1
          //console.log("exeucteNumOfPassRules1")
          //console.log(value)
          if (value) return true

          return '必填欄位'
        },
        value => { //rule 2
          //console.log("exeucteNumOfPassRules2")
          //console.log(value)
          var intValue = parseInt(value ? value : 0)
          if (intValue < 1 || intValue > 15) {
            return '只能輸入1~15'
          }
        },
      ],
      exeucteNumOfPass10starRules: [
        value => { //rule 1
          //console.log("exeucteNumOfPassRules1")
          //console.log(value)
          if (value) return true

          return '必填欄位'
        },
        value => { //rule 2
          //console.log("exeucteNumOfPassRules2")
          //console.log(value)
          var intValue = parseInt(value ? value : 0)
          if (intValue < 1 || intValue > 10) {
            return '只能輸入1~10'
          }
        },
      ],
      stopWinRules: [
        value => { //rule 1
          //console.log("stopWinRules1")
          //console.log(value)
          if (value) return true

          return '必填欄位'
        },
        value => { //rule 2
          //console.log("stopWinRules2")
          //console.log(value)
          var intValue = parseInt(value ? value : 0)
          if (intValue <= 0) {
            return '不能小於等於 0'
          }
        },
      ],
      stopLossRules: [
        value => { //rule 1
          //console.log("stopLossRules1")
          //console.log(value)
          if (value) return true

          return '必填欄位'
        },
        value => { //rule 2
          //console.log("stopLossRules2")
          //console.log(value)
          var intValue = parseInt(value ? value : 0)
          if (intValue >= 0) {
            return '不能大於等於 0'
          }
        },
      ],
      e1: 1,
      userId: 1,
      bettings: [],
      bet_types: ["凹注", "強攻"],
      betting: "",
      account: "",
      password: "",
      bet_type: "凹注",
      bet_type_batch: "凹注",
      bet_type_odd_1: "凹注",
      bet_type_even_1: "凹注",
      bet_type_big_1: "凹注",
      bet_type_small_1: "凹注",
      bet_type_random_1: "凹注",
      bet_type_odd_2: "凹注",
      bet_type_even_2: "凹注",
      bet_type_big_2: "凹注",
      bet_type_small_2: "凹注",
      bet_type_random_2: "凹注",
      bet_type_odd_3: "凹注",
      bet_type_even_3: "凹注",
      bet_type_big_3: "凹注",
      bet_type_small_3: "凹注",
      bet_type_random_3: "凹注",
      bet_type_odd_4: "凹注",
      bet_type_even_4: "凹注",
      bet_type_big_4: "凹注",
      bet_type_small_4: "凹注",
      bet_type_random_4: "凹注",
      bet_type_odd_5: "凹注",
      bet_type_even_5: "凹注",
      bet_type_big_5: "凹注",
      bet_type_small_5: "凹注",
      bet_type_random_5: "凹注",
      bet_type_odd_6: "凹注",
      bet_type_even_6: "凹注",
      bet_type_big_6: "凹注",
      bet_type_small_6: "凹注",
      bet_type_random_6: "凹注",
      bet_type_odd_7: "凹注",
      bet_type_even_7: "凹注",
      bet_type_big_7: "凹注",
      bet_type_small_7: "凹注",
      bet_type_random_7: "凹注",
      bet_type_odd_8: "凹注",
      bet_type_even_8: "凹注",
      bet_type_big_8: "凹注",
      bet_type_small_8: "凹注",
      bet_type_random_8: "凹注",
      bet_type_odd_9: "凹注",
      bet_type_even_9: "凹注",
      bet_type_big_9: "凹注",
      bet_type_small_9: "凹注",
      bet_type_random_9: "凹注",
      bet_type_odd_10: "凹注",
      bet_type_even_10: "凹注",
      bet_type_big_10: "凹注",
      bet_type_small_10: "凹注",
      bet_type_random_10: "凹注",
      random_types: ["單、雙，二選一", "大、小，二選一", "單、雙、大、小，四選一"],
      random_type: "",
      chk_odd_disable_1: 1,
      chk_even_disable_1: 1,
      chk_big_disable_1: 1,
      chk_small_disable_1: 1,
      chk_random_disable_1: 1,
      chk_odd_disable_2: 1,
      chk_even_disable_2: 1,
      chk_big_disable_2: 1,
      chk_small_disable_2: 1,
      chk_random_disable_2: 1,
      chk_odd_disable_3: 1,
      chk_even_disable_3: 1,
      chk_big_disable_3: 1,
      chk_small_disable_3: 1,
      chk_random_disable_3: 1,
      chk_odd_disable_4: 1,
      chk_even_disable_4: 1,
      chk_big_disable_4: 1,
      chk_small_disable_4: 1,
      chk_random_disable_4: 1,
      chk_odd_disable_5: 1,
      chk_even_disable_5: 1,
      chk_big_disable_5: 1,
      chk_small_disable_5: 1,
      chk_random_disable_5: 1,
      chk_odd_disable_6: 1,
      chk_even_disable_6: 1,
      chk_big_disable_6: 1,
      chk_small_disable_6: 1,
      chk_random_disable_6: 1,
      chk_odd_disable_7: 1,
      chk_even_disable_7: 1,
      chk_big_disable_7: 1,
      chk_small_disable_7: 1,
      chk_random_disable_7: 1,
      chk_odd_disable_8: 1,
      chk_even_disable_8: 1,
      chk_big_disable_8: 1,
      chk_small_disable_8: 1,
      chk_random_disable_8: 1,
      chk_odd_disable_9: 1,
      chk_even_disable_9: 1,
      chk_big_disable_9: 1,
      chk_small_disable_9: 1,
      chk_random_disable_9: 1,
      chk_odd_disable_10: 1,
      chk_even_disable_10: 1,
      chk_big_disable_10: 1,
      chk_small_disable_10: 1,
      chk_random_disable_10: 1,
      txt_odd_exeucte_num_of_pass_1: 1,
      txt_even_exeucte_num_of_pass_1: 1,
      txt_big_exeucte_num_of_pass_1: 1,
      txt_small_exeucte_num_of_pass_1: 1,
      txt_random_exeucte_num_of_pass_1: 1,
      txt_odd_exeucte_num_of_pass_2: 1,
      txt_even_exeucte_num_of_pass_2: 1,
      txt_big_exeucte_num_of_pass_2: 1,
      txt_small_exeucte_num_of_pass_2: 1,
      txt_random_exeucte_num_of_pass_2: 1,
      txt_odd_exeucte_num_of_pass_3: 1,
      txt_even_exeucte_num_of_pass_3: 1,
      txt_big_exeucte_num_of_pass_3: 1,
      txt_small_exeucte_num_of_pass_3: 1,
      txt_random_exeucte_num_of_pass_3: 1,
      txt_odd_exeucte_num_of_pass_4: 1,
      txt_even_exeucte_num_of_pass_4: 1,
      txt_big_exeucte_num_of_pass_4: 1,
      txt_small_exeucte_num_of_pass_4: 1,
      txt_random_exeucte_num_of_pass_4: 1,
      txt_odd_exeucte_num_of_pass_5: 1,
      txt_even_exeucte_num_of_pass_5: 1,
      txt_big_exeucte_num_of_pass_5: 1,
      txt_small_exeucte_num_of_pass_5: 1,
      txt_random_exeucte_num_of_pass_5: 1,
      txt_odd_exeucte_num_of_pass_6: 1,
      txt_even_exeucte_num_of_pass_6: 1,
      txt_big_exeucte_num_of_pass_6: 1,
      txt_small_exeucte_num_of_pass_6: 1,
      txt_random_exeucte_num_of_pass_6: 1,
      txt_odd_exeucte_num_of_pass_7: 1,
      txt_even_exeucte_num_of_pass_7: 1,
      txt_big_exeucte_num_of_pass_7: 1,
      txt_small_exeucte_num_of_pass_7: 1,
      txt_random_exeucte_num_of_pass_7: 1,
      txt_odd_exeucte_num_of_pass_8: 1,
      txt_even_exeucte_num_of_pass_8: 1,
      txt_big_exeucte_num_of_pass_8: 1,
      txt_small_exeucte_num_of_pass_8: 1,
      txt_random_exeucte_num_of_pass_8: 1,
      txt_odd_exeucte_num_of_pass_9: 1,
      txt_even_exeucte_num_of_pass_9: 1,
      txt_big_exeucte_num_of_pass_9: 1,
      txt_small_exeucte_num_of_pass_9: 1,
      txt_random_exeucte_num_of_pass_9: 1,
      txt_odd_exeucte_num_of_pass_10: 1,
      txt_even_exeucte_num_of_pass_10: 1,
      txt_big_exeucte_num_of_pass_10: 1,
      txt_small_exeucte_num_of_pass_10: 1,
      txt_random_exeucte_num_of_pass_10: 1,
      txt_odd_1_1: 0,
      txt_odd_1_2: 0,
      txt_odd_1_3: 0,
      txt_odd_1_4: 0,
      txt_odd_1_5: 0,
      txt_odd_1_6: 0,
      txt_odd_1_7: 0,
      txt_odd_1_8: 0,
      txt_odd_1_9: 0,
      txt_odd_1_10: 0,
      txt_odd_2_1: 0,
      txt_odd_2_2: 0,
      txt_odd_2_3: 0,
      txt_odd_2_4: 0,
      txt_odd_2_5: 0,
      txt_odd_2_6: 0,
      txt_odd_2_7: 0,
      txt_odd_2_8: 0,
      txt_odd_2_9: 0,
      txt_odd_2_10: 0,
      txt_odd_3_1: 0,
      txt_odd_3_2: 0,
      txt_odd_3_3: 0,
      txt_odd_3_4: 0,
      txt_odd_3_5: 0,
      txt_odd_3_6: 0,
      txt_odd_3_7: 0,
      txt_odd_3_8: 0,
      txt_odd_3_9: 0,
      txt_odd_3_10: 0,
      txt_odd_4_1: 0,
      txt_odd_4_2: 0,
      txt_odd_4_3: 0,
      txt_odd_4_4: 0,
      txt_odd_4_5: 0,
      txt_odd_4_6: 0,
      txt_odd_4_7: 0,
      txt_odd_4_8: 0,
      txt_odd_4_9: 0,
      txt_odd_4_10: 0,
      txt_odd_5_1: 0,
      txt_odd_5_2: 0,
      txt_odd_5_3: 0,
      txt_odd_5_4: 0,
      txt_odd_5_5: 0,
      txt_odd_5_6: 0,
      txt_odd_5_7: 0,
      txt_odd_5_8: 0,
      txt_odd_5_9: 0,
      txt_odd_5_10: 0,
      txt_odd_6_1: 0,
      txt_odd_6_2: 0,
      txt_odd_6_3: 0,
      txt_odd_6_4: 0,
      txt_odd_6_5: 0,
      txt_odd_6_6: 0,
      txt_odd_6_7: 0,
      txt_odd_6_8: 0,
      txt_odd_6_9: 0,
      txt_odd_6_10: 0,
      txt_odd_7_1: 0,
      txt_odd_7_2: 0,
      txt_odd_7_3: 0,
      txt_odd_7_4: 0,
      txt_odd_7_5: 0,
      txt_odd_7_6: 0,
      txt_odd_7_7: 0,
      txt_odd_7_8: 0,
      txt_odd_7_9: 0,
      txt_odd_7_10: 0,
      txt_odd_8_1: 0,
      txt_odd_8_2: 0,
      txt_odd_8_3: 0,
      txt_odd_8_4: 0,
      txt_odd_8_5: 0,
      txt_odd_8_6: 0,
      txt_odd_8_7: 0,
      txt_odd_8_8: 0,
      txt_odd_8_9: 0,
      txt_odd_8_10: 0,
      txt_odd_9_1: 0,
      txt_odd_9_2: 0,
      txt_odd_9_3: 0,
      txt_odd_9_4: 0,
      txt_odd_9_5: 0,
      txt_odd_9_6: 0,
      txt_odd_9_7: 0,
      txt_odd_9_8: 0,
      txt_odd_9_9: 0,
      txt_odd_9_10: 0,
      txt_odd_10_1: 0,
      txt_odd_10_2: 0,
      txt_odd_10_3: 0,
      txt_odd_10_4: 0,
      txt_odd_10_5: 0,
      txt_odd_10_6: 0,
      txt_odd_10_7: 0,
      txt_odd_10_8: 0,
      txt_odd_10_9: 0,
      txt_odd_10_10: 0,
      txt_even_1_1: 0,
      txt_even_1_2: 0,
      txt_even_1_3: 0,
      txt_even_1_4: 0,
      txt_even_1_5: 0,
      txt_even_1_6: 0,
      txt_even_1_7: 0,
      txt_even_1_8: 0,
      txt_even_1_9: 0,
      txt_even_1_10: 0,
      txt_even_2_1: 0,
      txt_even_2_2: 0,
      txt_even_2_3: 0,
      txt_even_2_4: 0,
      txt_even_2_5: 0,
      txt_even_2_6: 0,
      txt_even_2_7: 0,
      txt_even_2_8: 0,
      txt_even_2_9: 0,
      txt_even_2_10: 0,
      txt_even_3_1: 0,
      txt_even_3_2: 0,
      txt_even_3_3: 0,
      txt_even_3_4: 0,
      txt_even_3_5: 0,
      txt_even_3_6: 0,
      txt_even_3_7: 0,
      txt_even_3_8: 0,
      txt_even_3_9: 0,
      txt_even_3_10: 0,
      txt_even_4_1: 0,
      txt_even_4_2: 0,
      txt_even_4_3: 0,
      txt_even_4_4: 0,
      txt_even_4_5: 0,
      txt_even_4_6: 0,
      txt_even_4_7: 0,
      txt_even_4_8: 0,
      txt_even_4_9: 0,
      txt_even_4_10: 0,
      txt_even_5_1: 0,
      txt_even_5_2: 0,
      txt_even_5_3: 0,
      txt_even_5_4: 0,
      txt_even_5_5: 0,
      txt_even_5_6: 0,
      txt_even_5_7: 0,
      txt_even_5_8: 0,
      txt_even_5_9: 0,
      txt_even_5_10: 0,
      txt_even_6_1: 0,
      txt_even_6_2: 0,
      txt_even_6_3: 0,
      txt_even_6_4: 0,
      txt_even_6_5: 0,
      txt_even_6_6: 0,
      txt_even_6_7: 0,
      txt_even_6_8: 0,
      txt_even_6_9: 0,
      txt_even_6_10: 0,
      txt_even_7_1: 0,
      txt_even_7_2: 0,
      txt_even_7_3: 0,
      txt_even_7_4: 0,
      txt_even_7_5: 0,
      txt_even_7_6: 0,
      txt_even_7_7: 0,
      txt_even_7_8: 0,
      txt_even_7_9: 0,
      txt_even_7_10: 0,
      txt_even_8_1: 0,
      txt_even_8_2: 0,
      txt_even_8_3: 0,
      txt_even_8_4: 0,
      txt_even_8_5: 0,
      txt_even_8_6: 0,
      txt_even_8_7: 0,
      txt_even_8_8: 0,
      txt_even_8_9: 0,
      txt_even_8_10: 0,
      txt_even_9_1: 0,
      txt_even_9_2: 0,
      txt_even_9_3: 0,
      txt_even_9_4: 0,
      txt_even_9_5: 0,
      txt_even_9_6: 0,
      txt_even_9_7: 0,
      txt_even_9_8: 0,
      txt_even_9_9: 0,
      txt_even_9_10: 0,
      txt_even_10_1: 0,
      txt_even_10_2: 0,
      txt_even_10_3: 0,
      txt_even_10_4: 0,
      txt_even_10_5: 0,
      txt_even_10_6: 0,
      txt_even_10_7: 0,
      txt_even_10_8: 0,
      txt_even_10_9: 0,
      txt_even_10_10: 0,
      txt_big_1_1: 0,
      txt_big_1_2: 0,
      txt_big_1_3: 0,
      txt_big_1_4: 0,
      txt_big_1_5: 0,
      txt_big_1_6: 0,
      txt_big_1_7: 0,
      txt_big_1_8: 0,
      txt_big_1_9: 0,
      txt_big_1_10: 0,
      txt_big_2_1: 0,
      txt_big_2_2: 0,
      txt_big_2_3: 0,
      txt_big_2_4: 0,
      txt_big_2_5: 0,
      txt_big_2_6: 0,
      txt_big_2_7: 0,
      txt_big_2_8: 0,
      txt_big_2_9: 0,
      txt_big_2_10: 0,
      txt_big_3_1: 0,
      txt_big_3_2: 0,
      txt_big_3_3: 0,
      txt_big_3_4: 0,
      txt_big_3_5: 0,
      txt_big_3_6: 0,
      txt_big_3_7: 0,
      txt_big_3_8: 0,
      txt_big_3_9: 0,
      txt_big_3_10: 0,
      txt_big_4_1: 0,
      txt_big_4_2: 0,
      txt_big_4_3: 0,
      txt_big_4_4: 0,
      txt_big_4_5: 0,
      txt_big_4_6: 0,
      txt_big_4_7: 0,
      txt_big_4_8: 0,
      txt_big_4_9: 0,
      txt_big_4_10: 0,
      txt_big_5_1: 0,
      txt_big_5_2: 0,
      txt_big_5_3: 0,
      txt_big_5_4: 0,
      txt_big_5_5: 0,
      txt_big_5_6: 0,
      txt_big_5_7: 0,
      txt_big_5_8: 0,
      txt_big_5_9: 0,
      txt_big_5_10: 0,
      txt_big_6_1: 0,
      txt_big_6_2: 0,
      txt_big_6_3: 0,
      txt_big_6_4: 0,
      txt_big_6_5: 0,
      txt_big_6_6: 0,
      txt_big_6_7: 0,
      txt_big_6_8: 0,
      txt_big_6_9: 0,
      txt_big_6_10: 0,
      txt_big_7_1: 0,
      txt_big_7_2: 0,
      txt_big_7_3: 0,
      txt_big_7_4: 0,
      txt_big_7_5: 0,
      txt_big_7_6: 0,
      txt_big_7_7: 0,
      txt_big_7_8: 0,
      txt_big_7_9: 0,
      txt_big_7_10: 0,
      txt_big_8_1: 0,
      txt_big_8_2: 0,
      txt_big_8_3: 0,
      txt_big_8_4: 0,
      txt_big_8_5: 0,
      txt_big_8_6: 0,
      txt_big_8_7: 0,
      txt_big_8_8: 0,
      txt_big_8_9: 0,
      txt_big_8_10: 0,
      txt_big_9_1: 0,
      txt_big_9_2: 0,
      txt_big_9_3: 0,
      txt_big_9_4: 0,
      txt_big_9_5: 0,
      txt_big_9_6: 0,
      txt_big_9_7: 0,
      txt_big_9_8: 0,
      txt_big_9_9: 0,
      txt_big_9_10: 0,
      txt_big_10_1: 0,
      txt_big_10_2: 0,
      txt_big_10_3: 0,
      txt_big_10_4: 0,
      txt_big_10_5: 0,
      txt_big_10_6: 0,
      txt_big_10_7: 0,
      txt_big_10_8: 0,
      txt_big_10_9: 0,
      txt_big_10_10: 0,
      txt_small_1_1: 0,
      txt_small_1_2: 0,
      txt_small_1_3: 0,
      txt_small_1_4: 0,
      txt_small_1_5: 0,
      txt_small_1_6: 0,
      txt_small_1_7: 0,
      txt_small_1_8: 0,
      txt_small_1_9: 0,
      txt_small_1_10: 0,
      txt_small_2_1: 0,
      txt_small_2_2: 0,
      txt_small_2_3: 0,
      txt_small_2_4: 0,
      txt_small_2_5: 0,
      txt_small_2_6: 0,
      txt_small_2_7: 0,
      txt_small_2_8: 0,
      txt_small_2_9: 0,
      txt_small_2_10: 0,
      txt_small_3_1: 0,
      txt_small_3_2: 0,
      txt_small_3_3: 0,
      txt_small_3_4: 0,
      txt_small_3_5: 0,
      txt_small_3_6: 0,
      txt_small_3_7: 0,
      txt_small_3_8: 0,
      txt_small_3_9: 0,
      txt_small_3_10: 0,
      txt_small_4_1: 0,
      txt_small_4_2: 0,
      txt_small_4_3: 0,
      txt_small_4_4: 0,
      txt_small_4_5: 0,
      txt_small_4_6: 0,
      txt_small_4_7: 0,
      txt_small_4_8: 0,
      txt_small_4_9: 0,
      txt_small_4_10: 0,
      txt_small_5_1: 0,
      txt_small_5_2: 0,
      txt_small_5_3: 0,
      txt_small_5_4: 0,
      txt_small_5_5: 0,
      txt_small_5_6: 0,
      txt_small_5_7: 0,
      txt_small_5_8: 0,
      txt_small_5_9: 0,
      txt_small_5_10: 0,
      txt_small_6_1: 0,
      txt_small_6_2: 0,
      txt_small_6_3: 0,
      txt_small_6_4: 0,
      txt_small_6_5: 0,
      txt_small_6_6: 0,
      txt_small_6_7: 0,
      txt_small_6_8: 0,
      txt_small_6_9: 0,
      txt_small_6_10: 0,
      txt_small_7_1: 0,
      txt_small_7_2: 0,
      txt_small_7_3: 0,
      txt_small_7_4: 0,
      txt_small_7_5: 0,
      txt_small_7_6: 0,
      txt_small_7_7: 0,
      txt_small_7_8: 0,
      txt_small_7_9: 0,
      txt_small_7_10: 0,
      txt_small_8_1: 0,
      txt_small_8_2: 0,
      txt_small_8_3: 0,
      txt_small_8_4: 0,
      txt_small_8_5: 0,
      txt_small_8_6: 0,
      txt_small_8_7: 0,
      txt_small_8_8: 0,
      txt_small_8_9: 0,
      txt_small_8_10: 0,
      txt_small_9_1: 0,
      txt_small_9_2: 0,
      txt_small_9_3: 0,
      txt_small_9_4: 0,
      txt_small_9_5: 0,
      txt_small_9_6: 0,
      txt_small_9_7: 0,
      txt_small_9_8: 0,
      txt_small_9_9: 0,
      txt_small_9_10: 0,
      txt_small_10_1: 0,
      txt_small_10_2: 0,
      txt_small_10_3: 0,
      txt_small_10_4: 0,
      txt_small_10_5: 0,
      txt_small_10_6: 0,
      txt_small_10_7: 0,
      txt_small_10_8: 0,
      txt_small_10_9: 0,
      txt_small_10_10: 0,
      txt_random_1_1: 0,
      txt_random_1_2: 0,
      txt_random_1_3: 0,
      txt_random_1_4: 0,
      txt_random_1_5: 0,
      txt_random_1_6: 0,
      txt_random_1_7: 0,
      txt_random_1_8: 0,
      txt_random_1_9: 0,
      txt_random_1_10: 0,
      txt_random_2_1: 0,
      txt_random_2_2: 0,
      txt_random_2_3: 0,
      txt_random_2_4: 0,
      txt_random_2_5: 0,
      txt_random_2_6: 0,
      txt_random_2_7: 0,
      txt_random_2_8: 0,
      txt_random_2_9: 0,
      txt_random_2_10: 0,
      txt_random_3_1: 0,
      txt_random_3_2: 0,
      txt_random_3_3: 0,
      txt_random_3_4: 0,
      txt_random_3_5: 0,
      txt_random_3_6: 0,
      txt_random_3_7: 0,
      txt_random_3_8: 0,
      txt_random_3_9: 0,
      txt_random_3_10: 0,
      txt_random_4_1: 0,
      txt_random_4_2: 0,
      txt_random_4_3: 0,
      txt_random_4_4: 0,
      txt_random_4_5: 0,
      txt_random_4_6: 0,
      txt_random_4_7: 0,
      txt_random_4_8: 0,
      txt_random_4_9: 0,
      txt_random_4_10: 0,
      txt_random_5_1: 0,
      txt_random_5_2: 0,
      txt_random_5_3: 0,
      txt_random_5_4: 0,
      txt_random_5_5: 0,
      txt_random_5_6: 0,
      txt_random_5_7: 0,
      txt_random_5_8: 0,
      txt_random_5_9: 0,
      txt_random_5_10: 0,
      txt_random_6_1: 0,
      txt_random_6_2: 0,
      txt_random_6_3: 0,
      txt_random_6_4: 0,
      txt_random_6_5: 0,
      txt_random_6_6: 0,
      txt_random_6_7: 0,
      txt_random_6_8: 0,
      txt_random_6_9: 0,
      txt_random_6_10: 0,
      txt_random_7_1: 0,
      txt_random_7_2: 0,
      txt_random_7_3: 0,
      txt_random_7_4: 0,
      txt_random_7_5: 0,
      txt_random_7_6: 0,
      txt_random_7_7: 0,
      txt_random_7_8: 0,
      txt_random_7_9: 0,
      txt_random_7_10: 0,
      txt_random_8_1: 0,
      txt_random_8_2: 0,
      txt_random_8_3: 0,
      txt_random_8_4: 0,
      txt_random_8_5: 0,
      txt_random_8_6: 0,
      txt_random_8_7: 0,
      txt_random_8_8: 0,
      txt_random_8_9: 0,
      txt_random_8_10: 0,
      txt_random_9_1: 0,
      txt_random_9_2: 0,
      txt_random_9_3: 0,
      txt_random_9_4: 0,
      txt_random_9_5: 0,
      txt_random_9_6: 0,
      txt_random_9_7: 0,
      txt_random_9_8: 0,
      txt_random_9_9: 0,
      txt_random_9_10: 0,
      txt_random_10_1: 0,
      txt_random_10_2: 0,
      txt_random_10_3: 0,
      txt_random_10_4: 0,
      txt_random_10_5: 0,
      txt_random_10_6: 0,
      txt_random_10_7: 0,
      txt_random_10_8: 0,
      txt_random_10_9: 0,
      txt_random_10_10: 0,
      stop_win: 1000,
      stop_loss: -800,
      isShowProgress: false,
      alertSuccess: false,
      alertError: false,
      repsMessage: "",
      timeout: 1000 * 2, // 2秒
      dialog: false,
      dialog_wg: false,
      dialog_wg_batch: false,
      bet_type_ao_chu: false,
      bet_type_ciang_gong: false,
      bet_type_ao_chu_desc: "凹注法意旨當下投注金額關數沒通過就往下一關投注金額，反之過關就回到第一關投注金額。",
      bet_type_ciang_gong_desc: "強攻法意旨當下投注金額關數過關就往下一關投注金額，反之沒過關就回到第一關投注金額。",
      isShowTitle: true,
      sidebarTheme: "dark",
      selectedLotteryType: null,
      selectedOrderType: null,
      isRandomType: false,
      rankName: "",
      orderName: "",
      txt_exeucte_num_of_pass: 1,
      txt_exeucte_num_of_pass_batch: 1,
      txt_1: 0,
      txt_6: 0,
      txt_2: 0,
      txt_7: 0,
      txt_3: 0,
      txt_8: 0,
      txt_4: 0,
      txt_9: 0,
      txt_5: 0,
      txt_10: 0,
      txt_11: 0,
      txt_12: 0,
      txt_13: 0,
      txt_14: 0,
      txt_15: 0,
      txt_1_batch: 0,
      txt_6_batch: 0,
      txt_2_batch: 0,
      txt_7_batch: 0,
      txt_3_batch: 0,
      txt_8_batch: 0,
      txt_4_batch: 0,
      txt_9_batch: 0,
      txt_5_batch: 0,
      txt_10_batch: 0,
      txt_11_batch: 0,
      txt_12_batch: 0,
      txt_13_batch: 0,
      txt_14_batch: 0,
      txt_15_batch: 0,
      chk_disable: 1,
      txt_exeucte_num_of_pass_10star: 1,
      rank_1: 1,
      rank_2: 2,
      rank_3: 3,
      rank_4: 4,
      rank_5: 5,
      rank_6: 6,
      rank_7: 7,
      rank_8: 8,
      rank_9: 9,
      rank_10: 10,
      txt_1_10star: 0,
      txt_6_10star: 0,
      txt_2_10star: 0,
      txt_7_10star: 0,
      txt_3_10star: 0,
      txt_8_10star: 0,
      txt_4_10star: 0,
      txt_9_10star: 0,
      txt_5_10star: 0,
      txt_10_10star: 0,
      method_type_10star: "自選",
      items: [
        {
          name: "冠軍",
          rank: 1,
          isSetting: [0, 0, 0, 0, 0]
        },
        {
          name: "亞軍",
          rank: 2,
          isSetting: [0, 0, 0, 0, 0]
        },
        {
          name: "第三名",
          rank: 3,
          isSetting: [0, 0, 0, 0, 0]
        },
        {
          name: "第四名",
          rank: 4,
          isSetting: [0, 0, 0, 0, 0]
        },
        {
          name: "第五名",
          rank: 5,
          isSetting: [0, 0, 0, 0, 0]
        },
        {
          name: "第六名",
          rank: 6,
          isSetting: [0, 0, 0, 0, 0]
        },
        {
          name: "第七名",
          rank: 7,
          isSetting: [0, 0, 0, 0, 0]
        },
        {
          name: "第八名",
          rank: 8,
          isSetting: [0, 0, 0, 0, 0]
        },
        {
          name: "第九名",
          rank: 9,
          isSetting: [0, 0, 0, 0, 0]
        },
        {
          name: "第十名",
          rank: 10,
          isSetting: [0, 0, 0, 0, 0]
        },
      ],
      items_batch: [
        {
          name: "冠軍",
          rank: 1,
          isSetting: [false, false, false, false]
        },
        {
          name: "亞軍",
          rank: 2,
          isSetting: [false, false, false, false]
        },
        {
          name: "第三名",
          rank: 3,
          isSetting: [false, false, false, false]
        },
        {
          name: "第四名",
          rank: 4,
          isSetting: [false, false, false, false]
        },
        {
          name: "第五名",
          rank: 5,
          isSetting: [false, false, false, false]
        },
        {
          name: "第六名",
          rank: 6,
          isSetting: [false, false, false, false]
        },
        {
          name: "第七名",
          rank: 7,
          isSetting: [false, false, false, false]
        },
        {
          name: "第八名",
          rank: 8,
          isSetting: [false, false, false, false]
        },
        {
          name: "第九名",
          rank: 9,
          isSetting: [false, false, false, false]
        },
        {
          name: "第十名",
          rank: 10,
          isSetting: [false, false, false, false]
        },
      ],
      items_10star: [
        {
          name: "冠軍",
          rank: 1,
          isSetting: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "亞軍",
          rank: 2,
          isSetting: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "第三名",
          rank: 3,
          isSetting: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "第四名",
          rank: 4,
          isSetting: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "第五名",
          rank: 5,
          isSetting: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "第六名",
          rank: 6,
          isSetting: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "第七名",
          rank: 7,
          isSetting: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "第八名",
          rank: 8,
          isSetting: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "第九名",
          rank: 9,
          isSetting: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "第十名",
          rank: 10,
          isSetting: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
    };
  },
  watch: {
    e1: function () {
      if (this.e1 === 1) {
        this.isShowTitle = true
      } else {
        this.isShowTitle = false
      }

      setTimeout(() => {
        window.scrollTo(0, -100);
      }, 50);
    }
  },
  mounted() {
    const token = Cookie.get('auth')

    console.log(token)
    console.log(typeof (token))
    console.log((token === 'null') || (typeof (token) === "undefined"))
    if ((token === 'null') || (typeof (token) === "undefined")) {
      this.$router.push('/login');
    }

    const tokenParts = token.split('.')
    console.log(tokenParts)
    const body = JSON.parse(atob(tokenParts[1]))
    console.log(body)
    console.log(body.sub)

    this.userId = body.sub

    const data = {
      'game_name': 'WG',
    };
    casinoData(data)
      .then((response) => {
        this.repsData = response.data;
        console.log(this.repsData)
        this.bettings = this.repsData.casino_name_list

      })
      .catch(function (error) {
        // 請求失敗處理
        console.log(error);
      });

    this.$swal("", "啟動 Aiwin 自動下注機器人時，會使用您在WG彩票遊戲館和錢包裡的點數進行策略投注。請確認這些點數的餘額是否足夠。其他遊戲館的點數將不會被使用。", "warning");
  },
  methods: {
    // 10星下注法選擇自選將前往不同頁面設定
    selectMethodType10star(methodType) {
      this.method_type_10star = methodType

      if (methodType === "自選") {
        this.e1 = 7
      }
      else {
        this.e1 = 6
      }


    },
    // 儲存基本兩面盤批次設定
    saveBatchSetting() {
      for (let i = 0; i < this.items_batch.length; i++) {
        for (let j = 0; j < this.items_batch[i].isSetting.length; j++) {
          if (i === 0 && j === 0) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_big_disable_1 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_big_disable_1 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_big_1 = this.bet_type_batch
            this.txt_big_exeucte_num_of_pass_1 = this.txt_exeucte_num_of_pass_batch
            this.txt_big_1_1 = this.txt_1_batch
            this.txt_big_1_2 = this.txt_2_batch
            this.txt_big_1_3 = this.txt_3_batch
            this.txt_big_1_4 = this.txt_4_batch
            this.txt_big_1_5 = this.txt_5_batch
            this.txt_big_1_6 = this.txt_6_batch
            this.txt_big_1_7 = this.txt_7_batch
            this.txt_big_1_8 = this.txt_8_batch
            this.txt_big_1_9 = this.txt_9_batch
            this.txt_big_1_10 = this.txt_10_batch
            this.txt_big_1_11 = this.txt_11_batch
            this.txt_big_1_12 = this.txt_12_batch
            this.txt_big_1_13 = this.txt_13_batch
            this.txt_big_1_14 = this.txt_14_batch
            this.txt_big_1_15 = this.txt_15_batch

          } else if (i === 1 && j === 0) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_big_disable_2 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_big_disable_2 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_big_2 = this.bet_type_batch
            this.txt_big_exeucte_num_of_pass_2 = this.txt_exeucte_num_of_pass_batch
            this.txt_big_2_1 = this.txt_1_batch
            this.txt_big_2_2 = this.txt_2_batch
            this.txt_big_2_3 = this.txt_3_batch
            this.txt_big_2_4 = this.txt_4_batch
            this.txt_big_2_5 = this.txt_5_batch
            this.txt_big_2_6 = this.txt_6_batch
            this.txt_big_2_7 = this.txt_7_batch
            this.txt_big_2_8 = this.txt_8_batch
            this.txt_big_2_9 = this.txt_9_batch
            this.txt_big_2_10 = this.txt_10_batch
            this.txt_big_2_11 = this.txt_11_batch
            this.txt_big_2_12 = this.txt_12_batch
            this.txt_big_2_13 = this.txt_13_batch
            this.txt_big_2_14 = this.txt_14_batch
            this.txt_big_2_15 = this.txt_15_batch

          } else if (i === 2 && j === 0) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_big_disable_3 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_big_disable_3 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_big_3 = this.bet_type_batch
            this.txt_big_exeucte_num_of_pass_3 = this.txt_exeucte_num_of_pass_batch
            this.txt_big_3_1 = this.txt_1_batch
            this.txt_big_3_2 = this.txt_2_batch
            this.txt_big_3_3 = this.txt_3_batch
            this.txt_big_3_4 = this.txt_4_batch
            this.txt_big_3_5 = this.txt_5_batch
            this.txt_big_3_6 = this.txt_6_batch
            this.txt_big_3_7 = this.txt_7_batch
            this.txt_big_3_8 = this.txt_8_batch
            this.txt_big_3_9 = this.txt_9_batch
            this.txt_big_3_10 = this.txt_10_batch
            this.txt_big_3_11 = this.txt_11_batch
            this.txt_big_3_12 = this.txt_12_batch
            this.txt_big_3_13 = this.txt_13_batch
            this.txt_big_3_14 = this.txt_14_batch
            this.txt_big_3_15 = this.txt_15_batch

          } else if (i === 3 && j === 0) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_big_disable_4 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_big_disable_4 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_big_4 = this.bet_type_batch
            this.txt_big_exeucte_num_of_pass_4 = this.txt_exeucte_num_of_pass_batch
            this.txt_big_4_1 = this.txt_1_batch
            this.txt_big_4_2 = this.txt_2_batch
            this.txt_big_4_3 = this.txt_3_batch
            this.txt_big_4_4 = this.txt_4_batch
            this.txt_big_4_5 = this.txt_5_batch
            this.txt_big_4_6 = this.txt_6_batch
            this.txt_big_4_7 = this.txt_7_batch
            this.txt_big_4_8 = this.txt_8_batch
            this.txt_big_4_9 = this.txt_9_batch
            this.txt_big_4_10 = this.txt_10_batch
            this.txt_big_4_11 = this.txt_11_batch
            this.txt_big_4_12 = this.txt_12_batch
            this.txt_big_4_13 = this.txt_13_batch
            this.txt_big_4_14 = this.txt_14_batch
            this.txt_big_4_15 = this.txt_15_batch

          } else if (i === 4 && j === 0) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_big_disable_5 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_big_disable_5 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_big_5 = this.bet_type_batch
            this.txt_big_exeucte_num_of_pass_5 = this.txt_exeucte_num_of_pass_batch
            this.txt_big_5_1 = this.txt_1_batch
            this.txt_big_5_2 = this.txt_2_batch
            this.txt_big_5_3 = this.txt_3_batch
            this.txt_big_5_4 = this.txt_4_batch
            this.txt_big_5_5 = this.txt_5_batch
            this.txt_big_5_6 = this.txt_6_batch
            this.txt_big_5_7 = this.txt_7_batch
            this.txt_big_5_8 = this.txt_8_batch
            this.txt_big_5_9 = this.txt_9_batch
            this.txt_big_5_10 = this.txt_10_batch
            this.txt_big_5_11 = this.txt_11_batch
            this.txt_big_5_12 = this.txt_12_batch
            this.txt_big_5_13 = this.txt_13_batch
            this.txt_big_5_14 = this.txt_14_batch
            this.txt_big_5_15 = this.txt_15_batch

          } else if (i === 5 && j === 0) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_big_disable_6 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_big_disable_6 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_big_6 = this.bet_type_batch
            this.txt_big_exeucte_num_of_pass_6 = this.txt_exeucte_num_of_pass_batch
            this.txt_big_6_1 = this.txt_1_batch
            this.txt_big_6_2 = this.txt_2_batch
            this.txt_big_6_3 = this.txt_3_batch
            this.txt_big_6_4 = this.txt_4_batch
            this.txt_big_6_5 = this.txt_5_batch
            this.txt_big_6_6 = this.txt_6_batch
            this.txt_big_6_7 = this.txt_7_batch
            this.txt_big_6_8 = this.txt_8_batch
            this.txt_big_6_9 = this.txt_9_batch
            this.txt_big_6_10 = this.txt_10_batch
            this.txt_big_6_11 = this.txt_11_batch
            this.txt_big_6_12 = this.txt_12_batch
            this.txt_big_6_13 = this.txt_13_batch
            this.txt_big_6_14 = this.txt_14_batch
            this.txt_big_6_15 = this.txt_15_batch

          } else if (i === 6 && j === 0) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_big_disable_7 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_big_disable_7 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_big_7 = this.bet_type_batch
            this.txt_big_exeucte_num_of_pass_7 = this.txt_exeucte_num_of_pass_batch
            this.txt_big_7_1 = this.txt_1_batch
            this.txt_big_7_2 = this.txt_2_batch
            this.txt_big_7_3 = this.txt_3_batch
            this.txt_big_7_4 = this.txt_4_batch
            this.txt_big_7_5 = this.txt_5_batch
            this.txt_big_7_6 = this.txt_6_batch
            this.txt_big_7_7 = this.txt_7_batch
            this.txt_big_7_8 = this.txt_8_batch
            this.txt_big_7_9 = this.txt_9_batch
            this.txt_big_7_10 = this.txt_10_batch
            this.txt_big_7_11 = this.txt_11_batch
            this.txt_big_7_12 = this.txt_12_batch
            this.txt_big_7_13 = this.txt_13_batch
            this.txt_big_7_14 = this.txt_14_batch
            this.txt_big_7_15 = this.txt_15_batch

          } else if (i === 7 && j === 0) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_big_disable_8 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_big_disable_8 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_big_8 = this.bet_type_batch
            this.txt_big_exeucte_num_of_pass_8 = this.txt_exeucte_num_of_pass_batch
            this.txt_big_8_1 = this.txt_1_batch
            this.txt_big_8_2 = this.txt_2_batch
            this.txt_big_8_3 = this.txt_3_batch
            this.txt_big_8_4 = this.txt_4_batch
            this.txt_big_8_5 = this.txt_5_batch
            this.txt_big_8_6 = this.txt_6_batch
            this.txt_big_8_7 = this.txt_7_batch
            this.txt_big_8_8 = this.txt_8_batch
            this.txt_big_8_9 = this.txt_9_batch
            this.txt_big_8_10 = this.txt_10_batch
            this.txt_big_8_11 = this.txt_11_batch
            this.txt_big_8_12 = this.txt_12_batch
            this.txt_big_8_13 = this.txt_13_batch
            this.txt_big_8_14 = this.txt_14_batch
            this.txt_big_8_15 = this.txt_15_batch

          } else if (i === 8 && j === 0) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_big_disable_9 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_big_disable_9 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_big_9 = this.bet_type_batch
            this.txt_big_exeucte_num_of_pass_9 = this.txt_exeucte_num_of_pass_batch
            this.txt_big_9_1 = this.txt_1_batch
            this.txt_big_9_2 = this.txt_2_batch
            this.txt_big_9_3 = this.txt_3_batch
            this.txt_big_9_4 = this.txt_4_batch
            this.txt_big_9_5 = this.txt_5_batch
            this.txt_big_9_6 = this.txt_6_batch
            this.txt_big_9_7 = this.txt_7_batch
            this.txt_big_9_8 = this.txt_8_batch
            this.txt_big_9_9 = this.txt_9_batch
            this.txt_big_9_10 = this.txt_10_batch
            this.txt_big_9_11 = this.txt_11_batch
            this.txt_big_9_12 = this.txt_12_batch
            this.txt_big_9_13 = this.txt_13_batch
            this.txt_big_9_14 = this.txt_14_batch
            this.txt_big_9_15 = this.txt_15_batch

          } else if (i === 9 && j === 0) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_big_disable_10 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_big_disable_10 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_big_10 = this.bet_type_batch
            this.txt_big_exeucte_num_of_pass_10 = this.txt_exeucte_num_of_pass_batch
            this.txt_big_10_1 = this.txt_1_batch
            this.txt_big_10_2 = this.txt_2_batch
            this.txt_big_10_3 = this.txt_3_batch
            this.txt_big_10_4 = this.txt_4_batch
            this.txt_big_10_5 = this.txt_5_batch
            this.txt_big_10_6 = this.txt_6_batch
            this.txt_big_10_7 = this.txt_7_batch
            this.txt_big_10_8 = this.txt_8_batch
            this.txt_big_10_9 = this.txt_9_batch
            this.txt_big_10_10 = this.txt_10_batch
            this.txt_big_10_11 = this.txt_11_batch
            this.txt_big_10_12 = this.txt_12_batch
            this.txt_big_10_13 = this.txt_13_batch
            this.txt_big_10_14 = this.txt_14_batch
            this.txt_big_10_15 = this.txt_15_batch

          } else if (i === 0 && j === 1) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_small_disable_1 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_small_disable_1 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_small_1 = this.bet_type_batch
            this.txt_small_exeucte_num_of_pass_1 = this.txt_exeucte_num_of_pass_batch
            this.txt_small_1_1 = this.txt_1_batch
            this.txt_small_1_2 = this.txt_2_batch
            this.txt_small_1_3 = this.txt_3_batch
            this.txt_small_1_4 = this.txt_4_batch
            this.txt_small_1_5 = this.txt_5_batch
            this.txt_small_1_6 = this.txt_6_batch
            this.txt_small_1_7 = this.txt_7_batch
            this.txt_small_1_8 = this.txt_8_batch
            this.txt_small_1_9 = this.txt_9_batch
            this.txt_small_1_10 = this.txt_10_batch
            this.txt_small_1_11 = this.txt_11_batch
            this.txt_small_1_12 = this.txt_12_batch
            this.txt_small_1_13 = this.txt_13_batch
            this.txt_small_1_14 = this.txt_14_batch
            this.txt_small_1_15 = this.txt_15_batch
            
          } else if (i === 1 && j === 1) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_small_disable_2 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_small_disable_2 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_small_2 = this.bet_type_batch
            this.txt_small_exeucte_num_of_pass_2 = this.txt_exeucte_num_of_pass_batch
            this.txt_small_2_1 = this.txt_1_batch
            this.txt_small_2_2 = this.txt_2_batch
            this.txt_small_2_3 = this.txt_3_batch
            this.txt_small_2_4 = this.txt_4_batch
            this.txt_small_2_5 = this.txt_5_batch
            this.txt_small_2_6 = this.txt_6_batch
            this.txt_small_2_7 = this.txt_7_batch
            this.txt_small_2_8 = this.txt_8_batch
            this.txt_small_2_9 = this.txt_9_batch
            this.txt_small_2_10 = this.txt_10_batch
            this.txt_small_2_11 = this.txt_11_batch
            this.txt_small_2_12 = this.txt_12_batch
            this.txt_small_2_13 = this.txt_13_batch
            this.txt_small_2_14 = this.txt_14_batch
            this.txt_small_2_15 = this.txt_15_batch

          } else if (i === 2 && j === 1) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_small_disable_3 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_small_disable_3 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_small_3 = this.bet_type_batch
            this.txt_small_exeucte_num_of_pass_3 = this.txt_exeucte_num_of_pass_batch
            this.txt_small_3_1 = this.txt_1_batch
            this.txt_small_3_2 = this.txt_2_batch
            this.txt_small_3_3 = this.txt_3_batch
            this.txt_small_3_4 = this.txt_4_batch
            this.txt_small_3_5 = this.txt_5_batch
            this.txt_small_3_6 = this.txt_6_batch
            this.txt_small_3_7 = this.txt_7_batch
            this.txt_small_3_8 = this.txt_8_batch
            this.txt_small_3_9 = this.txt_9_batch
            this.txt_small_3_10 = this.txt_10_batch
            this.txt_small_3_11 = this.txt_11_batch
            this.txt_small_3_12 = this.txt_12_batch
            this.txt_small_3_13 = this.txt_13_batch
            this.txt_small_3_14 = this.txt_14_batch
            this.txt_small_3_15 = this.txt_15_batch

          } else if (i === 3 && j === 1) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_small_disable_4 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_small_disable_4 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_small_4 = this.bet_type_batch
            this.txt_small_exeucte_num_of_pass_4 = this.txt_exeucte_num_of_pass_batch
            this.txt_small_4_1 = this.txt_1_batch
            this.txt_small_4_2 = this.txt_2_batch
            this.txt_small_4_3 = this.txt_3_batch
            this.txt_small_4_4 = this.txt_4_batch
            this.txt_small_4_5 = this.txt_5_batch
            this.txt_small_4_6 = this.txt_6_batch
            this.txt_small_4_7 = this.txt_7_batch
            this.txt_small_4_8 = this.txt_8_batch
            this.txt_small_4_9 = this.txt_9_batch
            this.txt_small_4_10 = this.txt_10_batch
            this.txt_small_4_11 = this.txt_11_batch
            this.txt_small_4_12 = this.txt_12_batch
            this.txt_small_4_13 = this.txt_13_batch
            this.txt_small_4_14 = this.txt_14_batch
            this.txt_small_4_15 = this.txt_15_batch

          } else if (i === 4 && j === 1) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_small_disable_5 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_small_disable_5 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_small_5 = this.bet_type_batch
            this.txt_small_exeucte_num_of_pass_5 = this.txt_exeucte_num_of_pass_batch
            this.txt_small_5_1 = this.txt_1_batch
            this.txt_small_5_2 = this.txt_2_batch
            this.txt_small_5_3 = this.txt_3_batch
            this.txt_small_5_4 = this.txt_4_batch
            this.txt_small_5_5 = this.txt_5_batch
            this.txt_small_5_6 = this.txt_6_batch
            this.txt_small_5_7 = this.txt_7_batch
            this.txt_small_5_8 = this.txt_8_batch
            this.txt_small_5_9 = this.txt_9_batch
            this.txt_small_5_10 = this.txt_10_batch
            this.txt_small_5_11 = this.txt_11_batch
            this.txt_small_5_12 = this.txt_12_batch
            this.txt_small_5_13 = this.txt_13_batch
            this.txt_small_5_14 = this.txt_14_batch
            this.txt_small_5_15 = this.txt_15_batch

          } else if (i === 5 && j === 1) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_small_disable_6 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_small_disable_6 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_small_6 = this.bet_type_batch
            this.txt_small_exeucte_num_of_pass_6 = this.txt_exeucte_num_of_pass_batch
            this.txt_small_6_1 = this.txt_1_batch
            this.txt_small_6_2 = this.txt_2_batch
            this.txt_small_6_3 = this.txt_3_batch
            this.txt_small_6_4 = this.txt_4_batch
            this.txt_small_6_5 = this.txt_5_batch
            this.txt_small_6_6 = this.txt_6_batch
            this.txt_small_6_7 = this.txt_7_batch
            this.txt_small_6_8 = this.txt_8_batch
            this.txt_small_6_9 = this.txt_9_batch
            this.txt_small_6_10 = this.txt_10_batch
            this.txt_small_6_11 = this.txt_11_batch
            this.txt_small_6_12 = this.txt_12_batch
            this.txt_small_6_13 = this.txt_13_batch
            this.txt_small_6_14 = this.txt_14_batch
            this.txt_small_6_15 = this.txt_15_batch

          } else if (i === 6 && j === 1) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_small_disable_7 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_small_disable_7 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_small_7 = this.bet_type_batch
            this.txt_small_exeucte_num_of_pass_7 = this.txt_exeucte_num_of_pass_batch
            this.txt_small_7_1 = this.txt_1_batch
            this.txt_small_7_2 = this.txt_2_batch
            this.txt_small_7_3 = this.txt_3_batch
            this.txt_small_7_4 = this.txt_4_batch
            this.txt_small_7_5 = this.txt_5_batch
            this.txt_small_7_6 = this.txt_6_batch
            this.txt_small_7_7 = this.txt_7_batch
            this.txt_small_7_8 = this.txt_8_batch
            this.txt_small_7_9 = this.txt_9_batch
            this.txt_small_7_10 = this.txt_10_batch
            this.txt_small_7_11 = this.txt_11_batch
            this.txt_small_7_12 = this.txt_12_batch
            this.txt_small_7_13 = this.txt_13_batch
            this.txt_small_7_14 = this.txt_14_batch
            this.txt_small_7_15 = this.txt_15_batch

          } else if (i === 7 && j === 1) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_small_disable_8 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_small_disable_8 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_small_8 = this.bet_type_batch
            this.txt_small_exeucte_num_of_pass_8 = this.txt_exeucte_num_of_pass_batch
            this.txt_small_8_1 = this.txt_1_batch
            this.txt_small_8_2 = this.txt_2_batch
            this.txt_small_8_3 = this.txt_3_batch
            this.txt_small_8_4 = this.txt_4_batch
            this.txt_small_8_5 = this.txt_5_batch
            this.txt_small_8_6 = this.txt_6_batch
            this.txt_small_8_7 = this.txt_7_batch
            this.txt_small_8_8 = this.txt_8_batch
            this.txt_small_8_9 = this.txt_9_batch
            this.txt_small_8_10 = this.txt_10_batch
            this.txt_small_8_11 = this.txt_11_batch
            this.txt_small_8_12 = this.txt_12_batch
            this.txt_small_8_13 = this.txt_13_batch
            this.txt_small_8_14 = this.txt_14_batch
            this.txt_small_8_15 = this.txt_15_batch

          } else if (i === 8 && j === 1) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_small_disable_9 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_small_disable_9 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_small_9 = this.bet_type_batch
            this.txt_small_exeucte_num_of_pass_9 = this.txt_exeucte_num_of_pass_batch
            this.txt_small_9_1 = this.txt_1_batch
            this.txt_small_9_2 = this.txt_2_batch
            this.txt_small_9_3 = this.txt_3_batch
            this.txt_small_9_4 = this.txt_4_batch
            this.txt_small_9_5 = this.txt_5_batch
            this.txt_small_9_6 = this.txt_6_batch
            this.txt_small_9_7 = this.txt_7_batch
            this.txt_small_9_8 = this.txt_8_batch
            this.txt_small_9_9 = this.txt_9_batch
            this.txt_small_9_10 = this.txt_10_batch
            this.txt_small_9_11 = this.txt_11_batch
            this.txt_small_9_12 = this.txt_12_batch
            this.txt_small_9_13 = this.txt_13_batch
            this.txt_small_9_14 = this.txt_14_batch
            this.txt_small_9_15 = this.txt_15_batch

          } else if (i === 9 && j === 1) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_small_disable_10 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_small_disable_10 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_small_10 = this.bet_type_batch
            this.txt_small_exeucte_num_of_pass_10 = this.txt_exeucte_num_of_pass_batch
            this.txt_small_10_1 = this.txt_1_batch
            this.txt_small_10_2 = this.txt_2_batch
            this.txt_small_10_3 = this.txt_3_batch
            this.txt_small_10_4 = this.txt_4_batch
            this.txt_small_10_5 = this.txt_5_batch
            this.txt_small_10_6 = this.txt_6_batch
            this.txt_small_10_7 = this.txt_7_batch
            this.txt_small_10_8 = this.txt_8_batch
            this.txt_small_10_9 = this.txt_9_batch
            this.txt_small_10_10 = this.txt_10_batch
            this.txt_small_10_11 = this.txt_11_batch
            this.txt_small_10_12 = this.txt_12_batch
            this.txt_small_10_13 = this.txt_13_batch
            this.txt_small_10_14 = this.txt_14_batch
            this.txt_small_10_15 = this.txt_15_batch

          } else if (i === 0 && j === 2) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_odd_disable_1 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_odd_disable_1 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_odd_1 = this.bet_type_batch
            this.txt_odd_exeucte_num_of_pass_1 = this.txt_exeucte_num_of_pass_batch
            this.txt_odd_1_1 = this.txt_1_batch
            this.txt_odd_1_2 = this.txt_2_batch
            this.txt_odd_1_3 = this.txt_3_batch
            this.txt_odd_1_4 = this.txt_4_batch
            this.txt_odd_1_5 = this.txt_5_batch
            this.txt_odd_1_6 = this.txt_6_batch
            this.txt_odd_1_7 = this.txt_7_batch
            this.txt_odd_1_8 = this.txt_8_batch
            this.txt_odd_1_9 = this.txt_9_batch
            this.txt_odd_1_10 = this.txt_10_batch
            this.txt_odd_1_11 = this.txt_11_batch
            this.txt_odd_1_12 = this.txt_12_batch
            this.txt_odd_1_13 = this.txt_13_batch
            this.txt_odd_1_14 = this.txt_14_batch
            this.txt_odd_1_15 = this.txt_15_batch

          } else if (i === 1 && j === 2) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_odd_disable_2 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_odd_disable_2 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_odd_2 = this.bet_type_batch
            this.txt_odd_exeucte_num_of_pass_2 = this.txt_exeucte_num_of_pass_batch
            this.txt_odd_2_1 = this.txt_1_batch
            this.txt_odd_2_2 = this.txt_2_batch
            this.txt_odd_2_3 = this.txt_3_batch
            this.txt_odd_2_4 = this.txt_4_batch
            this.txt_odd_2_5 = this.txt_5_batch
            this.txt_odd_2_6 = this.txt_6_batch
            this.txt_odd_2_7 = this.txt_7_batch
            this.txt_odd_2_8 = this.txt_8_batch
            this.txt_odd_2_9 = this.txt_9_batch
            this.txt_odd_2_10 = this.txt_10_batch
            this.txt_odd_2_11 = this.txt_11_batch
            this.txt_odd_2_12 = this.txt_12_batch
            this.txt_odd_2_13 = this.txt_13_batch
            this.txt_odd_2_14 = this.txt_14_batch
            this.txt_odd_2_15 = this.txt_15_batch


          } else if (i === 2 && j === 2) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_odd_disable_3 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_odd_disable_3 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_odd_3 = this.bet_type_batch
            this.txt_odd_exeucte_num_of_pass_3 = this.txt_exeucte_num_of_pass_batch
            this.txt_odd_3_1 = this.txt_1_batch
            this.txt_odd_3_2 = this.txt_2_batch
            this.txt_odd_3_3 = this.txt_3_batch
            this.txt_odd_3_4 = this.txt_4_batch
            this.txt_odd_3_5 = this.txt_5_batch
            this.txt_odd_3_6 = this.txt_6_batch
            this.txt_odd_3_7 = this.txt_7_batch
            this.txt_odd_3_8 = this.txt_8_batch
            this.txt_odd_3_9 = this.txt_9_batch
            this.txt_odd_3_10 = this.txt_10_batch
            this.txt_odd_3_11 = this.txt_11_batch
            this.txt_odd_3_12 = this.txt_12_batch
            this.txt_odd_3_13 = this.txt_13_batch
            this.txt_odd_3_14 = this.txt_14_batch
            this.txt_odd_3_15 = this.txt_15_batch

          } else if (i === 3 && j === 2) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_odd_disable_4 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_odd_disable_4 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_odd_4 = this.bet_type_batch
            this.txt_odd_exeucte_num_of_pass_4 = this.txt_exeucte_num_of_pass_batch
            this.txt_odd_4_1 = this.txt_1_batch
            this.txt_odd_4_2 = this.txt_2_batch
            this.txt_odd_4_3 = this.txt_3_batch
            this.txt_odd_4_4 = this.txt_4_batch
            this.txt_odd_4_5 = this.txt_5_batch
            this.txt_odd_4_6 = this.txt_6_batch
            this.txt_odd_4_7 = this.txt_7_batch
            this.txt_odd_4_8 = this.txt_8_batch
            this.txt_odd_4_9 = this.txt_9_batch
            this.txt_odd_4_10 = this.txt_10_batch
            this.txt_odd_4_11 = this.txt_11_batch
            this.txt_odd_4_12 = this.txt_12_batch
            this.txt_odd_4_13 = this.txt_13_batch
            this.txt_odd_4_14 = this.txt_14_batch
            this.txt_odd_4_15 = this.txt_15_batch

          } else if (i === 4 && j === 2) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_odd_disable_5 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_odd_disable_5 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_odd_5 = this.bet_type_batch
            this.txt_odd_exeucte_num_of_pass_5 = this.txt_exeucte_num_of_pass_batch
            this.txt_odd_5_1 = this.txt_1_batch
            this.txt_odd_5_2 = this.txt_2_batch
            this.txt_odd_5_3 = this.txt_3_batch
            this.txt_odd_5_4 = this.txt_4_batch
            this.txt_odd_5_5 = this.txt_5_batch
            this.txt_odd_5_6 = this.txt_6_batch
            this.txt_odd_5_7 = this.txt_7_batch
            this.txt_odd_5_8 = this.txt_8_batch
            this.txt_odd_5_9 = this.txt_9_batch
            this.txt_odd_5_10 = this.txt_10_batch
            this.txt_odd_5_11 = this.txt_11_batch
            this.txt_odd_5_12 = this.txt_12_batch
            this.txt_odd_5_13 = this.txt_13_batch
            this.txt_odd_5_14 = this.txt_14_batch
            this.txt_odd_5_15 = this.txt_15_batch

          } else if (i === 5 && j === 2) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_odd_disable_6 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_odd_disable_6 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_odd_6 = this.bet_type_batch
            this.txt_odd_exeucte_num_of_pass_6 = this.txt_exeucte_num_of_pass_batch
            this.txt_odd_6_1 = this.txt_1_batch
            this.txt_odd_6_2 = this.txt_2_batch
            this.txt_odd_6_3 = this.txt_3_batch
            this.txt_odd_6_4 = this.txt_4_batch
            this.txt_odd_6_5 = this.txt_5_batch
            this.txt_odd_6_6 = this.txt_6_batch
            this.txt_odd_6_7 = this.txt_7_batch
            this.txt_odd_6_8 = this.txt_8_batch
            this.txt_odd_6_9 = this.txt_9_batch
            this.txt_odd_6_10 = this.txt_10_batch
            this.txt_odd_6_11 = this.txt_11_batch
            this.txt_odd_6_12 = this.txt_12_batch
            this.txt_odd_6_13 = this.txt_13_batch
            this.txt_odd_6_14 = this.txt_14_batch
            this.txt_odd_6_15 = this.txt_15_batch
            
          } else if (i === 6 && j === 2) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_odd_disable_7 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_odd_disable_7 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_odd_7 = this.bet_type_batch
            this.txt_odd_exeucte_num_of_pass_7 = this.txt_exeucte_num_of_pass_batch
            this.txt_odd_7_1 = this.txt_1_batch
            this.txt_odd_7_2 = this.txt_2_batch
            this.txt_odd_7_3 = this.txt_3_batch
            this.txt_odd_7_4 = this.txt_4_batch
            this.txt_odd_7_5 = this.txt_5_batch
            this.txt_odd_7_6 = this.txt_6_batch
            this.txt_odd_7_7 = this.txt_7_batch
            this.txt_odd_7_8 = this.txt_8_batch
            this.txt_odd_7_9 = this.txt_9_batch
            this.txt_odd_7_10 = this.txt_10_batch
            this.txt_odd_7_11 = this.txt_11_batch
            this.txt_odd_7_12 = this.txt_12_batch
            this.txt_odd_7_13 = this.txt_13_batch
            this.txt_odd_7_14 = this.txt_14_batch
            this.txt_odd_7_15 = this.txt_15_batch

          } else if (i === 7 && j === 2) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_odd_disable_8 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_odd_disable_8 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_odd_8 = this.bet_type_batch
            this.txt_odd_exeucte_num_of_pass_8 = this.txt_exeucte_num_of_pass_batch
            this.txt_odd_8_1 = this.txt_1_batch
            this.txt_odd_8_2 = this.txt_2_batch
            this.txt_odd_8_3 = this.txt_3_batch
            this.txt_odd_8_4 = this.txt_4_batch
            this.txt_odd_8_5 = this.txt_5_batch
            this.txt_odd_8_6 = this.txt_6_batch
            this.txt_odd_8_7 = this.txt_7_batch
            this.txt_odd_8_8 = this.txt_8_batch
            this.txt_odd_8_9 = this.txt_9_batch
            this.txt_odd_8_10 = this.txt_10_batch
            this.txt_odd_8_11 = this.txt_11_batch
            this.txt_odd_8_12 = this.txt_12_batch
            this.txt_odd_8_13 = this.txt_13_batch
            this.txt_odd_8_14 = this.txt_14_batch
            this.txt_odd_8_15 = this.txt_15_batch

          } else if (i === 8 && j === 2) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_odd_disable_9 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_odd_disable_9 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_odd_9 = this.bet_type_batch
            this.txt_odd_exeucte_num_of_pass_9 = this.txt_exeucte_num_of_pass_batch
            this.txt_odd_9_1 = this.txt_1_batch
            this.txt_odd_9_2 = this.txt_2_batch
            this.txt_odd_9_3 = this.txt_3_batch
            this.txt_odd_9_4 = this.txt_4_batch
            this.txt_odd_9_5 = this.txt_5_batch
            this.txt_odd_9_6 = this.txt_6_batch
            this.txt_odd_9_7 = this.txt_7_batch
            this.txt_odd_9_8 = this.txt_8_batch
            this.txt_odd_9_9 = this.txt_9_batch
            this.txt_odd_9_10 = this.txt_10_batch
            this.txt_odd_9_11 = this.txt_11_batch
            this.txt_odd_9_12 = this.txt_12_batch
            this.txt_odd_9_13 = this.txt_13_batch
            this.txt_odd_9_14 = this.txt_14_batch
            this.txt_odd_9_15 = this.txt_15_batch

          } else if (i === 9 && j === 2) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_odd_disable_10 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_odd_disable_10 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_odd_10 = this.bet_type_batch
            this.txt_odd_exeucte_num_of_pass_10 = this.txt_exeucte_num_of_pass_batch
            this.txt_odd_10_1 = this.txt_1_batch
            this.txt_odd_10_2 = this.txt_2_batch
            this.txt_odd_10_3 = this.txt_3_batch
            this.txt_odd_10_4 = this.txt_4_batch
            this.txt_odd_10_5 = this.txt_5_batch
            this.txt_odd_10_6 = this.txt_6_batch
            this.txt_odd_10_7 = this.txt_7_batch
            this.txt_odd_10_8 = this.txt_8_batch
            this.txt_odd_10_9 = this.txt_9_batch
            this.txt_odd_10_10 = this.txt_10_batch
            this.txt_odd_10_11 = this.txt_11_batch
            this.txt_odd_10_12 = this.txt_12_batch
            this.txt_odd_10_13 = this.txt_13_batch
            this.txt_odd_10_14 = this.txt_14_batch
            this.txt_odd_10_15 = this.txt_15_batch

          } else if (i === 0 && j === 3) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_even_disable_1 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_even_disable_1 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_even_1 = this.bet_type_batch
            this.txt_even_exeucte_num_of_pass_1 = this.txt_exeucte_num_of_pass_batch
            this.txt_even_1_1 = this.txt_1_batch
            this.txt_even_1_2 = this.txt_2_batch
            this.txt_even_1_3 = this.txt_3_batch
            this.txt_even_1_4 = this.txt_4_batch
            this.txt_even_1_5 = this.txt_5_batch
            this.txt_even_1_6 = this.txt_6_batch
            this.txt_even_1_7 = this.txt_7_batch
            this.txt_even_1_8 = this.txt_8_batch
            this.txt_even_1_9 = this.txt_9_batch
            this.txt_even_1_10 = this.txt_10_batch
            this.txt_even_1_11 = this.txt_11_batch
            this.txt_even_1_12 = this.txt_12_batch
            this.txt_even_1_13 = this.txt_13_batch
            this.txt_even_1_14 = this.txt_14_batch
            this.txt_even_1_15 = this.txt_15_batch

          } else if (i === 1 && j === 3) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_even_disable_2 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_even_disable_2 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_even_2 = this.bet_type_batch
            this.txt_even_exeucte_num_of_pass_2 = this.txt_exeucte_num_of_pass_batch
            this.txt_even_2_1 = this.txt_1_batch
            this.txt_even_2_2 = this.txt_2_batch
            this.txt_even_2_3 = this.txt_3_batch
            this.txt_even_2_4 = this.txt_4_batch
            this.txt_even_2_5 = this.txt_5_batch
            this.txt_even_2_6 = this.txt_6_batch
            this.txt_even_2_7 = this.txt_7_batch
            this.txt_even_2_8 = this.txt_8_batch
            this.txt_even_2_9 = this.txt_9_batch
            this.txt_even_2_10 = this.txt_10_batch
            this.txt_even_2_11 = this.txt_11_batch
            this.txt_even_2_12 = this.txt_12_batch
            this.txt_even_2_13 = this.txt_13_batch
            this.txt_even_2_14 = this.txt_14_batch
            this.txt_even_2_15 = this.txt_15_batch
            
          } else if (i === 2 && j === 3) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_even_disable_3 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_even_disable_3 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_even_3 = this.bet_type_batch
            this.txt_even_exeucte_num_of_pass_3 = this.txt_exeucte_num_of_pass_batch
            this.txt_even_3_1 = this.txt_1_batch
            this.txt_even_3_2 = this.txt_2_batch
            this.txt_even_3_3 = this.txt_3_batch
            this.txt_even_3_4 = this.txt_4_batch
            this.txt_even_3_5 = this.txt_5_batch
            this.txt_even_3_6 = this.txt_6_batch
            this.txt_even_3_7 = this.txt_7_batch
            this.txt_even_3_8 = this.txt_8_batch
            this.txt_even_3_9 = this.txt_9_batch
            this.txt_even_3_10 = this.txt_10_batch
            this.txt_even_3_11 = this.txt_11_batch
            this.txt_even_3_12 = this.txt_12_batch
            this.txt_even_3_13 = this.txt_13_batch
            this.txt_even_3_14 = this.txt_14_batch
            this.txt_even_3_15 = this.txt_15_batch

          } else if (i === 3 && j === 3) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_even_disable_4 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_even_disable_4 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_even_4 = this.bet_type_batch
            this.txt_even_exeucte_num_of_pass_4 = this.txt_exeucte_num_of_pass_batch
            this.txt_even_4_1 = this.txt_1_batch
            this.txt_even_4_2 = this.txt_2_batch
            this.txt_even_4_3 = this.txt_3_batch
            this.txt_even_4_4 = this.txt_4_batch
            this.txt_even_4_5 = this.txt_5_batch
            this.txt_even_4_6 = this.txt_6_batch
            this.txt_even_4_7 = this.txt_7_batch
            this.txt_even_4_8 = this.txt_8_batch
            this.txt_even_4_9 = this.txt_9_batch
            this.txt_even_4_10 = this.txt_10_batch
            this.txt_even_4_11 = this.txt_11_batch
            this.txt_even_4_12 = this.txt_12_batch
            this.txt_even_4_13 = this.txt_13_batch
            this.txt_even_4_14 = this.txt_14_batch
            this.txt_even_4_15 = this.txt_15_batch

          } else if (i === 4 && j === 3) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_even_disable_5 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_even_disable_5 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_even_5 = this.bet_type_batch
            this.txt_even_exeucte_num_of_pass_5 = this.txt_exeucte_num_of_pass_batch
            this.txt_even_5_1 = this.txt_1_batch
            this.txt_even_5_2 = this.txt_2_batch
            this.txt_even_5_3 = this.txt_3_batch
            this.txt_even_5_4 = this.txt_4_batch
            this.txt_even_5_5 = this.txt_5_batch
            this.txt_even_5_6 = this.txt_6_batch
            this.txt_even_5_7 = this.txt_7_batch
            this.txt_even_5_8 = this.txt_8_batch
            this.txt_even_5_9 = this.txt_9_batch
            this.txt_even_5_10 = this.txt_10_batch
            this.txt_even_5_11 = this.txt_11_batch
            this.txt_even_5_12 = this.txt_12_batch
            this.txt_even_5_13 = this.txt_13_batch
            this.txt_even_5_14 = this.txt_14_batch
            this.txt_even_5_15 = this.txt_15_batch

          } else if (i === 5 && j === 3) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_even_disable_6 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_even_disable_6 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_even_6 = this.bet_type_batch
            this.txt_even_exeucte_num_of_pass_6 = this.txt_exeucte_num_of_pass_batch
            this.txt_even_6_1 = this.txt_1_batch
            this.txt_even_6_2 = this.txt_2_batch
            this.txt_even_6_3 = this.txt_3_batch
            this.txt_even_6_4 = this.txt_4_batch
            this.txt_even_6_5 = this.txt_5_batch
            this.txt_even_6_6 = this.txt_6_batch
            this.txt_even_6_7 = this.txt_7_batch
            this.txt_even_6_8 = this.txt_8_batch
            this.txt_even_6_9 = this.txt_9_batch
            this.txt_even_6_10 = this.txt_10_batch
            this.txt_even_6_11 = this.txt_11_batch
            this.txt_even_6_12 = this.txt_12_batch
            this.txt_even_6_13 = this.txt_13_batch
            this.txt_even_6_14 = this.txt_14_batch
            this.txt_even_6_15 = this.txt_15_batch

          } else if (i === 6 && j === 3) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_even_disable_7 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_even_disable_7 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_even_7 = this.bet_type_batch
            this.txt_even_exeucte_num_of_pass_7 = this.txt_exeucte_num_of_pass_batch
            this.txt_even_7_1 = this.txt_1_batch
            this.txt_even_7_2 = this.txt_2_batch
            this.txt_even_7_3 = this.txt_3_batch
            this.txt_even_7_4 = this.txt_4_batch
            this.txt_even_7_5 = this.txt_5_batch
            this.txt_even_7_6 = this.txt_6_batch
            this.txt_even_7_7 = this.txt_7_batch
            this.txt_even_7_8 = this.txt_8_batch
            this.txt_even_7_9 = this.txt_9_batch
            this.txt_even_7_10 = this.txt_10_batch
            this.txt_even_7_11 = this.txt_11_batch
            this.txt_even_7_12 = this.txt_12_batch
            this.txt_even_7_13 = this.txt_13_batch
            this.txt_even_7_14 = this.txt_14_batch
            this.txt_even_7_15 = this.txt_15_batch

          } else if (i === 7 && j === 3) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_even_disable_8 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_even_disable_8 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_even_8 = this.bet_type_batch
            this.txt_even_exeucte_num_of_pass_8 = this.txt_exeucte_num_of_pass_batch
            this.txt_even_8_1 = this.txt_1_batch
            this.txt_even_8_2 = this.txt_2_batch
            this.txt_even_8_3 = this.txt_3_batch
            this.txt_even_8_4 = this.txt_4_batch
            this.txt_even_8_5 = this.txt_5_batch
            this.txt_even_8_6 = this.txt_6_batch
            this.txt_even_8_7 = this.txt_7_batch
            this.txt_even_8_8 = this.txt_8_batch
            this.txt_even_8_9 = this.txt_9_batch
            this.txt_even_8_10 = this.txt_10_batch
            this.txt_even_8_11 = this.txt_11_batch
            this.txt_even_8_12 = this.txt_12_batch
            this.txt_even_8_13 = this.txt_13_batch
            this.txt_even_8_14 = this.txt_14_batch
            this.txt_even_8_15 = this.txt_15_batch

          } else if (i === 8 && j === 3) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_even_disable_9 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_even_disable_9 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_even_9 = this.bet_type_batch
            this.txt_even_exeucte_num_of_pass_9 = this.txt_exeucte_num_of_pass_batch
            this.txt_even_9_1 = this.txt_1_batch
            this.txt_even_9_2 = this.txt_2_batch
            this.txt_even_9_3 = this.txt_3_batch
            this.txt_even_9_4 = this.txt_4_batch
            this.txt_even_9_5 = this.txt_5_batch
            this.txt_even_9_6 = this.txt_6_batch
            this.txt_even_9_7 = this.txt_7_batch
            this.txt_even_9_8 = this.txt_8_batch
            this.txt_even_9_9 = this.txt_9_batch
            this.txt_even_9_10 = this.txt_10_batch
            this.txt_even_9_11 = this.txt_11_batch
            this.txt_even_9_12 = this.txt_12_batch
            this.txt_even_9_13 = this.txt_13_batch
            this.txt_even_9_14 = this.txt_14_batch
            this.txt_even_9_15 = this.txt_15_batch

          } else if (i === 9 && j === 3) {
            if (this.items_batch[i].isSetting[j] === true) {
              this.chk_even_disable_10 = 0
              this.items[i].isSetting[j] = 1
            } else {
              this.chk_even_disable_10 = 1
              this.items[i].isSetting[j] = 0
            }

            this.bet_type_even_10 = this.bet_type_batch
            this.txt_even_exeucte_num_of_pass_10 = this.txt_exeucte_num_of_pass_batch
            this.txt_even_10_1 = this.txt_1_batch
            this.txt_even_10_2 = this.txt_2_batch
            this.txt_even_10_3 = this.txt_3_batch
            this.txt_even_10_4 = this.txt_4_batch
            this.txt_even_10_5 = this.txt_5_batch
            this.txt_even_10_6 = this.txt_6_batch
            this.txt_even_10_7 = this.txt_7_batch
            this.txt_even_10_8 = this.txt_8_batch
            this.txt_even_10_9 = this.txt_9_batch
            this.txt_even_10_10 = this.txt_10_batch
            this.txt_even_10_11 = this.txt_11_batch
            this.txt_even_10_12 = this.txt_12_batch
            this.txt_even_10_13 = this.txt_13_batch
            this.txt_even_10_14 = this.txt_14_batch
            this.txt_even_10_15 = this.txt_15_batch

          } else {
            console.log("Not Found")
          }

        }
      }

      console.log(this.items_batch)
      var trueCount = checkSettingTrueCount(this.items_batch)
      // check bet type
      var errorBetTypeCount = checkBetTypeNotNull(this.bet_type_batch)

      const amount_list = [];
      amount_list.push(this.txt_1_batch)
      amount_list.push(this.txt_2_batch)
      amount_list.push(this.txt_3_batch)
      amount_list.push(this.txt_4_batch)
      amount_list.push(this.txt_5_batch)
      amount_list.push(this.txt_6_batch)
      amount_list.push(this.txt_7_batch)
      amount_list.push(this.txt_8_batch)
      amount_list.push(this.txt_9_batch)
      amount_list.push(this.txt_10_batch)
      amount_list.push(this.txt_11_batch)
      amount_list.push(this.txt_12_batch)
      amount_list.push(this.txt_13_batch)
      amount_list.push(this.txt_14_batch)
      amount_list.push(this.txt_15_batch)

      // check amount
      var errorAmountCount = checkAnyAmount(amount_list)

      var errorZeroAmountCount = checkAnyAmountIsNotZero(amount_list, this.txt_exeucte_num_of_pass_batch)

      // check exeucte_num_of_pass
      var errorCount = checkExeucteNumOfPass(amount_list, this.txt_exeucte_num_of_pass_batch)

      if (trueCount > 0) {
        if (errorCount > 0) {
          this.alertError = true
          this.repsMessage = "設定執行關數與設定關數金額不符合"
        } else if (errorAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "不能輸入1，該遊戲最小下注金額為2"
        } else if (errorZeroAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "設定執行關數與設定關數金額不能為0"
        } else if (errorBetTypeCount > 0) {
          this.alertError = true
          this.repsMessage = "請處理必要欄位"
        }
        else {
          this.dialog_wg_batch = false
          this.e1 = 3
        }
      } else {
        this.dialog_wg_batch = false
        this.e1 = 3
      }

    },
    // 批次初始設定
    batchSetting() {
      console.log(this.items_batch)
      this.dialog_wg_batch = true
      this.bet_type_batch = "凹注"
      this.txt_exeucte_num_of_pass_batch = 1
      this.txt_1_batch = 0
      this.txt_6_batch = 0
      this.txt_2_batch = 0
      this.txt_7_batch = 0
      this.txt_3_batch = 0
      this.txt_8_batch = 0
      this.txt_4_batch = 0
      this.txt_9_batch = 0
      this.txt_5_batch = 0
      this.txt_10_batch = 0
      this.txt_11_batch = 0
      this.txt_12_batch = 0
      this.txt_13_batch = 0
      this.txt_14_batch = 0
      this.txt_15_batch = 0

    },
    cancelBatchSetting(nextStep) {
      window.scrollTo(0, -100);

      console.log(this.items_batch)
      for (let i = 0; i < this.items_batch.length; i++) {
        this.items_batch[i].isSetting = [false, false, false, false]
      }
      console.log(this.items_batch)
      this.e1 = nextStep
    },
    // WG彩票基本兩面盤設定
    wgSetting(name, type) {
      window.scrollTo(0, -100);

      for (let i = 0; i < this.items_batch.length; i++) {
        this.items_batch[i].isSetting = [false, false, false, false]
      }

      this.dialog_wg = true

      this.rankName = name
      this.orderName = type

      if (type === "隨機") {
        this.isRandomType = true
      } else {
        this.isRandomType = false
      }

      this.getVariableValue(name, type)

    },
    // WG彩票10星下注自選號碼檢查與設定
    wgNumberSelect(name, num) {
      window.scrollTo(0, -100);

      //console.log(name)
      //console.log(num)

      let r = 0
      if (name === "冠軍") {
        r = 0
      } else if (name === "亞軍") {
        r = 1
      } else if (name === "第三名") {
        r = 2
      } else if (name === "第四名") {
        r = 3
      } else if (name === "第五名") {
        r = 4
      } else if (name === "第六名") {
        r = 5
      } else if (name === "第七名") {
        r = 6
      } else if (name === "第八名") {
        r = 7
      } else if (name === "第九名") {
        r = 8
      } else if (name === "第十名") {
        r = 9
      } else {
        console.log("Not Found")
      }

      // 檢查各個名次是否有自選重複號碼
      let errorRankCount = 0
      for (let i = 0; i < this.items_10star.length; i++) {
        console.log(this.items_10star[i].isSetting[num - 1])
        if (this.items_10star[i].isSetting[num - 1] === 1 && i !== r) {
          errorRankCount = errorRankCount + 1
        }
      }

      if (errorRankCount > 0) {
        this.alertError = true
        this.repsMessage = "各個名次挑選的號碼不得重複"
        return
      }

      // 檢查各個名次只能挑選一個號碼
      let errorNumCount = 0
      for (let j = 0; j < this.items_10star[r].isSetting.length; j++) {
        if (this.items_10star[r].isSetting[j] === 1 && j !== num - 1) {
          errorNumCount = errorNumCount + 1
        }
      }

      if (errorNumCount > 0) {
        this.alertError = true
        this.repsMessage = "各個名次只能挑選一個號碼"
        return
      }

      // 確認選擇的號碼
      if (this.items_10star[r].isSetting[num - 1] === 0) {
        this.items_10star[r].isSetting[num - 1] = 1

        if (name === "冠軍") {
          this.rank_1 = num
        } else if (name === "亞軍") {
          this.rank_2 = num
        } else if (name === "第三名") {
          this.rank_3 = num
        } else if (name === "第四名") {
          this.rank_4 = num
        } else if (name === "第五名") {
          this.rank_5 = num
        } else if (name === "第六名") {
          this.rank_6 = num
        } else if (name === "第七名") {
          this.rank_7 = num
        } else if (name === "第八名") {
          this.rank_8 = num
        } else if (name === "第九名") {
          this.rank_9 = num
        } else if (name === "第十名") {
          this.rank_10 = num
        } else {
          console.log("Not Found")
        }

      } else {
        this.items_10star[r].isSetting[num - 1] = 0
      }

      console.log(this.items_10star)
      this.$forceUpdate()

    },
    // WG彩票10星下注自選號碼檢查是否選擇10個號碼
    wgNumberSelectCheck() {
      let count = 0
      for (let i = 0; i < this.items_10star.length; i++) {
        let numCount = 0
        for (let j = 0; j < this.items_10star[i].isSetting.length; j++) {
          if (this.items_10star[i].isSetting[j] === 1) {
            numCount = numCount + 1
          }
        }
        // 檢查各個名次只能挑選一個號碼
        if (numCount === 1) {
          count = count + 1
        }
      }

      // 檢查是否選擇10個號碼
      if (count < 10) {
        this.alertError = true
        this.repsMessage = "請選擇10個號碼"
      } else {
        this.e1 = 6
      }

    },
    step1Next() {
      var errorCount = 0

      let betting = this.betting
      if ((betting === 'null') || (typeof (betting) === "undefined") || (betting === "")) {
        errorCount = errorCount + 1
      }

      let account = this.account
      if ((account === 'null') || (typeof (account) === "undefined") || (account === "")) {
        errorCount = errorCount + 1
      }

      let password = this.password
      if ((password === 'null') || (typeof (password) === "undefined") || (password === "")) {
        errorCount = errorCount + 1
      }

      if (errorCount > 0) {
        this.alertError = true
        this.repsMessage = "請選擇或輸入必要欄位"
      }
      else {
        this.e1 = 2
      }
    },
    step2Next() {
      var errorLotteryCount = 0
      if ((this.selectedLotteryType === null) || (typeof (this.selectedLotteryType) === "undefined") || (this.selectedLotteryType === "")) {
        errorLotteryCount = errorLotteryCount + 1
      }

      var errorOrderCount = 0
      if ((this.selectedOrderType === null) || (typeof (this.selectedOrderType) === "undefined") || (this.selectedOrderType === "")) {
        errorOrderCount = errorOrderCount + 1
      }

      if (errorLotteryCount > 0) {
        this.alertError = true
        this.repsMessage = "請選擇任一彩種"
      } else if (errorOrderCount > 0) {
        this.alertError = true
        this.repsMessage = "請選擇任一下注法"
      }
      else {
        if (this.selectedOrderType === "basic") {
          this.e1 = 3
        } else if (this.selectedOrderType === "10star") {
          this.e1 = 5
        } else {
          console.log("Not Found")
        }

      }
    },
    verifyAndSaveVariableValue(rankName, orderName) {
      // check bet type
      var errorBetTypeCount = checkBetTypeNotNull(this.bet_type)
      if (orderName === "隨機") {
        errorBetTypeCount = errorBetTypeCount + checkBetTypeNotNull(this.random_type)
      }

      const amount_list = [];
      amount_list.push(this.txt_1)
      amount_list.push(this.txt_2)
      amount_list.push(this.txt_3)
      amount_list.push(this.txt_4)
      amount_list.push(this.txt_5)
      amount_list.push(this.txt_6)
      amount_list.push(this.txt_7)
      amount_list.push(this.txt_8)
      amount_list.push(this.txt_9)
      amount_list.push(this.txt_10)
      amount_list.push(this.txt_11)
      amount_list.push(this.txt_12)
      amount_list.push(this.txt_13)
      amount_list.push(this.txt_14)
      amount_list.push(this.txt_15)

      // check amount
      var errorAmountCount = checkAnyAmount(amount_list)

      var errorZeroAmountCount = checkAnyAmountIsNotZero(amount_list, this.txt_exeucte_num_of_pass)

      // check exeucte_num_of_pass
      var errorCount = checkExeucteNumOfPass(amount_list, this.txt_exeucte_num_of_pass)
      console.log("checkExeucteNumOfPass")
      console.log(errorCount)

      if ((this.chk_disable === 1) || this.chk_disable === true) {
        this.dialog_wg = false
        this.setVariableValue(rankName, orderName)
      } else {
        if (errorCount > 0) {
          this.alertError = true
          this.repsMessage = "設定執行關數與設定關數金額不符合"
        } else if (errorAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "不能輸入1，該遊戲最小下注金額為2"
        } else if (errorZeroAmountCount > 0) {
          this.alertError = true
          this.repsMessage = "設定執行關數與設定關數金額不能為0，但可勾選不啟動此設定"
        } else if (errorBetTypeCount > 0) {
          this.alertError = true
          this.repsMessage = "請處理必要欄位"
        }
        else {
          this.dialog_wg = false
          this.setVariableValue(rankName, orderName)
        }
      }
    },
    setVariableValue(rankName, orderName) {
      if (rankName === "冠軍" && orderName === "大") {
        this.bet_type_big_1 = this.bet_type
        this.txt_big_exeucte_num_of_pass_1 = this.txt_exeucte_num_of_pass
        this.txt_big_1_1 = this.txt_1
        this.txt_big_1_2 = this.txt_2
        this.txt_big_1_3 = this.txt_3
        this.txt_big_1_4 = this.txt_4
        this.txt_big_1_5 = this.txt_5
        this.txt_big_1_6 = this.txt_6
        this.txt_big_1_7 = this.txt_7
        this.txt_big_1_8 = this.txt_8
        this.txt_big_1_9 = this.txt_9
        this.txt_big_1_10 = this.txt_10
        this.txt_big_1_11 = this.txt_11
        this.txt_big_1_12 = this.txt_12
        this.txt_big_1_13 = this.txt_13
        this.txt_big_1_14 = this.txt_14
        this.txt_big_1_15 = this.txt_15
        this.chk_big_disable_1 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "冠軍") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[0] = 0
            } else {
              this.items[i].isSetting[0] = 1
            }
            break
          }
        }
      } else if (rankName === "冠軍" && orderName === "小") {
        this.bet_type_small_1 = this.bet_type
        this.txt_small_exeucte_num_of_pass_1 = this.txt_exeucte_num_of_pass
        this.txt_small_1_1 = this.txt_1
        this.txt_small_1_2 = this.txt_2
        this.txt_small_1_3 = this.txt_3
        this.txt_small_1_4 = this.txt_4
        this.txt_small_1_5 = this.txt_5
        this.txt_small_1_6 = this.txt_6
        this.txt_small_1_7 = this.txt_7
        this.txt_small_1_8 = this.txt_8
        this.txt_small_1_9 = this.txt_9
        this.txt_small_1_10 = this.txt_10
        this.txt_small_1_11 = this.txt_11
        this.txt_small_1_12 = this.txt_12
        this.txt_small_1_13 = this.txt_13
        this.txt_small_1_14 = this.txt_14
        this.txt_small_1_15 = this.txt_15
        this.chk_small_disable_1 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "冠軍") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[1] = 0
            } else {
              this.items[i].isSetting[1] = 1
            }
            break
          }
        }
      } else if (rankName === "冠軍" && orderName === "單") {
        this.bet_type_odd_1 = this.bet_type
        this.txt_odd_exeucte_num_of_pass_1 = this.txt_exeucte_num_of_pass
        this.txt_odd_1_1 = this.txt_1
        this.txt_odd_1_2 = this.txt_2
        this.txt_odd_1_3 = this.txt_3
        this.txt_odd_1_4 = this.txt_4
        this.txt_odd_1_5 = this.txt_5
        this.txt_odd_1_6 = this.txt_6
        this.txt_odd_1_7 = this.txt_7
        this.txt_odd_1_8 = this.txt_8
        this.txt_odd_1_9 = this.txt_9
        this.txt_odd_1_10 = this.txt_10
        this.txt_odd_1_11 = this.txt_11
        this.txt_odd_1_12 = this.txt_12
        this.txt_odd_1_13 = this.txt_13
        this.txt_odd_1_14 = this.txt_14
        this.txt_odd_1_15 = this.txt_15
        this.chk_odd_disable_1 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "冠軍") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[2] = 0
            } else {
              this.items[i].isSetting[2] = 1
            }
            break
          }
        }
      } else if (rankName === "冠軍" && orderName === "雙") {
        this.bet_type_even_1 = this.bet_type
        this.txt_even_exeucte_num_of_pass_1 = this.txt_exeucte_num_of_pass
        this.txt_even_1_1 = this.txt_1
        this.txt_even_1_2 = this.txt_2
        this.txt_even_1_3 = this.txt_3
        this.txt_even_1_4 = this.txt_4
        this.txt_even_1_5 = this.txt_5
        this.txt_even_1_6 = this.txt_6
        this.txt_even_1_7 = this.txt_7
        this.txt_even_1_8 = this.txt_8
        this.txt_even_1_9 = this.txt_9
        this.txt_even_1_10 = this.txt_10
        this.txt_even_1_11 = this.txt_11
        this.txt_even_1_12 = this.txt_12
        this.txt_even_1_13 = this.txt_13
        this.txt_even_1_14 = this.txt_14
        this.txt_even_1_15 = this.txt_15
        this.chk_even_disable_1 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "冠軍") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[3] = 0
            } else {
              this.items[i].isSetting[3] = 1
            }
            break
          }
        }
      } else if (rankName === "冠軍" && orderName === "隨機") {
        this.bet_type_random_1 = this.bet_type
        this.random_type_1 = this.random_type
        this.txt_random_exeucte_num_of_pass_1 = this.txt_exeucte_num_of_pass
        this.txt_random_1_1 = this.txt_1
        this.txt_random_1_2 = this.txt_2
        this.txt_random_1_3 = this.txt_3
        this.txt_random_1_4 = this.txt_4
        this.txt_random_1_5 = this.txt_5
        this.txt_random_1_6 = this.txt_6
        this.txt_random_1_7 = this.txt_7
        this.txt_random_1_8 = this.txt_8
        this.txt_random_1_9 = this.txt_9
        this.txt_random_1_10 = this.txt_10
        this.txt_random_1_11 = this.txt_11
        this.txt_random_1_12 = this.txt_12
        this.txt_random_1_13 = this.txt_13
        this.txt_random_1_14 = this.txt_14
        this.txt_random_1_15 = this.txt_15
        this.chk_random_disable_1 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "冠軍") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[4] = 0
            } else {
              this.items[i].isSetting[4] = 1
            }
            break
          }
        }
      } else if (rankName === "亞軍" && orderName === "大") {
        this.bet_type_big_2 = this.bet_type
        this.txt_big_exeucte_num_of_pass_2 = this.txt_exeucte_num_of_pass
        this.txt_big_2_1 = this.txt_1
        this.txt_big_2_2 = this.txt_2
        this.txt_big_2_3 = this.txt_3
        this.txt_big_2_4 = this.txt_4
        this.txt_big_2_5 = this.txt_5
        this.txt_big_2_6 = this.txt_6
        this.txt_big_2_7 = this.txt_7
        this.txt_big_2_8 = this.txt_8
        this.txt_big_2_9 = this.txt_9
        this.txt_big_2_10 = this.txt_10
        this.txt_big_2_11 = this.txt_11
        this.txt_big_2_12 = this.txt_12
        this.txt_big_2_13 = this.txt_13
        this.txt_big_2_14 = this.txt_14
        this.txt_big_2_15 = this.txt_15
        this.chk_big_disable_2 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "亞軍") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[0] = 0
            } else {
              this.items[i].isSetting[0] = 1
            }
            break
          }
        }
      } else if (rankName === "亞軍" && orderName === "小") {
        this.bet_type_small_2 = this.bet_type
        this.txt_small_exeucte_num_of_pass_2 = this.txt_exeucte_num_of_pass
        this.txt_small_2_1 = this.txt_1
        this.txt_small_2_2 = this.txt_2
        this.txt_small_2_3 = this.txt_3
        this.txt_small_2_4 = this.txt_4
        this.txt_small_2_5 = this.txt_5
        this.txt_small_2_6 = this.txt_6
        this.txt_small_2_7 = this.txt_7
        this.txt_small_2_8 = this.txt_8
        this.txt_small_2_9 = this.txt_9
        this.txt_small_2_10 = this.txt_10
        this.txt_small_2_11 = this.txt_11
        this.txt_small_2_12 = this.txt_12
        this.txt_small_2_13 = this.txt_13
        this.txt_small_2_14 = this.txt_14
        this.txt_small_2_15 = this.txt_15
        this.chk_small_disable_2 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "亞軍") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[1] = 0
            } else {
              this.items[i].isSetting[1] = 1
            }
            break
          }
        }
      } else if (rankName === "亞軍" && orderName === "單") {
        this.bet_type_odd_2 = this.bet_type
        this.txt_odd_exeucte_num_of_pass_2 = this.txt_exeucte_num_of_pass
        this.txt_odd_2_1 = this.txt_1
        this.txt_odd_2_2 = this.txt_2
        this.txt_odd_2_3 = this.txt_3
        this.txt_odd_2_4 = this.txt_4
        this.txt_odd_2_5 = this.txt_5
        this.txt_odd_2_6 = this.txt_6
        this.txt_odd_2_7 = this.txt_7
        this.txt_odd_2_8 = this.txt_8
        this.txt_odd_2_9 = this.txt_9
        this.txt_odd_2_10 = this.txt_10
        this.txt_odd_2_11 = this.txt_11
        this.txt_odd_2_12 = this.txt_12
        this.txt_odd_2_13 = this.txt_13
        this.txt_odd_2_14 = this.txt_14
        this.txt_odd_2_15 = this.txt_15
        this.chk_odd_disable_2 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "亞軍") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[2] = 0
            } else {
              this.items[i].isSetting[2] = 1
            }
            break
          }
        }
      } else if (rankName === "亞軍" && orderName === "雙") {
        this.bet_type_even_2 = this.bet_type
        this.txt_even_exeucte_num_of_pass_2 = this.txt_exeucte_num_of_pass
        this.txt_even_2_1 = this.txt_1
        this.txt_even_2_2 = this.txt_2
        this.txt_even_2_3 = this.txt_3
        this.txt_even_2_4 = this.txt_4
        this.txt_even_2_5 = this.txt_5
        this.txt_even_2_6 = this.txt_6
        this.txt_even_2_7 = this.txt_7
        this.txt_even_2_8 = this.txt_8
        this.txt_even_2_9 = this.txt_9
        this.txt_even_2_10 = this.txt_10
        this.txt_even_2_11 = this.txt_11
        this.txt_even_2_12 = this.txt_12
        this.txt_even_2_13 = this.txt_13
        this.txt_even_2_14 = this.txt_14
        this.txt_even_2_15 = this.txt_15
        this.chk_even_disable_2 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "亞軍") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[3] = 0
            } else {
              this.items[i].isSetting[3] = 1
            }
            break
          }
        }
      } else if (rankName === "亞軍" && orderName === "隨機") {
        this.bet_type_random_2 = this.bet_type
        this.random_type_2 = this.random_type
        this.txt_random_exeucte_num_of_pass_2 = this.txt_exeucte_num_of_pass
        this.txt_random_2_1 = this.txt_1
        this.txt_random_2_2 = this.txt_2
        this.txt_random_2_3 = this.txt_3
        this.txt_random_2_4 = this.txt_4
        this.txt_random_2_5 = this.txt_5
        this.txt_random_2_6 = this.txt_6
        this.txt_random_2_7 = this.txt_7
        this.txt_random_2_8 = this.txt_8
        this.txt_random_2_9 = this.txt_9
        this.txt_random_2_10 = this.txt_10
        this.txt_random_2_11 = this.txt_11
        this.txt_random_2_12 = this.txt_12
        this.txt_random_2_13 = this.txt_13
        this.txt_random_2_14 = this.txt_14
        this.txt_random_2_15 = this.txt_15
        this.chk_random_disable_2 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "亞軍") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[4] = 0
            } else {
              this.items[i].isSetting[4] = 1
            }
            break
          }
        }
      } else if (rankName === "第三名" && orderName === "大") {
        this.bet_type_big_3 = this.bet_type
        this.txt_big_exeucte_num_of_pass_3 = this.txt_exeucte_num_of_pass
        this.txt_big_3_1 = this.txt_1
        this.txt_big_3_2 = this.txt_2
        this.txt_big_3_3 = this.txt_3
        this.txt_big_3_4 = this.txt_4
        this.txt_big_3_5 = this.txt_5
        this.txt_big_3_6 = this.txt_6
        this.txt_big_3_7 = this.txt_7
        this.txt_big_3_8 = this.txt_8
        this.txt_big_3_9 = this.txt_9
        this.txt_big_3_10 = this.txt_10
        this.txt_big_3_11 = this.txt_11
        this.txt_big_3_12 = this.txt_12
        this.txt_big_3_13 = this.txt_13
        this.txt_big_3_14 = this.txt_14
        this.txt_big_3_15 = this.txt_15
        this.chk_big_disable_3 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第三名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[0] = 0
            } else {
              this.items[i].isSetting[0] = 1
            }
            break
          }
        }
      } else if (rankName === "第三名" && orderName === "小") {
        this.bet_type_small_3 = this.bet_type
        this.txt_small_exeucte_num_of_pass_3 = this.txt_exeucte_num_of_pass
        this.txt_small_3_1 = this.txt_1
        this.txt_small_3_2 = this.txt_2
        this.txt_small_3_3 = this.txt_3
        this.txt_small_3_4 = this.txt_4
        this.txt_small_3_5 = this.txt_5
        this.txt_small_3_6 = this.txt_6
        this.txt_small_3_7 = this.txt_7
        this.txt_small_3_8 = this.txt_8
        this.txt_small_3_9 = this.txt_9
        this.txt_small_3_10 = this.txt_10
        this.txt_small_3_11 = this.txt_11
        this.txt_small_3_12 = this.txt_12
        this.txt_small_3_13 = this.txt_13
        this.txt_small_3_14 = this.txt_14
        this.txt_small_3_15 = this.txt_15
        this.chk_small_disable_3 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第三名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[1] = 0
            } else {
              this.items[i].isSetting[1] = 1
            }
            break
          }
        }
      } else if (rankName === "第三名" && orderName === "單") {
        this.bet_type_odd_3 = this.bet_type
        this.txt_odd_exeucte_num_of_pass_3 = this.txt_exeucte_num_of_pass
        this.txt_odd_3_1 = this.txt_1
        this.txt_odd_3_2 = this.txt_2
        this.txt_odd_3_3 = this.txt_3
        this.txt_odd_3_4 = this.txt_4
        this.txt_odd_3_5 = this.txt_5
        this.txt_odd_3_6 = this.txt_6
        this.txt_odd_3_7 = this.txt_7
        this.txt_odd_3_8 = this.txt_8
        this.txt_odd_3_9 = this.txt_9
        this.txt_odd_3_10 = this.txt_10
        this.txt_odd_3_11 = this.txt_11
        this.txt_odd_3_12 = this.txt_12
        this.txt_odd_3_13 = this.txt_13
        this.txt_odd_3_14 = this.txt_14
        this.txt_odd_3_15 = this.txt_15
        this.chk_odd_disable_3 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第三名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[2] = 0
            } else {
              this.items[i].isSetting[2] = 1
            }
            break
          }
        }
      } else if (rankName === "第三名" && orderName === "雙") {
        this.bet_type_even_3 = this.bet_type
        this.txt_even_exeucte_num_of_pass_3 = this.txt_exeucte_num_of_pass
        this.txt_even_3_1 = this.txt_1
        this.txt_even_3_2 = this.txt_2
        this.txt_even_3_3 = this.txt_3
        this.txt_even_3_4 = this.txt_4
        this.txt_even_3_5 = this.txt_5
        this.txt_even_3_6 = this.txt_6
        this.txt_even_3_7 = this.txt_7
        this.txt_even_3_8 = this.txt_8
        this.txt_even_3_9 = this.txt_9
        this.txt_even_3_10 = this.txt_10
        this.txt_even_3_11 = this.txt_11
        this.txt_even_3_12 = this.txt_12
        this.txt_even_3_13 = this.txt_13
        this.txt_even_3_14 = this.txt_14
        this.txt_even_3_15 = this.txt_15
        this.chk_even_disable_3 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第三名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[3] = 0
            } else {
              this.items[i].isSetting[3] = 1
            }
            break
          }
        }
      } else if (rankName === "第三名" && orderName === "隨機") {
        this.bet_type_random_3 = this.bet_type
        this.random_type_3 = this.random_type
        this.txt_random_exeucte_num_of_pass_3 = this.txt_exeucte_num_of_pass
        this.txt_random_3_1 = this.txt_1
        this.txt_random_3_2 = this.txt_2
        this.txt_random_3_3 = this.txt_3
        this.txt_random_3_4 = this.txt_4
        this.txt_random_3_5 = this.txt_5
        this.txt_random_3_6 = this.txt_6
        this.txt_random_3_7 = this.txt_7
        this.txt_random_3_8 = this.txt_8
        this.txt_random_3_9 = this.txt_9
        this.txt_random_3_10 = this.txt_10
        this.txt_random_3_11 = this.txt_11
        this.txt_random_3_12 = this.txt_12
        this.txt_random_3_13 = this.txt_13
        this.txt_random_3_14 = this.txt_14
        this.txt_random_3_15 = this.txt_15
        this.chk_random_disable_3 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第三名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[4] = 0
            } else {
              this.items[i].isSetting[4] = 1
            }
            break
          }
        }
      } else if (rankName === "第四名" && orderName === "大") {
        this.bet_type_big_4 = this.bet_type
        this.txt_big_exeucte_num_of_pass_4 = this.txt_exeucte_num_of_pass
        this.txt_big_4_1 = this.txt_1
        this.txt_big_4_2 = this.txt_2
        this.txt_big_4_3 = this.txt_3
        this.txt_big_4_4 = this.txt_4
        this.txt_big_4_5 = this.txt_5
        this.txt_big_4_6 = this.txt_6
        this.txt_big_4_7 = this.txt_7
        this.txt_big_4_8 = this.txt_8
        this.txt_big_4_9 = this.txt_9
        this.txt_big_4_10 = this.txt_10
        this.txt_big_4_11 = this.txt_11
        this.txt_big_4_12 = this.txt_12
        this.txt_big_4_13 = this.txt_13
        this.txt_big_4_14 = this.txt_14
        this.txt_big_4_15 = this.txt_15
        this.chk_big_disable_4 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第四名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[0] = 0
            } else {
              this.items[i].isSetting[0] = 1
            }
            break
          }
        }
      } else if (rankName === "第四名" && orderName === "小") {
        this.bet_type_small_4 = this.bet_type
        this.txt_small_exeucte_num_of_pass_4 = this.txt_exeucte_num_of_pass
        this.txt_small_4_1 = this.txt_1
        this.txt_small_4_2 = this.txt_2
        this.txt_small_4_3 = this.txt_3
        this.txt_small_4_4 = this.txt_4
        this.txt_small_4_5 = this.txt_5
        this.txt_small_4_6 = this.txt_6
        this.txt_small_4_7 = this.txt_7
        this.txt_small_4_8 = this.txt_8
        this.txt_small_4_9 = this.txt_9
        this.txt_small_4_10 = this.txt_10
        this.txt_small_4_11 = this.txt_11
        this.txt_small_4_12 = this.txt_12
        this.txt_small_4_13 = this.txt_13
        this.txt_small_4_14 = this.txt_14
        this.txt_small_4_15 = this.txt_15
        this.chk_small_disable_4 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第四名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[1] = 0
            } else {
              this.items[i].isSetting[1] = 1
            }
            break
          }
        }
      } else if (rankName === "第四名" && orderName === "單") {
        this.bet_type_odd_4 = this.bet_type
        this.txt_odd_exeucte_num_of_pass_4 = this.txt_exeucte_num_of_pass
        this.txt_odd_4_1 = this.txt_1
        this.txt_odd_4_2 = this.txt_2
        this.txt_odd_4_3 = this.txt_3
        this.txt_odd_4_4 = this.txt_4
        this.txt_odd_4_5 = this.txt_5
        this.txt_odd_4_6 = this.txt_6
        this.txt_odd_4_7 = this.txt_7
        this.txt_odd_4_8 = this.txt_8
        this.txt_odd_4_9 = this.txt_9
        this.txt_odd_4_10 = this.txt_10
        this.txt_odd_4_11 = this.txt_11
        this.txt_odd_4_12 = this.txt_12
        this.txt_odd_4_13 = this.txt_13
        this.txt_odd_4_14 = this.txt_14
        this.txt_odd_4_15 = this.txt_15
        this.chk_odd_disable_4 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第四名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[2] = 0
            } else {
              this.items[i].isSetting[2] = 1
            }
            break
          }
        }
      } else if (rankName === "第四名" && orderName === "雙") {
        this.bet_type_even_4 = this.bet_type
        this.txt_even_exeucte_num_of_pass_4 = this.txt_exeucte_num_of_pass
        this.txt_even_4_1 = this.txt_1
        this.txt_even_4_2 = this.txt_2
        this.txt_even_4_3 = this.txt_3
        this.txt_even_4_4 = this.txt_4
        this.txt_even_4_5 = this.txt_5
        this.txt_even_4_6 = this.txt_6
        this.txt_even_4_7 = this.txt_7
        this.txt_even_4_8 = this.txt_8
        this.txt_even_4_9 = this.txt_9
        this.txt_even_4_10 = this.txt_10
        this.txt_even_4_11 = this.txt_11
        this.txt_even_4_12 = this.txt_12
        this.txt_even_4_13 = this.txt_13
        this.txt_even_4_14 = this.txt_14
        this.txt_even_4_15 = this.txt_15
        this.chk_even_disable_4 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第四名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[3] = 0
            } else {
              this.items[i].isSetting[3] = 1
            }
            break
          }
        }
      } else if (rankName === "第四名" && orderName === "隨機") {
        this.bet_type_random_4 = this.bet_type
        this.random_type_4 = this.random_type
        this.txt_random_exeucte_num_of_pass_4 = this.txt_exeucte_num_of_pass
        this.txt_random_4_1 = this.txt_1
        this.txt_random_4_2 = this.txt_2
        this.txt_random_4_3 = this.txt_3
        this.txt_random_4_4 = this.txt_4
        this.txt_random_4_5 = this.txt_5
        this.txt_random_4_6 = this.txt_6
        this.txt_random_4_7 = this.txt_7
        this.txt_random_4_8 = this.txt_8
        this.txt_random_4_9 = this.txt_9
        this.txt_random_4_10 = this.txt_10
        this.txt_random_4_11 = this.txt_11
        this.txt_random_4_12 = this.txt_12
        this.txt_random_4_13 = this.txt_13
        this.txt_random_4_14 = this.txt_14
        this.txt_random_4_15 = this.txt_15
        this.chk_random_disable_4 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第四名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[4] = 0
            } else {
              this.items[i].isSetting[4] = 1
            }
            break
          }
        }
      } else if (rankName === "第五名" && orderName === "大") {
        this.bet_type_big_5 = this.bet_type
        this.txt_big_exeucte_num_of_pass_5 = this.txt_exeucte_num_of_pass
        this.txt_big_5_1 = this.txt_1
        this.txt_big_5_2 = this.txt_2
        this.txt_big_5_3 = this.txt_3
        this.txt_big_5_4 = this.txt_4
        this.txt_big_5_5 = this.txt_5
        this.txt_big_5_6 = this.txt_6
        this.txt_big_5_7 = this.txt_7
        this.txt_big_5_8 = this.txt_8
        this.txt_big_5_9 = this.txt_9
        this.txt_big_5_10 = this.txt_10
        this.txt_big_5_11 = this.txt_11
        this.txt_big_5_12 = this.txt_12
        this.txt_big_5_13 = this.txt_13
        this.txt_big_5_14 = this.txt_14
        this.txt_big_5_15 = this.txt_15
        this.chk_big_disable_5 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第五名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[0] = 0
            } else {
              this.items[i].isSetting[0] = 1
            }
            break
          }
        }
      } else if (rankName === "第五名" && orderName === "小") {
        this.bet_type_small_5 = this.bet_type
        this.txt_small_exeucte_num_of_pass_5 = this.txt_exeucte_num_of_pass
        this.txt_small_5_1 = this.txt_1
        this.txt_small_5_2 = this.txt_2
        this.txt_small_5_3 = this.txt_3
        this.txt_small_5_4 = this.txt_4
        this.txt_small_5_5 = this.txt_5
        this.txt_small_5_6 = this.txt_6
        this.txt_small_5_7 = this.txt_7
        this.txt_small_5_8 = this.txt_8
        this.txt_small_5_9 = this.txt_9
        this.txt_small_5_10 = this.txt_10
        this.txt_small_5_11 = this.txt_11
        this.txt_small_5_12 = this.txt_12
        this.txt_small_5_13 = this.txt_13
        this.txt_small_5_14 = this.txt_14
        this.txt_small_5_15 = this.txt_15
        this.chk_small_disable_5 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第五名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[1] = 0
            } else {
              this.items[i].isSetting[1] = 1
            }
            break
          }
        }
      } else if (rankName === "第五名" && orderName === "單") {
        this.bet_type_odd_5 = this.bet_type
        this.txt_odd_exeucte_num_of_pass_5 = this.txt_exeucte_num_of_pass
        this.txt_odd_5_1 = this.txt_1
        this.txt_odd_5_2 = this.txt_2
        this.txt_odd_5_3 = this.txt_3
        this.txt_odd_5_4 = this.txt_4
        this.txt_odd_5_5 = this.txt_5
        this.txt_odd_5_6 = this.txt_6
        this.txt_odd_5_7 = this.txt_7
        this.txt_odd_5_8 = this.txt_8
        this.txt_odd_5_9 = this.txt_9
        this.txt_odd_5_10 = this.txt_10
        this.txt_odd_5_11 = this.txt_11
        this.txt_odd_5_12 = this.txt_12
        this.txt_odd_5_13 = this.txt_13
        this.txt_odd_5_14 = this.txt_14
        this.txt_odd_5_15 = this.txt_15
        this.chk_odd_disable_5 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第五名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[2] = 0
            } else {
              this.items[i].isSetting[2] = 1
            }
            break
          }
        }
      } else if (rankName === "第五名" && orderName === "雙") {
        this.bet_type_even_5 = this.bet_type
        this.txt_even_exeucte_num_of_pass_5 = this.txt_exeucte_num_of_pass
        this.txt_even_5_1 = this.txt_1
        this.txt_even_5_2 = this.txt_2
        this.txt_even_5_3 = this.txt_3
        this.txt_even_5_4 = this.txt_4
        this.txt_even_5_5 = this.txt_5
        this.txt_even_5_6 = this.txt_6
        this.txt_even_5_7 = this.txt_7
        this.txt_even_5_8 = this.txt_8
        this.txt_even_5_9 = this.txt_9
        this.txt_even_5_10 = this.txt_10
        this.txt_even_5_11 = this.txt_11
        this.txt_even_5_12 = this.txt_12
        this.txt_even_5_13 = this.txt_13
        this.txt_even_5_14 = this.txt_14
        this.txt_even_5_15 = this.txt_15
        this.chk_even_disable_5 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第五名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[3] = 0
            } else {
              this.items[i].isSetting[3] = 1
            }
            break
          }
        }
      } else if (rankName === "第五名" && orderName === "隨機") {
        this.bet_type_random_5 = this.bet_type
        this.random_type_5 = this.random_type
        this.txt_random_exeucte_num_of_pass_5 = this.txt_exeucte_num_of_pass
        this.txt_random_5_1 = this.txt_1
        this.txt_random_5_2 = this.txt_2
        this.txt_random_5_3 = this.txt_3
        this.txt_random_5_4 = this.txt_4
        this.txt_random_5_5 = this.txt_5
        this.txt_random_5_6 = this.txt_6
        this.txt_random_5_7 = this.txt_7
        this.txt_random_5_8 = this.txt_8
        this.txt_random_5_9 = this.txt_9
        this.txt_random_5_10 = this.txt_10
        this.txt_random_5_11 = this.txt_11
        this.txt_random_5_12 = this.txt_12
        this.txt_random_5_13 = this.txt_13
        this.txt_random_5_14 = this.txt_14
        this.txt_random_5_15 = this.txt_15
        this.chk_random_disable_5 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第五名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[4] = 0
            } else {
              this.items[i].isSetting[4] = 1
            }
            break
          }
        }
      } else if (rankName === "第六名" && orderName === "大") {
        this.bet_type_big_6 = this.bet_type
        this.txt_big_exeucte_num_of_pass_6 = this.txt_exeucte_num_of_pass
        this.txt_big_6_1 = this.txt_1
        this.txt_big_6_2 = this.txt_2
        this.txt_big_6_3 = this.txt_3
        this.txt_big_6_4 = this.txt_4
        this.txt_big_6_5 = this.txt_5
        this.txt_big_6_6 = this.txt_6
        this.txt_big_6_7 = this.txt_7
        this.txt_big_6_8 = this.txt_8
        this.txt_big_6_9 = this.txt_9
        this.txt_big_6_10 = this.txt_10
        this.txt_big_6_11 = this.txt_11
        this.txt_big_6_12 = this.txt_12
        this.txt_big_6_13 = this.txt_13
        this.txt_big_6_14 = this.txt_14
        this.txt_big_6_15 = this.txt_15
        this.chk_big_disable_6 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第六名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[0] = 0
            } else {
              this.items[i].isSetting[0] = 1
            }
            break
          }
        }
      } else if (rankName === "第六名" && orderName === "小") {
        this.bet_type_small_6 = this.bet_type
        this.txt_small_exeucte_num_of_pass_6 = this.txt_exeucte_num_of_pass
        this.txt_small_6_1 = this.txt_1
        this.txt_small_6_2 = this.txt_2
        this.txt_small_6_3 = this.txt_3
        this.txt_small_6_4 = this.txt_4
        this.txt_small_6_5 = this.txt_5
        this.txt_small_6_6 = this.txt_6
        this.txt_small_6_7 = this.txt_7
        this.txt_small_6_8 = this.txt_8
        this.txt_small_6_9 = this.txt_9
        this.txt_small_6_10 = this.txt_10
        this.txt_small_6_11 = this.txt_11
        this.txt_small_6_12 = this.txt_12
        this.txt_small_6_13 = this.txt_13
        this.txt_small_6_14 = this.txt_14
        this.txt_small_6_15 = this.txt_15
        this.chk_small_disable_6 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第六名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[1] = 0
            } else {
              this.items[i].isSetting[1] = 1
            }
            break
          }
        }
      } else if (rankName === "第六名" && orderName === "單") {
        this.bet_type_odd_6 = this.bet_type
        this.txt_odd_exeucte_num_of_pass_6 = this.txt_exeucte_num_of_pass
        this.txt_odd_6_1 = this.txt_1
        this.txt_odd_6_2 = this.txt_2
        this.txt_odd_6_3 = this.txt_3
        this.txt_odd_6_4 = this.txt_4
        this.txt_odd_6_5 = this.txt_5
        this.txt_odd_6_6 = this.txt_6
        this.txt_odd_6_7 = this.txt_7
        this.txt_odd_6_8 = this.txt_8
        this.txt_odd_6_9 = this.txt_9
        this.txt_odd_6_10 = this.txt_10
        this.txt_odd_6_11 = this.txt_11
        this.txt_odd_6_12 = this.txt_12
        this.txt_odd_6_13 = this.txt_13
        this.txt_odd_6_14 = this.txt_14
        this.txt_odd_6_15 = this.txt_15
        this.chk_odd_disable_6 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第六名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[2] = 0
            } else {
              this.items[i].isSetting[2] = 1
            }
            break
          }
        }
      } else if (rankName === "第六名" && orderName === "雙") {
        this.bet_type_even_6 = this.bet_type
        this.txt_even_exeucte_num_of_pass_6 = this.txt_exeucte_num_of_pass
        this.txt_even_6_1 = this.txt_1
        this.txt_even_6_2 = this.txt_2
        this.txt_even_6_3 = this.txt_3
        this.txt_even_6_4 = this.txt_4
        this.txt_even_6_5 = this.txt_5
        this.txt_even_6_6 = this.txt_6
        this.txt_even_6_7 = this.txt_7
        this.txt_even_6_8 = this.txt_8
        this.txt_even_6_9 = this.txt_9
        this.txt_even_6_10 = this.txt_10
        this.txt_even_6_11 = this.txt_11
        this.txt_even_6_12 = this.txt_12
        this.txt_even_6_13 = this.txt_13
        this.txt_even_6_14 = this.txt_14
        this.txt_even_6_15 = this.txt_15
        this.chk_even_disable_6 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第六名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[3] = 0
            } else {
              this.items[i].isSetting[3] = 1
            }
            break
          }
        }
      } else if (rankName === "第六名" && orderName === "隨機") {
        this.bet_type_random_6 = this.bet_type
        this.random_type_6 = this.random_type
        this.txt_random_exeucte_num_of_pass_6 = this.txt_exeucte_num_of_pass
        this.txt_random_6_1 = this.txt_1
        this.txt_random_6_2 = this.txt_2
        this.txt_random_6_3 = this.txt_3
        this.txt_random_6_4 = this.txt_4
        this.txt_random_6_5 = this.txt_5
        this.txt_random_6_6 = this.txt_6
        this.txt_random_6_7 = this.txt_7
        this.txt_random_6_8 = this.txt_8
        this.txt_random_6_9 = this.txt_9
        this.txt_random_6_10 = this.txt_10
        this.txt_random_6_11 = this.txt_11
        this.txt_random_6_12 = this.txt_12
        this.txt_random_6_13 = this.txt_13
        this.txt_random_6_14 = this.txt_14
        this.txt_random_6_15 = this.txt_15
        this.chk_random_disable_6 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第六名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[4] = 0
            } else {
              this.items[i].isSetting[4] = 1
            }
            break
          }
        }
      } else if (rankName === "第七名" && orderName === "大") {
        this.bet_type_big_7 = this.bet_type
        this.txt_big_exeucte_num_of_pass_7 = this.txt_exeucte_num_of_pass
        this.txt_big_7_1 = this.txt_1
        this.txt_big_7_2 = this.txt_2
        this.txt_big_7_3 = this.txt_3
        this.txt_big_7_4 = this.txt_4
        this.txt_big_7_5 = this.txt_5
        this.txt_big_7_6 = this.txt_6
        this.txt_big_7_7 = this.txt_7
        this.txt_big_7_8 = this.txt_8
        this.txt_big_7_9 = this.txt_9
        this.txt_big_7_10 = this.txt_10
        this.chk_big_disable_7 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第七名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[0] = 0
            } else {
              this.items[i].isSetting[0] = 1
            }
            break
          }
        }
      } else if (rankName === "第七名" && orderName === "小") {
        this.bet_type_small_7 = this.bet_type
        this.txt_small_exeucte_num_of_pass_7 = this.txt_exeucte_num_of_pass
        this.txt_small_7_1 = this.txt_1
        this.txt_small_7_2 = this.txt_2
        this.txt_small_7_3 = this.txt_3
        this.txt_small_7_4 = this.txt_4
        this.txt_small_7_5 = this.txt_5
        this.txt_small_7_6 = this.txt_6
        this.txt_small_7_7 = this.txt_7
        this.txt_small_7_8 = this.txt_8
        this.txt_small_7_9 = this.txt_9
        this.txt_small_7_10 = this.txt_10
        this.txt_small_7_11 = this.txt_11
        this.txt_small_7_12 = this.txt_12
        this.txt_small_7_13 = this.txt_13
        this.txt_small_7_14 = this.txt_14
        this.txt_small_7_15 = this.txt_15
        this.chk_small_disable_7 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第七名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[1] = 0
            } else {
              this.items[i].isSetting[1] = 1
            }
            break
          }
        }
      } else if (rankName === "第七名" && orderName === "單") {
        this.bet_type_odd_7 = this.bet_type
        this.txt_odd_exeucte_num_of_pass_7 = this.txt_exeucte_num_of_pass
        this.txt_odd_7_1 = this.txt_1
        this.txt_odd_7_2 = this.txt_2
        this.txt_odd_7_3 = this.txt_3
        this.txt_odd_7_4 = this.txt_4
        this.txt_odd_7_5 = this.txt_5
        this.txt_odd_7_6 = this.txt_6
        this.txt_odd_7_7 = this.txt_7
        this.txt_odd_7_8 = this.txt_8
        this.txt_odd_7_9 = this.txt_9
        this.txt_odd_7_10 = this.txt_10
        this.txt_odd_7_11 = this.txt_11
        this.txt_odd_7_12 = this.txt_12
        this.txt_odd_7_13 = this.txt_13
        this.txt_odd_7_14 = this.txt_14
        this.txt_odd_7_15 = this.txt_15
        this.chk_odd_disable_7 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第七名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[2] = 0
            } else {
              this.items[i].isSetting[2] = 1
            }
            break
          }
        }
      } else if (rankName === "第七名" && orderName === "雙") {
        this.bet_type_even_7 = this.bet_type
        this.txt_even_exeucte_num_of_pass_7 = this.txt_exeucte_num_of_pass
        this.txt_even_7_1 = this.txt_1
        this.txt_even_7_2 = this.txt_2
        this.txt_even_7_3 = this.txt_3
        this.txt_even_7_4 = this.txt_4
        this.txt_even_7_5 = this.txt_5
        this.txt_even_7_6 = this.txt_6
        this.txt_even_7_7 = this.txt_7
        this.txt_even_7_8 = this.txt_8
        this.txt_even_7_9 = this.txt_9
        this.txt_even_7_10 = this.txt_10
        this.txt_even_7_11 = this.txt_11
        this.txt_even_7_12 = this.txt_12
        this.txt_even_7_13 = this.txt_13
        this.txt_even_7_14 = this.txt_14
        this.txt_even_7_15 = this.txt_15
        this.chk_even_disable_7 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第七名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[3] = 0
            } else {
              this.items[i].isSetting[3] = 1
            }
            break
          }
        }
      } else if (rankName === "第七名" && orderName === "隨機") {
        this.bet_type_random_7 = this.bet_type
        this.random_type_7 = this.random_type
        this.txt_random_exeucte_num_of_pass_7 = this.txt_exeucte_num_of_pass
        this.txt_random_7_1 = this.txt_1
        this.txt_random_7_2 = this.txt_2
        this.txt_random_7_3 = this.txt_3
        this.txt_random_7_4 = this.txt_4
        this.txt_random_7_5 = this.txt_5
        this.txt_random_7_6 = this.txt_6
        this.txt_random_7_7 = this.txt_7
        this.txt_random_7_8 = this.txt_8
        this.txt_random_7_9 = this.txt_9
        this.txt_random_7_10 = this.txt_10
        this.txt_random_7_11 = this.txt_11
        this.txt_random_7_12 = this.txt_12
        this.txt_random_7_13 = this.txt_13
        this.txt_random_7_14 = this.txt_14
        this.txt_random_7_15 = this.txt_15
        this.chk_random_disable_7 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第七名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[4] = 0
            } else {
              this.items[i].isSetting[4] = 1
            }
            break
          }
        }
      } else if (rankName === "第八名" && orderName === "大") {
        this.bet_type_big_8 = this.bet_type
        this.txt_big_exeucte_num_of_pass_8 = this.txt_exeucte_num_of_pass
        this.txt_big_8_1 = this.txt_1
        this.txt_big_8_2 = this.txt_2
        this.txt_big_8_3 = this.txt_3
        this.txt_big_8_4 = this.txt_4
        this.txt_big_8_5 = this.txt_5
        this.txt_big_8_6 = this.txt_6
        this.txt_big_8_7 = this.txt_7
        this.txt_big_8_8 = this.txt_8
        this.txt_big_8_9 = this.txt_9
        this.txt_big_8_10 = this.txt_10
        this.txt_big_8_11 = this.txt_11
        this.txt_big_8_12 = this.txt_12
        this.txt_big_8_13 = this.txt_13
        this.txt_big_8_14 = this.txt_14
        this.txt_big_8_15 = this.txt_15
        this.chk_big_disable_8 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第八名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[0] = 0
            } else {
              this.items[i].isSetting[0] = 1
            }
            break
          }
        }
      } else if (rankName === "第八名" && orderName === "小") {
        this.bet_type_small_8 = this.bet_type
        this.txt_small_exeucte_num_of_pass_8 = this.txt_exeucte_num_of_pass
        this.txt_small_8_1 = this.txt_1
        this.txt_small_8_2 = this.txt_2
        this.txt_small_8_3 = this.txt_3
        this.txt_small_8_4 = this.txt_4
        this.txt_small_8_5 = this.txt_5
        this.txt_small_8_6 = this.txt_6
        this.txt_small_8_7 = this.txt_7
        this.txt_small_8_8 = this.txt_8
        this.txt_small_8_9 = this.txt_9
        this.txt_small_8_10 = this.txt_10
        this.txt_small_8_11 = this.txt_11
        this.txt_small_8_12 = this.txt_12
        this.txt_small_8_13 = this.txt_13
        this.txt_small_8_14 = this.txt_14
        this.txt_small_8_15 = this.txt_15
        this.chk_small_disable_8 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第八名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[1] = 0
            } else {
              this.items[i].isSetting[1] = 1
            }
            break
          }
        }
      } else if (rankName === "第八名" && orderName === "單") {
        this.bet_type_odd_8 = this.bet_type
        this.txt_odd_exeucte_num_of_pass_8 = this.txt_exeucte_num_of_pass
        this.txt_odd_8_1 = this.txt_1
        this.txt_odd_8_2 = this.txt_2
        this.txt_odd_8_3 = this.txt_3
        this.txt_odd_8_4 = this.txt_4
        this.txt_odd_8_5 = this.txt_5
        this.txt_odd_8_6 = this.txt_6
        this.txt_odd_8_7 = this.txt_7
        this.txt_odd_8_8 = this.txt_8
        this.txt_odd_8_9 = this.txt_9
        this.txt_odd_8_10 = this.txt_10
        this.txt_odd_8_11 = this.txt_11
        this.txt_odd_8_12 = this.txt_12
        this.txt_odd_8_13 = this.txt_13
        this.txt_odd_8_14 = this.txt_14
        this.txt_odd_8_15 = this.txt_15
        this.chk_odd_disable_8 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第八名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[2] = 0
            } else {
              this.items[i].isSetting[2] = 1
            }
            break
          }
        }
      } else if (rankName === "第八名" && orderName === "雙") {
        this.bet_type_even_8 = this.bet_type
        this.txt_even_exeucte_num_of_pass_8 = this.txt_exeucte_num_of_pass
        this.txt_even_8_1 = this.txt_1
        this.txt_even_8_2 = this.txt_2
        this.txt_even_8_3 = this.txt_3
        this.txt_even_8_4 = this.txt_4
        this.txt_even_8_5 = this.txt_5
        this.txt_even_8_6 = this.txt_6
        this.txt_even_8_7 = this.txt_7
        this.txt_even_8_8 = this.txt_8
        this.txt_even_8_9 = this.txt_9
        this.txt_even_8_10 = this.txt_10
        this.txt_even_8_11 = this.txt_11
        this.txt_even_8_12 = this.txt_12
        this.txt_even_8_13 = this.txt_13
        this.txt_even_8_14 = this.txt_14
        this.txt_even_8_15 = this.txt_15
        this.chk_even_disable_8 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第八名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[3] = 0
            } else {
              this.items[i].isSetting[3] = 1
            }
            break
          }
        }
      } else if (rankName === "第八名" && orderName === "隨機") {
        this.bet_type_random_8 = this.bet_type
        this.random_type_8 = this.random_type
        this.txt_random_exeucte_num_of_pass_8 = this.txt_exeucte_num_of_pass
        this.txt_random_8_1 = this.txt_1
        this.txt_random_8_2 = this.txt_2
        this.txt_random_8_3 = this.txt_3
        this.txt_random_8_4 = this.txt_4
        this.txt_random_8_5 = this.txt_5
        this.txt_random_8_6 = this.txt_6
        this.txt_random_8_7 = this.txt_7
        this.txt_random_8_8 = this.txt_8
        this.txt_random_8_9 = this.txt_9
        this.txt_random_8_10 = this.txt_10
        this.txt_random_8_11 = this.txt_11
        this.txt_random_8_12 = this.txt_12
        this.txt_random_8_13 = this.txt_13
        this.txt_random_8_14 = this.txt_14
        this.txt_random_8_15 = this.txt_15
        this.chk_random_disable_8 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第八名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[4] = 0
            } else {
              this.items[i].isSetting[4] = 1
            }
            break
          }
        }
      } else if (rankName === "第九名" && orderName === "大") {
        this.bet_type_big_9 = this.bet_type
        this.txt_big_exeucte_num_of_pass_9 = this.txt_exeucte_num_of_pass
        this.txt_big_9_1 = this.txt_1
        this.txt_big_9_2 = this.txt_2
        this.txt_big_9_3 = this.txt_3
        this.txt_big_9_4 = this.txt_4
        this.txt_big_9_5 = this.txt_5
        this.txt_big_9_6 = this.txt_6
        this.txt_big_9_7 = this.txt_7
        this.txt_big_9_8 = this.txt_8
        this.txt_big_9_9 = this.txt_9
        this.txt_big_9_10 = this.txt_10
        this.txt_big_9_11 = this.txt_11
        this.txt_big_9_12 = this.txt_12
        this.txt_big_9_13 = this.txt_13
        this.txt_big_9_14 = this.txt_14
        this.txt_big_9_15 = this.txt_15
        this.chk_big_disable_9 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第九名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[0] = 0
            } else {
              this.items[i].isSetting[0] = 1
            }
            break
          }
        }
      } else if (rankName === "第九名" && orderName === "小") {
        this.bet_type_small_9 = this.bet_type
        this.txt_small_exeucte_num_of_pass_9 = this.txt_exeucte_num_of_pass
        this.txt_small_9_1 = this.txt_1
        this.txt_small_9_2 = this.txt_2
        this.txt_small_9_3 = this.txt_3
        this.txt_small_9_4 = this.txt_4
        this.txt_small_9_5 = this.txt_5
        this.txt_small_9_6 = this.txt_6
        this.txt_small_9_7 = this.txt_7
        this.txt_small_9_8 = this.txt_8
        this.txt_small_9_9 = this.txt_9
        this.txt_small_9_10 = this.txt_10
        this.txt_small_9_11 = this.txt_11
        this.txt_small_9_12 = this.txt_12
        this.txt_small_9_13 = this.txt_13
        this.txt_small_9_14 = this.txt_14
        this.txt_small_9_15 = this.txt_15
        this.chk_small_disable_9 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第九名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[1] = 0
            } else {
              this.items[i].isSetting[1] = 1
            }
            break
          }
        }
      } else if (rankName === "第九名" && orderName === "單") {
        this.bet_type_odd_9 = this.bet_type
        this.txt_odd_exeucte_num_of_pass_9 = this.txt_exeucte_num_of_pass
        this.txt_odd_9_1 = this.txt_1
        this.txt_odd_9_2 = this.txt_2
        this.txt_odd_9_3 = this.txt_3
        this.txt_odd_9_4 = this.txt_4
        this.txt_odd_9_5 = this.txt_5
        this.txt_odd_9_6 = this.txt_6
        this.txt_odd_9_7 = this.txt_7
        this.txt_odd_9_8 = this.txt_8
        this.txt_odd_9_9 = this.txt_9
        this.txt_odd_9_10 = this.txt_10
        this.txt_odd_9_11 = this.txt_11
        this.txt_odd_9_12 = this.txt_12
        this.txt_odd_9_13 = this.txt_13
        this.txt_odd_9_14 = this.txt_14
        this.txt_odd_9_15 = this.txt_15
        this.chk_odd_disable_9 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第九名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[2] = 0
            } else {
              this.items[i].isSetting[2] = 1
            }
            break
          }
        }
      } else if (rankName === "第九名" && orderName === "雙") {
        this.bet_type_even_9 = this.bet_type
        this.txt_even_exeucte_num_of_pass_9 = this.txt_exeucte_num_of_pass
        this.txt_even_9_1 = this.txt_1
        this.txt_even_9_2 = this.txt_2
        this.txt_even_9_3 = this.txt_3
        this.txt_even_9_4 = this.txt_4
        this.txt_even_9_5 = this.txt_5
        this.txt_even_9_6 = this.txt_6
        this.txt_even_9_7 = this.txt_7
        this.txt_even_9_8 = this.txt_8
        this.txt_even_9_9 = this.txt_9
        this.txt_even_9_10 = this.txt_10
        this.txt_even_9_11 = this.txt_11
        this.txt_even_9_12 = this.txt_12
        this.txt_even_9_13 = this.txt_13
        this.txt_even_9_14 = this.txt_14
        this.txt_even_9_15 = this.txt_15
        this.chk_even_disable_9 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第九名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[3] = 0
            } else {
              this.items[i].isSetting[3] = 1
            }
            break
          }
        }
      } else if (rankName === "第九名" && orderName === "隨機") {
        this.bet_type_random_9 = this.bet_type
        this.random_type_9 = this.random_type
        this.txt_random_exeucte_num_of_pass_9 = this.txt_exeucte_num_of_pass
        this.txt_random_9_1 = this.txt_1
        this.txt_random_9_2 = this.txt_2
        this.txt_random_9_3 = this.txt_3
        this.txt_random_9_4 = this.txt_4
        this.txt_random_9_5 = this.txt_5
        this.txt_random_9_6 = this.txt_6
        this.txt_random_9_7 = this.txt_7
        this.txt_random_9_8 = this.txt_8
        this.txt_random_9_9 = this.txt_9
        this.txt_random_9_10 = this.txt_10
        this.txt_random_9_11 = this.txt_11
        this.txt_random_9_12 = this.txt_12
        this.txt_random_9_13 = this.txt_13
        this.txt_random_9_14 = this.txt_14
        this.txt_random_9_15 = this.txt_15
        this.chk_random_disable_9 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第九名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[4] = 0
            } else {
              this.items[i].isSetting[4] = 1
            }
            break
          }
        }
      } else if (rankName === "第十名" && orderName === "大") {
        this.bet_type_big_10 = this.bet_type
        this.txt_big_exeucte_num_of_pass_10 = this.txt_exeucte_num_of_pass
        this.txt_big_10_1 = this.txt_1
        this.txt_big_10_2 = this.txt_2
        this.txt_big_10_3 = this.txt_3
        this.txt_big_10_4 = this.txt_4
        this.txt_big_10_5 = this.txt_5
        this.txt_big_10_6 = this.txt_6
        this.txt_big_10_7 = this.txt_7
        this.txt_big_10_8 = this.txt_8
        this.txt_big_10_9 = this.txt_9
        this.txt_big_10_10 = this.txt_10
        this.txt_big_10_11 = this.txt_11
        this.txt_big_10_12 = this.txt_12
        this.txt_big_10_13 = this.txt_13
        this.txt_big_10_14 = this.txt_14
        this.txt_big_10_15 = this.txt_15
        this.chk_big_disable_10 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第十名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[0] = 0
            } else {
              this.items[i].isSetting[0] = 1
            }
            break
          }
        }
      } else if (rankName === "第十名" && orderName === "小") {
        this.bet_type_small_10 = this.bet_type
        this.txt_small_exeucte_num_of_pass_10 = this.txt_exeucte_num_of_pass
        this.txt_small_10_1 = this.txt_1
        this.txt_small_10_2 = this.txt_2
        this.txt_small_10_3 = this.txt_3
        this.txt_small_10_4 = this.txt_4
        this.txt_small_10_5 = this.txt_5
        this.txt_small_10_6 = this.txt_6
        this.txt_small_10_7 = this.txt_7
        this.txt_small_10_8 = this.txt_8
        this.txt_small_10_9 = this.txt_9
        this.txt_small_10_10 = this.txt_10
        this.txt_small_10_11 = this.txt_11
        this.txt_small_10_12 = this.txt_12
        this.txt_small_10_13 = this.txt_13
        this.txt_small_10_14 = this.txt_14
        this.txt_small_10_15 = this.txt_15
        this.chk_small_disable_10 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第十名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[1] = 0
            } else {
              this.items[i].isSetting[1] = 1
            }
            break
          }
        }
      } else if (rankName === "第十名" && orderName === "單") {
        this.bet_type_odd_10 = this.bet_type
        this.txt_odd_exeucte_num_of_pass_10 = this.txt_exeucte_num_of_pass
        this.txt_odd_10_1 = this.txt_1
        this.txt_odd_10_2 = this.txt_2
        this.txt_odd_10_3 = this.txt_3
        this.txt_odd_10_4 = this.txt_4
        this.txt_odd_10_5 = this.txt_5
        this.txt_odd_10_6 = this.txt_6
        this.txt_odd_10_7 = this.txt_7
        this.txt_odd_10_8 = this.txt_8
        this.txt_odd_10_9 = this.txt_9
        this.txt_odd_10_10 = this.txt_10
        this.txt_odd_10_11 = this.txt_11
        this.txt_odd_10_12 = this.txt_12
        this.txt_odd_10_13 = this.txt_13
        this.txt_odd_10_14 = this.txt_14
        this.txt_odd_10_15 = this.txt_15
        this.chk_odd_disable_10 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第十名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[2] = 0
            } else {
              this.items[i].isSetting[2] = 1
            }
            break
          }
        }
      } else if (rankName === "第十名" && orderName === "雙") {
        this.bet_type_even_10 = this.bet_type
        this.txt_even_exeucte_num_of_pass_10 = this.txt_exeucte_num_of_pass
        this.txt_even_10_1 = this.txt_1
        this.txt_even_10_2 = this.txt_2
        this.txt_even_10_3 = this.txt_3
        this.txt_even_10_4 = this.txt_4
        this.txt_even_10_5 = this.txt_5
        this.txt_even_10_6 = this.txt_6
        this.txt_even_10_7 = this.txt_7
        this.txt_even_10_8 = this.txt_8
        this.txt_even_10_9 = this.txt_9
        this.txt_even_10_10 = this.txt_10
        this.txt_even_10_11 = this.txt_11
        this.txt_even_10_12 = this.txt_12
        this.txt_even_10_13 = this.txt_13
        this.txt_even_10_14 = this.txt_14
        this.txt_even_10_15 = this.txt_15
        this.chk_even_disable_10 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第十名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[3] = 0
            } else {
              this.items[i].isSetting[3] = 1
            }
            break
          }
        }
      } else if (rankName === "第十名" && orderName === "隨機") {
        this.bet_type_random_10 = this.bet_type
        this.random_type_10 = this.random_type
        this.txt_random_exeucte_num_of_pass_10 = this.txt_exeucte_num_of_pass
        this.txt_random_10_1 = this.txt_1
        this.txt_random_10_2 = this.txt_2
        this.txt_random_10_3 = this.txt_3
        this.txt_random_10_4 = this.txt_4
        this.txt_random_10_5 = this.txt_5
        this.txt_random_10_6 = this.txt_6
        this.txt_random_10_7 = this.txt_7
        this.txt_random_10_8 = this.txt_8
        this.txt_random_10_9 = this.txt_9
        this.txt_random_10_10 = this.txt_10
        this.txt_random_10_11 = this.txt_11
        this.txt_random_10_12 = this.txt_12
        this.txt_random_10_13 = this.txt_13
        this.txt_random_10_14 = this.txt_14
        this.txt_random_10_15 = this.txt_15
        this.chk_random_disable_10 = this.chk_disable
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].name === "第十名") {
            if ((this.chk_disable === 1) || this.chk_disable === true) {
              this.items[i].isSetting[4] = 0
            } else {
              this.items[i].isSetting[4] = 1
            }
            break
          }
        }
      } else {
        console.log("error")
      }
    },
    getVariableValue(rankName, orderName) {
      if (rankName === "冠軍" && orderName === "大") {
        this.bet_type = this.bet_type_big_1
        this.txt_exeucte_num_of_pass = this.txt_big_exeucte_num_of_pass_1
        this.txt_1 = this.txt_big_1_1
        this.txt_2 = this.txt_big_1_2
        this.txt_3 = this.txt_big_1_3
        this.txt_4 = this.txt_big_1_4
        this.txt_5 = this.txt_big_1_5
        this.txt_6 = this.txt_big_1_6
        this.txt_7 = this.txt_big_1_7
        this.txt_8 = this.txt_big_1_8
        this.txt_9 = this.txt_big_1_9
        this.txt_10 = this.txt_big_1_10
        this.txt_11 = this.txt_big_1_11
        this.txt_12 = this.txt_big_1_12
        this.txt_13 = this.txt_big_1_13
        this.txt_14 = this.txt_big_1_14
        this.txt_15 = this.txt_big_1_15
        this.chk_disable = this.chk_big_disable_1
      } else if (rankName === "冠軍" && orderName === "小") {
        this.bet_type = this.bet_type_small_1
        this.txt_exeucte_num_of_pass = this.txt_small_exeucte_num_of_pass_1
        this.txt_1 = this.txt_small_1_1
        this.txt_2 = this.txt_small_1_2
        this.txt_3 = this.txt_small_1_3
        this.txt_4 = this.txt_small_1_4
        this.txt_5 = this.txt_small_1_5
        this.txt_6 = this.txt_small_1_6
        this.txt_7 = this.txt_small_1_7
        this.txt_8 = this.txt_small_1_8
        this.txt_9 = this.txt_small_1_9
        this.txt_10 = this.txt_small_1_10
        this.txt_11 = this.txt_small_1_11
        this.txt_12 = this.txt_small_1_12
        this.txt_13 = this.txt_small_1_13
        this.txt_14 = this.txt_small_1_14
        this.txt_15 = this.txt_small_1_15
        this.chk_disable = this.chk_small_disable_1
      } else if (rankName === "冠軍" && orderName === "單") {
        this.bet_type = this.bet_type_odd_1
        this.txt_exeucte_num_of_pass = this.txt_odd_exeucte_num_of_pass_1
        this.txt_1 = this.txt_odd_1_1
        this.txt_2 = this.txt_odd_1_2
        this.txt_3 = this.txt_odd_1_3
        this.txt_4 = this.txt_odd_1_4
        this.txt_5 = this.txt_odd_1_5
        this.txt_6 = this.txt_odd_1_6
        this.txt_7 = this.txt_odd_1_7
        this.txt_8 = this.txt_odd_1_8
        this.txt_9 = this.txt_odd_1_9
        this.txt_10 = this.txt_odd_1_10
        this.txt_11 = this.txt_odd_1_11
        this.txt_12 = this.txt_odd_1_12
        this.txt_13 = this.txt_odd_1_13
        this.txt_14 = this.txt_odd_1_14
        this.txt_15 = this.txt_odd_1_15
        this.chk_disable = this.chk_odd_disable_1
      } else if (rankName === "冠軍" && orderName === "雙") {
        this.bet_type = this.bet_type_even_1
        this.txt_exeucte_num_of_pass = this.txt_even_exeucte_num_of_pass_1
        this.txt_1 = this.txt_even_1_1
        this.txt_2 = this.txt_even_1_2
        this.txt_3 = this.txt_even_1_3
        this.txt_4 = this.txt_even_1_4
        this.txt_5 = this.txt_even_1_5
        this.txt_6 = this.txt_even_1_6
        this.txt_7 = this.txt_even_1_7
        this.txt_8 = this.txt_even_1_8
        this.txt_9 = this.txt_even_1_9
        this.txt_10 = this.txt_even_1_10
        this.txt_11 = this.txt_even_1_11
        this.txt_12 = this.txt_even_1_12
        this.txt_13 = this.txt_even_1_13
        this.txt_14 = this.txt_even_1_14
        this.txt_15 = this.txt_even_1_15
        this.chk_disable = this.chk_even_disable_1
      } else if (rankName === "冠軍" && orderName === "隨機") {
        this.bet_type = this.bet_type_random_1
        this.random_type = this.random_type_1
        this.txt_exeucte_num_of_pass = this.txt_random_exeucte_num_of_pass_1
        this.txt_1 = this.txt_random_1_1
        this.txt_2 = this.txt_random_1_2
        this.txt_3 = this.txt_random_1_3
        this.txt_4 = this.txt_random_1_4
        this.txt_5 = this.txt_random_1_5
        this.txt_6 = this.txt_random_1_6
        this.txt_7 = this.txt_random_1_7
        this.txt_8 = this.txt_random_1_8
        this.txt_9 = this.txt_random_1_9
        this.txt_10 = this.txt_random_1_10
        this.txt_11 = this.txt_random_1_11
        this.txt_12 = this.txt_random_1_12
        this.txt_13 = this.txt_random_1_13
        this.txt_14 = this.txt_random_1_14
        this.txt_15 = this.txt_random_1_15
        this.chk_disable = this.chk_random_disable_1
      } else if (rankName === "亞軍" && orderName === "大") {
        this.bet_type = this.bet_type_big_2
        this.txt_exeucte_num_of_pass = this.txt_big_exeucte_num_of_pass_2
        this.txt_1 = this.txt_big_2_1
        this.txt_2 = this.txt_big_2_2
        this.txt_3 = this.txt_big_2_3
        this.txt_4 = this.txt_big_2_4
        this.txt_5 = this.txt_big_2_5
        this.txt_6 = this.txt_big_2_6
        this.txt_7 = this.txt_big_2_7
        this.txt_8 = this.txt_big_2_8
        this.txt_9 = this.txt_big_2_9
        this.txt_10 = this.txt_big_2_10
        this.txt_11 = this.txt_big_2_11
        this.txt_12 = this.txt_big_2_12
        this.txt_13 = this.txt_big_2_13
        this.txt_14 = this.txt_big_2_14
        this.txt_15 = this.txt_big_2_15
        this.chk_disable = this.chk_big_disable_2
      } else if (rankName === "亞軍" && orderName === "小") {
        this.bet_type = this.bet_type_small_2
        this.txt_exeucte_num_of_pass = this.txt_small_exeucte_num_of_pass_2
        this.txt_1 = this.txt_small_2_1
        this.txt_2 = this.txt_small_2_2
        this.txt_3 = this.txt_small_2_3
        this.txt_4 = this.txt_small_2_4
        this.txt_5 = this.txt_small_2_5
        this.txt_6 = this.txt_small_2_6
        this.txt_7 = this.txt_small_2_7
        this.txt_8 = this.txt_small_2_8
        this.txt_9 = this.txt_small_2_9
        this.txt_10 = this.txt_small_2_10
        this.txt_11 = this.txt_small_2_11
        this.txt_12 = this.txt_small_2_12
        this.txt_13 = this.txt_small_2_13
        this.txt_14 = this.txt_small_2_14
        this.txt_15 = this.txt_small_2_15
        this.chk_disable = this.chk_small_disable_2
      } else if (rankName === "亞軍" && orderName === "單") {
        this.bet_type = this.bet_type_odd_2
        this.txt_exeucte_num_of_pass = this.txt_odd_exeucte_num_of_pass_2
        this.txt_1 = this.txt_odd_2_1
        this.txt_2 = this.txt_odd_2_2
        this.txt_3 = this.txt_odd_2_3
        this.txt_4 = this.txt_odd_2_4
        this.txt_5 = this.txt_odd_2_5
        this.txt_6 = this.txt_odd_2_6
        this.txt_7 = this.txt_odd_2_7
        this.txt_8 = this.txt_odd_2_8
        this.txt_9 = this.txt_odd_2_9
        this.txt_10 = this.txt_odd_2_10
        this.txt_11 = this.txt_odd_2_11
        this.txt_12 = this.txt_odd_2_12
        this.txt_13 = this.txt_odd_2_13
        this.txt_14 = this.txt_odd_2_14
        this.txt_15 = this.txt_odd_2_15
        this.chk_disable = this.chk_odd_disable_2
      } else if (rankName === "亞軍" && orderName === "雙") {
        this.bet_type = this.bet_type_even_2
        this.txt_exeucte_num_of_pass = this.txt_even_exeucte_num_of_pass_2
        this.txt_1 = this.txt_even_2_1
        this.txt_2 = this.txt_even_2_2
        this.txt_3 = this.txt_even_2_3
        this.txt_4 = this.txt_even_2_4
        this.txt_5 = this.txt_even_2_5
        this.txt_6 = this.txt_even_2_6
        this.txt_7 = this.txt_even_2_7
        this.txt_8 = this.txt_even_2_8
        this.txt_9 = this.txt_even_2_9
        this.txt_10 = this.txt_even_2_10
        this.txt_11 = this.txt_even_2_11
        this.txt_12 = this.txt_even_2_12
        this.txt_13 = this.txt_even_2_13
        this.txt_14 = this.txt_even_2_14
        this.txt_15 = this.txt_even_2_15
        this.chk_disable = this.chk_even_disable_2
      } else if (rankName === "亞軍" && orderName === "隨機") {
        this.bet_type = this.bet_type_random_2
        this.random_type = this.random_type_2
        this.txt_exeucte_num_of_pass = this.txt_random_exeucte_num_of_pass_2
        this.txt_1 = this.txt_random_2_1
        this.txt_2 = this.txt_random_2_2
        this.txt_3 = this.txt_random_2_3
        this.txt_4 = this.txt_random_2_4
        this.txt_5 = this.txt_random_2_5
        this.txt_6 = this.txt_random_2_6
        this.txt_7 = this.txt_random_2_7
        this.txt_8 = this.txt_random_2_8
        this.txt_9 = this.txt_random_2_9
        this.txt_10 = this.txt_random_2_10
        this.txt_11 = this.txt_random_2_11
        this.txt_12 = this.txt_random_2_12
        this.txt_13 = this.txt_random_2_13
        this.txt_14 = this.txt_random_2_14
        this.txt_15 = this.txt_random_2_15
        this.chk_disable = this.chk_random_disable_2
      } else if (rankName === "第三名" && orderName === "大") {
        this.bet_type = this.bet_type_big_3
        this.txt_exeucte_num_of_pass = this.txt_big_exeucte_num_of_pass_3
        this.txt_1 = this.txt_big_3_1
        this.txt_2 = this.txt_big_3_2
        this.txt_3 = this.txt_big_3_3
        this.txt_4 = this.txt_big_3_4
        this.txt_5 = this.txt_big_3_5
        this.txt_6 = this.txt_big_3_6
        this.txt_7 = this.txt_big_3_7
        this.txt_8 = this.txt_big_3_8
        this.txt_9 = this.txt_big_3_9
        this.txt_10 = this.txt_big_3_10
        this.txt_11 = this.txt_big_3_11
        this.txt_12 = this.txt_big_3_12
        this.txt_13 = this.txt_big_3_13
        this.txt_14 = this.txt_big_3_14
        this.txt_15 = this.txt_big_3_15
        this.chk_disable = this.chk_big_disable_3
      } else if (rankName === "第三名" && orderName === "小") {
        this.bet_type = this.bet_type_small_3
        this.txt_exeucte_num_of_pass = this.txt_small_exeucte_num_of_pass_3
        this.txt_1 = this.txt_small_3_1
        this.txt_2 = this.txt_small_3_2
        this.txt_3 = this.txt_small_3_3
        this.txt_4 = this.txt_small_3_4
        this.txt_5 = this.txt_small_3_5
        this.txt_6 = this.txt_small_3_6
        this.txt_7 = this.txt_small_3_7
        this.txt_8 = this.txt_small_3_8
        this.txt_9 = this.txt_small_3_9
        this.txt_10 = this.txt_small_3_10
        this.txt_11 = this.txt_small_3_11
        this.txt_12 = this.txt_small_3_12
        this.txt_13 = this.txt_small_3_13
        this.txt_14 = this.txt_small_3_14
        this.txt_15 = this.txt_small_3_15
        this.chk_disable = this.chk_small_disable_3
      } else if (rankName === "第三名" && orderName === "單") {
        this.bet_type = this.bet_type_odd_3
        this.txt_exeucte_num_of_pass = this.txt_odd_exeucte_num_of_pass_3
        this.txt_1 = this.txt_odd_3_1
        this.txt_2 = this.txt_odd_3_2
        this.txt_3 = this.txt_odd_3_3
        this.txt_4 = this.txt_odd_3_4
        this.txt_5 = this.txt_odd_3_5
        this.txt_6 = this.txt_odd_3_6
        this.txt_7 = this.txt_odd_3_7
        this.txt_8 = this.txt_odd_3_8
        this.txt_9 = this.txt_odd_3_9
        this.txt_10 = this.txt_odd_3_10
        this.txt_11 = this.txt_odd_3_11
        this.txt_12 = this.txt_odd_3_12
        this.txt_13 = this.txt_odd_3_13
        this.txt_14 = this.txt_odd_3_14
        this.txt_15 = this.txt_odd_3_15
        this.chk_disable = this.chk_odd_disable_3
      } else if (rankName === "第三名" && orderName === "雙") {
        this.bet_type = this.bet_type_even_3
        this.txt_exeucte_num_of_pass = this.txt_even_exeucte_num_of_pass_3
        this.txt_1 = this.txt_even_3_1
        this.txt_2 = this.txt_even_3_2
        this.txt_3 = this.txt_even_3_3
        this.txt_4 = this.txt_even_3_4
        this.txt_5 = this.txt_even_3_5
        this.txt_6 = this.txt_even_3_6
        this.txt_7 = this.txt_even_3_7
        this.txt_8 = this.txt_even_3_8
        this.txt_9 = this.txt_even_3_9
        this.txt_10 = this.txt_even_3_10
        this.txt_11 = this.txt_even_3_11
        this.txt_12 = this.txt_even_3_12
        this.txt_13 = this.txt_even_3_13
        this.txt_14 = this.txt_even_3_14
        this.txt_15 = this.txt_even_3_15
        this.chk_disable = this.chk_even_disable_3
      } else if (rankName === "第三名" && orderName === "隨機") {
        this.bet_type = this.bet_type_random_3
        this.random_type = this.random_type_3
        this.txt_exeucte_num_of_pass = this.txt_random_exeucte_num_of_pass_3
        this.txt_1 = this.txt_random_3_1
        this.txt_2 = this.txt_random_3_2
        this.txt_3 = this.txt_random_3_3
        this.txt_4 = this.txt_random_3_4
        this.txt_5 = this.txt_random_3_5
        this.txt_6 = this.txt_random_3_6
        this.txt_7 = this.txt_random_3_7
        this.txt_8 = this.txt_random_3_8
        this.txt_9 = this.txt_random_3_9
        this.txt_10 = this.txt_random_3_10
        this.txt_11 = this.txt_random_3_11
        this.txt_12 = this.txt_random_3_12
        this.txt_13 = this.txt_random_3_13
        this.txt_14 = this.txt_random_3_14
        this.txt_15 = this.txt_random_3_15
        this.chk_disable = this.chk_random_disable_3
      } else if (rankName === "第四名" && orderName === "大") {
        this.bet_type = this.bet_type_big_4
        this.txt_exeucte_num_of_pass = this.txt_big_exeucte_num_of_pass_4
        this.txt_1 = this.txt_big_4_1
        this.txt_2 = this.txt_big_4_2
        this.txt_3 = this.txt_big_4_3
        this.txt_4 = this.txt_big_4_4
        this.txt_5 = this.txt_big_4_5
        this.txt_6 = this.txt_big_4_6
        this.txt_7 = this.txt_big_4_7
        this.txt_8 = this.txt_big_4_8
        this.txt_9 = this.txt_big_4_9
        this.txt_10 = this.txt_big_4_10
        this.txt_11 = this.txt_big_4_11
        this.txt_12 = this.txt_big_4_12
        this.txt_13 = this.txt_big_4_13
        this.txt_14 = this.txt_big_4_14
        this.txt_15 = this.txt_big_4_15
        this.chk_disable = this.chk_big_disable_4
      } else if (rankName === "第四名" && orderName === "小") {
        this.bet_type = this.bet_type_small_4
        this.txt_exeucte_num_of_pass = this.txt_small_exeucte_num_of_pass_4
        this.txt_1 = this.txt_small_4_1
        this.txt_2 = this.txt_small_4_2
        this.txt_3 = this.txt_small_4_3
        this.txt_4 = this.txt_small_4_4
        this.txt_5 = this.txt_small_4_5
        this.txt_6 = this.txt_small_4_6
        this.txt_7 = this.txt_small_4_7
        this.txt_8 = this.txt_small_4_8
        this.txt_9 = this.txt_small_4_9
        this.txt_10 = this.txt_small_4_10
        this.txt_11 = this.txt_small_4_11
        this.txt_12 = this.txt_small_4_12
        this.txt_13 = this.txt_small_4_13
        this.txt_14 = this.txt_small_4_14
        this.txt_15 = this.txt_small_4_15
        this.chk_disable = this.chk_small_disable_4
      } else if (rankName === "第四名" && orderName === "單") {
        this.bet_type = this.bet_type_odd_4
        this.txt_exeucte_num_of_pass = this.txt_odd_exeucte_num_of_pass_4
        this.txt_1 = this.txt_odd_4_1
        this.txt_2 = this.txt_odd_4_2
        this.txt_3 = this.txt_odd_4_3
        this.txt_4 = this.txt_odd_4_4
        this.txt_5 = this.txt_odd_4_5
        this.txt_6 = this.txt_odd_4_6
        this.txt_7 = this.txt_odd_4_7
        this.txt_8 = this.txt_odd_4_8
        this.txt_9 = this.txt_odd_4_9
        this.txt_10 = this.txt_odd_4_10
        this.txt_11 = this.txt_odd_4_11
        this.txt_12 = this.txt_odd_4_12
        this.txt_13 = this.txt_odd_4_13
        this.txt_14 = this.txt_odd_4_14
        this.txt_15 = this.txt_odd_4_15
        this.chk_disable = this.chk_odd_disable_4
      } else if (rankName === "第四名" && orderName === "雙") {
        this.bet_type = this.bet_type_even_4
        this.txt_exeucte_num_of_pass = this.txt_even_exeucte_num_of_pass_4
        this.txt_1 = this.txt_even_4_1
        this.txt_2 = this.txt_even_4_2
        this.txt_3 = this.txt_even_4_3
        this.txt_4 = this.txt_even_4_4
        this.txt_5 = this.txt_even_4_5
        this.txt_6 = this.txt_even_4_6
        this.txt_7 = this.txt_even_4_7
        this.txt_8 = this.txt_even_4_8
        this.txt_9 = this.txt_even_4_9
        this.txt_10 = this.txt_even_4_10
        this.txt_11 = this.txt_even_4_11
        this.txt_12 = this.txt_even_4_12
        this.txt_13 = this.txt_even_4_13
        this.txt_14 = this.txt_even_4_14
        this.txt_15 = this.txt_even_4_15
        this.chk_disable = this.chk_even_disable_4
      } else if (rankName === "第四名" && orderName === "隨機") {
        this.bet_type = this.bet_type_random_4
        this.random_type = this.random_type_4
        this.txt_exeucte_num_of_pass = this.txt_random_exeucte_num_of_pass_4
        this.txt_1 = this.txt_random_4_1
        this.txt_2 = this.txt_random_4_2
        this.txt_3 = this.txt_random_4_3
        this.txt_4 = this.txt_random_4_4
        this.txt_5 = this.txt_random_4_5
        this.txt_6 = this.txt_random_4_6
        this.txt_7 = this.txt_random_4_7
        this.txt_8 = this.txt_random_4_8
        this.txt_9 = this.txt_random_4_9
        this.txt_10 = this.txt_random_4_10
        this.txt_11 = this.txt_random_4_11
        this.txt_12 = this.txt_random_4_12
        this.txt_13 = this.txt_random_4_13
        this.txt_14 = this.txt_random_4_14
        this.txt_15 = this.txt_random_4_15
        this.chk_disable = this.chk_random_disable_4
      } else if (rankName === "第五名" && orderName === "大") {
        this.bet_type = this.bet_type_big_5
        this.txt_exeucte_num_of_pass = this.txt_big_exeucte_num_of_pass_5
        this.txt_1 = this.txt_big_5_1
        this.txt_2 = this.txt_big_5_2
        this.txt_3 = this.txt_big_5_3
        this.txt_4 = this.txt_big_5_4
        this.txt_5 = this.txt_big_5_5
        this.txt_6 = this.txt_big_5_6
        this.txt_7 = this.txt_big_5_7
        this.txt_8 = this.txt_big_5_8
        this.txt_9 = this.txt_big_5_9
        this.txt_10 = this.txt_big_5_10
        this.txt_11 = this.txt_big_5_11
        this.txt_12 = this.txt_big_5_12
        this.txt_13 = this.txt_big_5_13
        this.txt_14 = this.txt_big_5_14
        this.txt_15 = this.txt_big_5_15
        this.chk_disable = this.chk_big_disable_5
      } else if (rankName === "第五名" && orderName === "小") {
        this.bet_type = this.bet_type_small_5
        this.txt_exeucte_num_of_pass = this.txt_small_exeucte_num_of_pass_5
        this.txt_1 = this.txt_small_5_1
        this.txt_2 = this.txt_small_5_2
        this.txt_3 = this.txt_small_5_3
        this.txt_4 = this.txt_small_5_4
        this.txt_5 = this.txt_small_5_5
        this.txt_6 = this.txt_small_5_6
        this.txt_7 = this.txt_small_5_7
        this.txt_8 = this.txt_small_5_8
        this.txt_9 = this.txt_small_5_9
        this.txt_10 = this.txt_small_5_10
        this.txt_11 = this.txt_small_5_11
        this.txt_12 = this.txt_small_5_12
        this.txt_13 = this.txt_small_5_13
        this.txt_14 = this.txt_small_5_14
        this.txt_15 = this.txt_small_5_15
        this.chk_disable = this.chk_small_disable_5
      } else if (rankName === "第五名" && orderName === "單") {
        this.bet_type = this.bet_type_odd_5
        this.txt_exeucte_num_of_pass = this.txt_odd_exeucte_num_of_pass_5
        this.txt_1 = this.txt_odd_5_1
        this.txt_2 = this.txt_odd_5_2
        this.txt_3 = this.txt_odd_5_3
        this.txt_4 = this.txt_odd_5_4
        this.txt_5 = this.txt_odd_5_5
        this.txt_6 = this.txt_odd_5_6
        this.txt_7 = this.txt_odd_5_7
        this.txt_8 = this.txt_odd_5_8
        this.txt_9 = this.txt_odd_5_9
        this.txt_10 = this.txt_odd_5_10
        this.txt_11 = this.txt_odd_5_11
        this.txt_12 = this.txt_odd_5_12
        this.txt_13 = this.txt_odd_5_13
        this.txt_14 = this.txt_odd_5_14
        this.txt_15 = this.txt_odd_5_15
        this.chk_disable = this.chk_odd_disable_5
      } else if (rankName === "第五名" && orderName === "雙") {
        this.bet_type = this.bet_type_even_5
        this.txt_exeucte_num_of_pass = this.txt_even_exeucte_num_of_pass_5
        this.txt_1 = this.txt_even_5_1
        this.txt_2 = this.txt_even_5_2
        this.txt_3 = this.txt_even_5_3
        this.txt_4 = this.txt_even_5_4
        this.txt_5 = this.txt_even_5_5
        this.txt_6 = this.txt_even_5_6
        this.txt_7 = this.txt_even_5_7
        this.txt_8 = this.txt_even_5_8
        this.txt_9 = this.txt_even_5_9
        this.txt_10 = this.txt_even_5_10
        this.txt_11 = this.txt_even_5_11
        this.txt_12 = this.txt_even_5_12
        this.txt_13 = this.txt_even_5_13
        this.txt_14 = this.txt_even_5_14
        this.txt_15 = this.txt_even_5_15
        this.chk_disable = this.chk_even_disable_5
      } else if (rankName === "第五名" && orderName === "隨機") {
        this.bet_type = this.bet_type_random_5
        this.random_type = this.random_type_5
        this.txt_exeucte_num_of_pass = this.txt_random_exeucte_num_of_pass_5
        this.txt_1 = this.txt_random_5_1
        this.txt_2 = this.txt_random_5_2
        this.txt_3 = this.txt_random_5_3
        this.txt_4 = this.txt_random_5_4
        this.txt_5 = this.txt_random_5_5
        this.txt_6 = this.txt_random_5_6
        this.txt_7 = this.txt_random_5_7
        this.txt_8 = this.txt_random_5_8
        this.txt_9 = this.txt_random_5_9
        this.txt_10 = this.txt_random_5_10
        this.txt_11 = this.txt_random_5_11
        this.txt_12 = this.txt_random_5_12
        this.txt_13 = this.txt_random_5_13
        this.txt_14 = this.txt_random_5_14
        this.txt_15 = this.txt_random_5_15
        this.chk_disable = this.chk_random_disable_5
      } else if (rankName === "第六名" && orderName === "大") {
        this.bet_type = this.bet_type_big_6
        this.txt_exeucte_num_of_pass = this.txt_big_exeucte_num_of_pass_6
        this.txt_1 = this.txt_big_6_1
        this.txt_2 = this.txt_big_6_2
        this.txt_3 = this.txt_big_6_3
        this.txt_4 = this.txt_big_6_4
        this.txt_5 = this.txt_big_6_5
        this.txt_6 = this.txt_big_6_6
        this.txt_7 = this.txt_big_6_7
        this.txt_8 = this.txt_big_6_8
        this.txt_9 = this.txt_big_6_9
        this.txt_10 = this.txt_big_6_10
        this.txt_11 = this.txt_big_6_11
        this.txt_12 = this.txt_big_6_12
        this.txt_13 = this.txt_big_6_13
        this.txt_14 = this.txt_big_6_14
        this.txt_15 = this.txt_big_6_15
        this.chk_disable = this.chk_big_disable_6
      } else if (rankName === "第六名" && orderName === "小") {
        this.bet_type = this.bet_type_small_6
        this.txt_exeucte_num_of_pass = this.txt_small_exeucte_num_of_pass_6
        this.txt_1 = this.txt_small_6_1
        this.txt_2 = this.txt_small_6_2
        this.txt_3 = this.txt_small_6_3
        this.txt_4 = this.txt_small_6_4
        this.txt_5 = this.txt_small_6_5
        this.txt_6 = this.txt_small_6_6
        this.txt_7 = this.txt_small_6_7
        this.txt_8 = this.txt_small_6_8
        this.txt_9 = this.txt_small_6_9
        this.txt_10 = this.txt_small_6_10
        this.txt_11 = this.txt_small_6_11
        this.txt_12 = this.txt_small_6_12
        this.txt_13 = this.txt_small_6_13
        this.txt_14 = this.txt_small_6_14
        this.txt_15 = this.txt_small_6_15
        this.chk_disable = this.chk_small_disable_6
      } else if (rankName === "第六名" && orderName === "單") {
        this.bet_type = this.bet_type_odd_6
        this.txt_exeucte_num_of_pass = this.txt_odd_exeucte_num_of_pass_6
        this.txt_1 = this.txt_odd_6_1
        this.txt_2 = this.txt_odd_6_2
        this.txt_3 = this.txt_odd_6_3
        this.txt_4 = this.txt_odd_6_4
        this.txt_5 = this.txt_odd_6_5
        this.txt_6 = this.txt_odd_6_6
        this.txt_7 = this.txt_odd_6_7
        this.txt_8 = this.txt_odd_6_8
        this.txt_9 = this.txt_odd_6_9
        this.txt_10 = this.txt_odd_6_10
        this.txt_11 = this.txt_odd_6_11
        this.txt_12 = this.txt_odd_6_12
        this.txt_13 = this.txt_odd_6_13
        this.txt_14 = this.txt_odd_6_14
        this.txt_15 = this.txt_odd_6_15
        this.chk_disable = this.chk_odd_disable_6
      } else if (rankName === "第六名" && orderName === "雙") {
        this.bet_type = this.bet_type_even_6
        this.txt_exeucte_num_of_pass = this.txt_even_exeucte_num_of_pass_6
        this.txt_1 = this.txt_even_6_1
        this.txt_2 = this.txt_even_6_2
        this.txt_3 = this.txt_even_6_3
        this.txt_4 = this.txt_even_6_4
        this.txt_5 = this.txt_even_6_5
        this.txt_6 = this.txt_even_6_6
        this.txt_7 = this.txt_even_6_7
        this.txt_8 = this.txt_even_6_8
        this.txt_9 = this.txt_even_6_9
        this.txt_10 = this.txt_even_6_10
        this.txt_11 = this.txt_even_6_11
        this.txt_12 = this.txt_even_6_12
        this.txt_13 = this.txt_even_6_13
        this.txt_14 = this.txt_even_6_14
        this.txt_15 = this.txt_even_6_15
        this.chk_disable = this.chk_even_disable_6
      } else if (rankName === "第六名" && orderName === "隨機") {
        this.bet_type = this.bet_type_random_6
        this.random_type = this.random_type_6
        this.txt_exeucte_num_of_pass = this.txt_random_exeucte_num_of_pass_6
        this.txt_1 = this.txt_random_6_1
        this.txt_2 = this.txt_random_6_2
        this.txt_3 = this.txt_random_6_3
        this.txt_4 = this.txt_random_6_4
        this.txt_5 = this.txt_random_6_5
        this.txt_6 = this.txt_random_6_6
        this.txt_7 = this.txt_random_6_7
        this.txt_8 = this.txt_random_6_8
        this.txt_9 = this.txt_random_6_9
        this.txt_10 = this.txt_random_6_10
        this.txt_11 = this.txt_random_6_11
        this.txt_12 = this.txt_random_6_12
        this.txt_13 = this.txt_random_6_13
        this.txt_14 = this.txt_random_6_14
        this.txt_15 = this.txt_random_6_15
        this.chk_disable = this.chk_random_disable_6
      } else if (rankName === "第七名" && orderName === "大") {
        this.bet_type = this.bet_type_big_7
        this.txt_exeucte_num_of_pass = this.txt_big_exeucte_num_of_pass_7
        this.txt_1 = this.txt_big_7_1
        this.txt_2 = this.txt_big_7_2
        this.txt_3 = this.txt_big_7_3
        this.txt_4 = this.txt_big_7_4
        this.txt_5 = this.txt_big_7_5
        this.txt_6 = this.txt_big_7_6
        this.txt_7 = this.txt_big_7_7
        this.txt_8 = this.txt_big_7_8
        this.txt_9 = this.txt_big_7_9
        this.txt_10 = this.txt_big_7_10
        this.txt_11 = this.txt_big_7_11
        this.txt_12 = this.txt_big_7_12
        this.txt_13 = this.txt_big_7_13
        this.txt_14 = this.txt_big_7_14
        this.txt_15 = this.txt_big_7_15
        this.chk_disable = this.chk_big_disable_7
      } else if (rankName === "第七名" && orderName === "小") {
        this.bet_type = this.bet_type_small_7
        this.txt_exeucte_num_of_pass = this.txt_small_exeucte_num_of_pass_7
        this.txt_1 = this.txt_small_7_1
        this.txt_2 = this.txt_small_7_2
        this.txt_3 = this.txt_small_7_3
        this.txt_4 = this.txt_small_7_4
        this.txt_5 = this.txt_small_7_5
        this.txt_6 = this.txt_small_7_6
        this.txt_7 = this.txt_small_7_7
        this.txt_8 = this.txt_small_7_8
        this.txt_9 = this.txt_small_7_9
        this.txt_10 = this.txt_small_7_10
        this.txt_11 = this.txt_small_7_11
        this.txt_12 = this.txt_small_7_12
        this.txt_13 = this.txt_small_7_13
        this.txt_14 = this.txt_small_7_14
        this.txt_15 = this.txt_small_7_15
        this.chk_disable = this.chk_small_disable_7
      } else if (rankName === "第七名" && orderName === "單") {
        this.bet_type = this.bet_type_odd_7
        this.txt_exeucte_num_of_pass = this.txt_odd_exeucte_num_of_pass_7
        this.txt_1 = this.txt_odd_7_1
        this.txt_2 = this.txt_odd_7_2
        this.txt_3 = this.txt_odd_7_3
        this.txt_4 = this.txt_odd_7_4
        this.txt_5 = this.txt_odd_7_5
        this.txt_6 = this.txt_odd_7_6
        this.txt_7 = this.txt_odd_7_7
        this.txt_8 = this.txt_odd_7_8
        this.txt_9 = this.txt_odd_7_9
        this.txt_10 = this.txt_odd_7_10
        this.txt_11 = this.txt_odd_7_11
        this.txt_12 = this.txt_odd_7_12
        this.txt_13 = this.txt_odd_7_13
        this.txt_14 = this.txt_odd_7_14
        this.txt_15 = this.txt_odd_7_15
        this.chk_disable = this.chk_odd_disable_7
      } else if (rankName === "第七名" && orderName === "雙") {
        this.bet_type = this.bet_type_even_7
        this.txt_exeucte_num_of_pass = this.txt_even_exeucte_num_of_pass_7
        this.txt_1 = this.txt_even_7_1
        this.txt_2 = this.txt_even_7_2
        this.txt_3 = this.txt_even_7_3
        this.txt_4 = this.txt_even_7_4
        this.txt_5 = this.txt_even_7_5
        this.txt_6 = this.txt_even_7_6
        this.txt_7 = this.txt_even_7_7
        this.txt_8 = this.txt_even_7_8
        this.txt_9 = this.txt_even_7_9
        this.txt_10 = this.txt_even_7_10
        this.txt_11 = this.txt_even_7_11
        this.txt_12 = this.txt_even_7_12
        this.txt_13 = this.txt_even_7_13
        this.txt_14 = this.txt_even_7_14
        this.txt_15 = this.txt_even_7_15
        this.chk_disable = this.chk_even_disable_7
      } else if (rankName === "第七名" && orderName === "隨機") {
        this.bet_type = this.bet_type_random_7
        this.random_type = this.random_type_7
        this.txt_exeucte_num_of_pass = this.txt_random_exeucte_num_of_pass_7
        this.txt_1 = this.txt_random_7_1
        this.txt_2 = this.txt_random_7_2
        this.txt_3 = this.txt_random_7_3
        this.txt_4 = this.txt_random_7_4
        this.txt_5 = this.txt_random_7_5
        this.txt_6 = this.txt_random_7_6
        this.txt_7 = this.txt_random_7_7
        this.txt_8 = this.txt_random_7_8
        this.txt_9 = this.txt_random_7_9
        this.txt_10 = this.txt_random_7_10
        this.txt_11 = this.txt_random_7_11
        this.txt_12 = this.txt_random_7_12
        this.txt_13 = this.txt_random_7_13
        this.txt_14 = this.txt_random_7_14
        this.txt_15 = this.txt_random_7_15
        this.chk_disable = this.chk_random_disable_7
      } else if (rankName === "第八名" && orderName === "大") {
        this.bet_type = this.bet_type_big_8
        this.txt_exeucte_num_of_pass = this.txt_big_exeucte_num_of_pass_8
        this.txt_1 = this.txt_big_8_1
        this.txt_2 = this.txt_big_8_2
        this.txt_3 = this.txt_big_8_3
        this.txt_4 = this.txt_big_8_4
        this.txt_5 = this.txt_big_8_5
        this.txt_6 = this.txt_big_8_6
        this.txt_7 = this.txt_big_8_7
        this.txt_8 = this.txt_big_8_8
        this.txt_9 = this.txt_big_8_9
        this.txt_10 = this.txt_big_8_10
        this.txt_11 = this.txt_big_8_11
        this.txt_12 = this.txt_big_8_12
        this.txt_13 = this.txt_big_8_13
        this.txt_14 = this.txt_big_8_14
        this.txt_15 = this.txt_big_8_15
        this.chk_disable = this.chk_big_disable_8
      } else if (rankName === "第八名" && orderName === "小") {
        this.bet_type = this.bet_type_small_8
        this.txt_exeucte_num_of_pass = this.txt_small_exeucte_num_of_pass_8
        this.txt_1 = this.txt_small_8_1
        this.txt_2 = this.txt_small_8_2
        this.txt_3 = this.txt_small_8_3
        this.txt_4 = this.txt_small_8_4
        this.txt_5 = this.txt_small_8_5
        this.txt_6 = this.txt_small_8_6
        this.txt_7 = this.txt_small_8_7
        this.txt_8 = this.txt_small_8_8
        this.txt_9 = this.txt_small_8_9
        this.txt_10 = this.txt_small_8_10
        this.txt_11 = this.txt_small_8_11
        this.txt_12 = this.txt_small_8_12
        this.txt_13 = this.txt_small_8_13
        this.txt_14 = this.txt_small_8_14
        this.txt_15 = this.txt_small_8_15
        this.chk_disable = this.chk_small_disable_8
      } else if (rankName === "第八名" && orderName === "單") {
        this.bet_type = this.bet_type_odd_8
        this.txt_exeucte_num_of_pass = this.txt_odd_exeucte_num_of_pass_8
        this.txt_1 = this.txt_odd_8_1
        this.txt_2 = this.txt_odd_8_2
        this.txt_3 = this.txt_odd_8_3
        this.txt_4 = this.txt_odd_8_4
        this.txt_5 = this.txt_odd_8_5
        this.txt_6 = this.txt_odd_8_6
        this.txt_7 = this.txt_odd_8_7
        this.txt_8 = this.txt_odd_8_8
        this.txt_9 = this.txt_odd_8_9
        this.txt_10 = this.txt_odd_8_10
        this.txt_11 = this.txt_odd_8_11
        this.txt_12 = this.txt_odd_8_12
        this.txt_13 = this.txt_odd_8_13
        this.txt_14 = this.txt_odd_8_14
        this.txt_15 = this.txt_odd_8_15
        this.chk_disable = this.chk_odd_disable_8
      } else if (rankName === "第八名" && orderName === "雙") {
        this.bet_type = this.bet_type_even_8
        this.txt_exeucte_num_of_pass = this.txt_even_exeucte_num_of_pass_8
        this.txt_1 = this.txt_even_8_1
        this.txt_2 = this.txt_even_8_2
        this.txt_3 = this.txt_even_8_3
        this.txt_4 = this.txt_even_8_4
        this.txt_5 = this.txt_even_8_5
        this.txt_6 = this.txt_even_8_6
        this.txt_7 = this.txt_even_8_7
        this.txt_8 = this.txt_even_8_8
        this.txt_9 = this.txt_even_8_9
        this.txt_10 = this.txt_even_8_10
        this.txt_11 = this.txt_even_8_11
        this.txt_12 = this.txt_even_8_12
        this.txt_13 = this.txt_even_8_13
        this.txt_14 = this.txt_even_8_14
        this.txt_15 = this.txt_even_8_15
        this.chk_disable = this.chk_even_disable_8
      } else if (rankName === "第八名" && orderName === "隨機") {
        this.bet_type = this.bet_type_random_8
        this.random_type = this.random_type_8
        this.txt_exeucte_num_of_pass = this.txt_random_exeucte_num_of_pass_8
        this.txt_1 = this.txt_random_8_1
        this.txt_2 = this.txt_random_8_2
        this.txt_3 = this.txt_random_8_3
        this.txt_4 = this.txt_random_8_4
        this.txt_5 = this.txt_random_8_5
        this.txt_6 = this.txt_random_8_6
        this.txt_7 = this.txt_random_8_7
        this.txt_8 = this.txt_random_8_8
        this.txt_9 = this.txt_random_8_9
        this.txt_10 = this.txt_random_8_10
        this.txt_11 = this.txt_random_8_11
        this.txt_12 = this.txt_random_8_12
        this.txt_13 = this.txt_random_8_13
        this.txt_14 = this.txt_random_8_14
        this.txt_15 = this.txt_random_8_15
        this.chk_disable = this.chk_random_disable_8
      } else if (rankName === "第九名" && orderName === "大") {
        this.bet_type = this.bet_type_big_9
        this.txt_exeucte_num_of_pass = this.txt_big_exeucte_num_of_pass_9
        this.txt_1 = this.txt_big_9_1
        this.txt_2 = this.txt_big_9_2
        this.txt_3 = this.txt_big_9_3
        this.txt_4 = this.txt_big_9_4
        this.txt_5 = this.txt_big_9_5
        this.txt_6 = this.txt_big_9_6
        this.txt_7 = this.txt_big_9_7
        this.txt_8 = this.txt_big_9_8
        this.txt_9 = this.txt_big_9_9
        this.txt_10 = this.txt_big_9_10
        this.txt_11 = this.txt_big_9_11
        this.txt_12 = this.txt_big_9_12
        this.txt_13 = this.txt_big_9_13
        this.txt_14 = this.txt_big_9_14
        this.txt_15 = this.txt_big_9_15
        this.chk_disable = this.chk_big_disable_9
      } else if (rankName === "第九名" && orderName === "小") {
        this.bet_type = this.bet_type_small_9
        this.txt_exeucte_num_of_pass = this.txt_small_exeucte_num_of_pass_9
        this.txt_1 = this.txt_small_9_1
        this.txt_2 = this.txt_small_9_2
        this.txt_3 = this.txt_small_9_3
        this.txt_4 = this.txt_small_9_4
        this.txt_5 = this.txt_small_9_5
        this.txt_6 = this.txt_small_9_6
        this.txt_7 = this.txt_small_9_7
        this.txt_8 = this.txt_small_9_8
        this.txt_9 = this.txt_small_9_9
        this.txt_10 = this.txt_small_9_10
        this.txt_11 = this.txt_small_9_11
        this.txt_12 = this.txt_small_9_12
        this.txt_13 = this.txt_small_9_13
        this.txt_14 = this.txt_small_9_14
        this.txt_15 = this.txt_small_9_15
        this.chk_disable = this.chk_small_disable_9
      } else if (rankName === "第九名" && orderName === "單") {
        this.bet_type = this.bet_type_odd_9
        this.txt_exeucte_num_of_pass = this.txt_odd_exeucte_num_of_pass_9
        this.txt_1 = this.txt_odd_9_1
        this.txt_2 = this.txt_odd_9_2
        this.txt_3 = this.txt_odd_9_3
        this.txt_4 = this.txt_odd_9_4
        this.txt_5 = this.txt_odd_9_5
        this.txt_6 = this.txt_odd_9_6
        this.txt_7 = this.txt_odd_9_7
        this.txt_8 = this.txt_odd_9_8
        this.txt_9 = this.txt_odd_9_9
        this.txt_10 = this.txt_odd_9_10
        this.txt_11 = this.txt_odd_9_11
        this.txt_12 = this.txt_odd_9_12
        this.txt_13 = this.txt_odd_9_13
        this.txt_14 = this.txt_odd_9_14
        this.txt_15 = this.txt_odd_9_15
        this.chk_disable = this.chk_odd_disable_9
      } else if (rankName === "第九名" && orderName === "雙") {
        this.bet_type = this.bet_type_even_9
        this.txt_exeucte_num_of_pass = this.txt_even_exeucte_num_of_pass_9
        this.txt_1 = this.txt_even_9_1
        this.txt_2 = this.txt_even_9_2
        this.txt_3 = this.txt_even_9_3
        this.txt_4 = this.txt_even_9_4
        this.txt_5 = this.txt_even_9_5
        this.txt_6 = this.txt_even_9_6
        this.txt_7 = this.txt_even_9_7
        this.txt_8 = this.txt_even_9_8
        this.txt_9 = this.txt_even_9_9
        this.txt_10 = this.txt_even_9_10
        this.txt_11 = this.txt_even_9_11
        this.txt_12 = this.txt_even_9_12
        this.txt_13 = this.txt_even_9_13
        this.txt_14 = this.txt_even_9_14
        this.txt_15 = this.txt_even_9_15
        this.chk_disable = this.chk_even_disable_9
      } else if (rankName === "第九名" && orderName === "隨機") {
        this.bet_type = this.bet_type_random_9
        this.random_type = this.random_type_9
        this.txt_exeucte_num_of_pass = this.txt_random_exeucte_num_of_pass_9
        this.txt_1 = this.txt_random_9_1
        this.txt_2 = this.txt_random_9_2
        this.txt_3 = this.txt_random_9_3
        this.txt_4 = this.txt_random_9_4
        this.txt_5 = this.txt_random_9_5
        this.txt_6 = this.txt_random_9_6
        this.txt_7 = this.txt_random_9_7
        this.txt_8 = this.txt_random_9_8
        this.txt_9 = this.txt_random_9_9
        this.txt_10 = this.txt_random_9_10
        this.txt_11 = this.txt_random_9_11
        this.txt_12 = this.txt_random_9_12
        this.txt_13 = this.txt_random_9_13
        this.txt_14 = this.txt_random_9_14
        this.txt_15 = this.txt_random_9_15
        this.chk_disable = this.chk_random_disable_9
      } else if (rankName === "第十名" && orderName === "大") {
        this.bet_type = this.bet_type_big_10
        this.txt_exeucte_num_of_pass = this.txt_big_exeucte_num_of_pass_10
        this.txt_1 = this.txt_big_10_1
        this.txt_2 = this.txt_big_10_2
        this.txt_3 = this.txt_big_10_3
        this.txt_4 = this.txt_big_10_4
        this.txt_5 = this.txt_big_10_5
        this.txt_6 = this.txt_big_10_6
        this.txt_7 = this.txt_big_10_7
        this.txt_8 = this.txt_big_10_8
        this.txt_9 = this.txt_big_10_9
        this.txt_10 = this.txt_big_10_10
        this.txt_11 = this.txt_big_10_11
        this.txt_12 = this.txt_big_10_12
        this.txt_13 = this.txt_big_10_13
        this.txt_14 = this.txt_big_10_14
        this.txt_15 = this.txt_big_10_15
        this.chk_disable = this.chk_big_disable_10
      } else if (rankName === "第十名" && orderName === "小") {
        this.bet_type = this.bet_type_small_10
        this.txt_exeucte_num_of_pass = this.txt_small_exeucte_num_of_pass_10
        this.txt_1 = this.txt_small_10_1
        this.txt_2 = this.txt_small_10_2
        this.txt_3 = this.txt_small_10_3
        this.txt_4 = this.txt_small_10_4
        this.txt_5 = this.txt_small_10_5
        this.txt_6 = this.txt_small_10_6
        this.txt_7 = this.txt_small_10_7
        this.txt_8 = this.txt_small_10_8
        this.txt_9 = this.txt_small_10_9
        this.txt_10 = this.txt_small_10_10
        this.txt_11 = this.txt_small_10_11
        this.txt_12 = this.txt_small_10_12
        this.txt_13 = this.txt_small_10_13
        this.txt_14 = this.txt_small_10_14
        this.txt_15 = this.txt_small_10_15
        this.chk_disable = this.chk_small_disable_10
      } else if (rankName === "第十名" && orderName === "單") {
        this.bet_type = this.bet_type_odd_10
        this.txt_exeucte_num_of_pass = this.txt_odd_exeucte_num_of_pass_10
        this.txt_1 = this.txt_odd_10_1
        this.txt_2 = this.txt_odd_10_2
        this.txt_3 = this.txt_odd_10_3
        this.txt_4 = this.txt_odd_10_4
        this.txt_5 = this.txt_odd_10_5
        this.txt_6 = this.txt_odd_10_6
        this.txt_7 = this.txt_odd_10_7
        this.txt_8 = this.txt_odd_10_8
        this.txt_9 = this.txt_odd_10_9
        this.txt_10 = this.txt_odd_10_10
        this.txt_11 = this.txt_odd_10_11
        this.txt_12 = this.txt_odd_10_12
        this.txt_13 = this.txt_odd_10_13
        this.txt_14 = this.txt_odd_10_14
        this.txt_15 = this.txt_odd_10_15
        this.chk_disable = this.chk_odd_disable_10
      } else if (rankName === "第十名" && orderName === "雙") {
        this.bet_type = this.bet_type_even_10
        this.txt_exeucte_num_of_pass = this.txt_even_exeucte_num_of_pass_10
        this.txt_1 = this.txt_even_10_1
        this.txt_2 = this.txt_even_10_2
        this.txt_3 = this.txt_even_10_3
        this.txt_4 = this.txt_even_10_4
        this.txt_5 = this.txt_even_10_5
        this.txt_6 = this.txt_even_10_6
        this.txt_7 = this.txt_even_10_7
        this.txt_8 = this.txt_even_10_8
        this.txt_9 = this.txt_even_10_9
        this.txt_10 = this.txt_even_10_10
        this.txt_11 = this.txt_even_10_11
        this.txt_12 = this.txt_even_10_12
        this.txt_13 = this.txt_even_10_13
        this.txt_14 = this.txt_even_10_14
        this.txt_15 = this.txt_even_10_15
        this.chk_disable = this.chk_even_disable_10
      } else if (rankName === "第十名" && orderName === "隨機") {
        this.bet_type = this.bet_type_random_10
        this.random_type = this.random_type_10
        this.txt_exeucte_num_of_pass = this.txt_random_exeucte_num_of_pass_10
        this.txt_1 = this.txt_random_10_1
        this.txt_2 = this.txt_random_10_2
        this.txt_3 = this.txt_random_10_3
        this.txt_4 = this.txt_random_10_4
        this.txt_5 = this.txt_random_10_5
        this.txt_6 = this.txt_random_10_6
        this.txt_7 = this.txt_random_10_7
        this.txt_8 = this.txt_random_10_8
        this.txt_9 = this.txt_random_10_9
        this.txt_10 = this.txt_random_10_10
        this.txt_11 = this.txt_random_10_11
        this.txt_12 = this.txt_random_10_12
        this.txt_13 = this.txt_random_10_13
        this.txt_14 = this.txt_random_10_14
        this.txt_15 = this.txt_random_10_15
        this.chk_disable = this.chk_random_disable_10
      } else {
        console.log("error")
      }
    },
    selectBetting(e) {
      this.betting = e

      const requestData = {
        'betting': this.betting,
        'game_name': 'WG',
      };

      gameData(requestData, this.userId)
        .then((response) => {
          console.log(response);
          this.repsData = response.data
          this.repsMessage = response.data.message;
          const errorCode = response.data.error_code;
          console.log(this.repsData);
          console.log(errorCode);

          if ((this.repsData.betting === 'null') || (typeof (this.repsData.betting) === "undefined") || (this.repsData.betting === "")) {
            this.betting = e
          } else {
            this.betting = this.repsData.betting;
          }

          this.selectedLotteryType = this.repsData.lottery_type;
          this.selectedOrderType = this.repsData.order_type;
          this.method_type_10star = this.repsData.method_type_10star;
          this.account = this.repsData.account;
          this.password = this.repsData.password;
          this.bet_type = this.repsData.bet_type;
          this.bet_type_odd_1 = this.repsData.bet_type_odd_1;
          this.bet_type_even_1 = this.repsData.bet_type_even_1;
          this.bet_type_big_1 = this.repsData.bet_type_big_1;
          this.bet_type_small_1 = this.repsData.bet_type_small_1;
          this.bet_type_random_1 = this.repsData.bet_type_random_1;
          this.bet_type_odd_2 = this.repsData.bet_type_odd_2;
          this.bet_type_even_2 = this.repsData.bet_type_even_2;
          this.bet_type_big_2 = this.repsData.bet_type_big_2;
          this.bet_type_small_2 = this.repsData.bet_type_small_2;
          this.bet_type_random_2 = this.repsData.bet_type_random_2;
          this.bet_type_odd_3 = this.repsData.bet_type_odd_3;
          this.bet_type_even_3 = this.repsData.bet_type_even_3;
          this.bet_type_big_3 = this.repsData.bet_type_big_3;
          this.bet_type_small_3 = this.repsData.bet_type_small_3;
          this.bet_type_random_3 = this.repsData.bet_type_random_3;
          this.bet_type_odd_4 = this.repsData.bet_type_odd_4;
          this.bet_type_even_4 = this.repsData.bet_type_even_4;
          this.bet_type_big_4 = this.repsData.bet_type_big_4;
          this.bet_type_small_4 = this.repsData.bet_type_small_4;
          this.bet_type_random_4 = this.repsData.bet_type_random_4;
          this.bet_type_odd_5 = this.repsData.bet_type_odd_5;
          this.bet_type_even_5 = this.repsData.bet_type_even_5;
          this.bet_type_big_5 = this.repsData.bet_type_big_5;
          this.bet_type_small_5 = this.repsData.bet_type_small_5;
          this.bet_type_random_5 = this.repsData.bet_type_random_5;
          this.bet_type_odd_6 = this.repsData.bet_type_odd_6;
          this.bet_type_even_6 = this.repsData.bet_type_even_6;
          this.bet_type_big_6 = this.repsData.bet_type_big_6;
          this.bet_type_small_6 = this.repsData.bet_type_small_6;
          this.bet_type_random_6 = this.repsData.bet_type_random_6;
          this.bet_type_odd_7 = this.repsData.bet_type_odd_7;
          this.bet_type_even_7 = this.repsData.bet_type_even_7;
          this.bet_type_big_7 = this.repsData.bet_type_big_7;
          this.bet_type_small_7 = this.repsData.bet_type_small_7;
          this.bet_type_random_7 = this.repsData.bet_type_random_7;
          this.bet_type_odd_8 = this.repsData.bet_type_odd_8;
          this.bet_type_even_8 = this.repsData.bet_type_even_8;
          this.bet_type_big_8 = this.repsData.bet_type_big_8;
          this.bet_type_small_8 = this.repsData.bet_type_small_8;
          this.bet_type_random_8 = this.repsData.bet_type_random_8;
          this.bet_type_odd_9 = this.repsData.bet_type_odd_9;
          this.bet_type_even_9 = this.repsData.bet_type_even_9;
          this.bet_type_big_9 = this.repsData.bet_type_big_9;
          this.bet_type_small_9 = this.repsData.bet_type_small_9;
          this.bet_type_random_9 = this.repsData.bet_type_random_9;
          this.bet_type_odd_10 = this.repsData.bet_type_odd_10;
          this.bet_type_even_10 = this.repsData.bet_type_even_10;
          this.bet_type_big_10 = this.repsData.bet_type_big_10;
          this.bet_type_small_10 = this.repsData.bet_type_small_10;
          this.bet_type_random_10 = this.repsData.bet_type_random_10;
          this.random_type = this.repsData.random_type;
          this.random_type_1 = this.repsData.random_type_1;
          this.random_type_2 = this.repsData.random_type_2;
          this.random_type_3 = this.repsData.random_type_3;
          this.random_type_4 = this.repsData.random_type_4;
          this.random_type_5 = this.repsData.random_type_5;
          this.random_type_6 = this.repsData.random_type_6;
          this.random_type_7 = this.repsData.random_type_7;
          this.random_type_8 = this.repsData.random_type_8;
          this.random_type_9 = this.repsData.random_type_9;
          this.random_type_10 = this.repsData.random_type_10;
          this.chk_odd_disable_1 = Number(this.repsData.chk_odd_disable_1 ? this.repsData.chk_odd_disable_1 : true);
          this.chk_even_disable_1 = Number(this.repsData.chk_even_disable_1 ? this.repsData.chk_even_disable_1 : true);
          this.chk_big_disable_1 = Number(this.repsData.chk_big_disable_1 ? this.repsData.chk_big_disable_1 : true);
          this.chk_small_disable_1 = Number(this.repsData.chk_small_disable_1 ? this.repsData.chk_small_disable_1 : true);
          this.chk_random_disable_1 = Number(this.repsData.chk_random_disable_1 ? this.repsData.chk_random_disable_1 : true);
          this.chk_odd_disable_2 = Number(this.repsData.chk_odd_disable_2 ? this.repsData.chk_odd_disable_2 : true);
          this.chk_even_disable_2 = Number(this.repsData.chk_even_disable_2 ? this.repsData.chk_even_disable_2 : true);
          this.chk_big_disable_2 = Number(this.repsData.chk_big_disable_2 ? this.repsData.chk_big_disable_2 : true);
          this.chk_small_disable_2 = Number(this.repsData.chk_small_disable_2 ? this.repsData.chk_small_disable_2 : true);
          this.chk_random_disable_2 = Number(this.repsData.chk_random_disable_2 ? this.repsData.chk_random_disable_2 : true);
          this.chk_odd_disable_3 = Number(this.repsData.chk_odd_disable_3 ? this.repsData.chk_odd_disable_3 : true);
          this.chk_even_disable_3 = Number(this.repsData.chk_even_disable_3 ? this.repsData.chk_even_disable_3 : true);
          this.chk_big_disable_3 = Number(this.repsData.chk_big_disable_3 ? this.repsData.chk_big_disable_3 : true);
          this.chk_small_disable_3 = Number(this.repsData.chk_small_disable_3 ? this.repsData.chk_small_disable_3 : true);
          this.chk_random_disable_3 = Number(this.repsData.chk_random_disable_3 ? this.repsData.chk_random_disable_3 : true);
          this.chk_odd_disable_4 = Number(this.repsData.chk_odd_disable_4 ? this.repsData.chk_odd_disable_4 : true);
          this.chk_even_disable_4 = Number(this.repsData.chk_even_disable_4 ? this.repsData.chk_even_disable_4 : true);
          this.chk_big_disable_4 = Number(this.repsData.chk_big_disable_4 ? this.repsData.chk_big_disable_4 : true);
          this.chk_small_disable_4 = Number(this.repsData.chk_small_disable_4 ? this.repsData.chk_small_disable_4 : true);
          this.chk_random_disable_4 = Number(this.repsData.chk_random_disable_4 ? this.repsData.chk_random_disable_4 : true);
          this.chk_odd_disable_5 = Number(this.repsData.chk_odd_disable_5 ? this.repsData.chk_odd_disable_5 : true);
          this.chk_even_disable_5 = Number(this.repsData.chk_even_disable_5 ? this.repsData.chk_even_disable_5 : true);
          this.chk_big_disable_5 = Number(this.repsData.chk_big_disable_5 ? this.repsData.chk_big_disable_5 : true);
          this.chk_small_disable_5 = Number(this.repsData.chk_small_disable_5 ? this.repsData.chk_small_disable_5 : true);
          this.chk_random_disable_5 = Number(this.repsData.chk_random_disable_5 ? this.repsData.chk_random_disable_5 : true);
          this.chk_odd_disable_6 = Number(this.repsData.chk_odd_disable_6 ? this.repsData.chk_odd_disable_6 : true);
          this.chk_even_disable_6 = Number(this.repsData.chk_even_disable_6 ? this.repsData.chk_even_disable_6 : true);
          this.chk_big_disable_6 = Number(this.repsData.chk_big_disable_6 ? this.repsData.chk_big_disable_6 : true);
          this.chk_small_disable_6 = Number(this.repsData.chk_small_disable_6 ? this.repsData.chk_small_disable_6 : true);
          this.chk_random_disable_6 = Number(this.repsData.chk_random_disable_6 ? this.repsData.chk_random_disable_6 : true);
          this.chk_odd_disable_7 = Number(this.repsData.chk_odd_disable_7 ? this.repsData.chk_odd_disable_7 : true);
          this.chk_even_disable_7 = Number(this.repsData.chk_even_disable_7 ? this.repsData.chk_even_disable_7 : true);
          this.chk_big_disable_7 = Number(this.repsData.chk_big_disable_7 ? this.repsData.chk_big_disable_7 : true);
          this.chk_small_disable_7 = Number(this.repsData.chk_small_disable_7 ? this.repsData.chk_small_disable_7 : true);
          this.chk_random_disable_7 = Number(this.repsData.chk_random_disable_7 ? this.repsData.chk_random_disable_7 : true);
          this.chk_odd_disable_8 = Number(this.repsData.chk_odd_disable_8 ? this.repsData.chk_odd_disable_8 : true);
          this.chk_even_disable_8 = Number(this.repsData.chk_even_disable_8 ? this.repsData.chk_even_disable_8 : true);
          this.chk_big_disable_8 = Number(this.repsData.chk_big_disable_8 ? this.repsData.chk_big_disable_8 : true);
          this.chk_small_disable_8 = Number(this.repsData.chk_small_disable_8 ? this.repsData.chk_small_disable_8 : true);
          this.chk_random_disable_8 = Number(this.repsData.chk_random_disable_8 ? this.repsData.chk_random_disable_8 : true);
          this.chk_odd_disable_9 = Number(this.repsData.chk_odd_disable_9 ? this.repsData.chk_odd_disable_9 : true);
          this.chk_even_disable_9 = Number(this.repsData.chk_even_disable_9 ? this.repsData.chk_even_disable_9 : true);
          this.chk_big_disable_9 = Number(this.repsData.chk_big_disable_9 ? this.repsData.chk_big_disable_9 : true);
          this.chk_small_disable_9 = Number(this.repsData.chk_small_disable_9 ? this.repsData.chk_small_disable_9 : true);
          this.chk_random_disable_9 = Number(this.repsData.chk_random_disable_9 ? this.repsData.chk_random_disable_9 : true);
          this.chk_odd_disable_10 = Number(this.repsData.chk_odd_disable_10 ? this.repsData.chk_odd_disable_10 : true);
          this.chk_even_disable_10 = Number(this.repsData.chk_even_disable_10 ? this.repsData.chk_even_disable_10 : true);
          this.chk_big_disable_10 = Number(this.repsData.chk_big_disable_10 ? this.repsData.chk_big_disable_10 : true);
          this.chk_small_disable_10 = Number(this.repsData.chk_small_disable_10 ? this.repsData.chk_small_disable_10 : true);
          this.chk_random_disable_10 = Number(this.repsData.chk_random_disable_10 ? this.repsData.chk_random_disable_10 : true);
          this.items[0].isSetting[0] = this.chk_big_disable_1 == 1 ? 0 : 1
          this.items[0].isSetting[1] = this.chk_small_disable_1 == 1 ? 0 : 1
          this.items[0].isSetting[2] = this.chk_odd_disable_1 == 1 ? 0 : 1
          this.items[0].isSetting[3] = this.chk_even_disable_1 == 1 ? 0 : 1
          this.items[0].isSetting[4] = this.chk_random_disable_1 == 1 ? 0 : 1
          this.items[1].isSetting[0] = this.chk_big_disable_2 == 1 ? 0 : 1
          this.items[1].isSetting[1] = this.chk_small_disable_2 == 1 ? 0 : 1
          this.items[1].isSetting[2] = this.chk_odd_disable_2 == 1 ? 0 : 1
          this.items[1].isSetting[3] = this.chk_even_disable_2 == 1 ? 0 : 1
          this.items[1].isSetting[4] = this.chk_random_disable_2 == 1 ? 0 : 1
          this.items[2].isSetting[0] = this.chk_big_disable_3 == 1 ? 0 : 1
          this.items[2].isSetting[1] = this.chk_small_disable_3 == 1 ? 0 : 1
          this.items[2].isSetting[2] = this.chk_odd_disable_3 == 1 ? 0 : 1
          this.items[2].isSetting[3] = this.chk_even_disable_3 == 1 ? 0 : 1
          this.items[2].isSetting[4] = this.chk_random_disable_3 == 1 ? 0 : 1
          this.items[3].isSetting[0] = this.chk_big_disable_4 == 1 ? 0 : 1
          this.items[3].isSetting[1] = this.chk_small_disable_4 == 1 ? 0 : 1
          this.items[3].isSetting[2] = this.chk_odd_disable_4 == 1 ? 0 : 1
          this.items[3].isSetting[3] = this.chk_even_disable_4 == 1 ? 0 : 1
          this.items[3].isSetting[4] = this.chk_random_disable_4 == 1 ? 0 : 1
          this.items[4].isSetting[0] = this.chk_big_disable_5 == 1 ? 0 : 1
          this.items[4].isSetting[1] = this.chk_small_disable_5 == 1 ? 0 : 1
          this.items[4].isSetting[2] = this.chk_odd_disable_5 == 1 ? 0 : 1
          this.items[4].isSetting[3] = this.chk_even_disable_5 == 1 ? 0 : 1
          this.items[4].isSetting[4] = this.chk_random_disable_5 == 1 ? 0 : 1
          this.items[5].isSetting[0] = this.chk_big_disable_6 == 1 ? 0 : 1
          this.items[5].isSetting[1] = this.chk_small_disable_6 == 1 ? 0 : 1
          this.items[5].isSetting[2] = this.chk_odd_disable_6 == 1 ? 0 : 1
          this.items[5].isSetting[3] = this.chk_even_disable_6 == 1 ? 0 : 1
          this.items[5].isSetting[4] = this.chk_random_disable_6 == 1 ? 0 : 1
          this.items[6].isSetting[0] = this.chk_big_disable_7 == 1 ? 0 : 1
          this.items[6].isSetting[1] = this.chk_small_disable_7 == 1 ? 0 : 1
          this.items[6].isSetting[2] = this.chk_odd_disable_7 == 1 ? 0 : 1
          this.items[6].isSetting[3] = this.chk_even_disable_7 == 1 ? 0 : 1
          this.items[6].isSetting[4] = this.chk_random_disable_7 == 1 ? 0 : 1
          this.items[7].isSetting[0] = this.chk_big_disable_8 == 1 ? 0 : 1
          this.items[7].isSetting[1] = this.chk_small_disable_8 == 1 ? 0 : 1
          this.items[7].isSetting[2] = this.chk_odd_disable_8 == 1 ? 0 : 1
          this.items[7].isSetting[3] = this.chk_even_disable_8 == 1 ? 0 : 1
          this.items[7].isSetting[4] = this.chk_random_disable_8 == 1 ? 0 : 1
          this.items[8].isSetting[0] = this.chk_big_disable_9 == 1 ? 0 : 1
          this.items[8].isSetting[1] = this.chk_small_disable_9 == 1 ? 0 : 1
          this.items[8].isSetting[2] = this.chk_odd_disable_9 == 1 ? 0 : 1
          this.items[8].isSetting[3] = this.chk_even_disable_9 == 1 ? 0 : 1
          this.items[8].isSetting[4] = this.chk_random_disable_9 == 1 ? 0 : 1
          this.items[9].isSetting[0] = this.chk_big_disable_10 == 1 ? 0 : 1
          this.items[9].isSetting[1] = this.chk_small_disable_10 == 1 ? 0 : 1
          this.items[9].isSetting[2] = this.chk_odd_disable_10 == 1 ? 0 : 1
          this.items[9].isSetting[3] = this.chk_even_disable_10 == 1 ? 0 : 1
          this.items[9].isSetting[4] = this.chk_random_disable_10 == 1 ? 0 : 1
          this.txt_exeucte_num_of_pass_10star = parseInt(this.repsData.txt_exeucte_num_of_pass_10star ? this.repsData.txt_exeucte_num_of_pass_10star : 1);
          this.txt_odd_exeucte_num_of_pass_1 = parseInt(this.repsData.txt_odd_exeucte_num_of_pass_1 ? this.repsData.txt_odd_exeucte_num_of_pass_1 : 1);
          this.txt_even_exeucte_num_of_pass_1 = parseInt(this.repsData.txt_even_exeucte_num_of_pass_1 ? this.repsData.txt_even_exeucte_num_of_pass_1 : 1);
          this.txt_big_exeucte_num_of_pass_1 = parseInt(this.repsData.txt_big_exeucte_num_of_pass_1 ? this.repsData.txt_big_exeucte_num_of_pass_1 : 1);
          this.txt_small_exeucte_num_of_pass_1 = parseInt(this.repsData.txt_small_exeucte_num_of_pass_1 ? this.repsData.txt_small_exeucte_num_of_pass_1 : 1);
          this.txt_random_exeucte_num_of_pass_1 = parseInt(this.repsData.txt_random_exeucte_num_of_pass_1 ? this.repsData.txt_random_exeucte_num_of_pass_1 : 1);
          this.txt_odd_exeucte_num_of_pass_2 = parseInt(this.repsData.txt_odd_exeucte_num_of_pass_2 ? this.repsData.txt_odd_exeucte_num_of_pass_2 : 1);
          this.txt_even_exeucte_num_of_pass_2 = parseInt(this.repsData.txt_even_exeucte_num_of_pass_2 ? this.repsData.txt_even_exeucte_num_of_pass_2 : 1);
          this.txt_big_exeucte_num_of_pass_2 = parseInt(this.repsData.txt_big_exeucte_num_of_pass_2 ? this.repsData.txt_big_exeucte_num_of_pass_2 : 1);
          this.txt_small_exeucte_num_of_pass_2 = parseInt(this.repsData.txt_small_exeucte_num_of_pass_2 ? this.repsData.txt_small_exeucte_num_of_pass_2 : 1);
          this.txt_random_exeucte_num_of_pass_2 = parseInt(this.repsData.txt_random_exeucte_num_of_pass_2 ? this.repsData.txt_random_exeucte_num_of_pass_2 : 1);
          this.txt_odd_exeucte_num_of_pass_3 = parseInt(this.repsData.txt_odd_exeucte_num_of_pass_3 ? this.repsData.txt_odd_exeucte_num_of_pass_3 : 1);
          this.txt_even_exeucte_num_of_pass_3 = parseInt(this.repsData.txt_even_exeucte_num_of_pass_3 ? this.repsData.txt_even_exeucte_num_of_pass_3 : 1);
          this.txt_big_exeucte_num_of_pass_3 = parseInt(this.repsData.txt_big_exeucte_num_of_pass_3 ? this.repsData.txt_big_exeucte_num_of_pass_3 : 1);
          this.txt_small_exeucte_num_of_pass_3 = parseInt(this.repsData.txt_small_exeucte_num_of_pass_3 ? this.repsData.txt_small_exeucte_num_of_pass_3 : 1);
          this.txt_random_exeucte_num_of_pass_3 = parseInt(this.repsData.txt_random_exeucte_num_of_pass_3 ? this.repsData.txt_random_exeucte_num_of_pass_3 : 1);
          this.txt_odd_exeucte_num_of_pass_4 = parseInt(this.repsData.txt_odd_exeucte_num_of_pass_4 ? this.repsData.txt_odd_exeucte_num_of_pass_4 : 1);
          this.txt_even_exeucte_num_of_pass_4 = parseInt(this.repsData.txt_even_exeucte_num_of_pass_4 ? this.repsData.txt_even_exeucte_num_of_pass_4 : 1);
          this.txt_big_exeucte_num_of_pass_4 = parseInt(this.repsData.txt_big_exeucte_num_of_pass_4 ? this.repsData.txt_big_exeucte_num_of_pass_4 : 1);
          this.txt_small_exeucte_num_of_pass_4 = parseInt(this.repsData.txt_small_exeucte_num_of_pass_4 ? this.repsData.txt_small_exeucte_num_of_pass_4 : 1);
          this.txt_random_exeucte_num_of_pass_4 = parseInt(this.repsData.txt_random_exeucte_num_of_pass_4 ? this.repsData.txt_random_exeucte_num_of_pass_4 : 1);
          this.txt_odd_exeucte_num_of_pass_5 = parseInt(this.repsData.txt_odd_exeucte_num_of_pass_5 ? this.repsData.txt_odd_exeucte_num_of_pass_5 : 1);
          this.txt_even_exeucte_num_of_pass_5 = parseInt(this.repsData.txt_even_exeucte_num_of_pass_5 ? this.repsData.txt_even_exeucte_num_of_pass_5 : 1);
          this.txt_big_exeucte_num_of_pass_5 = parseInt(this.repsData.txt_big_exeucte_num_of_pass_5 ? this.repsData.txt_big_exeucte_num_of_pass_5 : 1);
          this.txt_small_exeucte_num_of_pass_5 = parseInt(this.repsData.txt_small_exeucte_num_of_pass_5 ? this.repsData.txt_small_exeucte_num_of_pass_5 : 1);
          this.txt_random_exeucte_num_of_pass_5 = parseInt(this.repsData.txt_random_exeucte_num_of_pass_5 ? this.repsData.txt_random_exeucte_num_of_pass_5 : 1);
          this.txt_odd_exeucte_num_of_pass_6 = parseInt(this.repsData.txt_odd_exeucte_num_of_pass_6 ? this.repsData.txt_odd_exeucte_num_of_pass_6 : 1);
          this.txt_even_exeucte_num_of_pass_6 = parseInt(this.repsData.txt_even_exeucte_num_of_pass_6 ? this.repsData.txt_even_exeucte_num_of_pass_6 : 1);
          this.txt_big_exeucte_num_of_pass_6 = parseInt(this.repsData.txt_big_exeucte_num_of_pass_6 ? this.repsData.txt_big_exeucte_num_of_pass_6 : 1);
          this.txt_small_exeucte_num_of_pass_6 = parseInt(this.repsData.txt_small_exeucte_num_of_pass_6 ? this.repsData.txt_small_exeucte_num_of_pass_6 : 1);
          this.txt_random_exeucte_num_of_pass_6 = parseInt(this.repsData.txt_random_exeucte_num_of_pass_6 ? this.repsData.txt_random_exeucte_num_of_pass_6 : 1);
          this.txt_odd_exeucte_num_of_pass_7 = parseInt(this.repsData.txt_odd_exeucte_num_of_pass_7 ? this.repsData.txt_odd_exeucte_num_of_pass_7 : 1);
          this.txt_even_exeucte_num_of_pass_7 = parseInt(this.repsData.txt_even_exeucte_num_of_pass_7 ? this.repsData.txt_even_exeucte_num_of_pass_7 : 1);
          this.txt_big_exeucte_num_of_pass_7 = parseInt(this.repsData.txt_big_exeucte_num_of_pass_7 ? this.repsData.txt_big_exeucte_num_of_pass_7 : 1);
          this.txt_small_exeucte_num_of_pass_7 = parseInt(this.repsData.txt_small_exeucte_num_of_pass_7 ? this.repsData.txt_small_exeucte_num_of_pass_7 : 1);
          this.txt_random_exeucte_num_of_pass_7 = parseInt(this.repsData.txt_random_exeucte_num_of_pass_7 ? this.repsData.txt_random_exeucte_num_of_pass_7 : 1);
          this.txt_odd_exeucte_num_of_pass_8 = parseInt(this.repsData.txt_odd_exeucte_num_of_pass_8 ? this.repsData.txt_odd_exeucte_num_of_pass_8 : 1);
          this.txt_even_exeucte_num_of_pass_8 = parseInt(this.repsData.txt_even_exeucte_num_of_pass_8 ? this.repsData.txt_even_exeucte_num_of_pass_8 : 1);
          this.txt_big_exeucte_num_of_pass_8 = parseInt(this.repsData.txt_big_exeucte_num_of_pass_8 ? this.repsData.txt_big_exeucte_num_of_pass_8 : 1);
          this.txt_small_exeucte_num_of_pass_8 = parseInt(this.repsData.txt_small_exeucte_num_of_pass_8 ? this.repsData.txt_small_exeucte_num_of_pass_8 : 1);
          this.txt_random_exeucte_num_of_pass_8 = parseInt(this.repsData.txt_random_exeucte_num_of_pass_8 ? this.repsData.txt_random_exeucte_num_of_pass_8 : 1);
          this.txt_odd_exeucte_num_of_pass_9 = parseInt(this.repsData.txt_odd_exeucte_num_of_pass_9 ? this.repsData.txt_odd_exeucte_num_of_pass_9 : 1);
          this.txt_even_exeucte_num_of_pass_9 = parseInt(this.repsData.txt_even_exeucte_num_of_pass_9 ? this.repsData.txt_even_exeucte_num_of_pass_9 : 1);
          this.txt_big_exeucte_num_of_pass_9 = parseInt(this.repsData.txt_big_exeucte_num_of_pass_9 ? this.repsData.txt_big_exeucte_num_of_pass_9 : 1);
          this.txt_small_exeucte_num_of_pass_9 = parseInt(this.repsData.txt_small_exeucte_num_of_pass_9 ? this.repsData.txt_small_exeucte_num_of_pass_9 : 1);
          this.txt_random_exeucte_num_of_pass_9 = parseInt(this.repsData.txt_random_exeucte_num_of_pass_9 ? this.repsData.txt_random_exeucte_num_of_pass_9 : 1);
          this.txt_odd_exeucte_num_of_pass_10 = parseInt(this.repsData.txt_odd_exeucte_num_of_pass_10 ? this.repsData.txt_odd_exeucte_num_of_pass_10 : 1);
          this.txt_even_exeucte_num_of_pass_10 = parseInt(this.repsData.txt_even_exeucte_num_of_pass_10 ? this.repsData.txt_even_exeucte_num_of_pass_10 : 1);
          this.txt_big_exeucte_num_of_pass_10 = parseInt(this.repsData.txt_big_exeucte_num_of_pass_10 ? this.repsData.txt_big_exeucte_num_of_pass_10 : 1);
          this.txt_small_exeucte_num_of_pass_10 = parseInt(this.repsData.txt_small_exeucte_num_of_pass_10 ? this.repsData.txt_small_exeucte_num_of_pass_10 : 1);
          this.txt_random_exeucte_num_of_pass_10 = parseInt(this.repsData.txt_random_exeucte_num_of_pass_10 ? this.repsData.txt_random_exeucte_num_of_pass_10 : 1);
          this.txt_odd_1_1 = parseInt(this.repsData.txt_odd_1_1 ? this.repsData.txt_odd_1_1 : 0);
          this.txt_odd_1_2 = parseInt(this.repsData.txt_odd_1_2 ? this.repsData.txt_odd_1_2 : 0);
          this.txt_odd_1_3 = parseInt(this.repsData.txt_odd_1_3 ? this.repsData.txt_odd_1_3 : 0);
          this.txt_odd_1_4 = parseInt(this.repsData.txt_odd_1_4 ? this.repsData.txt_odd_1_4 : 0);
          this.txt_odd_1_5 = parseInt(this.repsData.txt_odd_1_5 ? this.repsData.txt_odd_1_5 : 0);
          this.txt_odd_1_6 = parseInt(this.repsData.txt_odd_1_6 ? this.repsData.txt_odd_1_6 : 0);
          this.txt_odd_1_7 = parseInt(this.repsData.txt_odd_1_7 ? this.repsData.txt_odd_1_7 : 0);
          this.txt_odd_1_8 = parseInt(this.repsData.txt_odd_1_8 ? this.repsData.txt_odd_1_8 : 0);
          this.txt_odd_1_9 = parseInt(this.repsData.txt_odd_1_9 ? this.repsData.txt_odd_1_9 : 0);
          this.txt_odd_1_10 = parseInt(this.repsData.txt_odd_1_10 ? this.repsData.txt_odd_1_10 : 0);
          this.txt_odd_1_11 = parseInt(this.repsData.txt_odd_1_11 ? this.repsData.txt_odd_1_11 : 0);
          this.txt_odd_1_12 = parseInt(this.repsData.txt_odd_1_12 ? this.repsData.txt_odd_1_12 : 0);
          this.txt_odd_1_13 = parseInt(this.repsData.txt_odd_1_13 ? this.repsData.txt_odd_1_13 : 0);
          this.txt_odd_1_14 = parseInt(this.repsData.txt_odd_1_14 ? this.repsData.txt_odd_1_14 : 0);
          this.txt_odd_1_15 = parseInt(this.repsData.txt_odd_1_15 ? this.repsData.txt_odd_1_15 : 0);
          this.txt_odd_2_1 = parseInt(this.repsData.txt_odd_2_1 ? this.repsData.txt_odd_2_1 : 0);
          this.txt_odd_2_2 = parseInt(this.repsData.txt_odd_2_2 ? this.repsData.txt_odd_2_2 : 0);
          this.txt_odd_2_3 = parseInt(this.repsData.txt_odd_2_3 ? this.repsData.txt_odd_2_3 : 0);
          this.txt_odd_2_4 = parseInt(this.repsData.txt_odd_2_4 ? this.repsData.txt_odd_2_4 : 0);
          this.txt_odd_2_5 = parseInt(this.repsData.txt_odd_2_5 ? this.repsData.txt_odd_2_5 : 0);
          this.txt_odd_2_6 = parseInt(this.repsData.txt_odd_2_6 ? this.repsData.txt_odd_2_6 : 0);
          this.txt_odd_2_7 = parseInt(this.repsData.txt_odd_2_7 ? this.repsData.txt_odd_2_7 : 0);
          this.txt_odd_2_8 = parseInt(this.repsData.txt_odd_2_8 ? this.repsData.txt_odd_2_8 : 0);
          this.txt_odd_2_9 = parseInt(this.repsData.txt_odd_2_9 ? this.repsData.txt_odd_2_9 : 0);
          this.txt_odd_2_10 = parseInt(this.repsData.txt_odd_2_10 ? this.repsData.txt_odd_2_10 : 0);
          this.txt_odd_2_11 = parseInt(this.repsData.txt_odd_2_11 ? this.repsData.txt_odd_2_11 : 0);
          this.txt_odd_2_12 = parseInt(this.repsData.txt_odd_2_12 ? this.repsData.txt_odd_2_12 : 0);
          this.txt_odd_2_13 = parseInt(this.repsData.txt_odd_2_13 ? this.repsData.txt_odd_2_13 : 0);
          this.txt_odd_2_14 = parseInt(this.repsData.txt_odd_2_14 ? this.repsData.txt_odd_2_14 : 0);
          this.txt_odd_2_15 = parseInt(this.repsData.txt_odd_2_15 ? this.repsData.txt_odd_2_15 : 0);
          this.txt_odd_3_1 = parseInt(this.repsData.txt_odd_3_1 ? this.repsData.txt_odd_3_1 : 0);
          this.txt_odd_3_2 = parseInt(this.repsData.txt_odd_3_2 ? this.repsData.txt_odd_3_2 : 0);
          this.txt_odd_3_3 = parseInt(this.repsData.txt_odd_3_3 ? this.repsData.txt_odd_3_3 : 0);
          this.txt_odd_3_4 = parseInt(this.repsData.txt_odd_3_4 ? this.repsData.txt_odd_3_4 : 0);
          this.txt_odd_3_5 = parseInt(this.repsData.txt_odd_3_5 ? this.repsData.txt_odd_3_5 : 0);
          this.txt_odd_3_6 = parseInt(this.repsData.txt_odd_3_6 ? this.repsData.txt_odd_3_6 : 0);
          this.txt_odd_3_7 = parseInt(this.repsData.txt_odd_3_7 ? this.repsData.txt_odd_3_7 : 0);
          this.txt_odd_3_8 = parseInt(this.repsData.txt_odd_3_8 ? this.repsData.txt_odd_3_8 : 0);
          this.txt_odd_3_9 = parseInt(this.repsData.txt_odd_3_9 ? this.repsData.txt_odd_3_9 : 0);
          this.txt_odd_3_10 = parseInt(this.repsData.txt_odd_3_10 ? this.repsData.txt_odd_3_10 : 0);
          this.txt_odd_3_11 = parseInt(this.repsData.txt_odd_3_11 ? this.repsData.txt_odd_3_11 : 0);
          this.txt_odd_3_12 = parseInt(this.repsData.txt_odd_3_12 ? this.repsData.txt_odd_3_12 : 0);
          this.txt_odd_3_13 = parseInt(this.repsData.txt_odd_3_13 ? this.repsData.txt_odd_3_13 : 0);
          this.txt_odd_3_14 = parseInt(this.repsData.txt_odd_3_14 ? this.repsData.txt_odd_3_14 : 0);
          this.txt_odd_3_15 = parseInt(this.repsData.txt_odd_3_15 ? this.repsData.txt_odd_3_15 : 0);
          this.txt_odd_4_1 = parseInt(this.repsData.txt_odd_4_1 ? this.repsData.txt_odd_4_1 : 0);
          this.txt_odd_4_2 = parseInt(this.repsData.txt_odd_4_2 ? this.repsData.txt_odd_4_2 : 0);
          this.txt_odd_4_3 = parseInt(this.repsData.txt_odd_4_3 ? this.repsData.txt_odd_4_3 : 0);
          this.txt_odd_4_4 = parseInt(this.repsData.txt_odd_4_4 ? this.repsData.txt_odd_4_4 : 0);
          this.txt_odd_4_5 = parseInt(this.repsData.txt_odd_4_5 ? this.repsData.txt_odd_4_5 : 0);
          this.txt_odd_4_6 = parseInt(this.repsData.txt_odd_4_6 ? this.repsData.txt_odd_4_6 : 0);
          this.txt_odd_4_7 = parseInt(this.repsData.txt_odd_4_7 ? this.repsData.txt_odd_4_7 : 0);
          this.txt_odd_4_8 = parseInt(this.repsData.txt_odd_4_8 ? this.repsData.txt_odd_4_8 : 0);
          this.txt_odd_4_9 = parseInt(this.repsData.txt_odd_4_9 ? this.repsData.txt_odd_4_9 : 0);
          this.txt_odd_4_10 = parseInt(this.repsData.txt_odd_4_10 ? this.repsData.txt_odd_4_10 : 0);
          this.txt_odd_4_11 = parseInt(this.repsData.txt_odd_4_11 ? this.repsData.txt_odd_4_11 : 0);
          this.txt_odd_4_12 = parseInt(this.repsData.txt_odd_4_12 ? this.repsData.txt_odd_4_12 : 0);
          this.txt_odd_4_13 = parseInt(this.repsData.txt_odd_4_13 ? this.repsData.txt_odd_4_13 : 0);
          this.txt_odd_4_14 = parseInt(this.repsData.txt_odd_4_14 ? this.repsData.txt_odd_4_14 : 0);
          this.txt_odd_4_15 = parseInt(this.repsData.txt_odd_4_15 ? this.repsData.txt_odd_4_15 : 0);
          this.txt_odd_5_1 = parseInt(this.repsData.txt_odd_5_1 ? this.repsData.txt_odd_5_1 : 0);
          this.txt_odd_5_2 = parseInt(this.repsData.txt_odd_5_2 ? this.repsData.txt_odd_5_2 : 0);
          this.txt_odd_5_3 = parseInt(this.repsData.txt_odd_5_3 ? this.repsData.txt_odd_5_3 : 0);
          this.txt_odd_5_4 = parseInt(this.repsData.txt_odd_5_4 ? this.repsData.txt_odd_5_4 : 0);
          this.txt_odd_5_5 = parseInt(this.repsData.txt_odd_5_5 ? this.repsData.txt_odd_5_5 : 0);
          this.txt_odd_5_6 = parseInt(this.repsData.txt_odd_5_6 ? this.repsData.txt_odd_5_6 : 0);
          this.txt_odd_5_7 = parseInt(this.repsData.txt_odd_5_7 ? this.repsData.txt_odd_5_7 : 0);
          this.txt_odd_5_8 = parseInt(this.repsData.txt_odd_5_8 ? this.repsData.txt_odd_5_8 : 0);
          this.txt_odd_5_9 = parseInt(this.repsData.txt_odd_5_9 ? this.repsData.txt_odd_5_9 : 0);
          this.txt_odd_5_10 = parseInt(this.repsData.txt_odd_5_10 ? this.repsData.txt_odd_5_10 : 0);
          this.txt_odd_5_11 = parseInt(this.repsData.txt_odd_5_11 ? this.repsData.txt_odd_5_11 : 0);
          this.txt_odd_5_12 = parseInt(this.repsData.txt_odd_5_12 ? this.repsData.txt_odd_5_12 : 0);
          this.txt_odd_5_13 = parseInt(this.repsData.txt_odd_5_13 ? this.repsData.txt_odd_5_13 : 0);
          this.txt_odd_5_14 = parseInt(this.repsData.txt_odd_5_14 ? this.repsData.txt_odd_5_14 : 0);
          this.txt_odd_5_15 = parseInt(this.repsData.txt_odd_5_15 ? this.repsData.txt_odd_5_15 : 0);
          this.txt_odd_6_1 = parseInt(this.repsData.txt_odd_6_1 ? this.repsData.txt_odd_6_1 : 0);
          this.txt_odd_6_2 = parseInt(this.repsData.txt_odd_6_2 ? this.repsData.txt_odd_6_2 : 0);
          this.txt_odd_6_3 = parseInt(this.repsData.txt_odd_6_3 ? this.repsData.txt_odd_6_3 : 0);
          this.txt_odd_6_4 = parseInt(this.repsData.txt_odd_6_4 ? this.repsData.txt_odd_6_4 : 0);
          this.txt_odd_6_5 = parseInt(this.repsData.txt_odd_6_5 ? this.repsData.txt_odd_6_5 : 0);
          this.txt_odd_6_6 = parseInt(this.repsData.txt_odd_6_6 ? this.repsData.txt_odd_6_6 : 0);
          this.txt_odd_6_7 = parseInt(this.repsData.txt_odd_6_7 ? this.repsData.txt_odd_6_7 : 0);
          this.txt_odd_6_8 = parseInt(this.repsData.txt_odd_6_8 ? this.repsData.txt_odd_6_8 : 0);
          this.txt_odd_6_9 = parseInt(this.repsData.txt_odd_6_9 ? this.repsData.txt_odd_6_9 : 0);
          this.txt_odd_6_10 = parseInt(this.repsData.txt_odd_6_10 ? this.repsData.txt_odd_6_10 : 0);
          this.txt_odd_6_11 = parseInt(this.repsData.txt_odd_6_11 ? this.repsData.txt_odd_6_11 : 0);
          this.txt_odd_6_12 = parseInt(this.repsData.txt_odd_6_12 ? this.repsData.txt_odd_6_12 : 0);
          this.txt_odd_6_13 = parseInt(this.repsData.txt_odd_6_13 ? this.repsData.txt_odd_6_13 : 0);
          this.txt_odd_6_14 = parseInt(this.repsData.txt_odd_6_14 ? this.repsData.txt_odd_6_14 : 0);
          this.txt_odd_6_15 = parseInt(this.repsData.txt_odd_6_15 ? this.repsData.txt_odd_6_15 : 0);
          this.txt_odd_7_1 = parseInt(this.repsData.txt_odd_7_1 ? this.repsData.txt_odd_7_1 : 0);
          this.txt_odd_7_2 = parseInt(this.repsData.txt_odd_7_2 ? this.repsData.txt_odd_7_2 : 0);
          this.txt_odd_7_3 = parseInt(this.repsData.txt_odd_7_3 ? this.repsData.txt_odd_7_3 : 0);
          this.txt_odd_7_4 = parseInt(this.repsData.txt_odd_7_4 ? this.repsData.txt_odd_7_4 : 0);
          this.txt_odd_7_5 = parseInt(this.repsData.txt_odd_7_5 ? this.repsData.txt_odd_7_5 : 0);
          this.txt_odd_7_6 = parseInt(this.repsData.txt_odd_7_6 ? this.repsData.txt_odd_7_6 : 0);
          this.txt_odd_7_7 = parseInt(this.repsData.txt_odd_7_7 ? this.repsData.txt_odd_7_7 : 0);
          this.txt_odd_7_8 = parseInt(this.repsData.txt_odd_7_8 ? this.repsData.txt_odd_7_8 : 0);
          this.txt_odd_7_9 = parseInt(this.repsData.txt_odd_7_9 ? this.repsData.txt_odd_7_9 : 0);
          this.txt_odd_7_10 = parseInt(this.repsData.txt_odd_7_10 ? this.repsData.txt_odd_7_10 : 0);
          this.txt_odd_7_11 = parseInt(this.repsData.txt_odd_7_11 ? this.repsData.txt_odd_7_11 : 0);
          this.txt_odd_7_12 = parseInt(this.repsData.txt_odd_7_12 ? this.repsData.txt_odd_7_12 : 0);
          this.txt_odd_7_13 = parseInt(this.repsData.txt_odd_7_13 ? this.repsData.txt_odd_7_13 : 0);
          this.txt_odd_7_14 = parseInt(this.repsData.txt_odd_7_14 ? this.repsData.txt_odd_7_14 : 0);
          this.txt_odd_7_15 = parseInt(this.repsData.txt_odd_7_15 ? this.repsData.txt_odd_7_15 : 0);
          this.txt_odd_8_1 = parseInt(this.repsData.txt_odd_8_1 ? this.repsData.txt_odd_8_1 : 0);
          this.txt_odd_8_2 = parseInt(this.repsData.txt_odd_8_2 ? this.repsData.txt_odd_8_2 : 0);
          this.txt_odd_8_3 = parseInt(this.repsData.txt_odd_8_3 ? this.repsData.txt_odd_8_3 : 0);
          this.txt_odd_8_4 = parseInt(this.repsData.txt_odd_8_4 ? this.repsData.txt_odd_8_4 : 0);
          this.txt_odd_8_5 = parseInt(this.repsData.txt_odd_8_5 ? this.repsData.txt_odd_8_5 : 0);
          this.txt_odd_8_6 = parseInt(this.repsData.txt_odd_8_6 ? this.repsData.txt_odd_8_6 : 0);
          this.txt_odd_8_7 = parseInt(this.repsData.txt_odd_8_7 ? this.repsData.txt_odd_8_7 : 0);
          this.txt_odd_8_8 = parseInt(this.repsData.txt_odd_8_8 ? this.repsData.txt_odd_8_8 : 0);
          this.txt_odd_8_9 = parseInt(this.repsData.txt_odd_8_9 ? this.repsData.txt_odd_8_9 : 0);
          this.txt_odd_8_10 = parseInt(this.repsData.txt_odd_8_10 ? this.repsData.txt_odd_8_10 : 0);
          this.txt_odd_8_11 = parseInt(this.repsData.txt_odd_8_11 ? this.repsData.txt_odd_8_11 : 0);
          this.txt_odd_8_12 = parseInt(this.repsData.txt_odd_8_12 ? this.repsData.txt_odd_8_12 : 0);
          this.txt_odd_8_13 = parseInt(this.repsData.txt_odd_8_13 ? this.repsData.txt_odd_8_13 : 0);
          this.txt_odd_8_14 = parseInt(this.repsData.txt_odd_8_14 ? this.repsData.txt_odd_8_14 : 0);
          this.txt_odd_8_15 = parseInt(this.repsData.txt_odd_8_15 ? this.repsData.txt_odd_8_15 : 0);
          this.txt_odd_9_1 = parseInt(this.repsData.txt_odd_9_1 ? this.repsData.txt_odd_9_1 : 0);
          this.txt_odd_9_2 = parseInt(this.repsData.txt_odd_9_2 ? this.repsData.txt_odd_9_2 : 0);
          this.txt_odd_9_3 = parseInt(this.repsData.txt_odd_9_3 ? this.repsData.txt_odd_9_3 : 0);
          this.txt_odd_9_4 = parseInt(this.repsData.txt_odd_9_4 ? this.repsData.txt_odd_9_4 : 0);
          this.txt_odd_9_5 = parseInt(this.repsData.txt_odd_9_5 ? this.repsData.txt_odd_9_5 : 0);
          this.txt_odd_9_6 = parseInt(this.repsData.txt_odd_9_6 ? this.repsData.txt_odd_9_6 : 0);
          this.txt_odd_9_7 = parseInt(this.repsData.txt_odd_9_7 ? this.repsData.txt_odd_9_7 : 0);
          this.txt_odd_9_8 = parseInt(this.repsData.txt_odd_9_8 ? this.repsData.txt_odd_9_8 : 0);
          this.txt_odd_9_9 = parseInt(this.repsData.txt_odd_9_9 ? this.repsData.txt_odd_9_9 : 0);
          this.txt_odd_9_10 = parseInt(this.repsData.txt_odd_9_10 ? this.repsData.txt_odd_9_10 : 0);
          this.txt_odd_9_11 = parseInt(this.repsData.txt_odd_9_11 ? this.repsData.txt_odd_9_11 : 0);
          this.txt_odd_9_12 = parseInt(this.repsData.txt_odd_9_12 ? this.repsData.txt_odd_9_12 : 0);
          this.txt_odd_9_13 = parseInt(this.repsData.txt_odd_9_13 ? this.repsData.txt_odd_9_13 : 0);
          this.txt_odd_9_14 = parseInt(this.repsData.txt_odd_9_14 ? this.repsData.txt_odd_9_14 : 0);
          this.txt_odd_9_15 = parseInt(this.repsData.txt_odd_9_15 ? this.repsData.txt_odd_9_15 : 0);
          this.txt_odd_10_1 = parseInt(this.repsData.txt_odd_10_1 ? this.repsData.txt_odd_10_1 : 0);
          this.txt_odd_10_2 = parseInt(this.repsData.txt_odd_10_2 ? this.repsData.txt_odd_10_2 : 0);
          this.txt_odd_10_3 = parseInt(this.repsData.txt_odd_10_3 ? this.repsData.txt_odd_10_3 : 0);
          this.txt_odd_10_4 = parseInt(this.repsData.txt_odd_10_4 ? this.repsData.txt_odd_10_4 : 0);
          this.txt_odd_10_5 = parseInt(this.repsData.txt_odd_10_5 ? this.repsData.txt_odd_10_5 : 0);
          this.txt_odd_10_6 = parseInt(this.repsData.txt_odd_10_6 ? this.repsData.txt_odd_10_6 : 0);
          this.txt_odd_10_7 = parseInt(this.repsData.txt_odd_10_7 ? this.repsData.txt_odd_10_7 : 0);
          this.txt_odd_10_8 = parseInt(this.repsData.txt_odd_10_8 ? this.repsData.txt_odd_10_8 : 0);
          this.txt_odd_10_9 = parseInt(this.repsData.txt_odd_10_9 ? this.repsData.txt_odd_10_9 : 0);
          this.txt_odd_10_10 = parseInt(this.repsData.txt_odd_10_10 ? this.repsData.txt_odd_10_10 : 0);
          this.txt_odd_10_11 = parseInt(this.repsData.txt_odd_10_11 ? this.repsData.txt_odd_10_11 : 0);
          this.txt_odd_10_12 = parseInt(this.repsData.txt_odd_10_12 ? this.repsData.txt_odd_10_12 : 0);
          this.txt_odd_10_13 = parseInt(this.repsData.txt_odd_10_13 ? this.repsData.txt_odd_10_13 : 0);
          this.txt_odd_10_14 = parseInt(this.repsData.txt_odd_10_14 ? this.repsData.txt_odd_10_14 : 0);
          this.txt_odd_10_15 = parseInt(this.repsData.txt_odd_10_15 ? this.repsData.txt_odd_10_15 : 0);
          this.txt_even_1_1 = parseInt(this.repsData.txt_even_1_1 ? this.repsData.txt_even_1_1 : 0);
          this.txt_even_1_2 = parseInt(this.repsData.txt_even_1_2 ? this.repsData.txt_even_1_2 : 0);
          this.txt_even_1_3 = parseInt(this.repsData.txt_even_1_3 ? this.repsData.txt_even_1_3 : 0);
          this.txt_even_1_4 = parseInt(this.repsData.txt_even_1_4 ? this.repsData.txt_even_1_4 : 0);
          this.txt_even_1_5 = parseInt(this.repsData.txt_even_1_5 ? this.repsData.txt_even_1_5 : 0);
          this.txt_even_1_6 = parseInt(this.repsData.txt_even_1_6 ? this.repsData.txt_even_1_6 : 0);
          this.txt_even_1_7 = parseInt(this.repsData.txt_even_1_7 ? this.repsData.txt_even_1_7 : 0);
          this.txt_even_1_8 = parseInt(this.repsData.txt_even_1_8 ? this.repsData.txt_even_1_8 : 0);
          this.txt_even_1_9 = parseInt(this.repsData.txt_even_1_9 ? this.repsData.txt_even_1_9 : 0);
          this.txt_even_1_10 = parseInt(this.repsData.txt_even_1_10 ? this.repsData.txt_even_1_10 : 0);
          this.txt_even_1_11 = parseInt(this.repsData.txt_even_1_11 ? this.repsData.txt_even_1_11 : 0);
          this.txt_even_1_12 = parseInt(this.repsData.txt_even_1_12 ? this.repsData.txt_even_1_12 : 0);
          this.txt_even_1_13 = parseInt(this.repsData.txt_even_1_13 ? this.repsData.txt_even_1_13 : 0);
          this.txt_even_1_14 = parseInt(this.repsData.txt_even_1_14 ? this.repsData.txt_even_1_14 : 0);
          this.txt_even_1_15 = parseInt(this.repsData.txt_even_1_15 ? this.repsData.txt_even_1_15 : 0);
          this.txt_even_2_1 = parseInt(this.repsData.txt_even_2_1 ? this.repsData.txt_even_2_1 : 0);
          this.txt_even_2_2 = parseInt(this.repsData.txt_even_2_2 ? this.repsData.txt_even_2_2 : 0);
          this.txt_even_2_3 = parseInt(this.repsData.txt_even_2_3 ? this.repsData.txt_even_2_3 : 0);
          this.txt_even_2_4 = parseInt(this.repsData.txt_even_2_4 ? this.repsData.txt_even_2_4 : 0);
          this.txt_even_2_5 = parseInt(this.repsData.txt_even_2_5 ? this.repsData.txt_even_2_5 : 0);
          this.txt_even_2_6 = parseInt(this.repsData.txt_even_2_6 ? this.repsData.txt_even_2_6 : 0);
          this.txt_even_2_7 = parseInt(this.repsData.txt_even_2_7 ? this.repsData.txt_even_2_7 : 0);
          this.txt_even_2_8 = parseInt(this.repsData.txt_even_2_8 ? this.repsData.txt_even_2_8 : 0);
          this.txt_even_2_9 = parseInt(this.repsData.txt_even_2_9 ? this.repsData.txt_even_2_9 : 0);
          this.txt_even_2_10 = parseInt(this.repsData.txt_even_2_10 ? this.repsData.txt_even_2_10 : 0);
          this.txt_even_2_11 = parseInt(this.repsData.txt_even_2_11 ? this.repsData.txt_even_2_11 : 0);
          this.txt_even_2_12 = parseInt(this.repsData.txt_even_2_12 ? this.repsData.txt_even_2_12 : 0);
          this.txt_even_2_13 = parseInt(this.repsData.txt_even_2_13 ? this.repsData.txt_even_2_13 : 0);
          this.txt_even_2_14 = parseInt(this.repsData.txt_even_2_14 ? this.repsData.txt_even_2_14 : 0);
          this.txt_even_2_15 = parseInt(this.repsData.txt_even_2_15 ? this.repsData.txt_even_2_15 : 0);
          this.txt_even_3_1 = parseInt(this.repsData.txt_even_3_1 ? this.repsData.txt_even_3_1 : 0);
          this.txt_even_3_2 = parseInt(this.repsData.txt_even_3_2 ? this.repsData.txt_even_3_2 : 0);
          this.txt_even_3_3 = parseInt(this.repsData.txt_even_3_3 ? this.repsData.txt_even_3_3 : 0);
          this.txt_even_3_4 = parseInt(this.repsData.txt_even_3_4 ? this.repsData.txt_even_3_4 : 0);
          this.txt_even_3_5 = parseInt(this.repsData.txt_even_3_5 ? this.repsData.txt_even_3_5 : 0);
          this.txt_even_3_6 = parseInt(this.repsData.txt_even_3_6 ? this.repsData.txt_even_3_6 : 0);
          this.txt_even_3_7 = parseInt(this.repsData.txt_even_3_7 ? this.repsData.txt_even_3_7 : 0);
          this.txt_even_3_8 = parseInt(this.repsData.txt_even_3_8 ? this.repsData.txt_even_3_8 : 0);
          this.txt_even_3_9 = parseInt(this.repsData.txt_even_3_9 ? this.repsData.txt_even_3_9 : 0);
          this.txt_even_3_10 = parseInt(this.repsData.txt_even_3_10 ? this.repsData.txt_even_3_10 : 0);
          this.txt_even_3_11 = parseInt(this.repsData.txt_even_3_11 ? this.repsData.txt_even_3_11 : 0);
          this.txt_even_3_12 = parseInt(this.repsData.txt_even_3_12 ? this.repsData.txt_even_3_12 : 0);
          this.txt_even_3_13 = parseInt(this.repsData.txt_even_3_13 ? this.repsData.txt_even_3_13 : 0);
          this.txt_even_3_14 = parseInt(this.repsData.txt_even_3_14 ? this.repsData.txt_even_3_14 : 0);
          this.txt_even_3_15 = parseInt(this.repsData.txt_even_3_15 ? this.repsData.txt_even_3_15 : 0);
          this.txt_even_4_1 = parseInt(this.repsData.txt_even_4_1 ? this.repsData.txt_even_4_1 : 0);
          this.txt_even_4_2 = parseInt(this.repsData.txt_even_4_2 ? this.repsData.txt_even_4_2 : 0);
          this.txt_even_4_3 = parseInt(this.repsData.txt_even_4_3 ? this.repsData.txt_even_4_3 : 0);
          this.txt_even_4_4 = parseInt(this.repsData.txt_even_4_4 ? this.repsData.txt_even_4_4 : 0);
          this.txt_even_4_5 = parseInt(this.repsData.txt_even_4_5 ? this.repsData.txt_even_4_5 : 0);
          this.txt_even_4_6 = parseInt(this.repsData.txt_even_4_6 ? this.repsData.txt_even_4_6 : 0);
          this.txt_even_4_7 = parseInt(this.repsData.txt_even_4_7 ? this.repsData.txt_even_4_7 : 0);
          this.txt_even_4_8 = parseInt(this.repsData.txt_even_4_8 ? this.repsData.txt_even_4_8 : 0);
          this.txt_even_4_9 = parseInt(this.repsData.txt_even_4_9 ? this.repsData.txt_even_4_9 : 0);
          this.txt_even_4_10 = parseInt(this.repsData.txt_even_4_10 ? this.repsData.txt_even_4_10 : 0);
          this.txt_even_4_11 = parseInt(this.repsData.txt_even_4_11 ? this.repsData.txt_even_4_11 : 0);
          this.txt_even_4_12 = parseInt(this.repsData.txt_even_4_12 ? this.repsData.txt_even_4_12 : 0);
          this.txt_even_4_13 = parseInt(this.repsData.txt_even_4_13 ? this.repsData.txt_even_4_13 : 0);
          this.txt_even_4_14 = parseInt(this.repsData.txt_even_4_14 ? this.repsData.txt_even_4_14 : 0);
          this.txt_even_4_15 = parseInt(this.repsData.txt_even_4_15 ? this.repsData.txt_even_4_15 : 0);
          this.txt_even_5_1 = parseInt(this.repsData.txt_even_5_1 ? this.repsData.txt_even_5_1 : 0);
          this.txt_even_5_2 = parseInt(this.repsData.txt_even_5_2 ? this.repsData.txt_even_5_2 : 0);
          this.txt_even_5_3 = parseInt(this.repsData.txt_even_5_3 ? this.repsData.txt_even_5_3 : 0);
          this.txt_even_5_4 = parseInt(this.repsData.txt_even_5_4 ? this.repsData.txt_even_5_4 : 0);
          this.txt_even_5_5 = parseInt(this.repsData.txt_even_5_5 ? this.repsData.txt_even_5_5 : 0);
          this.txt_even_5_6 = parseInt(this.repsData.txt_even_5_6 ? this.repsData.txt_even_5_6 : 0);
          this.txt_even_5_7 = parseInt(this.repsData.txt_even_5_7 ? this.repsData.txt_even_5_7 : 0);
          this.txt_even_5_8 = parseInt(this.repsData.txt_even_5_8 ? this.repsData.txt_even_5_8 : 0);
          this.txt_even_5_9 = parseInt(this.repsData.txt_even_5_9 ? this.repsData.txt_even_5_9 : 0);
          this.txt_even_5_10 = parseInt(this.repsData.txt_even_5_10 ? this.repsData.txt_even_5_10 : 0);
          this.txt_even_5_11 = parseInt(this.repsData.txt_even_5_11 ? this.repsData.txt_even_5_11 : 0);
          this.txt_even_5_12 = parseInt(this.repsData.txt_even_5_12 ? this.repsData.txt_even_5_12 : 0);
          this.txt_even_5_13 = parseInt(this.repsData.txt_even_5_13 ? this.repsData.txt_even_5_13 : 0);
          this.txt_even_5_14 = parseInt(this.repsData.txt_even_5_14 ? this.repsData.txt_even_5_14 : 0);
          this.txt_even_5_15 = parseInt(this.repsData.txt_even_5_15 ? this.repsData.txt_even_5_15 : 0);
          this.txt_even_6_1 = parseInt(this.repsData.txt_even_6_1 ? this.repsData.txt_even_6_1 : 0);
          this.txt_even_6_2 = parseInt(this.repsData.txt_even_6_2 ? this.repsData.txt_even_6_2 : 0);
          this.txt_even_6_3 = parseInt(this.repsData.txt_even_6_3 ? this.repsData.txt_even_6_3 : 0);
          this.txt_even_6_4 = parseInt(this.repsData.txt_even_6_4 ? this.repsData.txt_even_6_4 : 0);
          this.txt_even_6_5 = parseInt(this.repsData.txt_even_6_5 ? this.repsData.txt_even_6_5 : 0);
          this.txt_even_6_6 = parseInt(this.repsData.txt_even_6_6 ? this.repsData.txt_even_6_6 : 0);
          this.txt_even_6_7 = parseInt(this.repsData.txt_even_6_7 ? this.repsData.txt_even_6_7 : 0);
          this.txt_even_6_8 = parseInt(this.repsData.txt_even_6_8 ? this.repsData.txt_even_6_8 : 0);
          this.txt_even_6_9 = parseInt(this.repsData.txt_even_6_9 ? this.repsData.txt_even_6_9 : 0);
          this.txt_even_6_10 = parseInt(this.repsData.txt_even_6_10 ? this.repsData.txt_even_6_10 : 0);
          this.txt_even_6_11 = parseInt(this.repsData.txt_even_6_11 ? this.repsData.txt_even_6_11 : 0);
          this.txt_even_6_12 = parseInt(this.repsData.txt_even_6_12 ? this.repsData.txt_even_6_12 : 0);
          this.txt_even_6_13 = parseInt(this.repsData.txt_even_6_13 ? this.repsData.txt_even_6_13 : 0);
          this.txt_even_6_14 = parseInt(this.repsData.txt_even_6_14 ? this.repsData.txt_even_6_14 : 0);
          this.txt_even_6_15 = parseInt(this.repsData.txt_even_6_15 ? this.repsData.txt_even_6_15 : 0);
          this.txt_even_7_1 = parseInt(this.repsData.txt_even_7_1 ? this.repsData.txt_even_7_1 : 0);
          this.txt_even_7_2 = parseInt(this.repsData.txt_even_7_2 ? this.repsData.txt_even_7_2 : 0);
          this.txt_even_7_3 = parseInt(this.repsData.txt_even_7_3 ? this.repsData.txt_even_7_3 : 0);
          this.txt_even_7_4 = parseInt(this.repsData.txt_even_7_4 ? this.repsData.txt_even_7_4 : 0);
          this.txt_even_7_5 = parseInt(this.repsData.txt_even_7_5 ? this.repsData.txt_even_7_5 : 0);
          this.txt_even_7_6 = parseInt(this.repsData.txt_even_7_6 ? this.repsData.txt_even_7_6 : 0);
          this.txt_even_7_7 = parseInt(this.repsData.txt_even_7_7 ? this.repsData.txt_even_7_7 : 0);
          this.txt_even_7_8 = parseInt(this.repsData.txt_even_7_8 ? this.repsData.txt_even_7_8 : 0);
          this.txt_even_7_9 = parseInt(this.repsData.txt_even_7_9 ? this.repsData.txt_even_7_9 : 0);
          this.txt_even_7_10 = parseInt(this.repsData.txt_even_7_10 ? this.repsData.txt_even_7_10 : 0);
          this.txt_even_7_11 = parseInt(this.repsData.txt_even_7_11 ? this.repsData.txt_even_7_11 : 0);
          this.txt_even_7_12 = parseInt(this.repsData.txt_even_7_12 ? this.repsData.txt_even_7_12 : 0);
          this.txt_even_7_13 = parseInt(this.repsData.txt_even_7_13 ? this.repsData.txt_even_7_13 : 0);
          this.txt_even_7_14 = parseInt(this.repsData.txt_even_7_14 ? this.repsData.txt_even_7_14 : 0);
          this.txt_even_7_15 = parseInt(this.repsData.txt_even_7_15 ? this.repsData.txt_even_7_15 : 0);
          this.txt_even_8_1 = parseInt(this.repsData.txt_even_8_1 ? this.repsData.txt_even_8_1 : 0);
          this.txt_even_8_2 = parseInt(this.repsData.txt_even_8_2 ? this.repsData.txt_even_8_2 : 0);
          this.txt_even_8_3 = parseInt(this.repsData.txt_even_8_3 ? this.repsData.txt_even_8_3 : 0);
          this.txt_even_8_4 = parseInt(this.repsData.txt_even_8_4 ? this.repsData.txt_even_8_4 : 0);
          this.txt_even_8_5 = parseInt(this.repsData.txt_even_8_5 ? this.repsData.txt_even_8_5 : 0);
          this.txt_even_8_6 = parseInt(this.repsData.txt_even_8_6 ? this.repsData.txt_even_8_6 : 0);
          this.txt_even_8_7 = parseInt(this.repsData.txt_even_8_7 ? this.repsData.txt_even_8_7 : 0);
          this.txt_even_8_8 = parseInt(this.repsData.txt_even_8_8 ? this.repsData.txt_even_8_8 : 0);
          this.txt_even_8_9 = parseInt(this.repsData.txt_even_8_9 ? this.repsData.txt_even_8_9 : 0);
          this.txt_even_8_10 = parseInt(this.repsData.txt_even_8_10 ? this.repsData.txt_even_8_10 : 0);
          this.txt_even_8_11 = parseInt(this.repsData.txt_even_8_11 ? this.repsData.txt_even_8_11 : 0);
          this.txt_even_8_12 = parseInt(this.repsData.txt_even_8_12 ? this.repsData.txt_even_8_12 : 0);
          this.txt_even_8_13 = parseInt(this.repsData.txt_even_8_13 ? this.repsData.txt_even_8_13 : 0);
          this.txt_even_8_14 = parseInt(this.repsData.txt_even_8_14 ? this.repsData.txt_even_8_14 : 0);
          this.txt_even_8_15 = parseInt(this.repsData.txt_even_8_15 ? this.repsData.txt_even_8_15 : 0);
          this.txt_even_9_1 = parseInt(this.repsData.txt_even_9_1 ? this.repsData.txt_even_9_1 : 0);
          this.txt_even_9_2 = parseInt(this.repsData.txt_even_9_2 ? this.repsData.txt_even_9_2 : 0);
          this.txt_even_9_3 = parseInt(this.repsData.txt_even_9_3 ? this.repsData.txt_even_9_3 : 0);
          this.txt_even_9_4 = parseInt(this.repsData.txt_even_9_4 ? this.repsData.txt_even_9_4 : 0);
          this.txt_even_9_5 = parseInt(this.repsData.txt_even_9_5 ? this.repsData.txt_even_9_5 : 0);
          this.txt_even_9_6 = parseInt(this.repsData.txt_even_9_6 ? this.repsData.txt_even_9_6 : 0);
          this.txt_even_9_7 = parseInt(this.repsData.txt_even_9_7 ? this.repsData.txt_even_9_7 : 0);
          this.txt_even_9_8 = parseInt(this.repsData.txt_even_9_8 ? this.repsData.txt_even_9_8 : 0);
          this.txt_even_9_9 = parseInt(this.repsData.txt_even_9_9 ? this.repsData.txt_even_9_9 : 0);
          this.txt_even_9_10 = parseInt(this.repsData.txt_even_9_10 ? this.repsData.txt_even_9_10 : 0);
          this.txt_even_9_11 = parseInt(this.repsData.txt_even_9_11 ? this.repsData.txt_even_9_11 : 0);
          this.txt_even_9_12 = parseInt(this.repsData.txt_even_9_12 ? this.repsData.txt_even_9_12 : 0);
          this.txt_even_9_13 = parseInt(this.repsData.txt_even_9_13 ? this.repsData.txt_even_9_13 : 0);
          this.txt_even_9_14 = parseInt(this.repsData.txt_even_9_14 ? this.repsData.txt_even_9_14 : 0);
          this.txt_even_9_15 = parseInt(this.repsData.txt_even_9_15 ? this.repsData.txt_even_9_15 : 0);
          this.txt_even_10_1 = parseInt(this.repsData.txt_even_10_1 ? this.repsData.txt_even_10_1 : 0);
          this.txt_even_10_2 = parseInt(this.repsData.txt_even_10_2 ? this.repsData.txt_even_10_2 : 0);
          this.txt_even_10_3 = parseInt(this.repsData.txt_even_10_3 ? this.repsData.txt_even_10_3 : 0);
          this.txt_even_10_4 = parseInt(this.repsData.txt_even_10_4 ? this.repsData.txt_even_10_4 : 0);
          this.txt_even_10_5 = parseInt(this.repsData.txt_even_10_5 ? this.repsData.txt_even_10_5 : 0);
          this.txt_even_10_6 = parseInt(this.repsData.txt_even_10_6 ? this.repsData.txt_even_10_6 : 0);
          this.txt_even_10_7 = parseInt(this.repsData.txt_even_10_7 ? this.repsData.txt_even_10_7 : 0);
          this.txt_even_10_8 = parseInt(this.repsData.txt_even_10_8 ? this.repsData.txt_even_10_8 : 0);
          this.txt_even_10_9 = parseInt(this.repsData.txt_even_10_9 ? this.repsData.txt_even_10_9 : 0);
          this.txt_even_10_10 = parseInt(this.repsData.txt_even_10_10 ? this.repsData.txt_even_10_10 : 0);
          this.txt_even_10_11 = parseInt(this.repsData.txt_even_10_11 ? this.repsData.txt_even_10_11 : 0);
          this.txt_even_10_12 = parseInt(this.repsData.txt_even_10_12 ? this.repsData.txt_even_10_12 : 0);
          this.txt_even_10_13 = parseInt(this.repsData.txt_even_10_13 ? this.repsData.txt_even_10_13 : 0);
          this.txt_even_10_14 = parseInt(this.repsData.txt_even_10_14 ? this.repsData.txt_even_10_14 : 0);
          this.txt_even_10_15 = parseInt(this.repsData.txt_even_10_15 ? this.repsData.txt_even_10_15 : 0);
          this.txt_big_1_1 = parseInt(this.repsData.txt_big_1_1 ? this.repsData.txt_big_1_1 : 0);
          this.txt_big_1_2 = parseInt(this.repsData.txt_big_1_2 ? this.repsData.txt_big_1_2 : 0);
          this.txt_big_1_3 = parseInt(this.repsData.txt_big_1_3 ? this.repsData.txt_big_1_3 : 0);
          this.txt_big_1_4 = parseInt(this.repsData.txt_big_1_4 ? this.repsData.txt_big_1_4 : 0);
          this.txt_big_1_5 = parseInt(this.repsData.txt_big_1_5 ? this.repsData.txt_big_1_5 : 0);
          this.txt_big_1_6 = parseInt(this.repsData.txt_big_1_6 ? this.repsData.txt_big_1_6 : 0);
          this.txt_big_1_7 = parseInt(this.repsData.txt_big_1_7 ? this.repsData.txt_big_1_7 : 0);
          this.txt_big_1_8 = parseInt(this.repsData.txt_big_1_8 ? this.repsData.txt_big_1_8 : 0);
          this.txt_big_1_9 = parseInt(this.repsData.txt_big_1_9 ? this.repsData.txt_big_1_9 : 0);
          this.txt_big_1_10 = parseInt(this.repsData.txt_big_1_10 ? this.repsData.txt_big_1_10 : 0);
          this.txt_big_1_11 = parseInt(this.repsData.txt_big_1_11 ? this.repsData.txt_big_1_11 : 0);
          this.txt_big_1_12 = parseInt(this.repsData.txt_big_1_12 ? this.repsData.txt_big_1_12 : 0);
          this.txt_big_1_13 = parseInt(this.repsData.txt_big_1_13 ? this.repsData.txt_big_1_13 : 0);
          this.txt_big_1_14 = parseInt(this.repsData.txt_big_1_14 ? this.repsData.txt_big_1_14 : 0);
          this.txt_big_1_15 = parseInt(this.repsData.txt_big_1_15 ? this.repsData.txt_big_1_15 : 0);
          this.txt_big_2_1 = parseInt(this.repsData.txt_big_2_1 ? this.repsData.txt_big_2_1 : 0);
          this.txt_big_2_2 = parseInt(this.repsData.txt_big_2_2 ? this.repsData.txt_big_2_2 : 0);
          this.txt_big_2_3 = parseInt(this.repsData.txt_big_2_3 ? this.repsData.txt_big_2_3 : 0);
          this.txt_big_2_4 = parseInt(this.repsData.txt_big_2_4 ? this.repsData.txt_big_2_4 : 0);
          this.txt_big_2_5 = parseInt(this.repsData.txt_big_2_5 ? this.repsData.txt_big_2_5 : 0);
          this.txt_big_2_6 = parseInt(this.repsData.txt_big_2_6 ? this.repsData.txt_big_2_6 : 0);
          this.txt_big_2_7 = parseInt(this.repsData.txt_big_2_7 ? this.repsData.txt_big_2_7 : 0);
          this.txt_big_2_8 = parseInt(this.repsData.txt_big_2_8 ? this.repsData.txt_big_2_8 : 0);
          this.txt_big_2_9 = parseInt(this.repsData.txt_big_2_9 ? this.repsData.txt_big_2_9 : 0);
          this.txt_big_2_10 = parseInt(this.repsData.txt_big_2_10 ? this.repsData.txt_big_2_10 : 0);
          this.txt_big_2_11 = parseInt(this.repsData.txt_big_2_11 ? this.repsData.txt_big_2_11 : 0);
          this.txt_big_2_12 = parseInt(this.repsData.txt_big_2_12 ? this.repsData.txt_big_2_12 : 0);
          this.txt_big_2_13 = parseInt(this.repsData.txt_big_2_13 ? this.repsData.txt_big_2_13 : 0);
          this.txt_big_2_14 = parseInt(this.repsData.txt_big_2_14 ? this.repsData.txt_big_2_14 : 0);
          this.txt_big_2_15 = parseInt(this.repsData.txt_big_2_15 ? this.repsData.txt_big_2_15 : 0);
          this.txt_big_3_1 = parseInt(this.repsData.txt_big_3_1 ? this.repsData.txt_big_3_1 : 0);
          this.txt_big_3_2 = parseInt(this.repsData.txt_big_3_2 ? this.repsData.txt_big_3_2 : 0);
          this.txt_big_3_3 = parseInt(this.repsData.txt_big_3_3 ? this.repsData.txt_big_3_3 : 0);
          this.txt_big_3_4 = parseInt(this.repsData.txt_big_3_4 ? this.repsData.txt_big_3_4 : 0);
          this.txt_big_3_5 = parseInt(this.repsData.txt_big_3_5 ? this.repsData.txt_big_3_5 : 0);
          this.txt_big_3_6 = parseInt(this.repsData.txt_big_3_6 ? this.repsData.txt_big_3_6 : 0);
          this.txt_big_3_7 = parseInt(this.repsData.txt_big_3_7 ? this.repsData.txt_big_3_7 : 0);
          this.txt_big_3_8 = parseInt(this.repsData.txt_big_3_8 ? this.repsData.txt_big_3_8 : 0);
          this.txt_big_3_9 = parseInt(this.repsData.txt_big_3_9 ? this.repsData.txt_big_3_9 : 0);
          this.txt_big_3_10 = parseInt(this.repsData.txt_big_3_10 ? this.repsData.txt_big_3_10 : 0);
          this.txt_big_3_11 = parseInt(this.repsData.txt_big_3_11 ? this.repsData.txt_big_3_11 : 0);
          this.txt_big_3_12 = parseInt(this.repsData.txt_big_3_12 ? this.repsData.txt_big_3_12 : 0);
          this.txt_big_3_13 = parseInt(this.repsData.txt_big_3_13 ? this.repsData.txt_big_3_13 : 0);
          this.txt_big_3_14 = parseInt(this.repsData.txt_big_3_14 ? this.repsData.txt_big_3_14 : 0);
          this.txt_big_3_15 = parseInt(this.repsData.txt_big_3_15 ? this.repsData.txt_big_3_15 : 0);
          this.txt_big_4_1 = parseInt(this.repsData.txt_big_4_1 ? this.repsData.txt_big_4_1 : 0);
          this.txt_big_4_2 = parseInt(this.repsData.txt_big_4_2 ? this.repsData.txt_big_4_2 : 0);
          this.txt_big_4_3 = parseInt(this.repsData.txt_big_4_3 ? this.repsData.txt_big_4_3 : 0);
          this.txt_big_4_4 = parseInt(this.repsData.txt_big_4_4 ? this.repsData.txt_big_4_4 : 0);
          this.txt_big_4_5 = parseInt(this.repsData.txt_big_4_5 ? this.repsData.txt_big_4_5 : 0);
          this.txt_big_4_6 = parseInt(this.repsData.txt_big_4_6 ? this.repsData.txt_big_4_6 : 0);
          this.txt_big_4_7 = parseInt(this.repsData.txt_big_4_7 ? this.repsData.txt_big_4_7 : 0);
          this.txt_big_4_8 = parseInt(this.repsData.txt_big_4_8 ? this.repsData.txt_big_4_8 : 0);
          this.txt_big_4_9 = parseInt(this.repsData.txt_big_4_9 ? this.repsData.txt_big_4_9 : 0);
          this.txt_big_4_10 = parseInt(this.repsData.txt_big_4_10 ? this.repsData.txt_big_4_10 : 0);
          this.txt_big_4_11 = parseInt(this.repsData.txt_big_4_11 ? this.repsData.txt_big_4_11 : 0);
          this.txt_big_4_12 = parseInt(this.repsData.txt_big_4_12 ? this.repsData.txt_big_4_12 : 0);
          this.txt_big_4_13 = parseInt(this.repsData.txt_big_4_13 ? this.repsData.txt_big_4_13 : 0);
          this.txt_big_4_14 = parseInt(this.repsData.txt_big_4_14 ? this.repsData.txt_big_4_14 : 0);
          this.txt_big_4_15 = parseInt(this.repsData.txt_big_4_15 ? this.repsData.txt_big_4_15 : 0);
          this.txt_big_5_1 = parseInt(this.repsData.txt_big_5_1 ? this.repsData.txt_big_5_1 : 0);
          this.txt_big_5_2 = parseInt(this.repsData.txt_big_5_2 ? this.repsData.txt_big_5_2 : 0);
          this.txt_big_5_3 = parseInt(this.repsData.txt_big_5_3 ? this.repsData.txt_big_5_3 : 0);
          this.txt_big_5_4 = parseInt(this.repsData.txt_big_5_4 ? this.repsData.txt_big_5_4 : 0);
          this.txt_big_5_5 = parseInt(this.repsData.txt_big_5_5 ? this.repsData.txt_big_5_5 : 0);
          this.txt_big_5_6 = parseInt(this.repsData.txt_big_5_6 ? this.repsData.txt_big_5_6 : 0);
          this.txt_big_5_7 = parseInt(this.repsData.txt_big_5_7 ? this.repsData.txt_big_5_7 : 0);
          this.txt_big_5_8 = parseInt(this.repsData.txt_big_5_8 ? this.repsData.txt_big_5_8 : 0);
          this.txt_big_5_9 = parseInt(this.repsData.txt_big_5_9 ? this.repsData.txt_big_5_9 : 0);
          this.txt_big_5_10 = parseInt(this.repsData.txt_big_5_10 ? this.repsData.txt_big_5_10 : 0);
          this.txt_big_5_11 = parseInt(this.repsData.txt_big_5_11 ? this.repsData.txt_big_5_11 : 0);
          this.txt_big_5_12 = parseInt(this.repsData.txt_big_5_12 ? this.repsData.txt_big_5_12 : 0);
          this.txt_big_5_13 = parseInt(this.repsData.txt_big_5_13 ? this.repsData.txt_big_5_13 : 0);
          this.txt_big_5_14 = parseInt(this.repsData.txt_big_5_14 ? this.repsData.txt_big_5_14 : 0);
          this.txt_big_5_15 = parseInt(this.repsData.txt_big_5_15 ? this.repsData.txt_big_5_15 : 0);
          this.txt_big_6_1 = parseInt(this.repsData.txt_big_6_1 ? this.repsData.txt_big_6_1 : 0);
          this.txt_big_6_2 = parseInt(this.repsData.txt_big_6_2 ? this.repsData.txt_big_6_2 : 0);
          this.txt_big_6_3 = parseInt(this.repsData.txt_big_6_3 ? this.repsData.txt_big_6_3 : 0);
          this.txt_big_6_4 = parseInt(this.repsData.txt_big_6_4 ? this.repsData.txt_big_6_4 : 0);
          this.txt_big_6_5 = parseInt(this.repsData.txt_big_6_5 ? this.repsData.txt_big_6_5 : 0);
          this.txt_big_6_6 = parseInt(this.repsData.txt_big_6_6 ? this.repsData.txt_big_6_6 : 0);
          this.txt_big_6_7 = parseInt(this.repsData.txt_big_6_7 ? this.repsData.txt_big_6_7 : 0);
          this.txt_big_6_8 = parseInt(this.repsData.txt_big_6_8 ? this.repsData.txt_big_6_8 : 0);
          this.txt_big_6_9 = parseInt(this.repsData.txt_big_6_9 ? this.repsData.txt_big_6_9 : 0);
          this.txt_big_6_10 = parseInt(this.repsData.txt_big_6_10 ? this.repsData.txt_big_6_10 : 0);
          this.txt_big_6_11 = parseInt(this.repsData.txt_big_6_11 ? this.repsData.txt_big_6_11 : 0);
          this.txt_big_6_12 = parseInt(this.repsData.txt_big_6_12 ? this.repsData.txt_big_6_12 : 0);
          this.txt_big_6_13 = parseInt(this.repsData.txt_big_6_13 ? this.repsData.txt_big_6_13 : 0);
          this.txt_big_6_14 = parseInt(this.repsData.txt_big_6_14 ? this.repsData.txt_big_6_14 : 0);
          this.txt_big_6_15 = parseInt(this.repsData.txt_big_6_15 ? this.repsData.txt_big_6_15 : 0);
          this.txt_big_7_1 = parseInt(this.repsData.txt_big_7_1 ? this.repsData.txt_big_7_1 : 0);
          this.txt_big_7_2 = parseInt(this.repsData.txt_big_7_2 ? this.repsData.txt_big_7_2 : 0);
          this.txt_big_7_3 = parseInt(this.repsData.txt_big_7_3 ? this.repsData.txt_big_7_3 : 0);
          this.txt_big_7_4 = parseInt(this.repsData.txt_big_7_4 ? this.repsData.txt_big_7_4 : 0);
          this.txt_big_7_5 = parseInt(this.repsData.txt_big_7_5 ? this.repsData.txt_big_7_5 : 0);
          this.txt_big_7_6 = parseInt(this.repsData.txt_big_7_6 ? this.repsData.txt_big_7_6 : 0);
          this.txt_big_7_7 = parseInt(this.repsData.txt_big_7_7 ? this.repsData.txt_big_7_7 : 0);
          this.txt_big_7_8 = parseInt(this.repsData.txt_big_7_8 ? this.repsData.txt_big_7_8 : 0);
          this.txt_big_7_9 = parseInt(this.repsData.txt_big_7_9 ? this.repsData.txt_big_7_9 : 0);
          this.txt_big_7_10 = parseInt(this.repsData.txt_big_7_10 ? this.repsData.txt_big_7_10 : 0);
          this.txt_big_7_11 = parseInt(this.repsData.txt_big_7_11 ? this.repsData.txt_big_7_11 : 0);
          this.txt_big_7_12 = parseInt(this.repsData.txt_big_7_12 ? this.repsData.txt_big_7_12 : 0);
          this.txt_big_7_13 = parseInt(this.repsData.txt_big_7_13 ? this.repsData.txt_big_7_13 : 0);
          this.txt_big_7_14 = parseInt(this.repsData.txt_big_7_14 ? this.repsData.txt_big_7_14 : 0);
          this.txt_big_7_15 = parseInt(this.repsData.txt_big_7_15 ? this.repsData.txt_big_7_15 : 0);
          this.txt_big_8_1 = parseInt(this.repsData.txt_big_8_1 ? this.repsData.txt_big_8_1 : 0);
          this.txt_big_8_2 = parseInt(this.repsData.txt_big_8_2 ? this.repsData.txt_big_8_2 : 0);
          this.txt_big_8_3 = parseInt(this.repsData.txt_big_8_3 ? this.repsData.txt_big_8_3 : 0);
          this.txt_big_8_4 = parseInt(this.repsData.txt_big_8_4 ? this.repsData.txt_big_8_4 : 0);
          this.txt_big_8_5 = parseInt(this.repsData.txt_big_8_5 ? this.repsData.txt_big_8_5 : 0);
          this.txt_big_8_6 = parseInt(this.repsData.txt_big_8_6 ? this.repsData.txt_big_8_6 : 0);
          this.txt_big_8_7 = parseInt(this.repsData.txt_big_8_7 ? this.repsData.txt_big_8_7 : 0);
          this.txt_big_8_8 = parseInt(this.repsData.txt_big_8_8 ? this.repsData.txt_big_8_8 : 0);
          this.txt_big_8_9 = parseInt(this.repsData.txt_big_8_9 ? this.repsData.txt_big_8_9 : 0);
          this.txt_big_8_10 = parseInt(this.repsData.txt_big_8_10 ? this.repsData.txt_big_8_10 : 0);
          this.txt_big_8_11 = parseInt(this.repsData.txt_big_8_11 ? this.repsData.txt_big_8_11 : 0);
          this.txt_big_8_12 = parseInt(this.repsData.txt_big_8_12 ? this.repsData.txt_big_8_12 : 0);
          this.txt_big_8_13 = parseInt(this.repsData.txt_big_8_13 ? this.repsData.txt_big_8_13 : 0);
          this.txt_big_8_14 = parseInt(this.repsData.txt_big_8_14 ? this.repsData.txt_big_8_14 : 0);
          this.txt_big_8_15 = parseInt(this.repsData.txt_big_8_15 ? this.repsData.txt_big_8_15 : 0);
          this.txt_big_9_1 = parseInt(this.repsData.txt_big_9_1 ? this.repsData.txt_big_9_1 : 0);
          this.txt_big_9_2 = parseInt(this.repsData.txt_big_9_2 ? this.repsData.txt_big_9_2 : 0);
          this.txt_big_9_3 = parseInt(this.repsData.txt_big_9_3 ? this.repsData.txt_big_9_3 : 0);
          this.txt_big_9_4 = parseInt(this.repsData.txt_big_9_4 ? this.repsData.txt_big_9_4 : 0);
          this.txt_big_9_5 = parseInt(this.repsData.txt_big_9_5 ? this.repsData.txt_big_9_5 : 0);
          this.txt_big_9_6 = parseInt(this.repsData.txt_big_9_6 ? this.repsData.txt_big_9_6 : 0);
          this.txt_big_9_7 = parseInt(this.repsData.txt_big_9_7 ? this.repsData.txt_big_9_7 : 0);
          this.txt_big_9_8 = parseInt(this.repsData.txt_big_9_8 ? this.repsData.txt_big_9_8 : 0);
          this.txt_big_9_9 = parseInt(this.repsData.txt_big_9_9 ? this.repsData.txt_big_9_9 : 0);
          this.txt_big_9_10 = parseInt(this.repsData.txt_big_9_10 ? this.repsData.txt_big_9_10 : 0);
          this.txt_big_9_11 = parseInt(this.repsData.txt_big_9_11 ? this.repsData.txt_big_9_11 : 0);
          this.txt_big_9_12 = parseInt(this.repsData.txt_big_9_12 ? this.repsData.txt_big_9_12 : 0);
          this.txt_big_9_13 = parseInt(this.repsData.txt_big_9_13 ? this.repsData.txt_big_9_13 : 0);
          this.txt_big_9_14 = parseInt(this.repsData.txt_big_9_14 ? this.repsData.txt_big_9_14 : 0);
          this.txt_big_9_15 = parseInt(this.repsData.txt_big_9_15 ? this.repsData.txt_big_9_15 : 0);
          this.txt_big_10_1 = parseInt(this.repsData.txt_big_10_1 ? this.repsData.txt_big_10_1 : 0);
          this.txt_big_10_2 = parseInt(this.repsData.txt_big_10_2 ? this.repsData.txt_big_10_2 : 0);
          this.txt_big_10_3 = parseInt(this.repsData.txt_big_10_3 ? this.repsData.txt_big_10_3 : 0);
          this.txt_big_10_4 = parseInt(this.repsData.txt_big_10_4 ? this.repsData.txt_big_10_4 : 0);
          this.txt_big_10_5 = parseInt(this.repsData.txt_big_10_5 ? this.repsData.txt_big_10_5 : 0);
          this.txt_big_10_6 = parseInt(this.repsData.txt_big_10_6 ? this.repsData.txt_big_10_6 : 0);
          this.txt_big_10_7 = parseInt(this.repsData.txt_big_10_7 ? this.repsData.txt_big_10_7 : 0);
          this.txt_big_10_8 = parseInt(this.repsData.txt_big_10_8 ? this.repsData.txt_big_10_8 : 0);
          this.txt_big_10_9 = parseInt(this.repsData.txt_big_10_9 ? this.repsData.txt_big_10_9 : 0);
          this.txt_big_10_10 = parseInt(this.repsData.txt_big_10_10 ? this.repsData.txt_big_10_10 : 0);
          this.txt_big_10_11 = parseInt(this.repsData.txt_big_10_11 ? this.repsData.txt_big_10_11 : 0);
          this.txt_big_10_12 = parseInt(this.repsData.txt_big_10_12 ? this.repsData.txt_big_10_12 : 0);
          this.txt_big_10_13 = parseInt(this.repsData.txt_big_10_13 ? this.repsData.txt_big_10_13 : 0);
          this.txt_big_10_14 = parseInt(this.repsData.txt_big_10_14 ? this.repsData.txt_big_10_14 : 0);
          this.txt_big_10_15 = parseInt(this.repsData.txt_big_10_15 ? this.repsData.txt_big_10_15 : 0);
          this.txt_small_1_1 = parseInt(this.repsData.txt_small_1_1 ? this.repsData.txt_small_1_1 : 0);
          this.txt_small_1_2 = parseInt(this.repsData.txt_small_1_2 ? this.repsData.txt_small_1_2 : 0);
          this.txt_small_1_3 = parseInt(this.repsData.txt_small_1_3 ? this.repsData.txt_small_1_3 : 0);
          this.txt_small_1_4 = parseInt(this.repsData.txt_small_1_4 ? this.repsData.txt_small_1_4 : 0);
          this.txt_small_1_5 = parseInt(this.repsData.txt_small_1_5 ? this.repsData.txt_small_1_5 : 0);
          this.txt_small_1_6 = parseInt(this.repsData.txt_small_1_6 ? this.repsData.txt_small_1_6 : 0);
          this.txt_small_1_7 = parseInt(this.repsData.txt_small_1_7 ? this.repsData.txt_small_1_7 : 0);
          this.txt_small_1_8 = parseInt(this.repsData.txt_small_1_8 ? this.repsData.txt_small_1_8 : 0);
          this.txt_small_1_9 = parseInt(this.repsData.txt_small_1_9 ? this.repsData.txt_small_1_9 : 0);
          this.txt_small_1_10 = parseInt(this.repsData.txt_small_1_10 ? this.repsData.txt_small_1_10 : 0);
          this.txt_small_1_11 = parseInt(this.repsData.txt_small_1_11 ? this.repsData.txt_small_1_11 : 0);
          this.txt_small_1_12 = parseInt(this.repsData.txt_small_1_12 ? this.repsData.txt_small_1_12 : 0);
          this.txt_small_1_13 = parseInt(this.repsData.txt_small_1_13 ? this.repsData.txt_small_1_13 : 0);
          this.txt_small_1_14 = parseInt(this.repsData.txt_small_1_14 ? this.repsData.txt_small_1_14 : 0);
          this.txt_small_1_15 = parseInt(this.repsData.txt_small_1_15 ? this.repsData.txt_small_1_15 : 0);
          this.txt_small_2_1 = parseInt(this.repsData.txt_small_2_1 ? this.repsData.txt_small_2_1 : 0);
          this.txt_small_2_2 = parseInt(this.repsData.txt_small_2_2 ? this.repsData.txt_small_2_2 : 0);
          this.txt_small_2_3 = parseInt(this.repsData.txt_small_2_3 ? this.repsData.txt_small_2_3 : 0);
          this.txt_small_2_4 = parseInt(this.repsData.txt_small_2_4 ? this.repsData.txt_small_2_4 : 0);
          this.txt_small_2_5 = parseInt(this.repsData.txt_small_2_5 ? this.repsData.txt_small_2_5 : 0);
          this.txt_small_2_6 = parseInt(this.repsData.txt_small_2_6 ? this.repsData.txt_small_2_6 : 0);
          this.txt_small_2_7 = parseInt(this.repsData.txt_small_2_7 ? this.repsData.txt_small_2_7 : 0);
          this.txt_small_2_8 = parseInt(this.repsData.txt_small_2_8 ? this.repsData.txt_small_2_8 : 0);
          this.txt_small_2_9 = parseInt(this.repsData.txt_small_2_9 ? this.repsData.txt_small_2_9 : 0);
          this.txt_small_2_10 = parseInt(this.repsData.txt_small_2_10 ? this.repsData.txt_small_2_10 : 0);
          this.txt_small_2_11 = parseInt(this.repsData.txt_small_2_11 ? this.repsData.txt_small_2_11 : 0);
          this.txt_small_2_12 = parseInt(this.repsData.txt_small_2_12 ? this.repsData.txt_small_2_12 : 0);
          this.txt_small_2_13 = parseInt(this.repsData.txt_small_2_13 ? this.repsData.txt_small_2_13 : 0);
          this.txt_small_2_14 = parseInt(this.repsData.txt_small_2_14 ? this.repsData.txt_small_2_14 : 0);
          this.txt_small_2_15 = parseInt(this.repsData.txt_small_2_15 ? this.repsData.txt_small_2_15 : 0);
          this.txt_small_3_1 = parseInt(this.repsData.txt_small_3_1 ? this.repsData.txt_small_3_1 : 0);
          this.txt_small_3_2 = parseInt(this.repsData.txt_small_3_2 ? this.repsData.txt_small_3_2 : 0);
          this.txt_small_3_3 = parseInt(this.repsData.txt_small_3_3 ? this.repsData.txt_small_3_3 : 0);
          this.txt_small_3_4 = parseInt(this.repsData.txt_small_3_4 ? this.repsData.txt_small_3_4 : 0);
          this.txt_small_3_5 = parseInt(this.repsData.txt_small_3_5 ? this.repsData.txt_small_3_5 : 0);
          this.txt_small_3_6 = parseInt(this.repsData.txt_small_3_6 ? this.repsData.txt_small_3_6 : 0);
          this.txt_small_3_7 = parseInt(this.repsData.txt_small_3_7 ? this.repsData.txt_small_3_7 : 0);
          this.txt_small_3_8 = parseInt(this.repsData.txt_small_3_8 ? this.repsData.txt_small_3_8 : 0);
          this.txt_small_3_9 = parseInt(this.repsData.txt_small_3_9 ? this.repsData.txt_small_3_9 : 0);
          this.txt_small_3_10 = parseInt(this.repsData.txt_small_3_10 ? this.repsData.txt_small_3_10 : 0);
          this.txt_small_3_11 = parseInt(this.repsData.txt_small_3_11 ? this.repsData.txt_small_3_11 : 0);
          this.txt_small_3_12 = parseInt(this.repsData.txt_small_3_12 ? this.repsData.txt_small_3_12 : 0);
          this.txt_small_3_13 = parseInt(this.repsData.txt_small_3_13 ? this.repsData.txt_small_3_13 : 0);
          this.txt_small_3_14 = parseInt(this.repsData.txt_small_3_14 ? this.repsData.txt_small_3_14 : 0);
          this.txt_small_3_15 = parseInt(this.repsData.txt_small_3_15 ? this.repsData.txt_small_3_15 : 0);
          this.txt_small_4_1 = parseInt(this.repsData.txt_small_4_1 ? this.repsData.txt_small_4_1 : 0);
          this.txt_small_4_2 = parseInt(this.repsData.txt_small_4_2 ? this.repsData.txt_small_4_2 : 0);
          this.txt_small_4_3 = parseInt(this.repsData.txt_small_4_3 ? this.repsData.txt_small_4_3 : 0);
          this.txt_small_4_4 = parseInt(this.repsData.txt_small_4_4 ? this.repsData.txt_small_4_4 : 0);
          this.txt_small_4_5 = parseInt(this.repsData.txt_small_4_5 ? this.repsData.txt_small_4_5 : 0);
          this.txt_small_4_6 = parseInt(this.repsData.txt_small_4_6 ? this.repsData.txt_small_4_6 : 0);
          this.txt_small_4_7 = parseInt(this.repsData.txt_small_4_7 ? this.repsData.txt_small_4_7 : 0);
          this.txt_small_4_8 = parseInt(this.repsData.txt_small_4_8 ? this.repsData.txt_small_4_8 : 0);
          this.txt_small_4_9 = parseInt(this.repsData.txt_small_4_9 ? this.repsData.txt_small_4_9 : 0);
          this.txt_small_4_10 = parseInt(this.repsData.txt_small_4_10 ? this.repsData.txt_small_4_10 : 0);
          this.txt_small_4_11 = parseInt(this.repsData.txt_small_4_11 ? this.repsData.txt_small_4_11 : 0);
          this.txt_small_4_12 = parseInt(this.repsData.txt_small_4_12 ? this.repsData.txt_small_4_12 : 0);
          this.txt_small_4_13 = parseInt(this.repsData.txt_small_4_13 ? this.repsData.txt_small_4_13 : 0);
          this.txt_small_4_14 = parseInt(this.repsData.txt_small_4_14 ? this.repsData.txt_small_4_14 : 0);
          this.txt_small_4_15 = parseInt(this.repsData.txt_small_4_15 ? this.repsData.txt_small_4_15 : 0);
          this.txt_small_5_1 = parseInt(this.repsData.txt_small_5_1 ? this.repsData.txt_small_5_1 : 0);
          this.txt_small_5_2 = parseInt(this.repsData.txt_small_5_2 ? this.repsData.txt_small_5_2 : 0);
          this.txt_small_5_3 = parseInt(this.repsData.txt_small_5_3 ? this.repsData.txt_small_5_3 : 0);
          this.txt_small_5_4 = parseInt(this.repsData.txt_small_5_4 ? this.repsData.txt_small_5_4 : 0);
          this.txt_small_5_5 = parseInt(this.repsData.txt_small_5_5 ? this.repsData.txt_small_5_5 : 0);
          this.txt_small_5_6 = parseInt(this.repsData.txt_small_5_6 ? this.repsData.txt_small_5_6 : 0);
          this.txt_small_5_7 = parseInt(this.repsData.txt_small_5_7 ? this.repsData.txt_small_5_7 : 0);
          this.txt_small_5_8 = parseInt(this.repsData.txt_small_5_8 ? this.repsData.txt_small_5_8 : 0);
          this.txt_small_5_9 = parseInt(this.repsData.txt_small_5_9 ? this.repsData.txt_small_5_9 : 0);
          this.txt_small_5_10 = parseInt(this.repsData.txt_small_5_10 ? this.repsData.txt_small_5_10 : 0);
          this.txt_small_5_11 = parseInt(this.repsData.txt_small_5_11 ? this.repsData.txt_small_5_11 : 0);
          this.txt_small_5_12 = parseInt(this.repsData.txt_small_5_12 ? this.repsData.txt_small_5_12 : 0);
          this.txt_small_5_13 = parseInt(this.repsData.txt_small_5_13 ? this.repsData.txt_small_5_13 : 0);
          this.txt_small_5_14 = parseInt(this.repsData.txt_small_5_14 ? this.repsData.txt_small_5_14 : 0);
          this.txt_small_5_15 = parseInt(this.repsData.txt_small_5_15 ? this.repsData.txt_small_5_15 : 0);
          this.txt_small_6_1 = parseInt(this.repsData.txt_small_6_1 ? this.repsData.txt_small_6_1 : 0);
          this.txt_small_6_2 = parseInt(this.repsData.txt_small_6_2 ? this.repsData.txt_small_6_2 : 0);
          this.txt_small_6_3 = parseInt(this.repsData.txt_small_6_3 ? this.repsData.txt_small_6_3 : 0);
          this.txt_small_6_4 = parseInt(this.repsData.txt_small_6_4 ? this.repsData.txt_small_6_4 : 0);
          this.txt_small_6_5 = parseInt(this.repsData.txt_small_6_5 ? this.repsData.txt_small_6_5 : 0);
          this.txt_small_6_6 = parseInt(this.repsData.txt_small_6_6 ? this.repsData.txt_small_6_6 : 0);
          this.txt_small_6_7 = parseInt(this.repsData.txt_small_6_7 ? this.repsData.txt_small_6_7 : 0);
          this.txt_small_6_8 = parseInt(this.repsData.txt_small_6_8 ? this.repsData.txt_small_6_8 : 0);
          this.txt_small_6_9 = parseInt(this.repsData.txt_small_6_9 ? this.repsData.txt_small_6_9 : 0);
          this.txt_small_6_10 = parseInt(this.repsData.txt_small_6_10 ? this.repsData.txt_small_6_10 : 0);
          this.txt_small_6_11 = parseInt(this.repsData.txt_small_6_11 ? this.repsData.txt_small_6_11 : 0);
          this.txt_small_6_12 = parseInt(this.repsData.txt_small_6_12 ? this.repsData.txt_small_6_12 : 0);
          this.txt_small_6_13 = parseInt(this.repsData.txt_small_6_13 ? this.repsData.txt_small_6_13 : 0);
          this.txt_small_6_14 = parseInt(this.repsData.txt_small_6_14 ? this.repsData.txt_small_6_14 : 0);
          this.txt_small_6_15 = parseInt(this.repsData.txt_small_6_15 ? this.repsData.txt_small_6_15 : 0);
          this.txt_small_7_1 = parseInt(this.repsData.txt_small_7_1 ? this.repsData.txt_small_7_1 : 0);
          this.txt_small_7_2 = parseInt(this.repsData.txt_small_7_2 ? this.repsData.txt_small_7_2 : 0);
          this.txt_small_7_3 = parseInt(this.repsData.txt_small_7_3 ? this.repsData.txt_small_7_3 : 0);
          this.txt_small_7_4 = parseInt(this.repsData.txt_small_7_4 ? this.repsData.txt_small_7_4 : 0);
          this.txt_small_7_5 = parseInt(this.repsData.txt_small_7_5 ? this.repsData.txt_small_7_5 : 0);
          this.txt_small_7_6 = parseInt(this.repsData.txt_small_7_6 ? this.repsData.txt_small_7_6 : 0);
          this.txt_small_7_7 = parseInt(this.repsData.txt_small_7_7 ? this.repsData.txt_small_7_7 : 0);
          this.txt_small_7_8 = parseInt(this.repsData.txt_small_7_8 ? this.repsData.txt_small_7_8 : 0);
          this.txt_small_7_9 = parseInt(this.repsData.txt_small_7_9 ? this.repsData.txt_small_7_9 : 0);
          this.txt_small_7_10 = parseInt(this.repsData.txt_small_7_10 ? this.repsData.txt_small_7_10 : 0);
          this.txt_small_7_11 = parseInt(this.repsData.txt_small_7_11 ? this.repsData.txt_small_7_11 : 0);
          this.txt_small_7_12 = parseInt(this.repsData.txt_small_7_12 ? this.repsData.txt_small_7_12 : 0);
          this.txt_small_7_13 = parseInt(this.repsData.txt_small_7_13 ? this.repsData.txt_small_7_13 : 0);
          this.txt_small_7_14 = parseInt(this.repsData.txt_small_7_14 ? this.repsData.txt_small_7_14 : 0);
          this.txt_small_7_15 = parseInt(this.repsData.txt_small_7_15 ? this.repsData.txt_small_7_15 : 0);
          this.txt_small_8_1 = parseInt(this.repsData.txt_small_8_1 ? this.repsData.txt_small_8_1 : 0);
          this.txt_small_8_2 = parseInt(this.repsData.txt_small_8_2 ? this.repsData.txt_small_8_2 : 0);
          this.txt_small_8_3 = parseInt(this.repsData.txt_small_8_3 ? this.repsData.txt_small_8_3 : 0);
          this.txt_small_8_4 = parseInt(this.repsData.txt_small_8_4 ? this.repsData.txt_small_8_4 : 0);
          this.txt_small_8_5 = parseInt(this.repsData.txt_small_8_5 ? this.repsData.txt_small_8_5 : 0);
          this.txt_small_8_6 = parseInt(this.repsData.txt_small_8_6 ? this.repsData.txt_small_8_6 : 0);
          this.txt_small_8_7 = parseInt(this.repsData.txt_small_8_7 ? this.repsData.txt_small_8_7 : 0);
          this.txt_small_8_8 = parseInt(this.repsData.txt_small_8_8 ? this.repsData.txt_small_8_8 : 0);
          this.txt_small_8_9 = parseInt(this.repsData.txt_small_8_9 ? this.repsData.txt_small_8_9 : 0);
          this.txt_small_8_10 = parseInt(this.repsData.txt_small_8_10 ? this.repsData.txt_small_8_10 : 0);
          this.txt_small_8_11 = parseInt(this.repsData.txt_small_8_11 ? this.repsData.txt_small_8_11 : 0);
          this.txt_small_8_12 = parseInt(this.repsData.txt_small_8_12 ? this.repsData.txt_small_8_12 : 0);
          this.txt_small_8_13 = parseInt(this.repsData.txt_small_8_13 ? this.repsData.txt_small_8_13 : 0);
          this.txt_small_8_14 = parseInt(this.repsData.txt_small_8_14 ? this.repsData.txt_small_8_14 : 0);
          this.txt_small_8_15 = parseInt(this.repsData.txt_small_8_15 ? this.repsData.txt_small_8_15 : 0);
          this.txt_small_9_1 = parseInt(this.repsData.txt_small_9_1 ? this.repsData.txt_small_9_1 : 0);
          this.txt_small_9_2 = parseInt(this.repsData.txt_small_9_2 ? this.repsData.txt_small_9_2 : 0);
          this.txt_small_9_3 = parseInt(this.repsData.txt_small_9_3 ? this.repsData.txt_small_9_3 : 0);
          this.txt_small_9_4 = parseInt(this.repsData.txt_small_9_4 ? this.repsData.txt_small_9_4 : 0);
          this.txt_small_9_5 = parseInt(this.repsData.txt_small_9_5 ? this.repsData.txt_small_9_5 : 0);
          this.txt_small_9_6 = parseInt(this.repsData.txt_small_9_6 ? this.repsData.txt_small_9_6 : 0);
          this.txt_small_9_7 = parseInt(this.repsData.txt_small_9_7 ? this.repsData.txt_small_9_7 : 0);
          this.txt_small_9_8 = parseInt(this.repsData.txt_small_9_8 ? this.repsData.txt_small_9_8 : 0);
          this.txt_small_9_9 = parseInt(this.repsData.txt_small_9_9 ? this.repsData.txt_small_9_9 : 0);
          this.txt_small_9_10 = parseInt(this.repsData.txt_small_9_10 ? this.repsData.txt_small_9_10 : 0);
          this.txt_small_9_11 = parseInt(this.repsData.txt_small_9_11 ? this.repsData.txt_small_9_11 : 0);
          this.txt_small_9_12 = parseInt(this.repsData.txt_small_9_12 ? this.repsData.txt_small_9_12 : 0);
          this.txt_small_9_13 = parseInt(this.repsData.txt_small_9_13 ? this.repsData.txt_small_9_13 : 0);
          this.txt_small_9_14 = parseInt(this.repsData.txt_small_9_14 ? this.repsData.txt_small_9_14 : 0);
          this.txt_small_9_15 = parseInt(this.repsData.txt_small_9_15 ? this.repsData.txt_small_9_15 : 0);
          this.txt_small_10_1 = parseInt(this.repsData.txt_small_10_1 ? this.repsData.txt_small_10_1 : 0);
          this.txt_small_10_2 = parseInt(this.repsData.txt_small_10_2 ? this.repsData.txt_small_10_2 : 0);
          this.txt_small_10_3 = parseInt(this.repsData.txt_small_10_3 ? this.repsData.txt_small_10_3 : 0);
          this.txt_small_10_4 = parseInt(this.repsData.txt_small_10_4 ? this.repsData.txt_small_10_4 : 0);
          this.txt_small_10_5 = parseInt(this.repsData.txt_small_10_5 ? this.repsData.txt_small_10_5 : 0);
          this.txt_small_10_6 = parseInt(this.repsData.txt_small_10_6 ? this.repsData.txt_small_10_6 : 0);
          this.txt_small_10_7 = parseInt(this.repsData.txt_small_10_7 ? this.repsData.txt_small_10_7 : 0);
          this.txt_small_10_8 = parseInt(this.repsData.txt_small_10_8 ? this.repsData.txt_small_10_8 : 0);
          this.txt_small_10_9 = parseInt(this.repsData.txt_small_10_9 ? this.repsData.txt_small_10_9 : 0);
          this.txt_small_10_10 = parseInt(this.repsData.txt_small_10_10 ? this.repsData.txt_small_10_10 : 0);
          this.txt_small_10_11 = parseInt(this.repsData.txt_small_10_11 ? this.repsData.txt_small_10_11 : 0);
          this.txt_small_10_12 = parseInt(this.repsData.txt_small_10_12 ? this.repsData.txt_small_10_12 : 0);
          this.txt_small_10_13 = parseInt(this.repsData.txt_small_10_13 ? this.repsData.txt_small_10_13 : 0);
          this.txt_small_10_14 = parseInt(this.repsData.txt_small_10_14 ? this.repsData.txt_small_10_14 : 0);
          this.txt_small_10_15 = parseInt(this.repsData.txt_small_10_15 ? this.repsData.txt_small_10_15 : 0);
          this.txt_random_1_1 = parseInt(this.repsData.txt_random_1_1 ? this.repsData.txt_random_1_1 : 0);
          this.txt_random_1_2 = parseInt(this.repsData.txt_random_1_2 ? this.repsData.txt_random_1_2 : 0);
          this.txt_random_1_3 = parseInt(this.repsData.txt_random_1_3 ? this.repsData.txt_random_1_3 : 0);
          this.txt_random_1_4 = parseInt(this.repsData.txt_random_1_4 ? this.repsData.txt_random_1_4 : 0);
          this.txt_random_1_5 = parseInt(this.repsData.txt_random_1_5 ? this.repsData.txt_random_1_5 : 0);
          this.txt_random_1_6 = parseInt(this.repsData.txt_random_1_6 ? this.repsData.txt_random_1_6 : 0);
          this.txt_random_1_7 = parseInt(this.repsData.txt_random_1_7 ? this.repsData.txt_random_1_7 : 0);
          this.txt_random_1_8 = parseInt(this.repsData.txt_random_1_8 ? this.repsData.txt_random_1_8 : 0);
          this.txt_random_1_9 = parseInt(this.repsData.txt_random_1_9 ? this.repsData.txt_random_1_9 : 0);
          this.txt_random_1_10 = parseInt(this.repsData.txt_random_1_10 ? this.repsData.txt_random_1_10 : 0);
          this.txt_random_1_11 = parseInt(this.repsData.txt_random_1_11 ? this.repsData.txt_random_1_11 : 0);
          this.txt_random_1_12 = parseInt(this.repsData.txt_random_1_12 ? this.repsData.txt_random_1_12 : 0);
          this.txt_random_1_13 = parseInt(this.repsData.txt_random_1_13 ? this.repsData.txt_random_1_13 : 0);
          this.txt_random_1_14 = parseInt(this.repsData.txt_random_1_14 ? this.repsData.txt_random_1_14 : 0);
          this.txt_random_1_15 = parseInt(this.repsData.txt_random_1_15 ? this.repsData.txt_random_1_15 : 0);
          this.txt_random_2_1 = parseInt(this.repsData.txt_random_2_1 ? this.repsData.txt_random_2_1 : 0);
          this.txt_random_2_2 = parseInt(this.repsData.txt_random_2_2 ? this.repsData.txt_random_2_2 : 0);
          this.txt_random_2_3 = parseInt(this.repsData.txt_random_2_3 ? this.repsData.txt_random_2_3 : 0);
          this.txt_random_2_4 = parseInt(this.repsData.txt_random_2_4 ? this.repsData.txt_random_2_4 : 0);
          this.txt_random_2_5 = parseInt(this.repsData.txt_random_2_5 ? this.repsData.txt_random_2_5 : 0);
          this.txt_random_2_6 = parseInt(this.repsData.txt_random_2_6 ? this.repsData.txt_random_2_6 : 0);
          this.txt_random_2_7 = parseInt(this.repsData.txt_random_2_7 ? this.repsData.txt_random_2_7 : 0);
          this.txt_random_2_8 = parseInt(this.repsData.txt_random_2_8 ? this.repsData.txt_random_2_8 : 0);
          this.txt_random_2_9 = parseInt(this.repsData.txt_random_2_9 ? this.repsData.txt_random_2_9 : 0);
          this.txt_random_2_10 = parseInt(this.repsData.txt_random_2_10 ? this.repsData.txt_random_2_10 : 0);
          this.txt_random_2_11 = parseInt(this.repsData.txt_random_2_11 ? this.repsData.txt_random_2_11 : 0);
          this.txt_random_2_12 = parseInt(this.repsData.txt_random_2_12 ? this.repsData.txt_random_2_12 : 0);
          this.txt_random_2_13 = parseInt(this.repsData.txt_random_2_13 ? this.repsData.txt_random_2_13 : 0);
          this.txt_random_2_14 = parseInt(this.repsData.txt_random_2_14 ? this.repsData.txt_random_2_14 : 0);
          this.txt_random_2_15 = parseInt(this.repsData.txt_random_2_15 ? this.repsData.txt_random_2_15 : 0);
          this.txt_random_3_1 = parseInt(this.repsData.txt_random_3_1 ? this.repsData.txt_random_3_1 : 0);
          this.txt_random_3_2 = parseInt(this.repsData.txt_random_3_2 ? this.repsData.txt_random_3_2 : 0);
          this.txt_random_3_3 = parseInt(this.repsData.txt_random_3_3 ? this.repsData.txt_random_3_3 : 0);
          this.txt_random_3_4 = parseInt(this.repsData.txt_random_3_4 ? this.repsData.txt_random_3_4 : 0);
          this.txt_random_3_5 = parseInt(this.repsData.txt_random_3_5 ? this.repsData.txt_random_3_5 : 0);
          this.txt_random_3_6 = parseInt(this.repsData.txt_random_3_6 ? this.repsData.txt_random_3_6 : 0);
          this.txt_random_3_7 = parseInt(this.repsData.txt_random_3_7 ? this.repsData.txt_random_3_7 : 0);
          this.txt_random_3_8 = parseInt(this.repsData.txt_random_3_8 ? this.repsData.txt_random_3_8 : 0);
          this.txt_random_3_9 = parseInt(this.repsData.txt_random_3_9 ? this.repsData.txt_random_3_9 : 0);
          this.txt_random_3_10 = parseInt(this.repsData.txt_random_3_10 ? this.repsData.txt_random_3_10 : 0);
          this.txt_random_3_11 = parseInt(this.repsData.txt_random_3_11 ? this.repsData.txt_random_3_11 : 0);
          this.txt_random_3_12 = parseInt(this.repsData.txt_random_3_12 ? this.repsData.txt_random_3_12 : 0);
          this.txt_random_3_13 = parseInt(this.repsData.txt_random_3_13 ? this.repsData.txt_random_3_13 : 0);
          this.txt_random_3_14 = parseInt(this.repsData.txt_random_3_14 ? this.repsData.txt_random_3_14 : 0);
          this.txt_random_3_15 = parseInt(this.repsData.txt_random_3_15 ? this.repsData.txt_random_3_15 : 0);
          this.txt_random_4_1 = parseInt(this.repsData.txt_random_4_1 ? this.repsData.txt_random_4_1 : 0);
          this.txt_random_4_2 = parseInt(this.repsData.txt_random_4_2 ? this.repsData.txt_random_4_2 : 0);
          this.txt_random_4_3 = parseInt(this.repsData.txt_random_4_3 ? this.repsData.txt_random_4_3 : 0);
          this.txt_random_4_4 = parseInt(this.repsData.txt_random_4_4 ? this.repsData.txt_random_4_4 : 0);
          this.txt_random_4_5 = parseInt(this.repsData.txt_random_4_5 ? this.repsData.txt_random_4_5 : 0);
          this.txt_random_4_6 = parseInt(this.repsData.txt_random_4_6 ? this.repsData.txt_random_4_6 : 0);
          this.txt_random_4_7 = parseInt(this.repsData.txt_random_4_7 ? this.repsData.txt_random_4_7 : 0);
          this.txt_random_4_8 = parseInt(this.repsData.txt_random_4_8 ? this.repsData.txt_random_4_8 : 0);
          this.txt_random_4_9 = parseInt(this.repsData.txt_random_4_9 ? this.repsData.txt_random_4_9 : 0);
          this.txt_random_4_10 = parseInt(this.repsData.txt_random_4_10 ? this.repsData.txt_random_4_10 : 0);
          this.txt_random_4_11 = parseInt(this.repsData.txt_random_4_11 ? this.repsData.txt_random_4_11 : 0);
          this.txt_random_4_12 = parseInt(this.repsData.txt_random_4_12 ? this.repsData.txt_random_4_12 : 0);
          this.txt_random_4_13 = parseInt(this.repsData.txt_random_4_13 ? this.repsData.txt_random_4_13 : 0);
          this.txt_random_4_14 = parseInt(this.repsData.txt_random_4_14 ? this.repsData.txt_random_4_14 : 0);
          this.txt_random_4_15 = parseInt(this.repsData.txt_random_4_15 ? this.repsData.txt_random_4_15 : 0);
          this.txt_random_5_1 = parseInt(this.repsData.txt_random_5_1 ? this.repsData.txt_random_5_1 : 0);
          this.txt_random_5_2 = parseInt(this.repsData.txt_random_5_2 ? this.repsData.txt_random_5_2 : 0);
          this.txt_random_5_3 = parseInt(this.repsData.txt_random_5_3 ? this.repsData.txt_random_5_3 : 0);
          this.txt_random_5_4 = parseInt(this.repsData.txt_random_5_4 ? this.repsData.txt_random_5_4 : 0);
          this.txt_random_5_5 = parseInt(this.repsData.txt_random_5_5 ? this.repsData.txt_random_5_5 : 0);
          this.txt_random_5_6 = parseInt(this.repsData.txt_random_5_6 ? this.repsData.txt_random_5_6 : 0);
          this.txt_random_5_7 = parseInt(this.repsData.txt_random_5_7 ? this.repsData.txt_random_5_7 : 0);
          this.txt_random_5_8 = parseInt(this.repsData.txt_random_5_8 ? this.repsData.txt_random_5_8 : 0);
          this.txt_random_5_9 = parseInt(this.repsData.txt_random_5_9 ? this.repsData.txt_random_5_9 : 0);
          this.txt_random_5_10 = parseInt(this.repsData.txt_random_5_10 ? this.repsData.txt_random_5_10 : 0);
          this.txt_random_5_11 = parseInt(this.repsData.txt_random_5_11 ? this.repsData.txt_random_5_11 : 0);
          this.txt_random_5_12 = parseInt(this.repsData.txt_random_5_12 ? this.repsData.txt_random_5_12 : 0);
          this.txt_random_5_13 = parseInt(this.repsData.txt_random_5_13 ? this.repsData.txt_random_5_13 : 0);
          this.txt_random_5_14 = parseInt(this.repsData.txt_random_5_14 ? this.repsData.txt_random_5_14 : 0);
          this.txt_random_5_15 = parseInt(this.repsData.txt_random_5_15 ? this.repsData.txt_random_5_15 : 0);
          this.txt_random_6_1 = parseInt(this.repsData.txt_random_6_1 ? this.repsData.txt_random_6_1 : 0);
          this.txt_random_6_2 = parseInt(this.repsData.txt_random_6_2 ? this.repsData.txt_random_6_2 : 0);
          this.txt_random_6_3 = parseInt(this.repsData.txt_random_6_3 ? this.repsData.txt_random_6_3 : 0);
          this.txt_random_6_4 = parseInt(this.repsData.txt_random_6_4 ? this.repsData.txt_random_6_4 : 0);
          this.txt_random_6_5 = parseInt(this.repsData.txt_random_6_5 ? this.repsData.txt_random_6_5 : 0);
          this.txt_random_6_6 = parseInt(this.repsData.txt_random_6_6 ? this.repsData.txt_random_6_6 : 0);
          this.txt_random_6_7 = parseInt(this.repsData.txt_random_6_7 ? this.repsData.txt_random_6_7 : 0);
          this.txt_random_6_8 = parseInt(this.repsData.txt_random_6_8 ? this.repsData.txt_random_6_8 : 0);
          this.txt_random_6_9 = parseInt(this.repsData.txt_random_6_9 ? this.repsData.txt_random_6_9 : 0);
          this.txt_random_6_10 = parseInt(this.repsData.txt_random_6_10 ? this.repsData.txt_random_6_10 : 0);
          this.txt_random_6_11 = parseInt(this.repsData.txt_random_6_11 ? this.repsData.txt_random_6_11 : 0);
          this.txt_random_6_12 = parseInt(this.repsData.txt_random_6_12 ? this.repsData.txt_random_6_12 : 0);
          this.txt_random_6_13 = parseInt(this.repsData.txt_random_6_13 ? this.repsData.txt_random_6_13 : 0);
          this.txt_random_6_14 = parseInt(this.repsData.txt_random_6_14 ? this.repsData.txt_random_6_14 : 0);
          this.txt_random_6_15 = parseInt(this.repsData.txt_random_6_15 ? this.repsData.txt_random_6_15 : 0);
          this.txt_random_7_1 = parseInt(this.repsData.txt_random_7_1 ? this.repsData.txt_random_7_1 : 0);
          this.txt_random_7_2 = parseInt(this.repsData.txt_random_7_2 ? this.repsData.txt_random_7_2 : 0);
          this.txt_random_7_3 = parseInt(this.repsData.txt_random_7_3 ? this.repsData.txt_random_7_3 : 0);
          this.txt_random_7_4 = parseInt(this.repsData.txt_random_7_4 ? this.repsData.txt_random_7_4 : 0);
          this.txt_random_7_5 = parseInt(this.repsData.txt_random_7_5 ? this.repsData.txt_random_7_5 : 0);
          this.txt_random_7_6 = parseInt(this.repsData.txt_random_7_6 ? this.repsData.txt_random_7_6 : 0);
          this.txt_random_7_7 = parseInt(this.repsData.txt_random_7_7 ? this.repsData.txt_random_7_7 : 0);
          this.txt_random_7_8 = parseInt(this.repsData.txt_random_7_8 ? this.repsData.txt_random_7_8 : 0);
          this.txt_random_7_9 = parseInt(this.repsData.txt_random_7_9 ? this.repsData.txt_random_7_9 : 0);
          this.txt_random_7_10 = parseInt(this.repsData.txt_random_7_10 ? this.repsData.txt_random_7_10 : 0);
          this.txt_random_7_11 = parseInt(this.repsData.txt_random_7_11 ? this.repsData.txt_random_7_11 : 0);
          this.txt_random_7_12 = parseInt(this.repsData.txt_random_7_12 ? this.repsData.txt_random_7_12 : 0);
          this.txt_random_7_13 = parseInt(this.repsData.txt_random_7_13 ? this.repsData.txt_random_7_13 : 0);
          this.txt_random_7_14 = parseInt(this.repsData.txt_random_7_14 ? this.repsData.txt_random_7_14 : 0);
          this.txt_random_7_15 = parseInt(this.repsData.txt_random_7_15 ? this.repsData.txt_random_7_15 : 0);
          this.txt_random_8_1 = parseInt(this.repsData.txt_random_8_1 ? this.repsData.txt_random_8_1 : 0);
          this.txt_random_8_2 = parseInt(this.repsData.txt_random_8_2 ? this.repsData.txt_random_8_2 : 0);
          this.txt_random_8_3 = parseInt(this.repsData.txt_random_8_3 ? this.repsData.txt_random_8_3 : 0);
          this.txt_random_8_4 = parseInt(this.repsData.txt_random_8_4 ? this.repsData.txt_random_8_4 : 0);
          this.txt_random_8_5 = parseInt(this.repsData.txt_random_8_5 ? this.repsData.txt_random_8_5 : 0);
          this.txt_random_8_6 = parseInt(this.repsData.txt_random_8_6 ? this.repsData.txt_random_8_6 : 0);
          this.txt_random_8_7 = parseInt(this.repsData.txt_random_8_7 ? this.repsData.txt_random_8_7 : 0);
          this.txt_random_8_8 = parseInt(this.repsData.txt_random_8_8 ? this.repsData.txt_random_8_8 : 0);
          this.txt_random_8_9 = parseInt(this.repsData.txt_random_8_9 ? this.repsData.txt_random_8_9 : 0);
          this.txt_random_8_10 = parseInt(this.repsData.txt_random_8_10 ? this.repsData.txt_random_8_10 : 0);
          this.txt_random_8_11 = parseInt(this.repsData.txt_random_8_11 ? this.repsData.txt_random_8_11 : 0);
          this.txt_random_8_12 = parseInt(this.repsData.txt_random_8_12 ? this.repsData.txt_random_8_12 : 0);
          this.txt_random_8_13 = parseInt(this.repsData.txt_random_8_13 ? this.repsData.txt_random_8_13 : 0);
          this.txt_random_8_14 = parseInt(this.repsData.txt_random_8_14 ? this.repsData.txt_random_8_14 : 0);
          this.txt_random_8_15 = parseInt(this.repsData.txt_random_8_15 ? this.repsData.txt_random_8_15 : 0);
          this.txt_random_9_1 = parseInt(this.repsData.txt_random_9_1 ? this.repsData.txt_random_9_1 : 0);
          this.txt_random_9_2 = parseInt(this.repsData.txt_random_9_2 ? this.repsData.txt_random_9_2 : 0);
          this.txt_random_9_3 = parseInt(this.repsData.txt_random_9_3 ? this.repsData.txt_random_9_3 : 0);
          this.txt_random_9_4 = parseInt(this.repsData.txt_random_9_4 ? this.repsData.txt_random_9_4 : 0);
          this.txt_random_9_5 = parseInt(this.repsData.txt_random_9_5 ? this.repsData.txt_random_9_5 : 0);
          this.txt_random_9_6 = parseInt(this.repsData.txt_random_9_6 ? this.repsData.txt_random_9_6 : 0);
          this.txt_random_9_7 = parseInt(this.repsData.txt_random_9_7 ? this.repsData.txt_random_9_7 : 0);
          this.txt_random_9_8 = parseInt(this.repsData.txt_random_9_8 ? this.repsData.txt_random_9_8 : 0);
          this.txt_random_9_9 = parseInt(this.repsData.txt_random_9_9 ? this.repsData.txt_random_9_9 : 0);
          this.txt_random_9_10 = parseInt(this.repsData.txt_random_9_10 ? this.repsData.txt_random_9_10 : 0);
          this.txt_random_9_11 = parseInt(this.repsData.txt_random_9_11 ? this.repsData.txt_random_9_11 : 0);
          this.txt_random_9_12 = parseInt(this.repsData.txt_random_9_12 ? this.repsData.txt_random_9_12 : 0);
          this.txt_random_9_13 = parseInt(this.repsData.txt_random_9_13 ? this.repsData.txt_random_9_13 : 0);
          this.txt_random_9_14 = parseInt(this.repsData.txt_random_9_14 ? this.repsData.txt_random_9_14 : 0);
          this.txt_random_9_15 = parseInt(this.repsData.txt_random_9_15 ? this.repsData.txt_random_9_15 : 0);
          this.txt_random_10_1 = parseInt(this.repsData.txt_random_10_1 ? this.repsData.txt_random_10_1 : 0);
          this.txt_random_10_2 = parseInt(this.repsData.txt_random_10_2 ? this.repsData.txt_random_10_2 : 0);
          this.txt_random_10_3 = parseInt(this.repsData.txt_random_10_3 ? this.repsData.txt_random_10_3 : 0);
          this.txt_random_10_4 = parseInt(this.repsData.txt_random_10_4 ? this.repsData.txt_random_10_4 : 0);
          this.txt_random_10_5 = parseInt(this.repsData.txt_random_10_5 ? this.repsData.txt_random_10_5 : 0);
          this.txt_random_10_6 = parseInt(this.repsData.txt_random_10_6 ? this.repsData.txt_random_10_6 : 0);
          this.txt_random_10_7 = parseInt(this.repsData.txt_random_10_7 ? this.repsData.txt_random_10_7 : 0);
          this.txt_random_10_8 = parseInt(this.repsData.txt_random_10_8 ? this.repsData.txt_random_10_8 : 0);
          this.txt_random_10_9 = parseInt(this.repsData.txt_random_10_9 ? this.repsData.txt_random_10_9 : 0);
          this.txt_random_10_10 = parseInt(this.repsData.txt_random_10_10 ? this.repsData.txt_random_10_10 : 0);
          this.txt_random_10_11 = parseInt(this.repsData.txt_random_10_11 ? this.repsData.txt_random_10_11 : 0);
          this.txt_random_10_12 = parseInt(this.repsData.txt_random_10_12 ? this.repsData.txt_random_10_12 : 0);
          this.txt_random_10_13 = parseInt(this.repsData.txt_random_10_13 ? this.repsData.txt_random_10_13 : 0);
          this.txt_random_10_14 = parseInt(this.repsData.txt_random_10_14 ? this.repsData.txt_random_10_14 : 0);
          this.txt_random_10_15 = parseInt(this.repsData.txt_random_10_15 ? this.repsData.txt_random_10_15 : 0);
          this.rank_1 = parseInt(this.repsData.rank_1 ? this.repsData.rank_1 : 1);
          this.rank_2 = parseInt(this.repsData.rank_2 ? this.repsData.rank_2 : 2);
          this.rank_3 = parseInt(this.repsData.rank_3 ? this.repsData.rank_3 : 3);
          this.rank_4 = parseInt(this.repsData.rank_4 ? this.repsData.rank_4 : 4);
          this.rank_5 = parseInt(this.repsData.rank_5 ? this.repsData.rank_5 : 5);
          this.rank_6 = parseInt(this.repsData.rank_6 ? this.repsData.rank_6 : 6);
          this.rank_7 = parseInt(this.repsData.rank_7 ? this.repsData.rank_7 : 7);
          this.rank_8 = parseInt(this.repsData.rank_8 ? this.repsData.rank_8 : 8);
          this.rank_9 = parseInt(this.repsData.rank_9 ? this.repsData.rank_9 : 9);
          this.rank_10 = parseInt(this.repsData.rank_10 ? this.repsData.rank_10 : 10);
          this.txt_1_10star = parseInt(this.repsData.txt_1_10star ? this.repsData.txt_1_10star : 0);
          this.txt_2_10star = parseInt(this.repsData.txt_2_10star ? this.repsData.txt_2_10star : 0);
          this.txt_3_10star = parseInt(this.repsData.txt_3_10star ? this.repsData.txt_3_10star : 0);
          this.txt_4_10star = parseInt(this.repsData.txt_4_10star ? this.repsData.txt_4_10star : 0);
          this.txt_5_10star = parseInt(this.repsData.txt_5_10star ? this.repsData.txt_5_10star : 0);
          this.txt_6_10star = parseInt(this.repsData.txt_6_10star ? this.repsData.txt_6_10star : 0);
          this.txt_7_10star = parseInt(this.repsData.txt_7_10star ? this.repsData.txt_7_10star : 0);
          this.txt_8_10star = parseInt(this.repsData.txt_8_10star ? this.repsData.txt_8_10star : 0);
          this.txt_9_10star = parseInt(this.repsData.txt_9_10star ? this.repsData.txt_9_10star : 0);
          this.txt_10_10star = parseInt(this.repsData.txt_10_10star ? this.repsData.txt_10_10star : 0);
          this.stop_win = parseInt(this.repsData.stop_win ? this.repsData.stop_win : 1000);
          this.stop_loss = parseInt(this.repsData.stop_loss ? this.repsData.stop_loss : -800);

          // default message
          this.bet_type_ao_chu = this.bet_type === "凹注" ? true : false
          this.bet_type_ciang_gong = this.bet_type === "強攻" ? true : false
        })
        .catch(function (error) {
          // 請求失敗處理
          console.log(error);
        });

    },
    selectBetType(value) {
      this.bet_type_ao_chu = value === "凹注"
      this.bet_type_ciang_gong = value === "強攻"
    },
    selectRandomType(value) {
      this.random_type = value
    },
    openDisclaimer() {
      const order_tactic_list = [];
      order_tactic_list.push(this.chk_odd_disable_1)
      order_tactic_list.push(this.chk_even_disable_1)
      order_tactic_list.push(this.chk_big_disable_1)
      order_tactic_list.push(this.chk_small_disable_1)
      order_tactic_list.push(this.chk_random_disable_1)
      order_tactic_list.push(this.chk_odd_disable_2)
      order_tactic_list.push(this.chk_even_disable_2)
      order_tactic_list.push(this.chk_big_disable_2)
      order_tactic_list.push(this.chk_small_disable_2)
      order_tactic_list.push(this.chk_random_disable_2)
      order_tactic_list.push(this.chk_odd_disable_3)
      order_tactic_list.push(this.chk_even_disable_3)
      order_tactic_list.push(this.chk_big_disable_3)
      order_tactic_list.push(this.chk_small_disable_3)
      order_tactic_list.push(this.chk_random_disable_3)
      order_tactic_list.push(this.chk_odd_disable_4)
      order_tactic_list.push(this.chk_even_disable_4)
      order_tactic_list.push(this.chk_big_disable_4)
      order_tactic_list.push(this.chk_small_disable_4)
      order_tactic_list.push(this.chk_random_disable_4)
      order_tactic_list.push(this.chk_odd_disable_5)
      order_tactic_list.push(this.chk_even_disable_5)
      order_tactic_list.push(this.chk_big_disable_5)
      order_tactic_list.push(this.chk_small_disable_5)
      order_tactic_list.push(this.chk_random_disable_5)
      order_tactic_list.push(this.chk_odd_disable_6)
      order_tactic_list.push(this.chk_even_disable_6)
      order_tactic_list.push(this.chk_big_disable_6)
      order_tactic_list.push(this.chk_small_disable_6)
      order_tactic_list.push(this.chk_random_disable_6)
      order_tactic_list.push(this.chk_odd_disable_7)
      order_tactic_list.push(this.chk_even_disable_7)
      order_tactic_list.push(this.chk_big_disable_7)
      order_tactic_list.push(this.chk_small_disable_7)
      order_tactic_list.push(this.chk_random_disable_7)
      order_tactic_list.push(this.chk_odd_disable_8)
      order_tactic_list.push(this.chk_even_disable_8)
      order_tactic_list.push(this.chk_big_disable_8)
      order_tactic_list.push(this.chk_small_disable_8)
      order_tactic_list.push(this.chk_random_disable_8)
      order_tactic_list.push(this.chk_odd_disable_9)
      order_tactic_list.push(this.chk_even_disable_9)
      order_tactic_list.push(this.chk_big_disable_9)
      order_tactic_list.push(this.chk_small_disable_9)
      order_tactic_list.push(this.chk_random_disable_9)
      order_tactic_list.push(this.chk_odd_disable_10)
      order_tactic_list.push(this.chk_even_disable_10)
      order_tactic_list.push(this.chk_big_disable_10)
      order_tactic_list.push(this.chk_small_disable_10)
      order_tactic_list.push(this.chk_random_disable_10)
      var errorOrderTactic = checkAnyOrderTactic(order_tactic_list)
      var orderTacticCount = checkOrderTacticCount(order_tactic_list)

      if (this.stop_win < 0 || this.stop_loss > 0) {
        this.alertError = true
        this.repsMessage = "請處理必要欄位"
      }
      else if (errorOrderTactic) {
        this.alertError = true
        this.repsMessage = "至少啟動設定一組投注策略"
      }
      else if (this.selectedLotteryType === "1") {
        if (orderTacticCount > 9) {
          this.alertError = true
          this.repsMessage = "WG一分賽車最多啟動設定8組投注策略"
        } else {
          window.scrollTo(0, -100);
          this.dialog = true
        }
      }
      else if (this.selectedLotteryType === "3") {
        if (orderTacticCount > 41) {
          this.alertError = true
          this.repsMessage = "WG三分賽車最多啟動設定40組投注策略"
        } else {
          window.scrollTo(0, -100);
          this.dialog = true
        }
      }
      else {
        window.scrollTo(0, -100);
        this.dialog = true
      }
    },
    openDisclaimer10star() {
      const amount_list = [];
      amount_list.push(this.txt_1_10star)
      amount_list.push(this.txt_2_10star)
      amount_list.push(this.txt_3_10star)
      amount_list.push(this.txt_4_10star)
      amount_list.push(this.txt_5_10star)
      amount_list.push(this.txt_6_10star)
      amount_list.push(this.txt_7_10star)
      amount_list.push(this.txt_8_10star)
      amount_list.push(this.txt_9_10star)
      amount_list.push(this.txt_10_10star)

      // check amount
      var errorAmountCount = checkAnyAmount10star(amount_list)

      var errorZeroAmountCount = checkAnyAmountIsNotZero(amount_list, this.txt_exeucte_num_of_pass_10star)

      // check exeucte_num_of_pass
      var errorCount = checkExeucteNumOfPass(amount_list, this.txt_exeucte_num_of_pass_10star)
      console.log(errorCount)

      if (this.stop_win < 0 || this.stop_loss > 0) {
        this.alertError = true
        this.repsMessage = "請處理必要欄位"
      }
      else if (errorCount > 0) {
        this.alertError = true
        this.repsMessage = "設定執行關數與設定關數金額不符合"
      } else if (errorAmountCount > 0) {
        this.alertError = true
        this.repsMessage = "不能輸入1~19，該遊戲最小下注金額為20"
      } else if (errorZeroAmountCount > 0) {
        this.alertError = true
        this.repsMessage = "設定執行關數與設定關數金額不能為0"
      }
      else {
        window.scrollTo(0, -100);
        this.dialog = true
      }

    },
    startProcess() {
      this.dialog = false
      this.isShowProgress = true

      gameSettingData({
        betting: this.betting,
        game_name: 'WG',
        lottery_type: this.selectedLotteryType,
        order_type: this.selectedOrderType,
        method_type_10star: this.method_type_10star,
        account: this.account,
        password: this.password,
        bet_type: this.bet_type,
        bet_type_odd_1: this.bet_type_odd_1 ? this.bet_type_odd_1 : "凹注",
        bet_type_even_1: this.bet_type_even_1 ? this.bet_type_even_1 : "凹注",
        bet_type_big_1: this.bet_type_big_1 ? this.bet_type_big_1 : "凹注",
        bet_type_small_1: this.bet_type_small_1 ? this.bet_type_small_1 : "凹注",
        bet_type_random_1: this.bet_type_random_1 ? this.bet_type_random_1 : "凹注",
        bet_type_odd_2: this.bet_type_odd_2 ? this.bet_type_odd_2 : "凹注",
        bet_type_even_2: this.bet_type_even_2 ? this.bet_type_even_2 : "凹注",
        bet_type_big_2: this.bet_type_big_2 ? this.bet_type_big_2 : "凹注",
        bet_type_small_2: this.bet_type_small_2 ? this.bet_type_small_2 : "凹注",
        bet_type_random_2: this.bet_type_random_2 ? this.bet_type_random_2 : "凹注",
        bet_type_odd_3: this.bet_type_odd_3 ? this.bet_type_odd_3 : "凹注",
        bet_type_even_3: this.bet_type_even_3 ? this.bet_type_even_3 : "凹注",
        bet_type_big_3: this.bet_type_big_3 ? this.bet_type_big_3 : "凹注",
        bet_type_small_3: this.bet_type_small_3 ? this.bet_type_small_3 : "凹注",
        bet_type_random_3: this.bet_type_random_3 ? this.bet_type_random_3 : "凹注",
        bet_type_odd_4: this.bet_type_odd_4 ? this.bet_type_odd_4 : "凹注",
        bet_type_even_4: this.bet_type_even_4 ? this.bet_type_even_4 : "凹注",
        bet_type_big_4: this.bet_type_big_4 ? this.bet_type_big_4 : "凹注",
        bet_type_small_4: this.bet_type_small_4 ? this.bet_type_small_4 : "凹注",
        bet_type_random_4: this.bet_type_random_4 ? this.bet_type_random_4 : "凹注",
        bet_type_odd_5: this.bet_type_odd_5 ? this.bet_type_odd_5 : "凹注",
        bet_type_even_5: this.bet_type_even_5 ? this.bet_type_even_5 : "凹注",
        bet_type_big_5: this.bet_type_big_5 ? this.bet_type_big_5 : "凹注",
        bet_type_small_5: this.bet_type_small_5 ? this.bet_type_small_5 : "凹注",
        bet_type_random_5: this.bet_type_random_5 ? this.bet_type_random_5 : "凹注",
        bet_type_odd_6: this.bet_type_odd_6 ? this.bet_type_odd_6 : "凹注",
        bet_type_even_6: this.bet_type_even_6 ? this.bet_type_even_6 : "凹注",
        bet_type_big_6: this.bet_type_big_6 ? this.bet_type_big_6 : "凹注",
        bet_type_small_6: this.bet_type_small_6 ? this.bet_type_small_6 : "凹注",
        bet_type_random_6: this.bet_type_random_6 ? this.bet_type_random_6 : "凹注",
        bet_type_odd_7: this.bet_type_odd_7 ? this.bet_type_odd_7 : "凹注",
        bet_type_even_7: this.bet_type_even_7 ? this.bet_type_even_7 : "凹注",
        bet_type_big_7: this.bet_type_big_7 ? this.bet_type_big_7 : "凹注",
        bet_type_small_7: this.bet_type_small_7 ? this.bet_type_small_7 : "凹注",
        bet_type_random_7: this.bet_type_random_7 ? this.bet_type_random_7 : "凹注",
        bet_type_odd_8: this.bet_type_odd_8 ? this.bet_type_odd_8 : "凹注",
        bet_type_even_8: this.bet_type_even_8 ? this.bet_type_even_8 : "凹注",
        bet_type_big_8: this.bet_type_big_8 ? this.bet_type_big_8 : "凹注",
        bet_type_small_8: this.bet_type_small_8 ? this.bet_type_small_8 : "凹注",
        bet_type_random_8: this.bet_type_random_8 ? this.bet_type_random_8 : "凹注",
        bet_type_odd_9: this.bet_type_odd_9 ? this.bet_type_odd_9 : "凹注",
        bet_type_even_9: this.bet_type_even_9 ? this.bet_type_even_9 : "凹注",
        bet_type_big_9: this.bet_type_big_9 ? this.bet_type_big_9 : "凹注",
        bet_type_small_9: this.bet_type_small_9 ? this.bet_type_small_9 : "凹注",
        bet_type_random_9: this.bet_type_random_9 ? this.bet_type_random_9 : "凹注",
        bet_type_odd_10: this.bet_type_odd_10 ? this.bet_type_odd_10 : "凹注",
        bet_type_even_10: this.bet_type_even_10 ? this.bet_type_even_10 : "凹注",
        bet_type_big_10: this.bet_type_big_10 ? this.bet_type_big_10 : "凹注",
        bet_type_small_10: this.bet_type_small_10 ? this.bet_type_small_10 : "凹注",
        bet_type_random_10: this.bet_type_random_10 ? this.bet_type_random_10 : "凹注",
        random_type: this.random_type ? this.random_type : "單、雙，二選一",
        random_type_1: this.random_type_1 ? this.random_type_1 : "單、雙，二選一",
        random_type_2: this.random_type_2 ? this.random_type_2 : "單、雙，二選一",
        random_type_3: this.random_type_3 ? this.random_type_3 : "單、雙，二選一",
        random_type_4: this.random_type_4 ? this.random_type_4 : "單、雙，二選一",
        random_type_5: this.random_type_5 ? this.random_type_5 : "單、雙，二選一",
        random_type_6: this.random_type_6 ? this.random_type_6 : "單、雙，二選一",
        random_type_7: this.random_type_7 ? this.random_type_7 : "單、雙，二選一",
        random_type_8: this.random_type_8 ? this.random_type_8 : "單、雙，二選一",
        random_type_9: this.random_type_9 ? this.random_type_9 : "單、雙，二選一",
        random_type_10: this.random_type_10 ? this.random_type_10 : "單、雙，二選一",
        chk_odd_disable_1: Number(this.chk_odd_disable_1),
        chk_even_disable_1: Number(this.chk_even_disable_1),
        chk_big_disable_1: Number(this.chk_big_disable_1),
        chk_small_disable_1: Number(this.chk_small_disable_1),
        chk_random_disable_1: Number(this.chk_random_disable_1),
        chk_odd_disable_2: Number(this.chk_odd_disable_2),
        chk_even_disable_2: Number(this.chk_even_disable_2),
        chk_big_disable_2: Number(this.chk_big_disable_2),
        chk_small_disable_2: Number(this.chk_small_disable_2),
        chk_random_disable_2: Number(this.chk_random_disable_2),
        chk_odd_disable_3: Number(this.chk_odd_disable_3),
        chk_even_disable_3: Number(this.chk_even_disable_3),
        chk_big_disable_3: Number(this.chk_big_disable_3),
        chk_small_disable_3: Number(this.chk_small_disable_3),
        chk_random_disable_3: Number(this.chk_random_disable_3),
        chk_odd_disable_4: Number(this.chk_odd_disable_4),
        chk_even_disable_4: Number(this.chk_even_disable_4),
        chk_big_disable_4: Number(this.chk_big_disable_4),
        chk_small_disable_4: Number(this.chk_small_disable_4),
        chk_random_disable_4: Number(this.chk_random_disable_4),
        chk_odd_disable_5: Number(this.chk_odd_disable_5),
        chk_even_disable_5: Number(this.chk_even_disable_5),
        chk_big_disable_5: Number(this.chk_big_disable_5),
        chk_small_disable_5: Number(this.chk_small_disable_5),
        chk_random_disable_5: Number(this.chk_random_disable_5),
        chk_odd_disable_6: Number(this.chk_odd_disable_6),
        chk_even_disable_6: Number(this.chk_even_disable_6),
        chk_big_disable_6: Number(this.chk_big_disable_6),
        chk_small_disable_6: Number(this.chk_small_disable_6),
        chk_random_disable_6: Number(this.chk_random_disable_6),
        chk_odd_disable_7: Number(this.chk_odd_disable_7),
        chk_even_disable_7: Number(this.chk_even_disable_7),
        chk_big_disable_7: Number(this.chk_big_disable_7),
        chk_small_disable_7: Number(this.chk_small_disable_7),
        chk_random_disable_7: Number(this.chk_random_disable_7),
        chk_odd_disable_8: Number(this.chk_odd_disable_8),
        chk_even_disable_8: Number(this.chk_even_disable_8),
        chk_big_disable_8: Number(this.chk_big_disable_8),
        chk_small_disable_8: Number(this.chk_small_disable_8),
        chk_random_disable_8: Number(this.chk_random_disable_8),
        chk_odd_disable_9: Number(this.chk_odd_disable_9),
        chk_even_disable_9: Number(this.chk_even_disable_9),
        chk_big_disable_9: Number(this.chk_big_disable_9),
        chk_small_disable_9: Number(this.chk_small_disable_9),
        chk_random_disable_9: Number(this.chk_random_disable_9),
        chk_odd_disable_10: Number(this.chk_odd_disable_10),
        chk_even_disable_10: Number(this.chk_even_disable_10),
        chk_big_disable_10: Number(this.chk_big_disable_10),
        chk_small_disable_10: Number(this.chk_small_disable_10),
        chk_random_disable_10: Number(this.chk_random_disable_10),
        txt_exeucte_num_of_pass_10star: parseInt(this.txt_exeucte_num_of_pass_10star ? this.txt_exeucte_num_of_pass_10star : 1),
        txt_odd_exeucte_num_of_pass_1: parseInt(this.txt_odd_exeucte_num_of_pass_1 ? this.txt_odd_exeucte_num_of_pass_1 : 1),
        txt_even_exeucte_num_of_pass_1: parseInt(this.txt_even_exeucte_num_of_pass_1 ? this.txt_even_exeucte_num_of_pass_1 : 1),
        txt_big_exeucte_num_of_pass_1: parseInt(this.txt_big_exeucte_num_of_pass_1 ? this.txt_big_exeucte_num_of_pass_1 : 1),
        txt_small_exeucte_num_of_pass_1: parseInt(this.txt_small_exeucte_num_of_pass_1 ? this.txt_small_exeucte_num_of_pass_1 : 1),
        txt_random_exeucte_num_of_pass_1: parseInt(this.txt_random_exeucte_num_of_pass_1 ? this.txt_random_exeucte_num_of_pass_1 : 1),
        txt_odd_exeucte_num_of_pass_2: parseInt(this.txt_odd_exeucte_num_of_pass_2 ? this.txt_odd_exeucte_num_of_pass_2 : 1),
        txt_even_exeucte_num_of_pass_2: parseInt(this.txt_even_exeucte_num_of_pass_2 ? this.txt_even_exeucte_num_of_pass_2 : 1),
        txt_big_exeucte_num_of_pass_2: parseInt(this.txt_big_exeucte_num_of_pass_2 ? this.txt_big_exeucte_num_of_pass_2 : 1),
        txt_small_exeucte_num_of_pass_2: parseInt(this.txt_small_exeucte_num_of_pass_2 ? this.txt_small_exeucte_num_of_pass_2 : 1),
        txt_random_exeucte_num_of_pass_2: parseInt(this.txt_random_exeucte_num_of_pass_2 ? this.txt_random_exeucte_num_of_pass_2 : 1),
        txt_odd_exeucte_num_of_pass_3: parseInt(this.txt_odd_exeucte_num_of_pass_3 ? this.txt_odd_exeucte_num_of_pass_3 : 1),
        txt_even_exeucte_num_of_pass_3: parseInt(this.txt_even_exeucte_num_of_pass_3 ? this.txt_even_exeucte_num_of_pass_3 : 1),
        txt_big_exeucte_num_of_pass_3: parseInt(this.txt_big_exeucte_num_of_pass_3 ? this.txt_big_exeucte_num_of_pass_3 : 1),
        txt_small_exeucte_num_of_pass_3: parseInt(this.txt_small_exeucte_num_of_pass_3 ? this.txt_small_exeucte_num_of_pass_3 : 1),
        txt_random_exeucte_num_of_pass_3: parseInt(this.txt_random_exeucte_num_of_pass_3 ? this.txt_random_exeucte_num_of_pass_3 : 1),
        txt_odd_exeucte_num_of_pass_4: parseInt(this.txt_odd_exeucte_num_of_pass_4 ? this.txt_odd_exeucte_num_of_pass_4 : 1),
        txt_even_exeucte_num_of_pass_4: parseInt(this.txt_even_exeucte_num_of_pass_4 ? this.txt_even_exeucte_num_of_pass_4 : 1),
        txt_big_exeucte_num_of_pass_4: parseInt(this.txt_big_exeucte_num_of_pass_4 ? this.txt_big_exeucte_num_of_pass_4 : 1),
        txt_small_exeucte_num_of_pass_4: parseInt(this.txt_small_exeucte_num_of_pass_4 ? this.txt_small_exeucte_num_of_pass_4 : 1),
        txt_random_exeucte_num_of_pass_4: parseInt(this.txt_random_exeucte_num_of_pass_4 ? this.txt_random_exeucte_num_of_pass_4 : 1),
        txt_odd_exeucte_num_of_pass_5: parseInt(this.txt_odd_exeucte_num_of_pass_5 ? this.txt_odd_exeucte_num_of_pass_5 : 1),
        txt_even_exeucte_num_of_pass_5: parseInt(this.txt_even_exeucte_num_of_pass_5 ? this.txt_even_exeucte_num_of_pass_5 : 1),
        txt_big_exeucte_num_of_pass_5: parseInt(this.txt_big_exeucte_num_of_pass_5 ? this.txt_big_exeucte_num_of_pass_5 : 1),
        txt_small_exeucte_num_of_pass_5: parseInt(this.txt_small_exeucte_num_of_pass_5 ? this.txt_small_exeucte_num_of_pass_5 : 1),
        txt_random_exeucte_num_of_pass_5: parseInt(this.txt_random_exeucte_num_of_pass_5 ? this.txt_random_exeucte_num_of_pass_5 : 1),
        txt_odd_exeucte_num_of_pass_6: parseInt(this.txt_odd_exeucte_num_of_pass_6 ? this.txt_odd_exeucte_num_of_pass_6 : 1),
        txt_even_exeucte_num_of_pass_6: parseInt(this.txt_even_exeucte_num_of_pass_6 ? this.txt_even_exeucte_num_of_pass_6 : 1),
        txt_big_exeucte_num_of_pass_6: parseInt(this.txt_big_exeucte_num_of_pass_6 ? this.txt_big_exeucte_num_of_pass_6 : 1),
        txt_small_exeucte_num_of_pass_6: parseInt(this.txt_small_exeucte_num_of_pass_6 ? this.txt_small_exeucte_num_of_pass_6 : 1),
        txt_random_exeucte_num_of_pass_6: parseInt(this.txt_random_exeucte_num_of_pass_6 ? this.txt_random_exeucte_num_of_pass_6 : 1),
        txt_odd_exeucte_num_of_pass_7: parseInt(this.txt_odd_exeucte_num_of_pass_7 ? this.txt_odd_exeucte_num_of_pass_7 : 1),
        txt_even_exeucte_num_of_pass_7: parseInt(this.txt_even_exeucte_num_of_pass_7 ? this.txt_even_exeucte_num_of_pass_7 : 1),
        txt_big_exeucte_num_of_pass_7: parseInt(this.txt_big_exeucte_num_of_pass_7 ? this.txt_big_exeucte_num_of_pass_7 : 1),
        txt_small_exeucte_num_of_pass_7: parseInt(this.txt_small_exeucte_num_of_pass_7 ? this.txt_small_exeucte_num_of_pass_7 : 1),
        txt_random_exeucte_num_of_pass_7: parseInt(this.txt_random_exeucte_num_of_pass_7 ? this.txt_random_exeucte_num_of_pass_7 : 1),
        txt_odd_exeucte_num_of_pass_8: parseInt(this.txt_odd_exeucte_num_of_pass_8 ? this.txt_odd_exeucte_num_of_pass_8 : 1),
        txt_even_exeucte_num_of_pass_8: parseInt(this.txt_even_exeucte_num_of_pass_8 ? this.txt_even_exeucte_num_of_pass_8 : 1),
        txt_big_exeucte_num_of_pass_8: parseInt(this.txt_big_exeucte_num_of_pass_8 ? this.txt_big_exeucte_num_of_pass_8 : 1),
        txt_small_exeucte_num_of_pass_8: parseInt(this.txt_small_exeucte_num_of_pass_8 ? this.txt_small_exeucte_num_of_pass_8 : 1),
        txt_random_exeucte_num_of_pass_8: parseInt(this.txt_random_exeucte_num_of_pass_8 ? this.txt_random_exeucte_num_of_pass_8 : 1),
        txt_odd_exeucte_num_of_pass_9: parseInt(this.txt_odd_exeucte_num_of_pass_9 ? this.txt_odd_exeucte_num_of_pass_9 : 1),
        txt_even_exeucte_num_of_pass_9: parseInt(this.txt_even_exeucte_num_of_pass_9 ? this.txt_even_exeucte_num_of_pass_9 : 1),
        txt_big_exeucte_num_of_pass_9: parseInt(this.txt_big_exeucte_num_of_pass_9 ? this.txt_big_exeucte_num_of_pass_9 : 1),
        txt_small_exeucte_num_of_pass_9: parseInt(this.txt_small_exeucte_num_of_pass_9 ? this.txt_small_exeucte_num_of_pass_9 : 1),
        txt_random_exeucte_num_of_pass_9: parseInt(this.txt_random_exeucte_num_of_pass_9 ? this.txt_random_exeucte_num_of_pass_9 : 1),
        txt_odd_exeucte_num_of_pass_10: parseInt(this.txt_odd_exeucte_num_of_pass_10 ? this.txt_odd_exeucte_num_of_pass_10 : 1),
        txt_even_exeucte_num_of_pass_10: parseInt(this.txt_even_exeucte_num_of_pass_10 ? this.txt_even_exeucte_num_of_pass_10 : 1),
        txt_big_exeucte_num_of_pass_10: parseInt(this.txt_big_exeucte_num_of_pass_10 ? this.txt_big_exeucte_num_of_pass_10 : 1),
        txt_small_exeucte_num_of_pass_10: parseInt(this.txt_small_exeucte_num_of_pass_10 ? this.txt_small_exeucte_num_of_pass_10 : 1),
        txt_random_exeucte_num_of_pass_10: parseInt(this.txt_random_exeucte_num_of_pass_10 ? this.txt_random_exeucte_num_of_pass_10 : 1),
        txt_odd_1_1: parseInt(this.txt_odd_1_1 ? this.txt_odd_1_1 : 0),
        txt_odd_1_2: parseInt(this.txt_odd_1_2 ? this.txt_odd_1_2 : 0),
        txt_odd_1_3: parseInt(this.txt_odd_1_3 ? this.txt_odd_1_3 : 0),
        txt_odd_1_4: parseInt(this.txt_odd_1_4 ? this.txt_odd_1_4 : 0),
        txt_odd_1_5: parseInt(this.txt_odd_1_5 ? this.txt_odd_1_5 : 0),
        txt_odd_1_6: parseInt(this.txt_odd_1_6 ? this.txt_odd_1_6 : 0),
        txt_odd_1_7: parseInt(this.txt_odd_1_7 ? this.txt_odd_1_7 : 0),
        txt_odd_1_8: parseInt(this.txt_odd_1_8 ? this.txt_odd_1_8 : 0),
        txt_odd_1_9: parseInt(this.txt_odd_1_9 ? this.txt_odd_1_9 : 0),
        txt_odd_1_10: parseInt(this.txt_odd_1_10 ? this.txt_odd_1_10 : 0),
        txt_odd_1_11: parseInt(this.txt_odd_1_11 ? this.txt_odd_1_11 : 0),
        txt_odd_1_12: parseInt(this.txt_odd_1_12 ? this.txt_odd_1_12 : 0),
        txt_odd_1_13: parseInt(this.txt_odd_1_13 ? this.txt_odd_1_13 : 0),
        txt_odd_1_14: parseInt(this.txt_odd_1_14 ? this.txt_odd_1_14 : 0),
        txt_odd_1_15: parseInt(this.txt_odd_1_15 ? this.txt_odd_1_15 : 0),
        txt_odd_2_1: parseInt(this.txt_odd_2_1 ? this.txt_odd_2_1 : 0),
        txt_odd_2_2: parseInt(this.txt_odd_2_2 ? this.txt_odd_2_2 : 0),
        txt_odd_2_3: parseInt(this.txt_odd_2_3 ? this.txt_odd_2_3 : 0),
        txt_odd_2_4: parseInt(this.txt_odd_2_4 ? this.txt_odd_2_4 : 0),
        txt_odd_2_5: parseInt(this.txt_odd_2_5 ? this.txt_odd_2_5 : 0),
        txt_odd_2_6: parseInt(this.txt_odd_2_6 ? this.txt_odd_2_6 : 0),
        txt_odd_2_7: parseInt(this.txt_odd_2_7 ? this.txt_odd_2_7 : 0),
        txt_odd_2_8: parseInt(this.txt_odd_2_8 ? this.txt_odd_2_8 : 0),
        txt_odd_2_9: parseInt(this.txt_odd_2_9 ? this.txt_odd_2_9 : 0),
        txt_odd_2_10: parseInt(this.txt_odd_2_10 ? this.txt_odd_2_10 : 0),
        txt_odd_2_11: parseInt(this.txt_odd_2_11 ? this.txt_odd_2_11 : 0),
        txt_odd_2_12: parseInt(this.txt_odd_2_12 ? this.txt_odd_2_12 : 0),
        txt_odd_2_13: parseInt(this.txt_odd_2_13 ? this.txt_odd_2_13 : 0),
        txt_odd_2_14: parseInt(this.txt_odd_2_14 ? this.txt_odd_2_14 : 0),
        txt_odd_2_15: parseInt(this.txt_odd_2_15 ? this.txt_odd_2_15 : 0),
        txt_odd_3_1: parseInt(this.txt_odd_3_1 ? this.txt_odd_3_1 : 0),
        txt_odd_3_2: parseInt(this.txt_odd_3_2 ? this.txt_odd_3_2 : 0),
        txt_odd_3_3: parseInt(this.txt_odd_3_3 ? this.txt_odd_3_3 : 0),
        txt_odd_3_4: parseInt(this.txt_odd_3_4 ? this.txt_odd_3_4 : 0),
        txt_odd_3_5: parseInt(this.txt_odd_3_5 ? this.txt_odd_3_5 : 0),
        txt_odd_3_6: parseInt(this.txt_odd_3_6 ? this.txt_odd_3_6 : 0),
        txt_odd_3_7: parseInt(this.txt_odd_3_7 ? this.txt_odd_3_7 : 0),
        txt_odd_3_8: parseInt(this.txt_odd_3_8 ? this.txt_odd_3_8 : 0),
        txt_odd_3_9: parseInt(this.txt_odd_3_9 ? this.txt_odd_3_9 : 0),
        txt_odd_3_10: parseInt(this.txt_odd_3_10 ? this.txt_odd_3_10 : 0),
        txt_odd_3_11: parseInt(this.txt_odd_3_11 ? this.txt_odd_3_11 : 0),
        txt_odd_3_12: parseInt(this.txt_odd_3_12 ? this.txt_odd_3_12 : 0),
        txt_odd_3_13: parseInt(this.txt_odd_3_13 ? this.txt_odd_3_13 : 0),
        txt_odd_3_14: parseInt(this.txt_odd_3_14 ? this.txt_odd_3_14 : 0),
        txt_odd_3_15: parseInt(this.txt_odd_3_15 ? this.txt_odd_3_15 : 0),
        txt_odd_4_1: parseInt(this.txt_odd_4_1 ? this.txt_odd_4_1 : 0),
        txt_odd_4_2: parseInt(this.txt_odd_4_2 ? this.txt_odd_4_2 : 0),
        txt_odd_4_3: parseInt(this.txt_odd_4_3 ? this.txt_odd_4_3 : 0),
        txt_odd_4_4: parseInt(this.txt_odd_4_4 ? this.txt_odd_4_4 : 0),
        txt_odd_4_5: parseInt(this.txt_odd_4_5 ? this.txt_odd_4_5 : 0),
        txt_odd_4_6: parseInt(this.txt_odd_4_6 ? this.txt_odd_4_6 : 0),
        txt_odd_4_7: parseInt(this.txt_odd_4_7 ? this.txt_odd_4_7 : 0),
        txt_odd_4_8: parseInt(this.txt_odd_4_8 ? this.txt_odd_4_8 : 0),
        txt_odd_4_9: parseInt(this.txt_odd_4_9 ? this.txt_odd_4_9 : 0),
        txt_odd_4_10: parseInt(this.txt_odd_4_10 ? this.txt_odd_4_10 : 0),
        txt_odd_4_11: parseInt(this.txt_odd_4_11 ? this.txt_odd_4_11 : 0),
        txt_odd_4_12: parseInt(this.txt_odd_4_12 ? this.txt_odd_4_12 : 0),
        txt_odd_4_13: parseInt(this.txt_odd_4_13 ? this.txt_odd_4_13 : 0),
        txt_odd_4_14: parseInt(this.txt_odd_4_14 ? this.txt_odd_4_14 : 0),
        txt_odd_4_15: parseInt(this.txt_odd_4_15 ? this.txt_odd_4_15 : 0),
        txt_odd_5_1: parseInt(this.txt_odd_5_1 ? this.txt_odd_5_1 : 0),
        txt_odd_5_2: parseInt(this.txt_odd_5_2 ? this.txt_odd_5_2 : 0),
        txt_odd_5_3: parseInt(this.txt_odd_5_3 ? this.txt_odd_5_3 : 0),
        txt_odd_5_4: parseInt(this.txt_odd_5_4 ? this.txt_odd_5_4 : 0),
        txt_odd_5_5: parseInt(this.txt_odd_5_5 ? this.txt_odd_5_5 : 0),
        txt_odd_5_6: parseInt(this.txt_odd_5_6 ? this.txt_odd_5_6 : 0),
        txt_odd_5_7: parseInt(this.txt_odd_5_7 ? this.txt_odd_5_7 : 0),
        txt_odd_5_8: parseInt(this.txt_odd_5_8 ? this.txt_odd_5_8 : 0),
        txt_odd_5_9: parseInt(this.txt_odd_5_9 ? this.txt_odd_5_9 : 0),
        txt_odd_5_10: parseInt(this.txt_odd_5_10 ? this.txt_odd_5_10 : 0),
        txt_odd_5_11: parseInt(this.txt_odd_5_11 ? this.txt_odd_5_11 : 0),
        txt_odd_5_12: parseInt(this.txt_odd_5_12 ? this.txt_odd_5_12 : 0),
        txt_odd_5_13: parseInt(this.txt_odd_5_13 ? this.txt_odd_5_13 : 0),
        txt_odd_5_14: parseInt(this.txt_odd_5_14 ? this.txt_odd_5_14 : 0),
        txt_odd_5_15: parseInt(this.txt_odd_5_15 ? this.txt_odd_5_15 : 0),
        txt_odd_6_1: parseInt(this.txt_odd_6_1 ? this.txt_odd_6_1 : 0),
        txt_odd_6_2: parseInt(this.txt_odd_6_2 ? this.txt_odd_6_2 : 0),
        txt_odd_6_3: parseInt(this.txt_odd_6_3 ? this.txt_odd_6_3 : 0),
        txt_odd_6_4: parseInt(this.txt_odd_6_4 ? this.txt_odd_6_4 : 0),
        txt_odd_6_5: parseInt(this.txt_odd_6_5 ? this.txt_odd_6_5 : 0),
        txt_odd_6_6: parseInt(this.txt_odd_6_6 ? this.txt_odd_6_6 : 0),
        txt_odd_6_7: parseInt(this.txt_odd_6_7 ? this.txt_odd_6_7 : 0),
        txt_odd_6_8: parseInt(this.txt_odd_6_8 ? this.txt_odd_6_8 : 0),
        txt_odd_6_9: parseInt(this.txt_odd_6_9 ? this.txt_odd_6_9 : 0),
        txt_odd_6_10: parseInt(this.txt_odd_6_10 ? this.txt_odd_6_10 : 0),
        txt_odd_6_11: parseInt(this.txt_odd_6_11 ? this.txt_odd_6_11 : 0),
        txt_odd_6_12: parseInt(this.txt_odd_6_12 ? this.txt_odd_6_12 : 0),
        txt_odd_6_13: parseInt(this.txt_odd_6_13 ? this.txt_odd_6_13 : 0),
        txt_odd_6_14: parseInt(this.txt_odd_6_14 ? this.txt_odd_6_14 : 0),
        txt_odd_6_15: parseInt(this.txt_odd_6_15 ? this.txt_odd_6_15 : 0),
        txt_odd_7_1: parseInt(this.txt_odd_7_1 ? this.txt_odd_7_1 : 0),
        txt_odd_7_2: parseInt(this.txt_odd_7_2 ? this.txt_odd_7_2 : 0),
        txt_odd_7_3: parseInt(this.txt_odd_7_3 ? this.txt_odd_7_3 : 0),
        txt_odd_7_4: parseInt(this.txt_odd_7_4 ? this.txt_odd_7_4 : 0),
        txt_odd_7_5: parseInt(this.txt_odd_7_5 ? this.txt_odd_7_5 : 0),
        txt_odd_7_6: parseInt(this.txt_odd_7_6 ? this.txt_odd_7_6 : 0),
        txt_odd_7_7: parseInt(this.txt_odd_7_7 ? this.txt_odd_7_7 : 0),
        txt_odd_7_8: parseInt(this.txt_odd_7_8 ? this.txt_odd_7_8 : 0),
        txt_odd_7_9: parseInt(this.txt_odd_7_9 ? this.txt_odd_7_9 : 0),
        txt_odd_7_10: parseInt(this.txt_odd_7_10 ? this.txt_odd_7_10 : 0),
        txt_odd_7_11: parseInt(this.txt_odd_7_11 ? this.txt_odd_7_11 : 0),
        txt_odd_7_12: parseInt(this.txt_odd_7_12 ? this.txt_odd_7_12 : 0),
        txt_odd_7_13: parseInt(this.txt_odd_7_13 ? this.txt_odd_7_13 : 0),
        txt_odd_7_14: parseInt(this.txt_odd_7_14 ? this.txt_odd_7_14 : 0),
        txt_odd_7_15: parseInt(this.txt_odd_7_15 ? this.txt_odd_7_15 : 0),
        txt_odd_8_1: parseInt(this.txt_odd_8_1 ? this.txt_odd_8_1 : 0),
        txt_odd_8_2: parseInt(this.txt_odd_8_2 ? this.txt_odd_8_2 : 0),
        txt_odd_8_3: parseInt(this.txt_odd_8_3 ? this.txt_odd_8_3 : 0),
        txt_odd_8_4: parseInt(this.txt_odd_8_4 ? this.txt_odd_8_4 : 0),
        txt_odd_8_5: parseInt(this.txt_odd_8_5 ? this.txt_odd_8_5 : 0),
        txt_odd_8_6: parseInt(this.txt_odd_8_6 ? this.txt_odd_8_6 : 0),
        txt_odd_8_7: parseInt(this.txt_odd_8_7 ? this.txt_odd_8_7 : 0),
        txt_odd_8_8: parseInt(this.txt_odd_8_8 ? this.txt_odd_8_8 : 0),
        txt_odd_8_9: parseInt(this.txt_odd_8_9 ? this.txt_odd_8_9 : 0),
        txt_odd_8_10: parseInt(this.txt_odd_8_10 ? this.txt_odd_8_10 : 0),
        txt_odd_8_11: parseInt(this.txt_odd_8_11 ? this.txt_odd_8_11 : 0),
        txt_odd_8_12: parseInt(this.txt_odd_8_12 ? this.txt_odd_8_12 : 0),
        txt_odd_8_13: parseInt(this.txt_odd_8_13 ? this.txt_odd_8_13 : 0),
        txt_odd_8_14: parseInt(this.txt_odd_8_14 ? this.txt_odd_8_14 : 0),
        txt_odd_8_15: parseInt(this.txt_odd_8_15 ? this.txt_odd_8_15 : 0),
        txt_odd_9_1: parseInt(this.txt_odd_9_1 ? this.txt_odd_9_1 : 0),
        txt_odd_9_2: parseInt(this.txt_odd_9_2 ? this.txt_odd_9_2 : 0),
        txt_odd_9_3: parseInt(this.txt_odd_9_3 ? this.txt_odd_9_3 : 0),
        txt_odd_9_4: parseInt(this.txt_odd_9_4 ? this.txt_odd_9_4 : 0),
        txt_odd_9_5: parseInt(this.txt_odd_9_5 ? this.txt_odd_9_5 : 0),
        txt_odd_9_6: parseInt(this.txt_odd_9_6 ? this.txt_odd_9_6 : 0),
        txt_odd_9_7: parseInt(this.txt_odd_9_7 ? this.txt_odd_9_7 : 0),
        txt_odd_9_8: parseInt(this.txt_odd_9_8 ? this.txt_odd_9_8 : 0),
        txt_odd_9_9: parseInt(this.txt_odd_9_9 ? this.txt_odd_9_9 : 0),
        txt_odd_9_10: parseInt(this.txt_odd_9_10 ? this.txt_odd_9_10 : 0),
        txt_odd_9_11: parseInt(this.txt_odd_9_11 ? this.txt_odd_9_11 : 0),
        txt_odd_9_12: parseInt(this.txt_odd_9_12 ? this.txt_odd_9_12 : 0),
        txt_odd_9_13: parseInt(this.txt_odd_9_13 ? this.txt_odd_9_13 : 0),
        txt_odd_9_14: parseInt(this.txt_odd_9_14 ? this.txt_odd_9_14 : 0),
        txt_odd_9_15: parseInt(this.txt_odd_9_15 ? this.txt_odd_9_15 : 0),
        txt_odd_10_1: parseInt(this.txt_odd_10_1 ? this.txt_odd_10_1 : 0),
        txt_odd_10_2: parseInt(this.txt_odd_10_2 ? this.txt_odd_10_2 : 0),
        txt_odd_10_3: parseInt(this.txt_odd_10_3 ? this.txt_odd_10_3 : 0),
        txt_odd_10_4: parseInt(this.txt_odd_10_4 ? this.txt_odd_10_4 : 0),
        txt_odd_10_5: parseInt(this.txt_odd_10_5 ? this.txt_odd_10_5 : 0),
        txt_odd_10_6: parseInt(this.txt_odd_10_6 ? this.txt_odd_10_6 : 0),
        txt_odd_10_7: parseInt(this.txt_odd_10_7 ? this.txt_odd_10_7 : 0),
        txt_odd_10_8: parseInt(this.txt_odd_10_8 ? this.txt_odd_10_8 : 0),
        txt_odd_10_9: parseInt(this.txt_odd_10_9 ? this.txt_odd_10_9 : 0),
        txt_odd_10_10: parseInt(this.txt_odd_10_10 ? this.txt_odd_10_10 : 0),
        txt_odd_10_11: parseInt(this.txt_odd_10_11 ? this.txt_odd_10_11 : 0),
        txt_odd_10_12: parseInt(this.txt_odd_10_12 ? this.txt_odd_10_12 : 0),
        txt_odd_10_13: parseInt(this.txt_odd_10_13 ? this.txt_odd_10_13 : 0),
        txt_odd_10_14: parseInt(this.txt_odd_10_14 ? this.txt_odd_10_14 : 0),
        txt_odd_10_15: parseInt(this.txt_odd_10_15 ? this.txt_odd_10_15 : 0),
        txt_even_1_1: parseInt(this.txt_even_1_1 ? this.txt_even_1_1 : 0),
        txt_even_1_2: parseInt(this.txt_even_1_2 ? this.txt_even_1_2 : 0),
        txt_even_1_3: parseInt(this.txt_even_1_3 ? this.txt_even_1_3 : 0),
        txt_even_1_4: parseInt(this.txt_even_1_4 ? this.txt_even_1_4 : 0),
        txt_even_1_5: parseInt(this.txt_even_1_5 ? this.txt_even_1_5 : 0),
        txt_even_1_6: parseInt(this.txt_even_1_6 ? this.txt_even_1_6 : 0),
        txt_even_1_7: parseInt(this.txt_even_1_7 ? this.txt_even_1_7 : 0),
        txt_even_1_8: parseInt(this.txt_even_1_8 ? this.txt_even_1_8 : 0),
        txt_even_1_9: parseInt(this.txt_even_1_9 ? this.txt_even_1_9 : 0),
        txt_even_1_10: parseInt(this.txt_even_1_10 ? this.txt_even_1_10 : 0),
        txt_even_1_11: parseInt(this.txt_even_1_11 ? this.txt_even_1_11 : 0),
        txt_even_1_12: parseInt(this.txt_even_1_12 ? this.txt_even_1_12 : 0),
        txt_even_1_13: parseInt(this.txt_even_1_13 ? this.txt_even_1_13 : 0),
        txt_even_1_14: parseInt(this.txt_even_1_14 ? this.txt_even_1_14 : 0),
        txt_even_1_15: parseInt(this.txt_even_1_15 ? this.txt_even_1_15 : 0),
        txt_even_2_1: parseInt(this.txt_even_2_1 ? this.txt_even_2_1 : 0),
        txt_even_2_2: parseInt(this.txt_even_2_2 ? this.txt_even_2_2 : 0),
        txt_even_2_3: parseInt(this.txt_even_2_3 ? this.txt_even_2_3 : 0),
        txt_even_2_4: parseInt(this.txt_even_2_4 ? this.txt_even_2_4 : 0),
        txt_even_2_5: parseInt(this.txt_even_2_5 ? this.txt_even_2_5 : 0),
        txt_even_2_6: parseInt(this.txt_even_2_6 ? this.txt_even_2_6 : 0),
        txt_even_2_7: parseInt(this.txt_even_2_7 ? this.txt_even_2_7 : 0),
        txt_even_2_8: parseInt(this.txt_even_2_8 ? this.txt_even_2_8 : 0),
        txt_even_2_9: parseInt(this.txt_even_2_9 ? this.txt_even_2_9 : 0),
        txt_even_2_10: parseInt(this.txt_even_2_10 ? this.txt_even_2_10 : 0),
        txt_even_2_11: parseInt(this.txt_even_2_11 ? this.txt_even_2_11 : 0),
        txt_even_2_12: parseInt(this.txt_even_2_12 ? this.txt_even_2_12 : 0),
        txt_even_2_13: parseInt(this.txt_even_2_13 ? this.txt_even_2_13 : 0),
        txt_even_2_14: parseInt(this.txt_even_2_14 ? this.txt_even_2_14 : 0),
        txt_even_2_15: parseInt(this.txt_even_2_15 ? this.txt_even_2_15 : 0),
        txt_even_3_1: parseInt(this.txt_even_3_1 ? this.txt_even_3_1 : 0),
        txt_even_3_2: parseInt(this.txt_even_3_2 ? this.txt_even_3_2 : 0),
        txt_even_3_3: parseInt(this.txt_even_3_3 ? this.txt_even_3_3 : 0),
        txt_even_3_4: parseInt(this.txt_even_3_4 ? this.txt_even_3_4 : 0),
        txt_even_3_5: parseInt(this.txt_even_3_5 ? this.txt_even_3_5 : 0),
        txt_even_3_6: parseInt(this.txt_even_3_6 ? this.txt_even_3_6 : 0),
        txt_even_3_7: parseInt(this.txt_even_3_7 ? this.txt_even_3_7 : 0),
        txt_even_3_8: parseInt(this.txt_even_3_8 ? this.txt_even_3_8 : 0),
        txt_even_3_9: parseInt(this.txt_even_3_9 ? this.txt_even_3_9 : 0),
        txt_even_3_10: parseInt(this.txt_even_3_10 ? this.txt_even_3_10 : 0),
        txt_even_3_11: parseInt(this.txt_even_3_11 ? this.txt_even_3_11 : 0),
        txt_even_3_12: parseInt(this.txt_even_3_12 ? this.txt_even_3_12 : 0),
        txt_even_3_13: parseInt(this.txt_even_3_13 ? this.txt_even_3_13 : 0),
        txt_even_3_14: parseInt(this.txt_even_3_14 ? this.txt_even_3_14 : 0),
        txt_even_3_15: parseInt(this.txt_even_3_15 ? this.txt_even_3_15 : 0),
        txt_even_4_1: parseInt(this.txt_even_4_1 ? this.txt_even_4_1 : 0),
        txt_even_4_2: parseInt(this.txt_even_4_2 ? this.txt_even_4_2 : 0),
        txt_even_4_3: parseInt(this.txt_even_4_3 ? this.txt_even_4_3 : 0),
        txt_even_4_4: parseInt(this.txt_even_4_4 ? this.txt_even_4_4 : 0),
        txt_even_4_5: parseInt(this.txt_even_4_5 ? this.txt_even_4_5 : 0),
        txt_even_4_6: parseInt(this.txt_even_4_6 ? this.txt_even_4_6 : 0),
        txt_even_4_7: parseInt(this.txt_even_4_7 ? this.txt_even_4_7 : 0),
        txt_even_4_8: parseInt(this.txt_even_4_8 ? this.txt_even_4_8 : 0),
        txt_even_4_9: parseInt(this.txt_even_4_9 ? this.txt_even_4_9 : 0),
        txt_even_4_10: parseInt(this.txt_even_4_10 ? this.txt_even_4_10 : 0),
        txt_even_4_11: parseInt(this.txt_even_4_11 ? this.txt_even_4_11 : 0),
        txt_even_4_12: parseInt(this.txt_even_4_12 ? this.txt_even_4_12 : 0),
        txt_even_4_13: parseInt(this.txt_even_4_13 ? this.txt_even_4_13 : 0),
        txt_even_4_14: parseInt(this.txt_even_4_14 ? this.txt_even_4_14 : 0),
        txt_even_4_15: parseInt(this.txt_even_4_15 ? this.txt_even_4_15 : 0),
        txt_even_5_1: parseInt(this.txt_even_5_1 ? this.txt_even_5_1 : 0),
        txt_even_5_2: parseInt(this.txt_even_5_2 ? this.txt_even_5_2 : 0),
        txt_even_5_3: parseInt(this.txt_even_5_3 ? this.txt_even_5_3 : 0),
        txt_even_5_4: parseInt(this.txt_even_5_4 ? this.txt_even_5_4 : 0),
        txt_even_5_5: parseInt(this.txt_even_5_5 ? this.txt_even_5_5 : 0),
        txt_even_5_6: parseInt(this.txt_even_5_6 ? this.txt_even_5_6 : 0),
        txt_even_5_7: parseInt(this.txt_even_5_7 ? this.txt_even_5_7 : 0),
        txt_even_5_8: parseInt(this.txt_even_5_8 ? this.txt_even_5_8 : 0),
        txt_even_5_9: parseInt(this.txt_even_5_9 ? this.txt_even_5_9 : 0),
        txt_even_5_10: parseInt(this.txt_even_5_10 ? this.txt_even_5_10 : 0),
        txt_even_5_11: parseInt(this.txt_even_5_11 ? this.txt_even_5_11 : 0),
        txt_even_5_12: parseInt(this.txt_even_5_12 ? this.txt_even_5_12 : 0),
        txt_even_5_13: parseInt(this.txt_even_5_13 ? this.txt_even_5_13 : 0),
        txt_even_5_14: parseInt(this.txt_even_5_14 ? this.txt_even_5_14 : 0),
        txt_even_5_15: parseInt(this.txt_even_5_15 ? this.txt_even_5_15 : 0),
        txt_even_6_1: parseInt(this.txt_even_6_1 ? this.txt_even_6_1 : 0),
        txt_even_6_2: parseInt(this.txt_even_6_2 ? this.txt_even_6_2 : 0),
        txt_even_6_3: parseInt(this.txt_even_6_3 ? this.txt_even_6_3 : 0),
        txt_even_6_4: parseInt(this.txt_even_6_4 ? this.txt_even_6_4 : 0),
        txt_even_6_5: parseInt(this.txt_even_6_5 ? this.txt_even_6_5 : 0),
        txt_even_6_6: parseInt(this.txt_even_6_6 ? this.txt_even_6_6 : 0),
        txt_even_6_7: parseInt(this.txt_even_6_7 ? this.txt_even_6_7 : 0),
        txt_even_6_8: parseInt(this.txt_even_6_8 ? this.txt_even_6_8 : 0),
        txt_even_6_9: parseInt(this.txt_even_6_9 ? this.txt_even_6_9 : 0),
        txt_even_6_10: parseInt(this.txt_even_6_10 ? this.txt_even_6_10 : 0),
        txt_even_6_11: parseInt(this.txt_even_6_11 ? this.txt_even_6_11 : 0),
        txt_even_6_12: parseInt(this.txt_even_6_12 ? this.txt_even_6_12 : 0),
        txt_even_6_13: parseInt(this.txt_even_6_13 ? this.txt_even_6_13 : 0),
        txt_even_6_14: parseInt(this.txt_even_6_14 ? this.txt_even_6_14 : 0),
        txt_even_6_15: parseInt(this.txt_even_6_15 ? this.txt_even_6_15 : 0),
        txt_even_7_1: parseInt(this.txt_even_7_1 ? this.txt_even_7_1 : 0),
        txt_even_7_2: parseInt(this.txt_even_7_2 ? this.txt_even_7_2 : 0),
        txt_even_7_3: parseInt(this.txt_even_7_3 ? this.txt_even_7_3 : 0),
        txt_even_7_4: parseInt(this.txt_even_7_4 ? this.txt_even_7_4 : 0),
        txt_even_7_5: parseInt(this.txt_even_7_5 ? this.txt_even_7_5 : 0),
        txt_even_7_6: parseInt(this.txt_even_7_6 ? this.txt_even_7_6 : 0),
        txt_even_7_7: parseInt(this.txt_even_7_7 ? this.txt_even_7_7 : 0),
        txt_even_7_8: parseInt(this.txt_even_7_8 ? this.txt_even_7_8 : 0),
        txt_even_7_9: parseInt(this.txt_even_7_9 ? this.txt_even_7_9 : 0),
        txt_even_7_10: parseInt(this.txt_even_7_10 ? this.txt_even_7_10 : 0),
        txt_even_7_11: parseInt(this.txt_even_7_11 ? this.txt_even_7_11 : 0),
        txt_even_7_12: parseInt(this.txt_even_7_12 ? this.txt_even_7_12 : 0),
        txt_even_7_13: parseInt(this.txt_even_7_13 ? this.txt_even_7_13 : 0),
        txt_even_7_14: parseInt(this.txt_even_7_14 ? this.txt_even_7_14 : 0),
        txt_even_7_15: parseInt(this.txt_even_7_15 ? this.txt_even_7_15 : 0),
        txt_even_8_1: parseInt(this.txt_even_8_1 ? this.txt_even_8_1 : 0),
        txt_even_8_2: parseInt(this.txt_even_8_2 ? this.txt_even_8_2 : 0),
        txt_even_8_3: parseInt(this.txt_even_8_3 ? this.txt_even_8_3 : 0),
        txt_even_8_4: parseInt(this.txt_even_8_4 ? this.txt_even_8_4 : 0),
        txt_even_8_5: parseInt(this.txt_even_8_5 ? this.txt_even_8_5 : 0),
        txt_even_8_6: parseInt(this.txt_even_8_6 ? this.txt_even_8_6 : 0),
        txt_even_8_7: parseInt(this.txt_even_8_7 ? this.txt_even_8_7 : 0),
        txt_even_8_8: parseInt(this.txt_even_8_8 ? this.txt_even_8_8 : 0),
        txt_even_8_9: parseInt(this.txt_even_8_9 ? this.txt_even_8_9 : 0),
        txt_even_8_10: parseInt(this.txt_even_8_10 ? this.txt_even_8_10 : 0),
        txt_even_8_11: parseInt(this.txt_even_8_11 ? this.txt_even_8_11 : 0),
        txt_even_8_12: parseInt(this.txt_even_8_12 ? this.txt_even_8_12 : 0),
        txt_even_8_13: parseInt(this.txt_even_8_13 ? this.txt_even_8_13 : 0),
        txt_even_8_14: parseInt(this.txt_even_8_14 ? this.txt_even_8_14 : 0),
        txt_even_8_15: parseInt(this.txt_even_8_15 ? this.txt_even_8_15 : 0),
        txt_even_9_1: parseInt(this.txt_even_9_1 ? this.txt_even_9_1 : 0),
        txt_even_9_2: parseInt(this.txt_even_9_2 ? this.txt_even_9_2 : 0),
        txt_even_9_3: parseInt(this.txt_even_9_3 ? this.txt_even_9_3 : 0),
        txt_even_9_4: parseInt(this.txt_even_9_4 ? this.txt_even_9_4 : 0),
        txt_even_9_5: parseInt(this.txt_even_9_5 ? this.txt_even_9_5 : 0),
        txt_even_9_6: parseInt(this.txt_even_9_6 ? this.txt_even_9_6 : 0),
        txt_even_9_7: parseInt(this.txt_even_9_7 ? this.txt_even_9_7 : 0),
        txt_even_9_8: parseInt(this.txt_even_9_8 ? this.txt_even_9_8 : 0),
        txt_even_9_9: parseInt(this.txt_even_9_9 ? this.txt_even_9_9 : 0),
        txt_even_9_10: parseInt(this.txt_even_9_10 ? this.txt_even_9_10 : 0),
        txt_even_9_11: parseInt(this.txt_even_9_11 ? this.txt_even_9_11 : 0),
        txt_even_9_12: parseInt(this.txt_even_9_12 ? this.txt_even_9_12 : 0),
        txt_even_9_13: parseInt(this.txt_even_9_13 ? this.txt_even_9_13 : 0),
        txt_even_9_14: parseInt(this.txt_even_9_14 ? this.txt_even_9_14 : 0),
        txt_even_9_15: parseInt(this.txt_even_9_15 ? this.txt_even_9_15 : 0),
        txt_even_10_1: parseInt(this.txt_even_10_1 ? this.txt_even_10_1 : 0),
        txt_even_10_2: parseInt(this.txt_even_10_2 ? this.txt_even_10_2 : 0),
        txt_even_10_3: parseInt(this.txt_even_10_3 ? this.txt_even_10_3 : 0),
        txt_even_10_4: parseInt(this.txt_even_10_4 ? this.txt_even_10_4 : 0),
        txt_even_10_5: parseInt(this.txt_even_10_5 ? this.txt_even_10_5 : 0),
        txt_even_10_6: parseInt(this.txt_even_10_6 ? this.txt_even_10_6 : 0),
        txt_even_10_7: parseInt(this.txt_even_10_7 ? this.txt_even_10_7 : 0),
        txt_even_10_8: parseInt(this.txt_even_10_8 ? this.txt_even_10_8 : 0),
        txt_even_10_9: parseInt(this.txt_even_10_9 ? this.txt_even_10_9 : 0),
        txt_even_10_10: parseInt(this.txt_even_10_10 ? this.txt_even_10_10 : 0),
        txt_even_10_11: parseInt(this.txt_even_10_11 ? this.txt_even_10_11 : 0),
        txt_even_10_12: parseInt(this.txt_even_10_12 ? this.txt_even_10_12 : 0),
        txt_even_10_13: parseInt(this.txt_even_10_13 ? this.txt_even_10_13 : 0),
        txt_even_10_14: parseInt(this.txt_even_10_14 ? this.txt_even_10_14 : 0),
        txt_even_10_15: parseInt(this.txt_even_10_15 ? this.txt_even_10_15 : 0),
        txt_big_1_1: parseInt(this.txt_big_1_1 ? this.txt_big_1_1 : 0),
        txt_big_1_2: parseInt(this.txt_big_1_2 ? this.txt_big_1_2 : 0),
        txt_big_1_3: parseInt(this.txt_big_1_3 ? this.txt_big_1_3 : 0),
        txt_big_1_4: parseInt(this.txt_big_1_4 ? this.txt_big_1_4 : 0),
        txt_big_1_5: parseInt(this.txt_big_1_5 ? this.txt_big_1_5 : 0),
        txt_big_1_6: parseInt(this.txt_big_1_6 ? this.txt_big_1_6 : 0),
        txt_big_1_7: parseInt(this.txt_big_1_7 ? this.txt_big_1_7 : 0),
        txt_big_1_8: parseInt(this.txt_big_1_8 ? this.txt_big_1_8 : 0),
        txt_big_1_9: parseInt(this.txt_big_1_9 ? this.txt_big_1_9 : 0),
        txt_big_1_10: parseInt(this.txt_big_1_10 ? this.txt_big_1_10 : 0),
        txt_big_1_11: parseInt(this.txt_big_1_11 ? this.txt_big_1_11 : 0),
        txt_big_1_12: parseInt(this.txt_big_1_12 ? this.txt_big_1_12 : 0),
        txt_big_1_13: parseInt(this.txt_big_1_13 ? this.txt_big_1_13 : 0),
        txt_big_1_14: parseInt(this.txt_big_1_14 ? this.txt_big_1_14 : 0),
        txt_big_1_15: parseInt(this.txt_big_1_15 ? this.txt_big_1_15 : 0),
        txt_big_2_1: parseInt(this.txt_big_2_1 ? this.txt_big_2_1 : 0),
        txt_big_2_2: parseInt(this.txt_big_2_2 ? this.txt_big_2_2 : 0),
        txt_big_2_3: parseInt(this.txt_big_2_3 ? this.txt_big_2_3 : 0),
        txt_big_2_4: parseInt(this.txt_big_2_4 ? this.txt_big_2_4 : 0),
        txt_big_2_5: parseInt(this.txt_big_2_5 ? this.txt_big_2_5 : 0),
        txt_big_2_6: parseInt(this.txt_big_2_6 ? this.txt_big_2_6 : 0),
        txt_big_2_7: parseInt(this.txt_big_2_7 ? this.txt_big_2_7 : 0),
        txt_big_2_8: parseInt(this.txt_big_2_8 ? this.txt_big_2_8 : 0),
        txt_big_2_9: parseInt(this.txt_big_2_9 ? this.txt_big_2_9 : 0),
        txt_big_2_10: parseInt(this.txt_big_2_10 ? this.txt_big_2_10 : 0),
        txt_big_2_11: parseInt(this.txt_big_2_11 ? this.txt_big_2_11 : 0),
        txt_big_2_12: parseInt(this.txt_big_2_12 ? this.txt_big_2_12 : 0),
        txt_big_2_13: parseInt(this.txt_big_2_13 ? this.txt_big_2_13 : 0),
        txt_big_2_14: parseInt(this.txt_big_2_14 ? this.txt_big_2_14 : 0),
        txt_big_2_15: parseInt(this.txt_big_2_15 ? this.txt_big_2_15 : 0),
        txt_big_3_1: parseInt(this.txt_big_3_1 ? this.txt_big_3_1 : 0),
        txt_big_3_2: parseInt(this.txt_big_3_2 ? this.txt_big_3_2 : 0),
        txt_big_3_3: parseInt(this.txt_big_3_3 ? this.txt_big_3_3 : 0),
        txt_big_3_4: parseInt(this.txt_big_3_4 ? this.txt_big_3_4 : 0),
        txt_big_3_5: parseInt(this.txt_big_3_5 ? this.txt_big_3_5 : 0),
        txt_big_3_6: parseInt(this.txt_big_3_6 ? this.txt_big_3_6 : 0),
        txt_big_3_7: parseInt(this.txt_big_3_7 ? this.txt_big_3_7 : 0),
        txt_big_3_8: parseInt(this.txt_big_3_8 ? this.txt_big_3_8 : 0),
        txt_big_3_9: parseInt(this.txt_big_3_9 ? this.txt_big_3_9 : 0),
        txt_big_3_10: parseInt(this.txt_big_3_10 ? this.txt_big_3_10 : 0),
        txt_big_3_11: parseInt(this.txt_big_3_11 ? this.txt_big_3_11 : 0),
        txt_big_3_12: parseInt(this.txt_big_3_12 ? this.txt_big_3_12 : 0),
        txt_big_3_13: parseInt(this.txt_big_3_13 ? this.txt_big_3_13 : 0),
        txt_big_3_14: parseInt(this.txt_big_3_14 ? this.txt_big_3_14 : 0),
        txt_big_3_15: parseInt(this.txt_big_3_15 ? this.txt_big_3_15 : 0),
        txt_big_4_1: parseInt(this.txt_big_4_1 ? this.txt_big_4_1 : 0),
        txt_big_4_2: parseInt(this.txt_big_4_2 ? this.txt_big_4_2 : 0),
        txt_big_4_3: parseInt(this.txt_big_4_3 ? this.txt_big_4_3 : 0),
        txt_big_4_4: parseInt(this.txt_big_4_4 ? this.txt_big_4_4 : 0),
        txt_big_4_5: parseInt(this.txt_big_4_5 ? this.txt_big_4_5 : 0),
        txt_big_4_6: parseInt(this.txt_big_4_6 ? this.txt_big_4_6 : 0),
        txt_big_4_7: parseInt(this.txt_big_4_7 ? this.txt_big_4_7 : 0),
        txt_big_4_8: parseInt(this.txt_big_4_8 ? this.txt_big_4_8 : 0),
        txt_big_4_9: parseInt(this.txt_big_4_9 ? this.txt_big_4_9 : 0),
        txt_big_4_10: parseInt(this.txt_big_4_10 ? this.txt_big_4_10 : 0),
        txt_big_4_11: parseInt(this.txt_big_4_11 ? this.txt_big_4_11 : 0),
        txt_big_4_12: parseInt(this.txt_big_4_12 ? this.txt_big_4_12 : 0),
        txt_big_4_13: parseInt(this.txt_big_4_13 ? this.txt_big_4_13 : 0),
        txt_big_4_14: parseInt(this.txt_big_4_14 ? this.txt_big_4_14 : 0),
        txt_big_4_15: parseInt(this.txt_big_4_15 ? this.txt_big_4_15 : 0),
        txt_big_5_1: parseInt(this.txt_big_5_1 ? this.txt_big_5_1 : 0),
        txt_big_5_2: parseInt(this.txt_big_5_2 ? this.txt_big_5_2 : 0),
        txt_big_5_3: parseInt(this.txt_big_5_3 ? this.txt_big_5_3 : 0),
        txt_big_5_4: parseInt(this.txt_big_5_4 ? this.txt_big_5_4 : 0),
        txt_big_5_5: parseInt(this.txt_big_5_5 ? this.txt_big_5_5 : 0),
        txt_big_5_6: parseInt(this.txt_big_5_6 ? this.txt_big_5_6 : 0),
        txt_big_5_7: parseInt(this.txt_big_5_7 ? this.txt_big_5_7 : 0),
        txt_big_5_8: parseInt(this.txt_big_5_8 ? this.txt_big_5_8 : 0),
        txt_big_5_9: parseInt(this.txt_big_5_9 ? this.txt_big_5_9 : 0),
        txt_big_5_10: parseInt(this.txt_big_5_10 ? this.txt_big_5_10 : 0),
        txt_big_5_11: parseInt(this.txt_big_5_11 ? this.txt_big_5_11 : 0),
        txt_big_5_12: parseInt(this.txt_big_5_12 ? this.txt_big_5_12 : 0),
        txt_big_5_13: parseInt(this.txt_big_5_13 ? this.txt_big_5_13 : 0),
        txt_big_5_14: parseInt(this.txt_big_5_14 ? this.txt_big_5_14 : 0),
        txt_big_5_15: parseInt(this.txt_big_5_15 ? this.txt_big_5_15 : 0),
        txt_big_6_1: parseInt(this.txt_big_6_1 ? this.txt_big_6_1 : 0),
        txt_big_6_2: parseInt(this.txt_big_6_2 ? this.txt_big_6_2 : 0),
        txt_big_6_3: parseInt(this.txt_big_6_3 ? this.txt_big_6_3 : 0),
        txt_big_6_4: parseInt(this.txt_big_6_4 ? this.txt_big_6_4 : 0),
        txt_big_6_5: parseInt(this.txt_big_6_5 ? this.txt_big_6_5 : 0),
        txt_big_6_6: parseInt(this.txt_big_6_6 ? this.txt_big_6_6 : 0),
        txt_big_6_7: parseInt(this.txt_big_6_7 ? this.txt_big_6_7 : 0),
        txt_big_6_8: parseInt(this.txt_big_6_8 ? this.txt_big_6_8 : 0),
        txt_big_6_9: parseInt(this.txt_big_6_9 ? this.txt_big_6_9 : 0),
        txt_big_6_10: parseInt(this.txt_big_6_10 ? this.txt_big_6_10 : 0),
        txt_big_6_11: parseInt(this.txt_big_6_11 ? this.txt_big_6_11 : 0),
        txt_big_6_12: parseInt(this.txt_big_6_12 ? this.txt_big_6_12 : 0),
        txt_big_6_13: parseInt(this.txt_big_6_13 ? this.txt_big_6_13 : 0),
        txt_big_6_14: parseInt(this.txt_big_6_14 ? this.txt_big_6_14 : 0),
        txt_big_6_15: parseInt(this.txt_big_6_15 ? this.txt_big_6_15 : 0),
        txt_big_7_1: parseInt(this.txt_big_7_1 ? this.txt_big_7_1 : 0),
        txt_big_7_2: parseInt(this.txt_big_7_2 ? this.txt_big_7_2 : 0),
        txt_big_7_3: parseInt(this.txt_big_7_3 ? this.txt_big_7_3 : 0),
        txt_big_7_4: parseInt(this.txt_big_7_4 ? this.txt_big_7_4 : 0),
        txt_big_7_5: parseInt(this.txt_big_7_5 ? this.txt_big_7_5 : 0),
        txt_big_7_6: parseInt(this.txt_big_7_6 ? this.txt_big_7_6 : 0),
        txt_big_7_7: parseInt(this.txt_big_7_7 ? this.txt_big_7_7 : 0),
        txt_big_7_8: parseInt(this.txt_big_7_8 ? this.txt_big_7_8 : 0),
        txt_big_7_9: parseInt(this.txt_big_7_9 ? this.txt_big_7_9 : 0),
        txt_big_7_10: parseInt(this.txt_big_7_10 ? this.txt_big_7_10 : 0),
        txt_big_7_11: parseInt(this.txt_big_7_11 ? this.txt_big_7_11 : 0),
        txt_big_7_12: parseInt(this.txt_big_7_12 ? this.txt_big_7_12 : 0),
        txt_big_7_13: parseInt(this.txt_big_7_13 ? this.txt_big_7_13 : 0),
        txt_big_7_14: parseInt(this.txt_big_7_14 ? this.txt_big_7_14 : 0),
        txt_big_7_15: parseInt(this.txt_big_7_15 ? this.txt_big_7_15 : 0),
        txt_big_8_1: parseInt(this.txt_big_8_1 ? this.txt_big_8_1 : 0),
        txt_big_8_2: parseInt(this.txt_big_8_2 ? this.txt_big_8_2 : 0),
        txt_big_8_3: parseInt(this.txt_big_8_3 ? this.txt_big_8_3 : 0),
        txt_big_8_4: parseInt(this.txt_big_8_4 ? this.txt_big_8_4 : 0),
        txt_big_8_5: parseInt(this.txt_big_8_5 ? this.txt_big_8_5 : 0),
        txt_big_8_6: parseInt(this.txt_big_8_6 ? this.txt_big_8_6 : 0),
        txt_big_8_7: parseInt(this.txt_big_8_7 ? this.txt_big_8_7 : 0),
        txt_big_8_8: parseInt(this.txt_big_8_8 ? this.txt_big_8_8 : 0),
        txt_big_8_9: parseInt(this.txt_big_8_9 ? this.txt_big_8_9 : 0),
        txt_big_8_10: parseInt(this.txt_big_8_10 ? this.txt_big_8_10 : 0),
        txt_big_8_11: parseInt(this.txt_big_8_11 ? this.txt_big_8_11 : 0),
        txt_big_8_12: parseInt(this.txt_big_8_12 ? this.txt_big_8_12 : 0),
        txt_big_8_13: parseInt(this.txt_big_8_13 ? this.txt_big_8_13 : 0),
        txt_big_8_14: parseInt(this.txt_big_8_14 ? this.txt_big_8_14 : 0),
        txt_big_8_15: parseInt(this.txt_big_8_15 ? this.txt_big_8_15 : 0),
        txt_big_9_1: parseInt(this.txt_big_9_1 ? this.txt_big_9_1 : 0),
        txt_big_9_2: parseInt(this.txt_big_9_2 ? this.txt_big_9_2 : 0),
        txt_big_9_3: parseInt(this.txt_big_9_3 ? this.txt_big_9_3 : 0),
        txt_big_9_4: parseInt(this.txt_big_9_4 ? this.txt_big_9_4 : 0),
        txt_big_9_5: parseInt(this.txt_big_9_5 ? this.txt_big_9_5 : 0),
        txt_big_9_6: parseInt(this.txt_big_9_6 ? this.txt_big_9_6 : 0),
        txt_big_9_7: parseInt(this.txt_big_9_7 ? this.txt_big_9_7 : 0),
        txt_big_9_8: parseInt(this.txt_big_9_8 ? this.txt_big_9_8 : 0),
        txt_big_9_9: parseInt(this.txt_big_9_9 ? this.txt_big_9_9 : 0),
        txt_big_9_10: parseInt(this.txt_big_9_10 ? this.txt_big_9_10 : 0),
        txt_big_9_11: parseInt(this.txt_big_9_11 ? this.txt_big_9_11 : 0),
        txt_big_9_12: parseInt(this.txt_big_9_12 ? this.txt_big_9_12 : 0),
        txt_big_9_13: parseInt(this.txt_big_9_13 ? this.txt_big_9_13 : 0),
        txt_big_9_14: parseInt(this.txt_big_9_14 ? this.txt_big_9_14 : 0),
        txt_big_9_15: parseInt(this.txt_big_9_15 ? this.txt_big_9_15 : 0),
        txt_big_10_1: parseInt(this.txt_big_10_1 ? this.txt_big_10_1 : 0),
        txt_big_10_2: parseInt(this.txt_big_10_2 ? this.txt_big_10_2 : 0),
        txt_big_10_3: parseInt(this.txt_big_10_3 ? this.txt_big_10_3 : 0),
        txt_big_10_4: parseInt(this.txt_big_10_4 ? this.txt_big_10_4 : 0),
        txt_big_10_5: parseInt(this.txt_big_10_5 ? this.txt_big_10_5 : 0),
        txt_big_10_6: parseInt(this.txt_big_10_6 ? this.txt_big_10_6 : 0),
        txt_big_10_7: parseInt(this.txt_big_10_7 ? this.txt_big_10_7 : 0),
        txt_big_10_8: parseInt(this.txt_big_10_8 ? this.txt_big_10_8 : 0),
        txt_big_10_9: parseInt(this.txt_big_10_9 ? this.txt_big_10_9 : 0),
        txt_big_10_10: parseInt(this.txt_big_10_10 ? this.txt_big_10_10 : 0),
        txt_big_10_11: parseInt(this.txt_big_10_11 ? this.txt_big_10_11 : 0),
        txt_big_10_12: parseInt(this.txt_big_10_12 ? this.txt_big_10_12 : 0),
        txt_big_10_13: parseInt(this.txt_big_10_13 ? this.txt_big_10_13 : 0),
        txt_big_10_14: parseInt(this.txt_big_10_14 ? this.txt_big_10_14 : 0),
        txt_big_10_15: parseInt(this.txt_big_10_15 ? this.txt_big_10_15 : 0),
        txt_small_1_1: parseInt(this.txt_small_1_1 ? this.txt_small_1_1 : 0),
        txt_small_1_2: parseInt(this.txt_small_1_2 ? this.txt_small_1_2 : 0),
        txt_small_1_3: parseInt(this.txt_small_1_3 ? this.txt_small_1_3 : 0),
        txt_small_1_4: parseInt(this.txt_small_1_4 ? this.txt_small_1_4 : 0),
        txt_small_1_5: parseInt(this.txt_small_1_5 ? this.txt_small_1_5 : 0),
        txt_small_1_6: parseInt(this.txt_small_1_6 ? this.txt_small_1_6 : 0),
        txt_small_1_7: parseInt(this.txt_small_1_7 ? this.txt_small_1_7 : 0),
        txt_small_1_8: parseInt(this.txt_small_1_8 ? this.txt_small_1_8 : 0),
        txt_small_1_9: parseInt(this.txt_small_1_9 ? this.txt_small_1_9 : 0),
        txt_small_1_10: parseInt(this.txt_small_1_10 ? this.txt_small_1_10 : 0),
        txt_small_1_11: parseInt(this.txt_small_1_11 ? this.txt_small_1_11 : 0),
        txt_small_1_12: parseInt(this.txt_small_1_12 ? this.txt_small_1_12 : 0),
        txt_small_1_13: parseInt(this.txt_small_1_13 ? this.txt_small_1_13 : 0),
        txt_small_1_14: parseInt(this.txt_small_1_14 ? this.txt_small_1_14 : 0),
        txt_small_1_15: parseInt(this.txt_small_1_15 ? this.txt_small_1_15 : 0),
        txt_small_2_1: parseInt(this.txt_small_2_1 ? this.txt_small_2_1 : 0),
        txt_small_2_2: parseInt(this.txt_small_2_2 ? this.txt_small_2_2 : 0),
        txt_small_2_3: parseInt(this.txt_small_2_3 ? this.txt_small_2_3 : 0),
        txt_small_2_4: parseInt(this.txt_small_2_4 ? this.txt_small_2_4 : 0),
        txt_small_2_5: parseInt(this.txt_small_2_5 ? this.txt_small_2_5 : 0),
        txt_small_2_6: parseInt(this.txt_small_2_6 ? this.txt_small_2_6 : 0),
        txt_small_2_7: parseInt(this.txt_small_2_7 ? this.txt_small_2_7 : 0),
        txt_small_2_8: parseInt(this.txt_small_2_8 ? this.txt_small_2_8 : 0),
        txt_small_2_9: parseInt(this.txt_small_2_9 ? this.txt_small_2_9 : 0),
        txt_small_2_10: parseInt(this.txt_small_2_10 ? this.txt_small_2_10 : 0),
        txt_small_2_11: parseInt(this.txt_small_2_11 ? this.txt_small_2_11 : 0),
        txt_small_2_12: parseInt(this.txt_small_2_12 ? this.txt_small_2_12 : 0),
        txt_small_2_13: parseInt(this.txt_small_2_13 ? this.txt_small_2_13 : 0),
        txt_small_2_14: parseInt(this.txt_small_2_14 ? this.txt_small_2_14 : 0),
        txt_small_2_15: parseInt(this.txt_small_2_15 ? this.txt_small_2_15 : 0),
        txt_small_3_1: parseInt(this.txt_small_3_1 ? this.txt_small_3_1 : 0),
        txt_small_3_2: parseInt(this.txt_small_3_2 ? this.txt_small_3_2 : 0),
        txt_small_3_3: parseInt(this.txt_small_3_3 ? this.txt_small_3_3 : 0),
        txt_small_3_4: parseInt(this.txt_small_3_4 ? this.txt_small_3_4 : 0),
        txt_small_3_5: parseInt(this.txt_small_3_5 ? this.txt_small_3_5 : 0),
        txt_small_3_6: parseInt(this.txt_small_3_6 ? this.txt_small_3_6 : 0),
        txt_small_3_7: parseInt(this.txt_small_3_7 ? this.txt_small_3_7 : 0),
        txt_small_3_8: parseInt(this.txt_small_3_8 ? this.txt_small_3_8 : 0),
        txt_small_3_9: parseInt(this.txt_small_3_9 ? this.txt_small_3_9 : 0),
        txt_small_3_10: parseInt(this.txt_small_3_10 ? this.txt_small_3_10 : 0),
        txt_small_3_11: parseInt(this.txt_small_3_11 ? this.txt_small_3_11 : 0),
        txt_small_3_12: parseInt(this.txt_small_3_12 ? this.txt_small_3_12 : 0),
        txt_small_3_13: parseInt(this.txt_small_3_13 ? this.txt_small_3_13 : 0),
        txt_small_3_14: parseInt(this.txt_small_3_14 ? this.txt_small_3_14 : 0),
        txt_small_3_15: parseInt(this.txt_small_3_15 ? this.txt_small_3_15 : 0),
        txt_small_4_1: parseInt(this.txt_small_4_1 ? this.txt_small_4_1 : 0),
        txt_small_4_2: parseInt(this.txt_small_4_2 ? this.txt_small_4_2 : 0),
        txt_small_4_3: parseInt(this.txt_small_4_3 ? this.txt_small_4_3 : 0),
        txt_small_4_4: parseInt(this.txt_small_4_4 ? this.txt_small_4_4 : 0),
        txt_small_4_5: parseInt(this.txt_small_4_5 ? this.txt_small_4_5 : 0),
        txt_small_4_6: parseInt(this.txt_small_4_6 ? this.txt_small_4_6 : 0),
        txt_small_4_7: parseInt(this.txt_small_4_7 ? this.txt_small_4_7 : 0),
        txt_small_4_8: parseInt(this.txt_small_4_8 ? this.txt_small_4_8 : 0),
        txt_small_4_9: parseInt(this.txt_small_4_9 ? this.txt_small_4_9 : 0),
        txt_small_4_10: parseInt(this.txt_small_4_10 ? this.txt_small_4_10 : 0),
        txt_small_4_11: parseInt(this.txt_small_4_11 ? this.txt_small_4_11 : 0),
        txt_small_4_12: parseInt(this.txt_small_4_12 ? this.txt_small_4_12 : 0),
        txt_small_4_13: parseInt(this.txt_small_4_13 ? this.txt_small_4_13 : 0),
        txt_small_4_14: parseInt(this.txt_small_4_14 ? this.txt_small_4_14 : 0),
        txt_small_4_15: parseInt(this.txt_small_4_15 ? this.txt_small_4_15 : 0),
        txt_small_5_1: parseInt(this.txt_small_5_1 ? this.txt_small_5_1 : 0),
        txt_small_5_2: parseInt(this.txt_small_5_2 ? this.txt_small_5_2 : 0),
        txt_small_5_3: parseInt(this.txt_small_5_3 ? this.txt_small_5_3 : 0),
        txt_small_5_4: parseInt(this.txt_small_5_4 ? this.txt_small_5_4 : 0),
        txt_small_5_5: parseInt(this.txt_small_5_5 ? this.txt_small_5_5 : 0),
        txt_small_5_6: parseInt(this.txt_small_5_6 ? this.txt_small_5_6 : 0),
        txt_small_5_7: parseInt(this.txt_small_5_7 ? this.txt_small_5_7 : 0),
        txt_small_5_8: parseInt(this.txt_small_5_8 ? this.txt_small_5_8 : 0),
        txt_small_5_9: parseInt(this.txt_small_5_9 ? this.txt_small_5_9 : 0),
        txt_small_5_10: parseInt(this.txt_small_5_10 ? this.txt_small_5_10 : 0),
        txt_small_5_11: parseInt(this.txt_small_5_11 ? this.txt_small_5_11 : 0),
        txt_small_5_12: parseInt(this.txt_small_5_12 ? this.txt_small_5_12 : 0),
        txt_small_5_13: parseInt(this.txt_small_5_13 ? this.txt_small_5_13 : 0),
        txt_small_5_14: parseInt(this.txt_small_5_14 ? this.txt_small_5_14 : 0),
        txt_small_5_15: parseInt(this.txt_small_5_15 ? this.txt_small_5_15 : 0),
        txt_small_6_1: parseInt(this.txt_small_6_1 ? this.txt_small_6_1 : 0),
        txt_small_6_2: parseInt(this.txt_small_6_2 ? this.txt_small_6_2 : 0),
        txt_small_6_3: parseInt(this.txt_small_6_3 ? this.txt_small_6_3 : 0),
        txt_small_6_4: parseInt(this.txt_small_6_4 ? this.txt_small_6_4 : 0),
        txt_small_6_5: parseInt(this.txt_small_6_5 ? this.txt_small_6_5 : 0),
        txt_small_6_6: parseInt(this.txt_small_6_6 ? this.txt_small_6_6 : 0),
        txt_small_6_7: parseInt(this.txt_small_6_7 ? this.txt_small_6_7 : 0),
        txt_small_6_8: parseInt(this.txt_small_6_8 ? this.txt_small_6_8 : 0),
        txt_small_6_9: parseInt(this.txt_small_6_9 ? this.txt_small_6_9 : 0),
        txt_small_6_10: parseInt(this.txt_small_6_10 ? this.txt_small_6_10 : 0),
        txt_small_6_11: parseInt(this.txt_small_6_11 ? this.txt_small_6_11 : 0),
        txt_small_6_12: parseInt(this.txt_small_6_12 ? this.txt_small_6_12 : 0),
        txt_small_6_13: parseInt(this.txt_small_6_13 ? this.txt_small_6_13 : 0),
        txt_small_6_14: parseInt(this.txt_small_6_14 ? this.txt_small_6_14 : 0),
        txt_small_6_15: parseInt(this.txt_small_6_15 ? this.txt_small_6_15 : 0),
        txt_small_7_1: parseInt(this.txt_small_7_1 ? this.txt_small_7_1 : 0),
        txt_small_7_2: parseInt(this.txt_small_7_2 ? this.txt_small_7_2 : 0),
        txt_small_7_3: parseInt(this.txt_small_7_3 ? this.txt_small_7_3 : 0),
        txt_small_7_4: parseInt(this.txt_small_7_4 ? this.txt_small_7_4 : 0),
        txt_small_7_5: parseInt(this.txt_small_7_5 ? this.txt_small_7_5 : 0),
        txt_small_7_6: parseInt(this.txt_small_7_6 ? this.txt_small_7_6 : 0),
        txt_small_7_7: parseInt(this.txt_small_7_7 ? this.txt_small_7_7 : 0),
        txt_small_7_8: parseInt(this.txt_small_7_8 ? this.txt_small_7_8 : 0),
        txt_small_7_9: parseInt(this.txt_small_7_9 ? this.txt_small_7_9 : 0),
        txt_small_7_10: parseInt(this.txt_small_7_10 ? this.txt_small_7_10 : 0),
        txt_small_7_11: parseInt(this.txt_small_7_11 ? this.txt_small_7_11 : 0),
        txt_small_7_12: parseInt(this.txt_small_7_12 ? this.txt_small_7_12 : 0),
        txt_small_7_13: parseInt(this.txt_small_7_13 ? this.txt_small_7_13 : 0),
        txt_small_7_14: parseInt(this.txt_small_7_14 ? this.txt_small_7_14 : 0),
        txt_small_7_15: parseInt(this.txt_small_7_15 ? this.txt_small_7_15 : 0),
        txt_small_8_1: parseInt(this.txt_small_8_1 ? this.txt_small_8_1 : 0),
        txt_small_8_2: parseInt(this.txt_small_8_2 ? this.txt_small_8_2 : 0),
        txt_small_8_3: parseInt(this.txt_small_8_3 ? this.txt_small_8_3 : 0),
        txt_small_8_4: parseInt(this.txt_small_8_4 ? this.txt_small_8_4 : 0),
        txt_small_8_5: parseInt(this.txt_small_8_5 ? this.txt_small_8_5 : 0),
        txt_small_8_6: parseInt(this.txt_small_8_6 ? this.txt_small_8_6 : 0),
        txt_small_8_7: parseInt(this.txt_small_8_7 ? this.txt_small_8_7 : 0),
        txt_small_8_8: parseInt(this.txt_small_8_8 ? this.txt_small_8_8 : 0),
        txt_small_8_9: parseInt(this.txt_small_8_9 ? this.txt_small_8_9 : 0),
        txt_small_8_10: parseInt(this.txt_small_8_10 ? this.txt_small_8_10 : 0),
        txt_small_8_11: parseInt(this.txt_small_8_11 ? this.txt_small_8_11 : 0),
        txt_small_8_12: parseInt(this.txt_small_8_12 ? this.txt_small_8_12 : 0),
        txt_small_8_13: parseInt(this.txt_small_8_13 ? this.txt_small_8_13 : 0),
        txt_small_8_14: parseInt(this.txt_small_8_14 ? this.txt_small_8_14 : 0),
        txt_small_8_15: parseInt(this.txt_small_8_15 ? this.txt_small_8_15 : 0),
        txt_small_9_1: parseInt(this.txt_small_9_1 ? this.txt_small_9_1 : 0),
        txt_small_9_2: parseInt(this.txt_small_9_2 ? this.txt_small_9_2 : 0),
        txt_small_9_3: parseInt(this.txt_small_9_3 ? this.txt_small_9_3 : 0),
        txt_small_9_4: parseInt(this.txt_small_9_4 ? this.txt_small_9_4 : 0),
        txt_small_9_5: parseInt(this.txt_small_9_5 ? this.txt_small_9_5 : 0),
        txt_small_9_6: parseInt(this.txt_small_9_6 ? this.txt_small_9_6 : 0),
        txt_small_9_7: parseInt(this.txt_small_9_7 ? this.txt_small_9_7 : 0),
        txt_small_9_8: parseInt(this.txt_small_9_8 ? this.txt_small_9_8 : 0),
        txt_small_9_9: parseInt(this.txt_small_9_9 ? this.txt_small_9_9 : 0),
        txt_small_9_10: parseInt(this.txt_small_9_10 ? this.txt_small_9_10 : 0),
        txt_small_9_11: parseInt(this.txt_small_9_11 ? this.txt_small_9_11 : 0),
        txt_small_9_12: parseInt(this.txt_small_9_12 ? this.txt_small_9_12 : 0),
        txt_small_9_13: parseInt(this.txt_small_9_13 ? this.txt_small_9_13 : 0),
        txt_small_9_14: parseInt(this.txt_small_9_14 ? this.txt_small_9_14 : 0),
        txt_small_9_15: parseInt(this.txt_small_9_15 ? this.txt_small_9_15 : 0),
        txt_small_10_1: parseInt(this.txt_small_10_1 ? this.txt_small_10_1 : 0),
        txt_small_10_2: parseInt(this.txt_small_10_2 ? this.txt_small_10_2 : 0),
        txt_small_10_3: parseInt(this.txt_small_10_3 ? this.txt_small_10_3 : 0),
        txt_small_10_4: parseInt(this.txt_small_10_4 ? this.txt_small_10_4 : 0),
        txt_small_10_5: parseInt(this.txt_small_10_5 ? this.txt_small_10_5 : 0),
        txt_small_10_6: parseInt(this.txt_small_10_6 ? this.txt_small_10_6 : 0),
        txt_small_10_7: parseInt(this.txt_small_10_7 ? this.txt_small_10_7 : 0),
        txt_small_10_8: parseInt(this.txt_small_10_8 ? this.txt_small_10_8 : 0),
        txt_small_10_9: parseInt(this.txt_small_10_9 ? this.txt_small_10_9 : 0),
        txt_small_10_10: parseInt(this.txt_small_10_10 ? this.txt_small_10_10 : 0),
        txt_small_10_11: parseInt(this.txt_small_10_11 ? this.txt_small_10_11 : 0),
        txt_small_10_12: parseInt(this.txt_small_10_12 ? this.txt_small_10_12 : 0),
        txt_small_10_13: parseInt(this.txt_small_10_13 ? this.txt_small_10_13 : 0),
        txt_small_10_14: parseInt(this.txt_small_10_14 ? this.txt_small_10_14 : 0),
        txt_small_10_15: parseInt(this.txt_small_10_15 ? this.txt_small_10_15 : 0),
        txt_random_1_1: parseInt(this.txt_random_1_1 ? this.txt_random_1_1 : 0),
        txt_random_1_2: parseInt(this.txt_random_1_2 ? this.txt_random_1_2 : 0),
        txt_random_1_3: parseInt(this.txt_random_1_3 ? this.txt_random_1_3 : 0),
        txt_random_1_4: parseInt(this.txt_random_1_4 ? this.txt_random_1_4 : 0),
        txt_random_1_5: parseInt(this.txt_random_1_5 ? this.txt_random_1_5 : 0),
        txt_random_1_6: parseInt(this.txt_random_1_6 ? this.txt_random_1_6 : 0),
        txt_random_1_7: parseInt(this.txt_random_1_7 ? this.txt_random_1_7 : 0),
        txt_random_1_8: parseInt(this.txt_random_1_8 ? this.txt_random_1_8 : 0),
        txt_random_1_9: parseInt(this.txt_random_1_9 ? this.txt_random_1_9 : 0),
        txt_random_1_10: parseInt(this.txt_random_1_10 ? this.txt_random_1_10 : 0),
        txt_random_1_11: parseInt(this.txt_random_1_11 ? this.txt_random_1_11 : 0),
        txt_random_1_12: parseInt(this.txt_random_1_12 ? this.txt_random_1_12 : 0),
        txt_random_1_13: parseInt(this.txt_random_1_13 ? this.txt_random_1_13 : 0),
        txt_random_1_14: parseInt(this.txt_random_1_14 ? this.txt_random_1_14 : 0),
        txt_random_1_15: parseInt(this.txt_random_1_15 ? this.txt_random_1_15 : 0),
        txt_random_2_1: parseInt(this.txt_random_2_1 ? this.txt_random_2_1 : 0),
        txt_random_2_2: parseInt(this.txt_random_2_2 ? this.txt_random_2_2 : 0),
        txt_random_2_3: parseInt(this.txt_random_2_3 ? this.txt_random_2_3 : 0),
        txt_random_2_4: parseInt(this.txt_random_2_4 ? this.txt_random_2_4 : 0),
        txt_random_2_5: parseInt(this.txt_random_2_5 ? this.txt_random_2_5 : 0),
        txt_random_2_6: parseInt(this.txt_random_2_6 ? this.txt_random_2_6 : 0),
        txt_random_2_7: parseInt(this.txt_random_2_7 ? this.txt_random_2_7 : 0),
        txt_random_2_8: parseInt(this.txt_random_2_8 ? this.txt_random_2_8 : 0),
        txt_random_2_9: parseInt(this.txt_random_2_9 ? this.txt_random_2_9 : 0),
        txt_random_2_10: parseInt(this.txt_random_2_10 ? this.txt_random_2_10 : 0),
        txt_random_2_11: parseInt(this.txt_random_2_11 ? this.txt_random_2_11 : 0),
        txt_random_2_12: parseInt(this.txt_random_2_12 ? this.txt_random_2_12 : 0),
        txt_random_2_13: parseInt(this.txt_random_2_13 ? this.txt_random_2_13 : 0),
        txt_random_2_14: parseInt(this.txt_random_2_14 ? this.txt_random_2_14 : 0),
        txt_random_2_15: parseInt(this.txt_random_2_15 ? this.txt_random_2_15 : 0),
        txt_random_3_1: parseInt(this.txt_random_3_1 ? this.txt_random_3_1 : 0),
        txt_random_3_2: parseInt(this.txt_random_3_2 ? this.txt_random_3_2 : 0),
        txt_random_3_3: parseInt(this.txt_random_3_3 ? this.txt_random_3_3 : 0),
        txt_random_3_4: parseInt(this.txt_random_3_4 ? this.txt_random_3_4 : 0),
        txt_random_3_5: parseInt(this.txt_random_3_5 ? this.txt_random_3_5 : 0),
        txt_random_3_6: parseInt(this.txt_random_3_6 ? this.txt_random_3_6 : 0),
        txt_random_3_7: parseInt(this.txt_random_3_7 ? this.txt_random_3_7 : 0),
        txt_random_3_8: parseInt(this.txt_random_3_8 ? this.txt_random_3_8 : 0),
        txt_random_3_9: parseInt(this.txt_random_3_9 ? this.txt_random_3_9 : 0),
        txt_random_3_10: parseInt(this.txt_random_3_10 ? this.txt_random_3_10 : 0),
        txt_random_3_11: parseInt(this.txt_random_3_11 ? this.txt_random_3_11 : 0),
        txt_random_3_12: parseInt(this.txt_random_3_12 ? this.txt_random_3_12 : 0),
        txt_random_3_13: parseInt(this.txt_random_3_13 ? this.txt_random_3_13 : 0),
        txt_random_3_14: parseInt(this.txt_random_3_14 ? this.txt_random_3_14 : 0),
        txt_random_3_15: parseInt(this.txt_random_3_15 ? this.txt_random_3_15 : 0),
        txt_random_4_1: parseInt(this.txt_random_4_1 ? this.txt_random_4_1 : 0),
        txt_random_4_2: parseInt(this.txt_random_4_2 ? this.txt_random_4_2 : 0),
        txt_random_4_3: parseInt(this.txt_random_4_3 ? this.txt_random_4_3 : 0),
        txt_random_4_4: parseInt(this.txt_random_4_4 ? this.txt_random_4_4 : 0),
        txt_random_4_5: parseInt(this.txt_random_4_5 ? this.txt_random_4_5 : 0),
        txt_random_4_6: parseInt(this.txt_random_4_6 ? this.txt_random_4_6 : 0),
        txt_random_4_7: parseInt(this.txt_random_4_7 ? this.txt_random_4_7 : 0),
        txt_random_4_8: parseInt(this.txt_random_4_8 ? this.txt_random_4_8 : 0),
        txt_random_4_9: parseInt(this.txt_random_4_9 ? this.txt_random_4_9 : 0),
        txt_random_4_10: parseInt(this.txt_random_4_10 ? this.txt_random_4_10 : 0),
        txt_random_4_11: parseInt(this.txt_random_4_11 ? this.txt_random_4_11 : 0),
        txt_random_4_12: parseInt(this.txt_random_4_12 ? this.txt_random_4_12 : 0),
        txt_random_4_13: parseInt(this.txt_random_4_13 ? this.txt_random_4_13 : 0),
        txt_random_4_14: parseInt(this.txt_random_4_14 ? this.txt_random_4_14 : 0),
        txt_random_4_15: parseInt(this.txt_random_4_15 ? this.txt_random_4_15 : 0),
        txt_random_5_1: parseInt(this.txt_random_5_1 ? this.txt_random_5_1 : 0),
        txt_random_5_2: parseInt(this.txt_random_5_2 ? this.txt_random_5_2 : 0),
        txt_random_5_3: parseInt(this.txt_random_5_3 ? this.txt_random_5_3 : 0),
        txt_random_5_4: parseInt(this.txt_random_5_4 ? this.txt_random_5_4 : 0),
        txt_random_5_5: parseInt(this.txt_random_5_5 ? this.txt_random_5_5 : 0),
        txt_random_5_6: parseInt(this.txt_random_5_6 ? this.txt_random_5_6 : 0),
        txt_random_5_7: parseInt(this.txt_random_5_7 ? this.txt_random_5_7 : 0),
        txt_random_5_8: parseInt(this.txt_random_5_8 ? this.txt_random_5_8 : 0),
        txt_random_5_9: parseInt(this.txt_random_5_9 ? this.txt_random_5_9 : 0),
        txt_random_5_10: parseInt(this.txt_random_5_10 ? this.txt_random_5_10 : 0),
        txt_random_5_11: parseInt(this.txt_random_5_11 ? this.txt_random_5_11 : 0),
        txt_random_5_12: parseInt(this.txt_random_5_12 ? this.txt_random_5_12 : 0),
        txt_random_5_13: parseInt(this.txt_random_5_13 ? this.txt_random_5_13 : 0),
        txt_random_5_14: parseInt(this.txt_random_5_14 ? this.txt_random_5_14 : 0),
        txt_random_5_15: parseInt(this.txt_random_5_15 ? this.txt_random_5_15 : 0),
        txt_random_6_1: parseInt(this.txt_random_6_1 ? this.txt_random_6_1 : 0),
        txt_random_6_2: parseInt(this.txt_random_6_2 ? this.txt_random_6_2 : 0),
        txt_random_6_3: parseInt(this.txt_random_6_3 ? this.txt_random_6_3 : 0),
        txt_random_6_4: parseInt(this.txt_random_6_4 ? this.txt_random_6_4 : 0),
        txt_random_6_5: parseInt(this.txt_random_6_5 ? this.txt_random_6_5 : 0),
        txt_random_6_6: parseInt(this.txt_random_6_6 ? this.txt_random_6_6 : 0),
        txt_random_6_7: parseInt(this.txt_random_6_7 ? this.txt_random_6_7 : 0),
        txt_random_6_8: parseInt(this.txt_random_6_8 ? this.txt_random_6_8 : 0),
        txt_random_6_9: parseInt(this.txt_random_6_9 ? this.txt_random_6_9 : 0),
        txt_random_6_10: parseInt(this.txt_random_6_10 ? this.txt_random_6_10 : 0),
        txt_random_6_11: parseInt(this.txt_random_6_11 ? this.txt_random_6_11 : 0),
        txt_random_6_12: parseInt(this.txt_random_6_12 ? this.txt_random_6_12 : 0),
        txt_random_6_13: parseInt(this.txt_random_6_13 ? this.txt_random_6_13 : 0),
        txt_random_6_14: parseInt(this.txt_random_6_14 ? this.txt_random_6_14 : 0),
        txt_random_6_15: parseInt(this.txt_random_6_15 ? this.txt_random_6_15 : 0),
        txt_random_7_1: parseInt(this.txt_random_7_1 ? this.txt_random_7_1 : 0),
        txt_random_7_2: parseInt(this.txt_random_7_2 ? this.txt_random_7_2 : 0),
        txt_random_7_3: parseInt(this.txt_random_7_3 ? this.txt_random_7_3 : 0),
        txt_random_7_4: parseInt(this.txt_random_7_4 ? this.txt_random_7_4 : 0),
        txt_random_7_5: parseInt(this.txt_random_7_5 ? this.txt_random_7_5 : 0),
        txt_random_7_6: parseInt(this.txt_random_7_6 ? this.txt_random_7_6 : 0),
        txt_random_7_7: parseInt(this.txt_random_7_7 ? this.txt_random_7_7 : 0),
        txt_random_7_8: parseInt(this.txt_random_7_8 ? this.txt_random_7_8 : 0),
        txt_random_7_9: parseInt(this.txt_random_7_9 ? this.txt_random_7_9 : 0),
        txt_random_7_10: parseInt(this.txt_random_7_10 ? this.txt_random_7_10 : 0),
        txt_random_7_11: parseInt(this.txt_random_7_11 ? this.txt_random_7_11 : 0),
        txt_random_7_12: parseInt(this.txt_random_7_12 ? this.txt_random_7_12 : 0),
        txt_random_7_13: parseInt(this.txt_random_7_13 ? this.txt_random_7_13 : 0),
        txt_random_7_14: parseInt(this.txt_random_7_14 ? this.txt_random_7_14 : 0),
        txt_random_7_15: parseInt(this.txt_random_7_15 ? this.txt_random_7_15 : 0),
        txt_random_8_1: parseInt(this.txt_random_8_1 ? this.txt_random_8_1 : 0),
        txt_random_8_2: parseInt(this.txt_random_8_2 ? this.txt_random_8_2 : 0),
        txt_random_8_3: parseInt(this.txt_random_8_3 ? this.txt_random_8_3 : 0),
        txt_random_8_4: parseInt(this.txt_random_8_4 ? this.txt_random_8_4 : 0),
        txt_random_8_5: parseInt(this.txt_random_8_5 ? this.txt_random_8_5 : 0),
        txt_random_8_6: parseInt(this.txt_random_8_6 ? this.txt_random_8_6 : 0),
        txt_random_8_7: parseInt(this.txt_random_8_7 ? this.txt_random_8_7 : 0),
        txt_random_8_8: parseInt(this.txt_random_8_8 ? this.txt_random_8_8 : 0),
        txt_random_8_9: parseInt(this.txt_random_8_9 ? this.txt_random_8_9 : 0),
        txt_random_8_10: parseInt(this.txt_random_8_10 ? this.txt_random_8_10 : 0),
        txt_random_8_11: parseInt(this.txt_random_8_11 ? this.txt_random_8_11 : 0),
        txt_random_8_12: parseInt(this.txt_random_8_12 ? this.txt_random_8_12 : 0),
        txt_random_8_13: parseInt(this.txt_random_8_13 ? this.txt_random_8_13 : 0),
        txt_random_8_14: parseInt(this.txt_random_8_14 ? this.txt_random_8_14 : 0),
        txt_random_8_15: parseInt(this.txt_random_8_15 ? this.txt_random_8_15 : 0),
        txt_random_9_1: parseInt(this.txt_random_9_1 ? this.txt_random_9_1 : 0),
        txt_random_9_2: parseInt(this.txt_random_9_2 ? this.txt_random_9_2 : 0),
        txt_random_9_3: parseInt(this.txt_random_9_3 ? this.txt_random_9_3 : 0),
        txt_random_9_4: parseInt(this.txt_random_9_4 ? this.txt_random_9_4 : 0),
        txt_random_9_5: parseInt(this.txt_random_9_5 ? this.txt_random_9_5 : 0),
        txt_random_9_6: parseInt(this.txt_random_9_6 ? this.txt_random_9_6 : 0),
        txt_random_9_7: parseInt(this.txt_random_9_7 ? this.txt_random_9_7 : 0),
        txt_random_9_8: parseInt(this.txt_random_9_8 ? this.txt_random_9_8 : 0),
        txt_random_9_9: parseInt(this.txt_random_9_9 ? this.txt_random_9_9 : 0),
        txt_random_9_10: parseInt(this.txt_random_9_10 ? this.txt_random_9_10 : 0),
        txt_random_9_11: parseInt(this.txt_random_9_11 ? this.txt_random_9_11 : 0),
        txt_random_9_12: parseInt(this.txt_random_9_12 ? this.txt_random_9_12 : 0),
        txt_random_9_13: parseInt(this.txt_random_9_13 ? this.txt_random_9_13 : 0),
        txt_random_9_14: parseInt(this.txt_random_9_14 ? this.txt_random_9_14 : 0),
        txt_random_9_15: parseInt(this.txt_random_9_15 ? this.txt_random_9_15 : 0),
        txt_random_10_1: parseInt(this.txt_random_10_1 ? this.txt_random_10_1 : 0),
        txt_random_10_2: parseInt(this.txt_random_10_2 ? this.txt_random_10_2 : 0),
        txt_random_10_3: parseInt(this.txt_random_10_3 ? this.txt_random_10_3 : 0),
        txt_random_10_4: parseInt(this.txt_random_10_4 ? this.txt_random_10_4 : 0),
        txt_random_10_5: parseInt(this.txt_random_10_5 ? this.txt_random_10_5 : 0),
        txt_random_10_6: parseInt(this.txt_random_10_6 ? this.txt_random_10_6 : 0),
        txt_random_10_7: parseInt(this.txt_random_10_7 ? this.txt_random_10_7 : 0),
        txt_random_10_8: parseInt(this.txt_random_10_8 ? this.txt_random_10_8 : 0),
        txt_random_10_9: parseInt(this.txt_random_10_9 ? this.txt_random_10_9 : 0),
        txt_random_10_10: parseInt(this.txt_random_10_10 ? this.txt_random_10_10 : 0),
        txt_random_10_11: parseInt(this.txt_random_10_11 ? this.txt_random_10_11 : 0),
        txt_random_10_12: parseInt(this.txt_random_10_12 ? this.txt_random_10_12 : 0),
        txt_random_10_13: parseInt(this.txt_random_10_13 ? this.txt_random_10_13 : 0),
        txt_random_10_14: parseInt(this.txt_random_10_14 ? this.txt_random_10_14 : 0),
        txt_random_10_15: parseInt(this.txt_random_10_15 ? this.txt_random_10_15 : 0),
        rank_1: parseInt(this.rank_1 ? this.rank_1 : 1),
        rank_2: parseInt(this.rank_2 ? this.rank_2 : 2),
        rank_3: parseInt(this.rank_3 ? this.rank_3 : 3),
        rank_4: parseInt(this.rank_4 ? this.rank_4 : 4),
        rank_5: parseInt(this.rank_5 ? this.rank_5 : 5),
        rank_6: parseInt(this.rank_6 ? this.rank_6 : 6),
        rank_7: parseInt(this.rank_7 ? this.rank_7 : 7),
        rank_8: parseInt(this.rank_8 ? this.rank_8 : 8),
        rank_9: parseInt(this.rank_9 ? this.rank_9 : 9),
        rank_10: parseInt(this.rank_10 ? this.rank_10 : 10),
        txt_1_10star: parseInt(this.txt_1_10star ? this.txt_1_10star : 0),
        txt_2_10star: parseInt(this.txt_2_10star ? this.txt_2_10star : 0),
        txt_3_10star: parseInt(this.txt_3_10star ? this.txt_3_10star : 0),
        txt_4_10star: parseInt(this.txt_4_10star ? this.txt_4_10star : 0),
        txt_5_10star: parseInt(this.txt_5_10star ? this.txt_5_10star : 0),
        txt_6_10star: parseInt(this.txt_6_10star ? this.txt_6_10star : 0),
        txt_7_10star: parseInt(this.txt_7_10star ? this.txt_7_10star : 0),
        txt_8_10star: parseInt(this.txt_8_10star ? this.txt_8_10star : 0),
        txt_9_10star: parseInt(this.txt_9_10star ? this.txt_9_10star : 0),
        txt_10_10star: parseInt(this.txt_10_10star ? this.txt_10_10star : 0),
        stop_win: parseInt(this.stop_win ? this.stop_win : 1000),
        stop_loss: parseInt(this.stop_loss ? this.stop_loss : -800),
      }, this.userId)
        .then((response) => {
          console.log(response);
          this.repsMessage = response.data.message;
          const errorCode = response.data.error_code;
          console.log(this.repsMessage);
          console.log(errorCode);

          this.isShowProgress = false;

          if (errorCode === "") {
            this.alertSuccess = true;
            setTimeout(() => {
              this.$router.push("/robot-agent-overview");
            }, this.timeout);

          } else {
            this.alertError = true;
          }
        })
        .catch(function (error) {
          // 請求失敗處理
          console.log(error);

        });
    },
  },

};
</script>
<style scoped>
/* 使進度條在螢幕上居中，並且至於最頂層 */
.progress-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.disclaimer-overlay {
  position: fixed;
  top: 90%;
  left: 11%;
  z-index: 2000;
}

/* 視訊賽車 B */
.custom-radio-lf {
  display: flex;
  align-items: center;
}

::v-deep .custom-radio-lf input[type="radio"] {
  display: none;
  /* 隐藏默认的radio按钮 */
}

.custom-radio-lf-icon {
  width: 60px;
  height: 60px;
  background: url('../../../assets/img/common/wg/lucky-flyboat-off-string.png') top center no-repeat;
  background-size: cover;
}

::v-deep .custom-radio-lf input[type="radio"]:checked+.custom-radio-lf-icon {
  background-color: #FFFFFF;
}

.custom-radio-lf-icon::after {
  content: "";
  width: 60px;
  height: 60px;
  background: url('../../../assets/img/common/wg/lucky-flyboat-on-string.png') top center no-repeat;
  background-size: cover;
  display: none;
}

::v-deep .custom-radio-lf input[type="radio"]:checked+.custom-radio-lf-icon::after {
  display: block;
}

/* 一分賽車 */
.custom-radio-1 {
  display: flex;
  align-items: center;
}

::v-deep .custom-radio-1 input[type="radio"] {
  display: none;
  /* 隐藏默认的radio按钮 */
}

.custom-radio-1-icon {
  width: 60px;
  height: 60px;
  background: url('../../../assets/img/common/wg/1-off-string.png') top center no-repeat;
  background-size: cover;
}

::v-deep .custom-radio-1 input[type="radio"]:checked+.custom-radio-1-icon {
  background-color: #FFFFFF;
}

.custom-radio-1-icon::after {
  content: "";
  width: 60px;
  height: 60px;
  background: url('../../../assets/img/common/wg/1-on-string.png') top center no-repeat;
  background-size: cover;
  display: none;
}

::v-deep .custom-radio-1 input[type="radio"]:checked+.custom-radio-1-icon::after {
  display: block;
}


/* 三分賽車 */
.custom-radio-3 {
  display: flex;
  align-items: center;
}

::v-deep .custom-radio-3 input[type="radio"] {
  display: none;
  /* 隐藏默认的radio按钮 */
}

.custom-radio-3-icon {
  width: 60px;
  height: 60px;
  background: url('../../../assets/img/common/wg/3-off-string.png') top center no-repeat;
  background-size: cover;
}

::v-deep .custom-radio-3 input[type="radio"]:checked+.custom-radio-3-icon {
  background-color: #FFFFFF;
}

.custom-radio-3-icon::after {
  content: "";
  width: 60px;
  height: 60px;
  background: url('../../../assets/img/common/wg/3-on-string.png') top center no-repeat;
  background-size: cover;
  display: none;
}

::v-deep .custom-radio-3 input[type="radio"]:checked+.custom-radio-3-icon::after {
  display: block;
}

/* 五分賽車 */
.custom-radio-5 {
  display: flex;
  align-items: center;
}

::v-deep .custom-radio-5 input[type="radio"] {
  display: none;
  /* 隐藏默认的radio按钮 */
}

.custom-radio-5-icon {
  width: 60px;
  height: 60px;
  background: url('../../../assets/img/common/wg/5-off-string.png') top center no-repeat;
  background-size: cover;
}

::v-deep .custom-radio-5 input[type="radio"]:checked+.custom-radio-5-icon {
  background-color: #FFFFFF;
}

.custom-radio-5-icon::after {
  content: "";
  width: 60px;
  height: 60px;
  background: url('../../../assets/img/common/wg/5-on-string.png') top center no-repeat;
  background-size: cover;
  display: none;
}

::v-deep .custom-radio-5 input[type="radio"]:checked+.custom-radio-5-icon::after {
  display: block;
}

/* 基本兩面盤 */
.custom-radio-basic {
  display: flex;
  align-items: center;
}

::v-deep .custom-radio-basic input[type="radio"] {
  display: none;
  /* 隐藏默认的radio按钮 */
}

.custom-radio-basic-icon {
  width: 120px;
  height: 60px;
  background: url('../../../assets/img/common/wg/basic-off.png') top center no-repeat;
  background-size: cover;
}

::v-deep .custom-radio-basic input[type="radio"]:checked+.custom-radio-basic-icon {
  background-color: #FFFFFF;
}

.custom-radio-basic-icon::after {
  content: "";
  width: 120px;
  height: 60px;
  background: url('../../../assets/img/common/wg/basic-on.png') top center no-repeat;
  background-size: cover;
  display: none;
}

::v-deep .custom-radio-basic input[type="radio"]:checked+.custom-radio-basic-icon::after {
  display: block;
}

/* 10星下注法 */
.custom-radio-10star {
  display: flex;
  align-items: center;
}

::v-deep .custom-radio-10star input[type="radio"] {
  display: none;
  /* 隐藏默认的radio按钮 */
}

.custom-radio-10star-icon {
  width: 120px;
  height: 60px;
  background: url('../../../assets/img/common/wg/10star-off.png') top center no-repeat;
  background-size: cover;
}

::v-deep .custom-radio-10star input[type="radio"]:checked+.custom-radio-10star-icon {
  background-color: #FFFFFF;
}

.custom-radio-10star-icon::after {
  content: "";
  width: 120px;
  height: 60px;
  background: url('../../../assets/img/common/wg/10star-on.png') top center no-repeat;
  background-size: cover;
  display: none;
}

::v-deep .custom-radio-10star input[type="radio"]:checked+.custom-radio-10star-icon::after {
  display: block;
}

/* 跟隨下注法 */
.custom-radio-follow {
  display: flex;
  align-items: center;
}

::v-deep .custom-radio-follow input[type="radio"] {
  display: none;
  /* 隐藏默认的radio按钮 */
}

.custom-radio-follow-icon {
  width: 120px;
  height: 60px;
  background: url('../../../assets/img/common/wg/dev.png') top center no-repeat;
  background-size: cover;
}

::v-deep .custom-radio-follow input[type="radio"]:checked+.custom-radio-follow-icon {
  background-color: #FFFFFF;
}

.custom-radio-follow-icon::after {
  content: "";
  width: 120px;
  height: 60px;
  background: url('../../../assets/img/common/wg/dev.png') top center no-repeat;
  background-size: cover;
  display: none;
}

::v-deep .custom-radio-follow input[type="radio"]:checked+.custom-radio-follow-icon::after {
  display: block;
}

/* 開發中 */
.custom-radio-dev {
  display: flex;
  align-items: center;
}

::v-deep .custom-radio-dev input[type="radio"] {
  display: none;
  /* 隐藏默认的radio按钮 */
}

.custom-radio-dev-icon {
  width: 120px;
  height: 60px;
  background: url('../../../assets/img/common/wg/dev.png') top center no-repeat;
  background-size: cover;
}

::v-deep .custom-radio-dev input[type="radio"]:checked+.custom-radio-dev-icon {
  background-color: #FFFFFF;
}

.custom-radio-dev-icon::after {
  content: "";
  width: 120px;
  height: 60px;
  background: url('../../../assets/img/common/wg/dev.png') top center no-repeat;
  background-size: cover;
  display: none;
}

::v-deep .custom-radio-dev input[type="radio"]:checked+.custom-radio-dev-icon::after {
  display: block;
}
</style>